import multimethod from '../lib/multimethod';
import Cookies from 'universal-cookie';

function cookieOptions({ domain, secure = false }) {
  return {
    domain,
    path: '/',
    secure,
  };
}

function checkDomain(domain) {
  if (domain && !window.location.hostname.endsWith(domain)) {
    throw new Error('The authorization domain for this system is misconfigured.');
  }
}

/* Legacy Auth */

const legacyTokenName = 'x-lg-session';

export function setLegacySessionToken(token) {
  checkDomain(token.domain);
  const cookies = new Cookies();
  cookies.set(legacyTokenName, token.value, cookieOptions({ domain: token.domain }));
}

function getLegacySessionToken() {
  const cookies = new Cookies();
  return cookies.get(legacyTokenName);
}

function deleteLegacySessionToken() {
  const cookies = new Cookies();
  cookies.remove(legacyTokenName);
}

/* Multimethods (Public) */

export const tokenName = multimethod('type', () => legacyTokenName);

export const getSessionToken = multimethod('type', getLegacySessionToken);

export const deleteSessionToken = multimethod('type', deleteLegacySessionToken);

/* Oauth2 */

const lgAuthCookie = 'x-lg-authorization';
const lgIdCookie = 'x-lg-id';

tokenName.method.oauth2 = () => {
  return lgAuthCookie;
};

// this is oauth2 specific right now and thus isn't in a multimethod
export function getIdToken() {
  const cookies = new Cookies();
  return cookies.get(lgIdCookie);
}

// This is no longer used in scoutPRIME UI because of now getting tokens directly
// from Okta's oktaAuth object, which is setup in Okta/index.js and accessed
// via withSessionToken.js or tokenThunk (see determineTokenAndAuth()).
getSessionToken.method.oauth2 = () => {
  const cookies = new Cookies();
  return cookies.get(lgAuthCookie);
};

deleteSessionToken.method.oauth2 = token => {
  const cookies = new Cookies();
  cookies.remove(lgAuthCookie, cookieOptions({ domain: token.domain }));
  cookies.remove(lgIdCookie, cookieOptions({ domain: token.domain }));
};

/**
 * The auth token is set as a cookie so that it can be shared with other LG products
 * (including other SP instances). This is what facilitates single sign-on (SSO).
 *
 * @param authToken
 * @param domain
 */
export function setOauth2AuthToken(authToken, { domain, secure }) {
  checkDomain(domain);
  const cookies = new Cookies();
  cookies.set(lgAuthCookie, authToken, cookieOptions({ domain, secure }));
}

/**
 * The id token is set as a cookie so that it can be shared with other LG products if
 * they don't know about the user yet.
 *
 * @param idToken
 * @param domain
 */
export function setOauth2IdToken(idToken, { domain, secure }) {
  const cookies = new Cookies();
  cookies.set(lgIdCookie, idToken, cookieOptions({ domain, secure }));
}

export function canConvertToken() {
  const cookies = new Cookies();
  return Boolean(cookies.get(lgAuthCookie));
}

/**
 * The okta react widget does not provide the id nonce, but it's necessary to actually
 * verify the id token on the server. It's available via a cookie that the Okta react
 * widget sets.
 *
 * This is an implementation detail that's a bit fragile.
 */
export function oktaIdNonce() {
  const cookies = new Cookies();
  return cookies.get('okta-oauth-nonce');
}
