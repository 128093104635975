export default theme => ({
  container: {
    display: 'inline-flex',
    width: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.typography.body,
    color: theme.colors.darkestGray,
    fontWeight: 500,
  },
  label: {
    marginRight: 5,
  },
  separator: {
    marginLeft: 5,
    marginRight: 5,
  },
  range: {
    display: 'inline-flex',
  },
});
