import multimethod from '../../../../../lib/multimethod';
import { actionError, actionStart, actionSuccess, apiData } from '../../../../../redux/common';
import actions from './actions';
import assign from 'lodash/fp/assign';
import { compact, get, isEmpty } from 'lodash';

const productInformation = multimethod('type', (action, state) => state);

productInformation.method[actionStart(actions.FETCH_ProductInformation)] = apiData.fetchStarted;

productInformation.method[actionSuccess(actions.FETCH_ProductInformation)] = (action, state) => {
  return assign(state, {
    data: compact(
      get(action, 'payload.results', []).map(result => {
        const cpes = get(result, 'right.cpes', []);
        const infVulns = get(result, 'right.inferredVulnerabilityIds', []);
        if (isEmpty(cpes)) {
          return null;
        }
        return {
          cpes,
          infVulns,
        };
      })
    ),
    isLoading: false,
    isRequesting: false,
  });
};

productInformation.method[actionError(actions.FETCH_ProductInformation)] = apiData.fetchErrored;

export default (state = apiData.initialState, action) => productInformation(action, state);
