import multimethod from '../../../../../lib/multimethod';
import actions from './actions';
import assign from 'lodash/fp/assign';
import { actionError, actionStart, actionSuccess, apiData } from '../../../../../redux/common';

const threatDescription = multimethod('type', (action, state) => state);

threatDescription.method[actions.CANCEL_EDIT_THREAT_DESCRIPTION] = (action, state) => {
  return assign(state, {
    error: null,
    isEditing: false,
  });
};

threatDescription.method[actions.EDIT_THREAT_DESCRIPTION] = (action, state) => {
  return assign(state, {
    isEditing: true,
  });
};

threatDescription.method[actionError(actions.UPDATE_THREAT_DESCRIPTION)] = (action, state) => {
  return assign(state, {
    error: action.payload,
    isUpdating: false,
  });
};

threatDescription.method[actionStart(actions.UPDATE_THREAT_DESCRIPTION)] = (action, state) => {
  return assign(state, {
    error: null,
    isUpdating: true,
  });
};

threatDescription.method[actionSuccess(actions.UPDATE_THREAT_DESCRIPTION)] = (action, state) => {
  return assign(state, {
    isEditing: false,
    isUpdating: false,
  });
};

export default (state = apiData.initialState, action) => threatDescription(action, state);
