/**
 * AbstractButton
 * Component that is used as an underlying component for buttons.
 * This component should likely not be used in other ways than how it is used in:
 * PrimaryButton, SecondaryButton, SecondaryModalButton, etc.
 */

import React from 'react';

// Configuration
import PropTypes from 'prop-types';

// Styles
import cx from 'classnames';
import injectStyles from 'react-jss';

// Utils
import { renderLocalized } from '../../../utils/localization';

export const abstractPropTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onClick: PropTypes.func,
  processing: PropTypes.bool,
  text: PropTypes.string,
  textId: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
};

class AbstractButton extends React.Component {
  static propTypes = abstractPropTypes;

  static defaultProps = {
    disabled: false,
    processing: false,
  };

  render() {
    const props = this.props;
    const {
      children,
      classes,
      className,
      'data-test': dataTest,
      disabled,
      id,
      onClick,
      processing,
      type,
    } = props;
    let { text, textId } = props;
    if (processing) {
      text = 'Processing...';
      textId = 'button-processing';
    }
    return (
      <button
        className={cx(classes.button, className)}
        data-test={dataTest}
        disabled={disabled || processing}
        id={id}
        onClick={onClick}
        type={type}>
        {renderLocalized(children, text, textId)}
      </button>
    );
  }
}

const styles = theme => ({
  button: {
    ...theme.buttons,
    borderRadius: theme.borderRadius.default,
    transition: 'all 0.2s ease-in-out',
    border: {
      style: 'solid',
      width: 1,
    },
    cursor: 'pointer',
    padding: {
      left: 15,
      right: 15,
    },
    '& span': {
      pointerEvents: 'none',
    },
    '&:focus': theme.focused,
    '&:disabled': {
      color: theme.colors.buttonDisabledText,
      cursor: 'default',
    },
  },
});

export default injectStyles(styles)(AbstractButton);
