export const ENVIRONMENTS = [
  {
    url: 'https://delta.lookingglasscyber.com',
    trackingId: 'G-8CRPZMEX00',
  },
  {
    url: 'https://ea2.lookingglasscyber.com',
    trackingId: 'G-D4EZ9VNMM9',
  },
  {
    url: 'https://octavia.lookingglasscyber.com',
    trackingId: 'G-KE9NFK8834',
  },
  {
    url: 'https://sp-analyst.lookingglasscyber.com',
    trackingId: 'G-GCRT948DHX',
  },
  {
    url: 'https://uat-sp.lookingglasscyber.com',
    trackingId: 'G-T7R85LYFQD',
  },
  {
    url: 'https://tibet.lookingglasscyber.com',
    trackingId: 'G-6V10YRSV7X',
  },
  {
    url: 'https://pov-sp.lookingglasscyber.com',
    trackingId: 'G-WYD153MW6Z',
  },
  {
    url: 'https://viking-csg.lookingglasscyber.com',
    trackingId: 'G-XH107VQ3MF',
  },
  {
    url: 'https://alphabravo.sp.lookingglasscyber.com',
    trackingId: 'G-L49MDMHTDY',
  },
  {
    url: 'https://foxtrot.lookingglasscyber.com',
    trackingId: 'G-TRJVJ68YEM',
  },
];

export const PAGEVIEW = 'pageview';
