import React from 'react';
import cx from 'classnames';
import injectStyles from 'react-jss';
import { injectIntl } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import FormattedLabel from '../../FormattedLabel';
import { goBack } from '../../../../utils/routing';

class BackLink extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    to: PropTypes.string,
  };

  backTo = () => {
    const { history, location } = this.props;
    goBack(location, history);
  };

  textContent = () => {
    const { classes } = this.props;
    return (
      <span className={classes.back}>
        <i className={cx('fa fa-chevron-left', classes.icon)} />
        <FormattedLabel defaultMessage="Back" messageId="app-back-button" />
      </span>
    );
  };

  render() {
    const { classes, className, to } = this.props;
    const text = this.textContent();

    if (to) {
      return (
        <Link to={to} className={cx(classes.header__name, className)}>
          {text}
        </Link>
      );
    }

    return (
      <div onClick={this.backTo} className={cx(classes.header__name, className)}>
        {text}
      </div>
    );
  }
}

const styles = theme => ({
  back: {
    alignItems: 'baseline',
    cursor: 'pointer',
    display: 'inline-flex',
  },
  header__name: {
    color: theme.colors.link,
    display: 'inline-flex',
    textDecoration: 'none',
  },
  icon: {
    marginRight: 5,
  },
});

export default withRouter(injectIntl(injectStyles(styles)(BackLink)));
