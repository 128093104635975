export function permissionToId(userId, action, resourceType, resourceId) {
  // this preserves the legacy id type
  const subjectId = userId;
  const subjectType = 'user';
  return btoa(`${action}${resourceId}${resourceType}${subjectId}${subjectType}`);
}

export function hasAdminPermission(permissions) {
  let isAdmin = false;
  for (const key in permissions) {
    if (permissions.hasOwnProperty(key)) {
      try {
        if (atob(key).includes('manage-permissions') && !isAdmin) {
          isAdmin = permissions[key].isPermitted;
        }
      } catch (e) {
        //Do nothing
      }
    }
  }
  return isAdmin;
}

function shorthandToPermission([action, resourceType, resourceId]) {
  return { action, 'resource-type': resourceType, 'resource-id': resourceId || null };
}

export function baseWorkspacePermissions(userId, organizationId, workspaceId) {
  return [
    ['api-access-tokens', 'system'],
    ['create-source', 'system'],
    ['create-threat', 'system'],
    ['create-threat-association', 'system'],
    ['create-workspace', 'system'],
    ['dataloads', 'system'],
    ['delete', 'workspace', workspaceId],
    ['manage-collections', 'workspace', workspaceId],
    ['manage-folders', 'workspace', workspaceId],
    ['manage-incidents', 'workspace', workspaceId],
    ['manage-labels', 'workspace', workspaceId],
    ['manage-permissions', 'org', organizationId],
    ['manage-permissions', 'user', userId],
    ['manage-notes', 'workspace', workspaceId],
    ['manage-saved-searches', 'workspace', workspaceId],
    ['tic-edit', 'workspace', workspaceId],
    ['update', 'workspace', workspaceId],
  ].map(shorthandToPermission);
}
