import multimethod from '../../../lib/multimethod';
import { actionError, actionStart, actionSuccess, apiData } from '../../../redux/common';
import actions from './actions';
import { combineReducers } from 'redux';
import assign from 'lodash/fp/assign';
import set from 'lodash/fp/set';
import update from 'lodash/fp/update';
import dnsHistory from './sections/DnsHistorySection/reducer';
import enumeration from './sections/EnumerationSection/reducer';
import fileThreats from './sections/FileThreatsSection/reducer';
import ownedElements from './sections/OwnedElementsSection/reducer';
import ownership from './sections/OwnershipSection/reducer';
import productInformation from './sections/ProductInformationSection/reducer';
import threatDescription from './sections/ThreatDescriptionSection/reducer';
import threatProperties from './sections/ThreatPropertiesSection/reducer';
import whois from './sections/WhoisSection/reducer';
import { APIError } from '../../../utils/errors';
import { astBaseTypes } from '../../../utils/elements';

const synthesizableTypes = astBaseTypes;

function isSynthesizable(elementType) {
  return synthesizableTypes.includes(elementType);
}

function normalizeElement(element) {
  if (element.affectedCollections) {
    element.collections = element.affectedCollections;
    delete element.affectedCollections;
  }
  return element;
}

const element = multimethod('type', (action, state) => state);

element.method[actionStart(actions.FETCH_ELEMENT)] = (action, state) => {
  let s = apiData.fetchStarted(action, state);
  if (!action.merge) {
    s = set('data', { ref: { type: action.elementType } }, s);
  }
  return s;
};

element.method[actionSuccess(actions.FETCH_ELEMENT)] = (action, state) => {
  const element = action.payload;
  if (element.found === false && !isSynthesizable(element.ref.type)) {
    throw new APIError(404, 'The element was not found.');
  }
  let newData = normalizeElement(element);
  if (action.merge) {
    newData = assign(state.data, newData);
  }
  return assign(state, {
    data: newData,
    isLoading: false,
    isRequesting: false,
  });
};

element.method[actionError(actions.FETCH_ELEMENT)] = apiData.fetchErrored;

element.method[actionSuccess(actions.UPDATE_THREAT_DESCRIPTION)] = (action, state) => {
  return update('data', element => set('description', action.description, element), state);
};

element.method[actions.CLEAR_ELEMENT] = (actions, state) => {
  return update('data', element => null, state);
};

export default combineReducers({
  dnsHistory,
  element: (state = apiData.initialState, action) => element(action, state),
  enumeration,
  fileThreats,
  ownedElements,
  ownership,
  productInformation,
  threatDescription,
  threatProperties,
  whois,
});
