import React from 'react';

// Config
import PropTypes from 'prop-types';

// Styles
import injectStyles from 'react-jss';
import cx from 'classnames';
import styles from './styles';

// Components
import { default as SpinKit } from 'react-spinkit';
import { injectIntl } from 'react-intl';

class LoadingIndicator extends React.Component {
  static propTypes = {
    fadeIn: PropTypes.string,
    full: PropTypes.bool,
    showing: PropTypes.bool,
    text: PropTypes.string,
    textId: PropTypes.string,
    textClassName: PropTypes.string,
  };

  static defaultProps = {
    name: 'ball-beat',
    showing: true,
  };

  text = () => {
    const { intl, text, textId } = this.props;
    if (textId) {
      return intl.formatMessage({
        defaultMessage: text,
        id: textId,
      });
    }
    return text;
  };

  renderText = () => {
    const { classes, textClassName } = this.props;
    return <h1 className={cx(classes.text, textClassName)}>{this.text()}</h1>;
  };

  render() {
    const { classes, className, children, fadeIn, full, id, name, showing, text } = this.props;
    return (
      <div
        id={id}
        className={cx(
          classes.container,
          full && classes.full,
          className,
          !showing && classes.hidden
        )}>
        {text && this.renderText()}
        <SpinKit className={cx(classes.loader, className)} fadeIn={fadeIn} name={name} />
        {children}
      </div>
    );
  }
}

export default injectIntl(injectStyles(styles)(LoadingIndicator));
