import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import injectStyles from 'react-jss';
import Loadable from 'react-loadable';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import { setConfiguration } from 'react-grid-system';
import './app.scss';
import {
  AsyncLoader,
  ErrorBoundary,
  NotFound,
  Modal,
  Notifications,
  OneColumn,
  RouteWithTitle,
  StatusBanner,
} from '../../components/base';
import { clearStatusBanner } from '../../redux/modules/app/actions';
import styles from './styles';
import BadGateway from '../../components/base/errors/BadGateway';
import { workspaceUrl } from '../../utils/routing';
import { closeDialog } from '../../utils/history';
import smoothscroll from 'smoothscroll-polyfill';
import ObjectValues from 'object.values';
import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import AuthBoundary from './Auth/BoundaryHooks';
import Init from './Init';
import IdleTimeout from './Auth/IdleTimeout';
import withSessionToken from '../common/withSessionToken';

// Initialize the configuration for `react-grid-system`
setConfiguration({ defaultScreenClass: 'xl', gridColumns: 24 });

Chart.plugins.unregister(ChartDataLabels);

const About = Loadable({
  loader: () => import('./About'),
  loading: AsyncLoader,
});

const AdminPage = Loadable({
  loader: () => import('./Admin'),
  loading: AsyncLoader,
});

const Header = Loadable({
  loader: () => import('./Header'),
  loading: AsyncLoader,
});

const Navbar = Loadable({
  loader: () => import('./Navbar'),
  loading: AsyncLoader,
});

const TICConfiguration = Loadable({
  loader: () => import('./TICConfiguration'),
  loading: AsyncLoader,
});

const CollectionCreateForm = Loadable({
  loader: () => import('../common/forms/CollectionCreateForm'),
  loading: AsyncLoader,
});

const UpdateOrganizationForm = Loadable({
  loader: () => import('../common/forms/UpdateOrganizationForm'),
  loading: AsyncLoader,
});

const ChangePasswordForm = Loadable({
  loader: () => import('../common/forms/ChangePasswordForm'),
  loading: AsyncLoader,
});

const UserPage = Loadable({
  loader: () => import('./User'),
  loading: AsyncLoader,
});

const NotificationsPage = Loadable({
  loader: () => import('./Notifications'),
  loading: AsyncLoader,
});

const Workspace = Loadable({
  loader: () => import('./Workspace'),

  loading: AsyncLoader,
});

class App extends React.Component {
  polyfill = () => {
    // kick off the polyfill!
    if (!Object.values) {
      ObjectValues.shim();
    }
    smoothscroll.polyfill();
    Number.isNaN =
      Number.isNaN ||
      function (value) {
        // eslint-disable-next-line
        return value !== value;
      };

    if (typeof Math.sign === 'undefined') {
      Math.sign = function (x) {
        return x > 0 ? 1 : x < 0 ? -1 : x;
      };
    }
  };

  renderDialogs = () => {
    const { history, intl, username } = this.props;
    return (
      <Switch>
        <RouteWithTitle
          path="*/dialog/about"
          render={() => (
            <Modal>
              <About />
            </Modal>
          )}
          title={intl.formatMessage({
            id: 'route-title-about',
            defaultMessage: 'About',
          })}
        />
        <RouteWithTitle
          path="*/dialog/change-password"
          render={() => (
            <Modal>
              <ChangePasswordForm username={username} onCancel={() => closeDialog(history)} />
            </Modal>
          )}
          onCancel={() => closeDialog(history)}
          title={intl.formatMessage({
            id: 'route-title-change-password',
            defaultMessage: 'Change Password',
          })}
        />
        <RouteWithTitle
          path="*/dialog/tic-configuration"
          render={() => (
            <Modal noDecorator>
              <TICConfiguration />
            </Modal>
          )}
          title={intl.formatMessage({
            id: 'route-title-tic-configuration',
            defaultMessage: 'TIC Scoring',
          })}
        />
        <RouteWithTitle
          path="*/dialog/add-workspace-collection"
          render={() => (
            <Modal styles={{ dialog: { content: { overflow: 'visible', maxWidth: '40%' } } }}>
              <CollectionCreateForm history={history} onCancel={() => closeDialog(history)} />
            </Modal>
          )}
          onCancel={() => closeDialog(history)}
          title={intl.formatMessage({
            id: 'route-title-add-workspace-collection',
            defaultMessage: 'Add Workspace Collection',
          })}
        />
        <RouteWithTitle
          path="*/dialog/update-organization"
          render={() => (
            <Modal
              styles={{ dialog: { content: { overflow: 'visible', maxWidth: '40%' } } }}
              noDecorator>
              <UpdateOrganizationForm history={history} onCancel={() => closeDialog(history)} />
            </Modal>
          )}
          onCancel={() => closeDialog(history)}
          title={intl.formatMessage({
            id: 'route-title-update-organization',
            defaultMessage: 'Update Organization',
          })}
        />
      </Switch>
    );
  };

  componentDidMount() {
    this.polyfill();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.sessionToken &&
      this.props.sessionToken.type === 'oauth2' &&
      !isEqual(this.props.sessionToken, nextProps.sessionToken)
    ) {
      // Don't render if there was a change to sessionToken, new token values are retrieved as needed.
      // This will prevent auto renewed tokens from cause a refresh which would restart the idle timer
      // while the user is still idle, and could interupt a user doing work as well.
      return false;
    } else {
      return true;
    }
  }

  render() {
    const {
      classes,
      dismissStatusBanner,
      intl,
      isLoadingWorkspaces,
      location,
      serverDown,
      statusBanner,
      userOrganizationId,
    } = this.props;
    return (
      <ErrorBoundary>
        <AuthBoundary>
          <IdleTimeout />
          <Init>
            {this.renderDialogs()}
            <OneColumn id="app-main" className={cx({ [classes.blur]: isLoadingWorkspaces })}>
              <StatusBanner
                id="app-status-banner"
                {...statusBanner}
                isShowing={!isLoadingWorkspaces && statusBanner && statusBanner.isShowing}
                onDismiss={dismissStatusBanner}
              />
              <Header userOrganizationId={userOrganizationId} />
              <Navbar />
              <div id="app-content" className={classes.content}>
                {serverDown ? (
                  <BadGateway />
                ) : !isLoadingWorkspaces ? (
                  <Switch>
                    <RouteWithTitle path="/admin/:type" component={AdminPage} />
                    <RouteWithTitle path="/user/:userId/:type" component={UserPage} />
                    <Route path="/notifications" component={NotificationsPage} />
                    <Redirect exact from="/" to={workspaceUrl(location)} />
                    <Route
                      path="/(collection-health|collection-management|elements|report|search|threats|workspace|system-metrics)"
                      component={Workspace}
                    />
                    <Route
                      render={() =>
                        !isLoadingWorkspaces && (
                          <NotFound
                            context={intl.formatMessage({
                              id: 'app-404-context',
                              defaultMessage: 'Please select a workspace.',
                            })}
                          />
                        )
                      }
                    />
                  </Switch>
                ) : null}
              </div>
            </OneColumn>
          </Init>
        </AuthBoundary>
        <Notifications pauseOnHover={true} autoClose={5000} />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = ({
  app: { serverDown, statusBanner },
  entities: {
    workspaces: { loading: isLoadingWorkspaces, data: workspaces },
  },
  user: { organizationId, username },
}) => ({
  isLoadingWorkspaces: isLoadingWorkspaces,
  serverDown,
  statusBanner,
  username,
  userOrganizationId: organizationId,
  workspaces,
});

const mapDispatchToProps = {
  dismissStatusBanner: clearStatusBanner.bind(this),
};

export default withRouter(
  withSessionToken(
    connect(mapStateToProps, mapDispatchToProps)(injectIntl(injectStyles(styles)(App)))
  )
);
