import multimethod from '../../../../../lib/multimethod';
import { actionError, actionStart, actionSuccess, apiData } from '../../../../../redux/common';
import actions from './actions';
import assign from 'lodash/fp/assign';
import graphApi from '../../../../../lib/http/graph';

/**
 * Normalizes the data from an indicates rel from the API to the legacy format the UI
 * is expecting to show an "association".
 *
 * @param indicatesRel
 * @returns association
 */
function indicatesToAssociation(indicatesRel) {
  const threat = indicatesRel.right;
  return {
    ...threat,
    ...threat.ref,
    sources: indicatesRel.sources,
  };
}

const fileThreats = multimethod('type', (action, state) => state);

fileThreats.method[actionStart(actions.FETCH_THREATS)] = apiData.fetchStarted;

fileThreats.method[actionSuccess(actions.FETCH_THREATS)] = (action, state) => {
  return assign(state, {
    data: graphApi.response.toResults(action.payload).map(indicatesToAssociation),
    isLoading: false,
    isRequesting: false,
    total: graphApi.response.toTotalHits(action.payload),
  });
};

fileThreats.method[actionError(actions.FETCH_THREATS)] = apiData.fetchErrored;

export default (state = apiData.initialState, action) => fileThreats(action, state);
