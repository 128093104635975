export default theme => ({
  nav: {
    backgroundColor: 'inherit',
    // nav only has one child, but making it flex conveniently aligns the items
    display: 'flex',
    // flexGrow: 1,
    flexShrink: 1,
    height: '100%',
  },
  list: ({ vertical }) => ({
    display: 'inline-flex',
    flexDirection: vertical ? 'column' : 'row',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    width: '100%',
  }),
  item: ({ itemHeight, gap, vertical }) => ({
    display: 'flex',
    alignItems: vertical ? 'center' : 'flex-end',
    justifyContent: 'center',
    height: itemHeight,
    ...(gap &&
      (vertical
        ? {
            marginBottom: gap,
          }
        : {
            marginRight: gap,
          })),
    ...(vertical && { width: '100%' }),
  }),
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textDecoration: 'none',
    color: theme.colors.darkGray,
    transition: 'all 0.2s linear',
    height: '100%',
    width: '100%',
    borderBottom: 0,
    borderBottomColor: theme.colors.accent,
    opacity: 0.7,
    '&:hover': {
      color: theme.colors.primary,
      opacity: 1,
    },
  },
});
