import React from 'react';
import PropTypes from 'prop-types';
import { fetchClassifications } from '../../../../../../redux/modules/threatEditor/actions';
import { Field } from 'redux-form';
import ThreatSelectField from '../../custom/ThreatSelectField';
import styles from './styles';
import injectStyles from 'react-jss';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import uniqBy from 'lodash/uniqBy';
import { capitalize } from '../../../../../../utils/strings';

class ClassificationsField extends React.Component {
  static propTypes = {
    workspaceId: PropTypes.string.isRequired,
  };

  // State
  state = { inputValue: '' };

  // Functions
  formattedData = () => {
    const { data } = this.props;
    const { inputValue } = this.state;

    if (data) {
      let filteredData = data
        .sort((a, b) =>
          a.ref.id.toLowerCase() > b.ref.id.toLowerCase()
            ? 1
            : b.ref.id.toLowerCase() > a.ref.id.toLowerCase()
            ? -1
            : 0
        )
        .filter(option => String(option.ref.id.toLowerCase()).startsWith(inputValue.toLowerCase()));
      return uniqBy(
        filteredData.map(({ ref: { id } }) => ({
          label: capitalize(id),
          value: id,
        })),
        'value'
      );
    }
  };

  // Lifecycle Methods
  componentDidMount() {
    const { fetchClassifications, workspaceId } = this.props;
    fetchClassifications(workspaceId);
  }

  render() {
    const { classes, intl, required = true, multi = true } = this.props;
    const { inputValue } = this.state;
    return (
      <Field
        className={classes.select__classification}
        closeMenuOnSelect={false}
        component={ThreatSelectField}
        options={this.formattedData()}
        onInputChange={input => this.setState({ inputValue: input })}
        name="classifications"
        multi={multi}
        placeholder={intl.formatMessage({
          id: 'threat-assoc-classifications-placeholder',
          defaultMessage: 'Add Classification(s)',
        })}
        required={required}
        value={inputValue}
      />
    );
  }
}

// Redux State
const mapStateToProps = ({
  app2: {
    threatEditor: { classifications: data },
  },
}) => {
  return { data: data };
};

// Dispatch Methods
const mapDispatchToProps = {
  fetchClassifications,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(injectStyles(styles)(ClassificationsField)));
