const baseButton = {
  height: 44,
  color: '#ffffff',
  background: '#ffffff',
  border: '1px solid #cccccc',
  cursor: 'pointer',
  outline: 'none',
  borderRadius: 0,
  transition: 'background-color 0.25s linear',
};

// Returns a button style map
const createButton = ({ boxShadows, borderRadius, fontFamily, fontSize, spacing }) => params => {
  if (!params || !params.background || !params.color) {
    return {};
  }
  const {
    background,
    color,
    borderColor = background,
    hoverBackgroundColor = background,
    hoverColor = color,
  } = params;

  return {
    ...baseButton,
    borderRadius,
    fontFamily,
    fontSize,
    fontWeight: 400,
    background: background.string(),
    color: color.string(),
    border: `1px solid ${borderColor.darken(0.1).string()}`,
    paddingLeft: spacing,
    paddingRight: spacing,
    boxShadow: boxShadows.base,
    '&:hover': {
      backgroundColor: hoverBackgroundColor.lighten(0.1).string(),
      color: hoverColor.string(),
    },
    '&:active': {
      backgroundColor: hoverBackgroundColor.lighten(0.1).string(),
      color: hoverColor.string(),
    },
    '&:focus': {
      backgroundColor: hoverBackgroundColor.lighten(0.1).string(),
      color: hoverColor.string(),
    },
  };
};

// Accepts `Color` instances not strings
export default params => {
  const {
    base,
    primary,
    secondary,
    accent,
    error,
    success,
    warning,
    info,
    inverse,
    text,
    link,
    cancel,
  } = params.config;
  const creator = createButton(params);
  return {
    base: creator(base),
    primary: creator(primary),
    secondary: creator(secondary),
    accent: creator(accent),
    error: creator(error),
    success: creator(success),
    warning: creator(warning),
    info: creator(info),
    inverse: creator(inverse),
    text: {
      ...creator(text),
      boxShadow: 'none',
      '&:hover': {
        color: text.color.darken(0.2).string(),
      },
      '&:active': {
        color: text.color.darken(0.3).string(),
      },
      '&:focus': {
        color: text.color.darken(0.3).string(),
      },
    },
    link: {
      ...creator(link),
      minWidth: 'auto',
      boxShadow: 'none',
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
      textTransform: 'uppercase',
      textDecoration: 'none',
      fontSize: '0.85rem',
      '&:active': {
        color: params.colorMapping.black.string(),
      },
      '&:hover': {
        color: params.colorMapping.black.string(),
      },
      '&:focus': {
        color: params.colorMapping.black.string(),
      },
    },
    cancel: creator(cancel),
    disabled: {
      opacity: 0.6,
      cursor: 'not-allowed',
    },
  };
};
