import multimethod from '../../../../../lib/multimethod';
import { actionError, actionStart, actionSuccess, apiData } from '../../../../../redux/common';
import actions from './actions';
import assign from 'lodash/fp/assign';
import { get } from 'lodash';

const enumeration = multimethod('type', (action, state) => state);

enumeration.method[actionStart(actions.FETCH_ENUMERATION)] = apiData.fetchStarted;

enumeration.method[actionSuccess(actions.FETCH_ENUMERATION)] = (action, state) => {
  return assign(state, {
    data: get(action, 'payload'),
    isLoading: false,
    isRequesting: false,
  });
};

enumeration.method[actionError(actions.FETCH_ENUMERATION)] = apiData.fetchErrored;

export default (state = apiData.initialState, action) => enumeration(action, state);
