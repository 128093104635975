import React from 'react';
import injectStyles from 'react-jss';
import cx from 'classnames';

class CollapsibleToggle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { children, classes, className, isCollapsible } = this.props;
    const elements = React.Children.toArray(children);
    if (!isCollapsible || elements.length < 2) {
      return elements;
    }
    let [element, ...rest] = elements;
    const { expanded } = this.state;
    const anchor = (
      <i
        key="anchor-tag"
        className={cx(classes.caretIcon, 'fas', expanded ? 'fa-caret-down' : 'fa-caret-right')}
        onClick={() => this.toggleExpanded()}
      />
    );

    rest = (
      <div className={cx(classes.childrenMargin, className)} key="children-elements">
        {rest}
      </div>
    );

    const totalElements = [anchor, element, rest];
    if (expanded) {
      return totalElements;
    }
    return totalElements.slice(0, 2);
  }
}

const styles = {
  caretIcon: {
    cursor: 'pointer',
    marginRight: 10,
    marginTop: 3,
  },
  childrenMargin: {
    // This margin should account for both the width and marginRight for the caretIcon.
  },
};

export default injectStyles(styles)(CollapsibleToggle);
