import { apiRequest } from '../../../../lib/http';
import { API } from '../../../../environment/api';
import { buildFieldSetUpdates, tokenThunk } from '../../../../utils/api';

const actions = {
  SET_EDITOR_STATE: 'workspace/SET_EDITOR_STATE',
  EDIT_SUMMARY: 'workspace/EDIT_SUMMARY',
  CANCEL_EDIT_SUMMARY: 'workspace/CANCEL_EDIT_SUMMARY',
  SAVE_SUMMARY: 'workspace/SAVE_SUMMARY',
};

/**
 * Set the current `editorState`, the draftJS json representation`, for a summary
 * @param editorState
 * @returns {{type: string, payload: *}}
 */
export const setEditorState = editorState => ({
  type: actions.SET_EDITOR_STATE,
  payload: editorState,
});

/**
 * Toggle a summary editor into edit mode
 * @returns {{type: string}}
 */
export const editSummary = () => ({
  type: actions.EDIT_SUMMARY,
});

/**
 * Return a summary editor back into read-only mode
 * @returns {{type: string}}
 */
export const cancelEditSummary = () => ({
  type: actions.CANCEL_EDIT_SUMMARY,
});

/**
 * Saves a workspace summary
 * @param workspaceId
 * @param summary
 * @returns {Function}
 */
export const saveSummary = ({ workspaceId, summary }) =>
  tokenThunk((dispatch, token) => {
    dispatch({
      workspaceId,
      summary,
      type: actions.SAVE_SUMMARY,
      payload: apiRequest(token, API.WORKSPACES_UPDATE, {
        'workspace-id': workspaceId,
        'field-updates': buildFieldSetUpdates({
          summary,
        }),
      }),
    });
  });

export default actions;
