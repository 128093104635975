import { tokenThunk } from '../../../utils/api';
import graph from '../../../lib/http/graph';

const actions = {
  CLASSIFICATIONS: 'threatEditor/CLASSIFICATIONS',
  CLEAR_THREATS_FORM: 'threatEditor/CLEAR_THREATS_FORM',
  CREATE_CLASSIFICATION: 'threatEditor/CREATE_CLASSIFICATIONS',
  CREATE_SOURCE: 'threatEditor/CREATE_SOURCE',
  CREATE_THREAT: 'threatEditor/CREATE_THREAT',
  GET_SOURCE: 'threatEditor/GET_SOURCE',
  GET_THREAT: 'threatEditor/GET_THREAT',
  SOURCES: 'threatEditor/SOURCES',
  THREATS: 'threatEditor/THREATS',
};

export const createClassification = ({ name }) => {
  return tokenThunk((dispatch, token) => {
    return dispatch({
      type: actions.CREATE_CLASSIFICATION,
      payload: graph.api.put(
        token,
        {
          type: 'classification',
          ticClassificationScore: 75,
          name: name,
        },
        true
      ),
    });
  });
};

export const createSource = ({ ticSourceScore, name }) => {
  return tokenThunk((dispatch, token) => {
    return dispatch({
      type: actions.CREATE_SOURCE,
      payload: graph.api.put(
        token,
        {
          type: 'source',
          ticSourceScore: parseInt(ticSourceScore, 10),
          name: name,
        },
        true
      ),
    });
  });
};

export function fetchClassifications(workspaceId) {
  return tokenThunk((dispatch, token) => {
    const ast = ['=', 'type', 'classification'];
    return dispatch({
      type: actions.CLASSIFICATIONS,
      payload: graph.api.query(token, workspaceId, ast),
    });
  });
}

export function fetchSources(workspaceId, isUserUploaded) {
  return tokenThunk((dispatch, token) => {
    const ast = isUserUploaded
      ? ['and', ['=', 'type', 'source'], ['=', 'userUploaded', true]]
      : ['=', 'type', ['source']];
    return dispatch({
      type: actions.SOURCES,
      payload: graph.api.query(token, workspaceId, ast),
    });
  });
}

export const fetchThreats = input => {
  return tokenThunk((dispatch, token) => {
    const ast = [
      'and',
      ['like', 'name', `${input}*`],
      ['=', 'type', ['threat']],
      ['=', 'userUploaded', true],
    ];
    return dispatch({
      type: actions.THREATS,
      payload: graph.api.query(token, ast, { limit: 100 }),
    });
  });
};

export const getSource = (workspaceId, { id }) => {
  return tokenThunk((dispatch, token) => {
    return dispatch({
      type: actions.GET_SOURCE,
      payload: graph.api.get(token, workspaceId, [{ type: 'source', id: id }]),
    });
  });
};

export const clearThreatEditor = () => {
  return {
    type: actions.CLEAR_THREATS_FORM,
    payload: [],
  };
};

export default actions;
