import React from 'react';

// Components
import WorkspaceManager from '../common/WorkspaceManager';

// API
import { API } from '../../environment/api';

// Redux
import { connect } from 'react-redux';
import {
  fetchEntities,
  fetchEntitiesSilently,
  refreshOrgUsers,
} from '../../redux/modules/entities/actions';
import { LoadingIndicator } from '../base';

// Util
import packageInfo from '../../../package.json';
import { configureScope, init } from '@sentry/browser';
import { injectIntl } from 'react-intl';

class Init extends React.Component {
  init = () => {
    const {
      fetchOrganizations,
      refreshOrgUsers,
      organizationId,
      userId,
      username,
      sentryDsn,
    } = this.props;

    if (organizationId) {
      // Initialize the organization data that we need to load the app e.g, workspace and users
      fetchOrganizations(organizationId);
      refreshOrgUsers(organizationId);
    }

    // Enable sentry user logging
    // Initiate raven-js error capture framework
    if (sentryDsn) {
      init({ dsn: sentryDsn, release: `sp@${packageInfo.version}` });

      // Set user information, as well as tags and further extras
      configureScope(scope => {
        scope.setExtra('organizationId', organizationId);
        scope.setExtra('userId', userId);
        scope.setUser({ id: userId, username });
      });
    }
  };

  loadingText = () => {
    const { intl, isFetchingPermissions } = this.props;
    if (isFetchingPermissions) {
      return intl.formatMessage({
        id: 'workspace-loading',
        defaultMessage: 'Loading Workspace...',
      });
    }
    return intl.formatMessage({
      id: 'workspaces-loading',
      defaultMessage: 'Loading Workspaces...',
    });
  };

  componentDidMount() {
    console.log('Mounted init');
    this.init();
  }

  render() {
    const { children, isFetchingPermissions, isLoadingWorkspaces } = this.props;
    const isLoading = isLoadingWorkspaces || isFetchingPermissions;
    return (
      <WorkspaceManager>
        <LoadingIndicator
          id="app-loading-indicator"
          fadeIn="quarter"
          showing={isLoading}
          text={this.loadingText()}
          full
        />
        {!isLoading && children}
      </WorkspaceManager>
    );
  }
}

const mapStateToProps = ({
  auth: { apiTokens, isFetchingPermissions },
  entities: {
    workspaces: { loading: isLoadingWorkspaces, data: workspaces },
  },
  user: { organizationId, userId, username },
}) => ({
  isFetchingPermissions: Boolean(isFetchingPermissions),
  isLoadingWorkspaces: isLoadingWorkspaces,
  organizationId,
  sentryDsn: apiTokens.sentry,
  userId,
  username,
  workspaces,
});

const mapDispatchToProps = {
  fetchOrgWorkspaces: (organizationId, silently = false) => {
    const fetchFn = silently ? fetchEntitiesSilently : fetchEntities;
    return fetchFn('workspaces', API.WORKSPACES_READ_ALL, {
      'organization-id': organizationId,
    });
  },
  fetchOrganizations: (organizationId, silently = false) => {
    const fetchFn = silently ? fetchEntitiesSilently : fetchEntities;
    return fetchFn('organizations', API.ORGANIZATION_READ_ALL, {
      'organization-id': organizationId,
    });
  },
  refreshOrgUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Init));
