/**
 * Returns a generated uuid
 * @returns {string}
 */
export const uuid = () =>
  'b-' +
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, a =>
    (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
  );

/**
 * Gets the displayName of a particular wrapped React component
 * @param WrappedComponent
 * @returns {*|string}
 */
export const getDisplayName = WrappedComponent =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component';
