import React from 'react';
import StandardError from './StandardError';
import { FormattedMessage } from 'react-intl';
import { BackLink } from '../links';

const NotFound = ({ context, error = '' }) => (
  <StandardError
    error={404}
    context={context || error.toString()}
    header={<FormattedMessage id="fourOhFour-title" defaultMessage={'Oops!'} />}
    description={
      <div>
        <p>
          <FormattedMessage
            id="fourOhFour-description"
            defaultMessage={"We can't find the page you are looking for."}
          />
        </p>
      </div>
    }
    footer={<BackLink />}
  />
);

export default NotFound;
