import { reduce, update } from 'lodash';

export const DEFAULT_TIC_SCORE = 10;

const severities = {
  critical: [75, 101],
  elevated: [50, 75],
  normal: [0, 50],
};
const severitiesByTicScore = reduce(
  severities,
  (result, [min, max], severity) => {
    for (let i = min; i < max; i++) {
      result[i] = severity;
    }
    return result;
  },
  []
);

export function ticScoreToSeverity(ticScore) {
  return severitiesByTicScore[ticScore];
}

function bucketizeSeverity(accumulator, aggResult) {
  const severity = ticScoreToSeverity(aggResult.ticScore);
  return update(accumulator, severity, currentCount => (currentCount || 0) + aggResult._count);
}

export function bucketizeSeverities(ticScoresWithCounts) {
  return ticScoresWithCounts.reduce(bucketizeSeverity, {});
}

function bucketizeThreat(accumulator, aggResult) {
  const severity = ticScoreToSeverity(aggResult.ticScore);
  return update(accumulator, severity, currentCount => (currentCount || 0) + 1);
}

export function bucketizeThreats(ticScoresWithCounts) {
  return ticScoresWithCounts.reduce(bucketizeThreat, {});
}

// Resets the TIC range to the default if the range is out of bounds of the min/max or the min is higher than the max.
export function validateTICRange(range) {
  const defaultRange = [severities.normal[0], severities.critical[1] - 1];
  const TICRange = range.map(Number);

  if (TICRange[0] > TICRange[1]) {
    return defaultRange;
  }

  if (TICRange[0] < severities.normal[0] || TICRange[1] > severities.critical[1]) {
    return defaultRange;
  }

  return TICRange;
}
