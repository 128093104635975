/**
 * Returns a millisecond interval that reflects approximately 60fps
 * @type {number}
 */
export const SIXTY_FPS_TIMEOUT_MS = 16.7;

const tabbableElements = '[tabindex]';

/**
 * Given an activeElement, `el`, traverse the children and return the next element that
 * can be focused on. This helps with finding the next "tabbable" element
 * @param el
 * @param selector
 * @returns {T[]}
 */
export const nextFocusableElements = ({
  el = document.activeElement,
  selector = tabbableElements,
}) => {
  if (el) {
    const childElements = el.querySelectorAll(selector);
    return Array.prototype.filter.call(
      childElements,
      element => element === el || element.offsetWidth > 0 || element.offsetHeight > 0
    );
  }
};

/**
 * A mapping from browser keycode keys to their numeric equivalents
 * @type {{ZERO: number, NINE: number, NUMPAD_ZERO: number, NUMPAD_NINE: number, BACKSPACE: number, DELETE: number, ENTER: number, ARROW_UP: number, ARROW_DOWN: number}}
 */
export const KEYCODE = {
  ZERO: 48,
  NINE: 57,

  NUMPAD_ZERO: 96,
  NUMPAD_NINE: 105,

  BACKSPACE: 8,
  DELETE: 46,
  ENTER: 13,

  ARROW_UP: 38,
  ARROW_DOWN: 40,
};

/**
 * Returns a noop function
 */
export function noop() {}

/**
 * Simulates a click event when a user presses enter. An element's id is required.
 * @param e
 * @param id
 */
export const simulateClickById = (e, id) => {
  if (e.key === 'Enter' || e.charCode === 13) {
    document.getElementById(id).click();
  }
};
