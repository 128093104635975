import React from 'react';
import injectStyles from 'react-jss';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles';

const Label = ({ classes, className, value }) => (
  <div className={cx(classes.label, className)}>
    <span className={classes.value}>{value}</span>
  </div>
);

Label.defaultProps = {
  type: '',
};

Label.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default injectStyles(styles)(Label);
