import React from 'react';
import { Field } from 'redux-form';

import FieldError from '../FieldError';
import RadioButtons from './';

export default class RadioButtonsField extends React.Component {
  renderComponent = ({ input, meta: { error, touched } }) => {
    const { children, className, showInitialError } = this.props;
    return (
      <div className={className}>
        <RadioButtons {...input}>{children}</RadioButtons>
        {/* Show validation errors when only validate function is passed  */}
        {this.props.validate ? (
          <FieldError touched={touched || showInitialError} error={error} />
        ) : null}
      </div>
    );
  };

  render() {
    const { name, onChange, validate } = this.props;
    return (
      <Field
        name={name}
        component={this.renderComponent}
        validate={validate ? [...validate] : null}
        onChange={onChange}
      />
    );
  }
}
