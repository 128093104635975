/**
 * A layer on top of the raw graph HTTP API that addresses use cases specifically used
 * by this UI. The functions exported from this file are meant to address common
 * interactions used across the app.
 */
import multimethod from '../lib/multimethod';
import graphApi from '../lib/http/graph';
import { hashToAlg, ipv4ToInt } from '../utils/network';
import { first, isEmpty, omit } from 'lodash';
import { update } from '../lib/immutable';
import { apiDownload } from '../lib/http';
import { collectionFilename } from '../utils/collections';

function unpackOne(response) {
  return first(response);
}

const TypeAstMap = {
  0: ['or', ['=', 'type', 'vulnerable-to'], ['=', 'type', 'associated-with']],
  1: ['=', 'type', 'associated-with'],
  2: ['=', 'type', 'vulnerable-to'],
};

// Associations
// ============

// this is a normalization step to put the data in a legacy format that the UI is currently expecting
function normalizeAssociation(association) {
  association.relationship = association.ref.type;
  // the ref is always returned, if it's not removed then the below checks will always
  // return true
  const left = omit(association.left, ['ref']);
  const right = omit(association.right, ['ref']);
  const type = association.relationship === 'vulnerable-to' ? 'vulnerability' : 'threat';
  delete association.ref;
  delete association.left;
  delete association.right;
  if (!isEmpty(left)) {
    association = Object.assign(association, {
      name: left.name,
      ticScore: left.ticScore,
      type: left.type,
    });
  }
  // if the right and left are both there, these values will stomp on the left values,
  // the current assumption is that they both won't be there....if they do both need
  // to be there, this might as well just return the 4 realz association instead of
  // normalizing it
  if (!isEmpty(right)) {
    association = Object.assign(association, {
      classifications: right.classifications,
      id: right.threatId,
      name: right.name || 'pending',
      source: right.source,
      ticScore: right.ticScore,
      type: type,
      cvss: right.cvss,
    });
  }
  if (association.meta) {
    association = Object.assign(association, association.meta);
    delete association.meta;
  }
  return association;
}

// fetchElementAssociations
// ------------------------

export const fetchElementAssociations = multimethod(
  (token, workspaceId, ref, filter, from, limit, sortPair) => ref.type
);

function threatAggToAssociation(threatAgg) {
  const { _count, classifications, name, ref, sources, ticScore } = threatAgg;
  return {
    classifications,
    elementCount: _count,
    id: ref.id,
    name,
    sources,
    ticScore,
    type: ref.type,
  };
}

function parentResponseToAssociationsResponse(response) {
  // Analytics query for risks (response)
  const risks = graphApi.response.toAnalyticsResults(response).risks;
  const totalResults = risks.results;
  const totalHits = risks.totalHits;
  return {
    results: totalResults.map(threatAggToAssociation),
    totalHits: totalHits,
  };
}

function fetchParentAssociations(
  token,
  workspaceId,
  { id, type },
  filter,
  from,
  limit,
  sortPair,
  selectedType
) {
  const ast = [
    'and',
    TypeAstMap[selectedType],
    [
      'or',
      //asns and cidrv4s need to search both plural and singular here
      ['=', `left.${type}`, id],
      ['=', `left.${type}s`, id],
    ],
  ];
  // Analytics query for risks (request)
  let analytics = graphApi.builder
    .analytics()
    .groupByAgg('risks', 'riskId')
    .from(from)
    .limit(limit)
    .fields(['classifications', 'name', 'sources', 'ticScore', 'description'])
    .sortBy(sortPair);
  if (filter) {
    analytics = analytics.filter(['like', 'name', `*${filter}*`]).build();
  } else {
    analytics = analytics.build();
  }
  return graphApi.api
    .analytics(token, workspaceId, ast, analytics)
    .then(parentResponseToAssociationsResponse);
}

fetchElementAssociations.method.asn = fetchParentAssociations;

fetchElementAssociations.method.cidrv4 = fetchParentAssociations;

fetchElementAssociations.method.cidrv6 = fetchParentAssociations;

fetchElementAssociations.method.owner = fetchParentAssociations;

function fetchLeafAssociations(
  token,
  workspaceId,
  { id, type },
  filter,
  from,
  limit,
  sortPair,
  selectedType
) {
  sortPair = update(sortPair, 0, field => `right.${field}`);
  const fields = [
    'right.description',
    'right.cvss',
    'right.classifications',
    'right.ticScore',
    'right.threatId',
    'right.name',
    'firstSeen',
    'lastSeen',
    'meta',
    'port',
    'sources',
    'userUploaded',
  ];
  const ast = ['and', ['=', `left.${type}`, id]];
  let typeAst = ['=', 'type', 'associated-with'];
  if (type === 'ipv4' || type === 'ipv6') {
    typeAst = TypeAstMap[selectedType];
  }

  ast.push(typeAst);
  if (filter) {
    ast.push(['like', 'right.name', `*${filter}*`]);
  }
  return graphApi.api
    .query(token, workspaceId, ast, { fields, from, limit, sortBy: [sortPair] })
    .then(response => {
      return update(response, 'results', results => results.map(normalizeAssociation));
    });
}

fetchElementAssociations.method.fqdn = fetchLeafAssociations;

fetchElementAssociations.method.vulnerability = function(
  token,
  workspaceId,
  { id, type },
  filter,
  from,
  limit,
  sortPair
) {
  sortPair = update(sortPair, 0, field => `left.${field}`);
  const fields = [
    'firstSeen',
    'lastSeen',
    'meta',
    'sources',
    'tic-score',
    'left.name',
    'left.type',
    'left.ticScore',
    'left.collectionIds',
  ];
  const ast = ['and', ['=', 'type', 'vulnerable-to'], ['=', 'right.vulnerabilityId', id]];

  if (filter) {
    ast.push(['like', 'left.name', `*${filter}*`]);
  }
  return graphApi.api
    .query(token, workspaceId, ast, { fields, from, limit, sortBy: [sortPair] })
    .then(response => {
      return update(response, 'results', results => results.map(normalizeAssociation));
    });
};

fetchElementAssociations.method.ipv4 = (
  token,
  workspaceId,
  ref,
  filter,
  from,
  limit,
  sortPair,
  selectedType
) => {
  // TODO: it would be nice to not have to do this
  ref = update(ref, 'id', id => ipv4ToInt(id));
  return fetchLeafAssociations(
    token,
    workspaceId,
    ref,
    filter,
    from,
    limit,
    sortPair,
    selectedType
  );
};

// TODO: We need to know the right way to query associations for ipv6
fetchElementAssociations.method.ipv6 = fetchLeafAssociations;

const affectedElementFields = [
  'left.name',
  'left.ticScore',
  'left.type',
  'firstSeen',
  'lastSeen',
  'meta',
];

fetchElementAssociations.method.threat = (
  token,
  workspaceId,
  { id },
  filter,
  from,
  limit,
  sortPair
) => {
  sortPair = update(sortPair, 0, field => `left.${field}`);
  const ast = ['and', ['=', 'type', 'associated-with'], ['=', `right.threatId`, id]];
  if (filter) {
    ast.push(['like', 'left.name', `*${filter}*`]);
  }
  return graphApi.api
    .query(token, workspaceId, ast, {
      fields: affectedElementFields,
      from,
      limit,
      sortBy: [sortPair],
    })
    .then(response => {
      return update(response, 'results', results => results.map(normalizeAssociation));
    });
};

// fetchChildAssociations
// ----------------------

export function fetchChildAssociations(
  token,
  workspaceId,
  ref,
  riskId,
  filter,
  from,
  limit,
  sortPair,
  riskType
) {
  sortPair = update(sortPair, 0, field => `left.${field}`);
  const riskQueryFragment = riskType === 'threat' ? 'right.threatId' : 'right.vulnerabilityId';
  const ast = [
    'and',
    ['or', ['=', 'type', 'vulnerable-to'], ['=', 'type', 'associated-with']],

    ['=', `left.${ref.type}s`, ref.id],
    ['=', riskQueryFragment, riskId],
  ];
  if (filter) {
    ast.push(['like', 'left.name', `*${filter}*`]);
  }
  return graphApi.api
    .query(token, workspaceId, ast, {
      fields: affectedElementFields,
      from,
      limit,
      sortBy: [sortPair],
    })
    .then(response => {
      return update(response, 'results', results => results.map(normalizeAssociation));
    });
}

// fetchFileAssociations
// ---------------------

// this is a separate function because it takes the full file object (including hashes)
export function fetchFileAssociations(token, workspaceId, file, filter, from, limit, sortPair) {
  sortPair = update(sortPair, 0, field => `left.${field}`);
  const fields = ['firstSeen', 'lastSeen', 'left.name', 'left.ticScore', 'left.type', 'sources'];
  const hashClauses = ['md5', 'sha1', 'sha256', 'sha512']
    .filter(alg => file[alg])
    .map(alg => ['=', `right.${alg}`, file[alg]]);
  if (isEmpty(hashClauses)) {
    // there's nothing to lookup
    return;
  }
  hashClauses.unshift('or');
  const ast = ['and', ['=', 'type', ['destination-for', 'distributes']], hashClauses];
  if (filter) {
    ast.push(['like', 'left.name', `*${filter}*`]);
  }
  return graphApi.api
    .query(token, workspaceId, ast, { fields, from, limit, sortBy: [sortPair] })
    .then(response => {
      return update(response, 'results', results => results.map(normalizeAssociation));
    });
}

// Element
// =======

/**
 * Removes system collections from an element's list of collections. This is a temporary
 * measure to make the UI consistent until the API stops returning the system collections
 * completely.
 *
 * TODO: This can be removed after SP-4435 is completed.
 *
 * @param element
 * @returns {*}
 */
function removeSystemCollections(element) {
  if (!element) {
    return element;
  }
  // asn, cidrv4, fqdn, ipv4, owner
  if (element.collections) {
    element = update(element, 'collections', collections => {
      return collections.filter(c => c.collectionType !== 'system');
    });
  }
  // files, threats
  if (element.affectedCollections) {
    element = update(element, 'affectedCollections', collections => {
      return collections.filter(c => c.collectionType !== 'system');
    });
  }
  return element;
}

function defaultFetchElement(token, workspaceId, type, id, fields) {
  const elementRefs = [{ id, type }];
  return graphApi.api
    .get(token, workspaceId, elementRefs, fields)
    .then(unpackOne)
    .then(removeSystemCollections);
}

export const fetchElement = multimethod(
  (token, workspaceId, type, id, fields) => type,
  defaultFetchElement
);

function fetchFileByAlg(token, workspaceId, alg, hash, fields) {
  const elementRefs = [{ [alg]: hash, type: 'file' }];
  return graphApi.api
    .get(token, workspaceId, elementRefs, fields)
    .then(unpackOne)
    .then(removeSystemCollections);
}

fetchElement.method.file = (token, workspaceId, type, idOrHash, fields) => {
  const alg = hashToAlg(idOrHash);
  if (alg) {
    return fetchFileByAlg(token, workspaceId, alg, idOrHash, fields);
  }
  return defaultFetchElement(token, workspaceId, 'file', idOrHash, fields);
};

// Export
// ======

export function downloadCollectionAssociationsCsv(token, collection) {
  const { 'collection-id': collectionId, 'workspace-id': workspaceId } = collection;
  const params = {
    columns: [
      { field: 'left.name', header: 'Element' },
      { field: 'left.type', header: 'Type' },
      { field: 'left.ticScore', header: 'Element TIC' },
      { field: 'right.name', header: 'Risk' },
      { field: 'right.type', header: 'Risk Type' },
      { field: 'right.ticScore', header: 'Risk TIC' },
      { field: 'firstSeen', header: 'First Seen' },
      { field: 'lastSeen', header: 'Last Seen' },
      { field: 'sources', header: 'Source' },
      { field: 'right.classifications', header: 'Classifications' },
    ],
    dateFormat: {
      format: 'iso-8601',
    },
    limit: 20000,
    query: [
      'and',
      ['or', ['=', 'type', 'vulnerable-to'], ['=', 'type', 'associated-with']],
      ['=', 'left.collectionIds', collectionId],
    ],
    workspaceIds: [workspaceId],
  };
  const filename = collectionFilename('associations', collection.name);
  return apiDownload('POST', '/graph/query', token, filename, {
    accept: 'text/csv',
    body: params,
  });
}

/**
 * Gets the threat associations in a collection, calls onSuccess and onFailure to
 * handle conditions appropriately
 * @param sessionToken
 * @param workspaceId
 * @param collectionId
 * @param onSuccess: param: threat association count in the collection
 * @param onFailure: param: reason for failure
 * @returns {Promise<unknown>}
 */
export function getThreatAssocsInCollection(
  sessionToken,
  workspaceId,
  collectionId,
  onSuccess,
  onFailure
) {
  const ast = [
    'and',
    ['=', 'left.collectionIds', collectionId],
    ['=', 'right.type', 'threat'],
    ['=', 'type', 'associated-with'],
  ];
  const params = {
    limit: 0,
  };
  return graphApi.api
    .query(sessionToken, workspaceId, ast, params)
    .then(response => {
      onSuccess(graphApi.response.toTotalHits(response));
    })
    .catch(onFailure);
}
