import React from 'react';
import { Toast as ToastWrapper } from '../Toast';
const { InnerComponent: Toast } = ToastWrapper;

// Operations
// ==========

export function dismissNotification(notificationId) {
  Toast.hide(notificationId);
}

export function updateNotificationMessage(notificationId, message, options = {}) {
  Toast.update(notificationId, message, options);
}

// Types
// =====

/**
 * Creates a success notification
 * @param message
 * @param autoClose
 * @returns {Function} A 0-arg function that will dismiss the toast if called.
 */
function success(message, autoClose) {
  return Toast.display(message, 'success', autoClose);
}

/**
 * Creates an error notification
 * @param message
 * @param autoClose
 * @returns {Function} A 0-arg function that will dismiss the toast if called.
 */
function error(message, autoClose) {
  return Toast.display(message, 'error', autoClose);
}

/**
 * Creates an info notification
 * @param message
 * @param autoClose
 * @returns {Function} A 0-arg function that will dismiss the toast if called.
 */
function info(message, autoClose) {
  return Toast.display(message, 'info', autoClose);
}

export default { error, info, success };
