import { API } from '../../../../../environment/api';
import { apiRequest } from '../../../../../lib/http';
import { tokenThunk } from '../../../../../utils/api';

const actions = {
  FETCH_NUM_NOTES: 'collectionManagement/header/FETCH_NUM_NOTES',
  FETCH_NUM_RULES: 'collectionManagement/header/FETCH_NUM_RULES',
};

// TODO: these could be combined into a single "fetch header vals" action using a Promise.all
// if the notes hit wasn't expected to fail sometimes to establish read-only

// this also controls whether the collection is read only for some reason
export function fetchNumNotes(collectionId, workspaceId) {
  let params = {
    'parent-id': collectionId,
    'workspace-id': workspaceId,
  };
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.FETCH_NUM_NOTES,
      payload: apiRequest(token, API.NOTES_READ_ALL, params),
    });
  });
}

export function fetchNumRules(collectionId) {
  let params = {
    'collection-id': collectionId,
    limit: 0,
  };
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.FETCH_NUM_RULES,
      payload: apiRequest(token, API.COLLECTIONS_RULES, params),
    });
  });
}

export default actions;
