import React from 'react';
import injectStyles from 'react-jss';
import styles from '../../styles';
import cx from 'classnames';

class GroupHelp extends React.Component {
  render() {
    const { classes, children, className } = this.props;
    return <p className={cx(classes.help, className, 'form-help')}>{children}</p>;
  }
}

export default injectStyles(styles)(GroupHelp);
