import React from 'react';
import Error from '../../../../assets/images/icon_error.svg';
import injectStyles from 'react-jss';

const ErrorIcon = ({ classes }) => <img src={Error} alt="error" className={classes.icon__error} />;

const styles = theme => ({
  icon__error: {
    backgroundColor: 'transparent',
    color: theme.colors.red,
    flexShrink: 0,
    height: '24px',
    width: '24px',
  },
});

export default injectStyles(styles)(ErrorIcon);
