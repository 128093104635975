import React from 'react';

// Components
import { LoadingIndicator } from '../../base';
import { Redirect, withRouter } from 'react-router-dom';
import withSessionToken from '../../common/withSessionToken';

// Redux
import { connect } from 'react-redux';

// Utils
import { get } from 'lodash';

class Boundary extends React.Component {
  // This function should return true as a user is signed in.
  canConvertToken = () => {
    const { isAuthenticated, authType, sessionToken } = this.props;
    // Allow to convert token when not authorized AND in oauth2 mode AND tokens exists. Due to authType and sessionToken
    // from withSessionToken this function will also prevent token convert when needed elements are undefined.
    // (also see isAuthenticated undefined check in render() in Convert.js)
    return !isAuthenticated && authType && authType === 'oauth2' && sessionToken;
  };

  renderAuthRedirect = () => {
    const to = {
      pathname: '/login',
      // Capture referrer location when redirecting back to unauthenticated state
      state: { referrer: this.props.location },
    };
    if (this.canConvertToken()) {
      to.pathname = '/auth/convert';
    }
    return <Redirect to={to} />;
  };

  render() {
    const { isAuthenticated, isLoading, location } = this.props;
    if (isLoading) {
      return <LoadingIndicator fadeIn="quarter" showing={true} full />;
    }
    if (!isAuthenticated) {
      return this.renderAuthRedirect();
    }
    const referrerUrl = get(location, ['state', 'referrer']);
    if (isAuthenticated && referrerUrl) {
      // A referrer exists post login, redirect to it
      return <Redirect to={referrerUrl} />;
    }
    const { children } = this.props;
    return <React.Fragment>{children}</React.Fragment>;
  }
}

const mapStateToProps = ({ auth: { config, isLoggingIn } }) => {
  return {
    authConfig: config.data,
    // either the user is currently logging in or the auth config hasn't loaded yet
    isLoading: isLoggingIn || !config.data,
  };
};

export default withRouter(withSessionToken(connect(mapStateToProps)(Boundary)));
