import React from 'react';
import PropTypes from 'prop-types';

class PageAnchor extends React.Component {
  render() {
    const { link } = this.props;
    // The spaces are a bit gross, but you need a content inside an a tag and an href
    // An anchor without content gives the warning jsx-a11y/anchor-has-content
    // No href gives the warning jsx-a11y/anchor-is-valid
    return (
      <a name={link} href={'# '}>
        {' '}
      </a>
    );
  }
}

PageAnchor.propTypes = {
  link: PropTypes.string.isRequired,
};

export default PageAnchor;
