export default {
  container: {
    display: 'inline-flex',
    height: '100%',
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    flexGrow: 1,
    backgroundColor: 'inherit',
  },
};
