import React from 'react';
import cx from 'classnames';
import injectStyles from 'react-jss';
import DatePicker from 'react-datepicker';
import CustomInput from './CustomInput';
import 'react-datepicker/dist/react-datepicker.css';

class DatePickerTime extends React.Component {
  handleChange = date => {
    const { onChange } = this.props.input;
    onChange(date);
  };

  render() {
    const {
      classes,
      className,
      input,
      meta: { touched, error } = {},
      maxDate,
      minDate,
      selectedValue,
      redOutlineOnError,
    } = this.props;

    return (
      <div className={cx(classes.container, className)}>
        <DatePicker
          customInput={
            <CustomInput error={error} redOutlineOnError={touched && redOutlineOnError} />
          }
          dateFormat="MM-dd-yyyy h:mm aa"
          maxDate={maxDate}
          minDate={minDate}
          onChange={this.handleChange}
          selected={input.value ? new Date(input.value) : selectedValue}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="Time"
        />
        {touched && error && <span className={classes.error}>{error}</span>}
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'inline-block',
    margin: `5px 0`,
  },
  error: {
    display: 'inline-block',
    color: theme.colors.red,
    marginLeft: 5,
  },
});

export default injectStyles(styles)(DatePickerTime);
