import actions from './actions';

const initialState = {
  notes: [],
  fetchingNotes: false,
};

const notesReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${actions.NOTES_READ_ALL}_START`:
      return {
        ...state,
        fetchingNotes: true,
      };
    case `${actions.NOTES_READ_ALL}_ERROR`:
      return {
        ...state,
        ...payload,
      };
    case actions.SET_NOTES:
      let notes = payload;
      notes.sort((a, b) => b['updated-at'] - a['updated-at']);
      return {
        ...state,
        notes: payload,
      };
    case `${actions.NOTES_READ_ALL}_SUCCESS`:
      notes = payload.result;
      notes.sort((a, b) => b['updated-at'] - a['updated-at']);
      return {
        ...state,
        notes,
        fetchingNotes: false,
      };
    default:
      return state;
  }
};

export default notesReducer;
