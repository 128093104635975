import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import SelectField from '../../SelectField';
import uniqBy from 'lodash/uniqBy';
import { injectIntl } from 'react-intl';
import graph from '../../../../../../lib/http/graph';
import injectStyles from 'react-jss';
import { isIpv4, isIpv6 } from '../../../../../../utils/elements';
import styles from './styles';
import withSessionToken from '../../../../../common/withSessionToken';

class UserGeneratedThreatSelectField extends React.Component {
  static propTypes = {
    workspaceId: PropTypes.string.isRequired,
  };

  // State
  state = {
    options: [],
  };

  // Functions
  fetchUserGeneratedThreats = () => {
    const { elementId, sessionToken, workspaceId } = this.props;
    const ast = [
      'and',
      ['=', 'type', 'associated-with'],
      [
        '=',
        isIpv4(elementId) ? 'left.ipv4' : isIpv6(elementId) ? 'left.ipv6' : 'left.fqdn',
        `${elementId}`,
      ],
      ['=', 'userUploaded', true],
    ];
    const params = {
      fields: ['right.threatName', 'userUploaded'],
    };
    return graph.api
      .query(sessionToken, workspaceId, ast, params)
      .then(data => this.setState({ options: data.results }));
  };

  formattedData = () => {
    const { options } = this.state;
    if (options) {
      return uniqBy(
        options.map(({ right: { threatName, ref: { id } } }) => ({
          label: threatName,
          value: threatName,
          id: id,
        })),
        'value'
      );
    }
  };

  componentDidMount() {
    this.fetchUserGeneratedThreats();
  }

  render() {
    const { classes, intl } = this.props;
    return (
      <>
        <Field
          className={classes.select__threat}
          closeMenuOnSelect={true}
          component={SelectField}
          name="threats"
          options={this.formattedData()}
          placeholder={intl.formatMessage({
            id: 'threat-assoc-select-placeholder',
            defaultMessage: 'Enter Threat',
          })}
          disabled={this.formattedData().length === 0}
          required={true}
        />
        {this.formattedData().length === 0 && (
          <span className={classes.span__noData}>
            There are no threats associated with this element.
          </span>
        )}
      </>
    );
  }
}

export default withSessionToken(injectIntl(injectStyles(styles)(UserGeneratedThreatSelectField)));
