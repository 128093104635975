import React from 'react';
import injectStyles from 'react-jss';
import styles from '../../styles';
import cx from 'classnames';

class Fieldset extends React.Component {
  render() {
    const { classes, children, className, disabled, title, titleClassName } = this.props;
    return (
      <fieldset className={cx(classes.fieldset, className, 'form-fieldset')} disabled={disabled}>
        {title && <h2 className={cx(classes.fieldset__title, titleClassName)}>{title}</h2>}
        {children}
      </fieldset>
    );
  }
}

export default injectStyles(styles)(Fieldset);
