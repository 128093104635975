import React from 'react';
import injectStyles from 'react-jss';
import cx from 'classnames';
import Title from '../../Title';
import styles from './styles';

const Panel = ({
  actions,
  children,
  className,
  classes,
  headerClassName,
  title,
  titleClassName,
  titleId,
}) => (
  <div className={cx(classes.container, className)}>
    <header className={cx(classes.header, headerClassName)}>
      <Title
        className={cx(classes.title, titleClassName)}
        title={title}
        titleId={titleId}
        withHelmet={false}
      />
      <div className={classes.actions}>{actions}</div>
    </header>
    <div className={classes.body}>{children}</div>
  </div>
);

export default injectStyles(styles)(Panel);
