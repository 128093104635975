import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

function FormattedLabel({ defaultMessage, messageId, values, ...props }) {
  return (
    <span {...props}>
      {messageId ? (
        <FormattedMessage id={messageId} defaultMessage={defaultMessage} values={values} />
      ) : (
        defaultMessage
      )}
    </span>
  );
}

FormattedLabel.propTypes = {
  className: PropTypes.string,
  defaultMessage: PropTypes.string,
  id: PropTypes.string,
  messageId: PropTypes.string,
  values: PropTypes.object,
};

export default FormattedLabel;
