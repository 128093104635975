import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import injectStyles from 'react-jss';
import StandardError from './StandardError';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '../Button';

const InternalServerError = ({
  classes,
  details,
  intl,
  showDescription = true,
  loginAgain,
  error,
}) => {
  const history = useHistory();
  const logout = () => {
    history.push('/logout');
  };

  return (
    <StandardError
      error={error.code}
      header={
        <FormattedMessage id="internal-server-title" defaultMessage={'Internal Server Error!'} />
      }
      headerClass={classes.header__class}
      context={intl.formatMessage({
        id: 'app-500-context',
        defaultMessage: 'Please contact support if this continues to happen.',
      })}
      description={
        <React.Fragment>
          {showDescription && (
            <p>
              <FormattedMessage
                id="internal-server-description"
                defaultMessage={'Something thing went wrong with our server.'}
              />
            </p>
          )}
          {details && <p>{details}</p>}
        </React.Fragment>
      }
      footerClass={classes.footer}
      footer={
        loginAgain ? (
          <Button
            type="button"
            role="text"
            onClick={logout}
            style={{ width: 200, marginBottom: 16 }}>
            {intl.formatMessage({
              id: 'login-retry',
              defaultMessage: 'Retry Login',
            })}
          </Button>
        ) : (
          <div style={{ cursor: 'pointer' }} onClick={() => window.location.reload(true)}>
            <FormattedMessage id="internal-server-reload" defaultMessage={'Refresh'} />
          </div>
        )
      }
    />
  );
};

const styles = theme => ({
  header__class: {
    fontSize: '3em',
    color: 'red',
    marginTop: '2em',
  },
  footer: {
    cursor: 'inherit',
  },
});

export default withRouter(injectIntl(injectStyles(styles)(InternalServerError)));
