export default theme => ({
  input: {
    height: '34px',
    borderColor: theme.colors.threatAssocGray,
  },
  text__desc: {
    width: '765px',
    resize: 'vertical',
    minHeight: '60px',
  },
});
