/***
 * Collapsible
 * Collapsible container component for sections
 */
import React from 'react';

// Components
import ReactCollapsible from 'react-collapsible';

// Redux
import { connect } from 'react-redux';

// Styles
import injectStyles from 'react-jss';
import cx from 'classnames';
import './stylesheets/collapsible.css';

class Collapsible extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
    };
  }

  actionClick = e => {
    e.stopPropagation();
    if (this.props.actionClick) {
      this.props.actionClick();
    }
  };

  toggleOpen = () => {
    let { disableToggle } = this.props;
    // Stop toggling when disableToggle is set to true
    const isOpen = disableToggle ? true : !this.state.open;
    this.setState({
      open: isOpen,
    });
  };

  render() {
    const { action, classes, disableToggle, title } = this.props;
    const isOpen = disableToggle ? true : this.state.open;
    let trigger = (
      <div
        className={cx(classes.trigger, !disableToggle && classes.trigger__enabled)}
        data-test="collapsible-trigger"
        onClick={this.toggleOpen}>
        <div className={classes.title} data-test="title">
          {title}
        </div>
        <div className={classes.actions}>
          {this.props.action && this.props.displayAction && (
            <span className={classes.action} onClick={this.actionClick}>
              {action}
            </span>
          )}
          <i className={cx(classes.carotIcon, isOpen ? 'fas fa-angle-up' : 'fas fa-angle-down')} />
        </div>
      </div>
    );

    return (
      <ReactCollapsible triggerDisabled={disableToggle} open={isOpen} trigger={trigger}>
        {this.props.content}
      </ReactCollapsible>
    );
  }
}

const styles = theme => ({
  trigger: {
    width: '100%',
    padding: 10,
    boxSizing: 'border-box',
    background: theme.colors.lilac,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  trigger__enabled: {
    cursor: 'pointer',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  action: {
    marginRight: 10,
    color: theme.colors.link,
    textTransform: 'uppercase',
  },
  carotIcon: {
    fontSize: 18,
    color: theme.colors.iris,
  },
  title: {
    fontWeight: 'bold',
    fontFamily: theme.fontFamilySecondary,
    textTransform: 'uppercase',
    color: theme.colors.iris,
  },
});

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectStyles(styles)(Collapsible));
