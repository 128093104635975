export default theme => ({
  heading: {
    ...theme.typography.h1,
    fontSize: 30,
    fontWeight: 400,
    marginTop: theme.spacing,
    marginBottom: theme.spacing,
    ...theme.overflow,
    color: theme.colors.black,
  },
});
