import { find, get } from 'lodash';
import assign from 'lodash/fp/assign';
import multimethod from '../../../../../lib/multimethod';
import actions from './actions';
import { actionError, actionStart, actionSuccess, apiData } from '../../../../../redux/common';
import { frequencyOptions } from '../../actions';
import notification from '../../../../common/notification';

const initialState = {
  data: '',
  isDeleting: false,
  isRequesting: false,
  error: null,
  isCreating: false,
};

const { fetchErrored, fetchStarted } = apiData;

const reportActions = multimethod('type', (action, state) => state);

reportActions.method[actionStart(actions.READ_SCHEDULE)] = fetchStarted;

reportActions.method[actionError(actions.READ_SCHEDULE)] = fetchErrored;

reportActions.method[actionSuccess(actions.READ_SCHEDULE)] = (action, state) => {
  const data = get(action, 'payload.result', []);
  return assign(state, {
    data: formattedSchedule(data),
    isRequesting: false,
  });
};

reportActions.method[actionStart(actions.DELETE_SCHEDULE)] = (action, state) => {
  return assign(state, {
    isDeleting: true,
    error: null,
  });
};

reportActions.method[actionError(actions.DELETE_SCHEDULE)] = (action, state) => {
  return assign(state, {
    isDeleting: false,
    error: action.payload,
  });
};

reportActions.method[actionSuccess(actions.DELETE_SCHEDULE)] = (action, state) => {
  return assign(state, {
    data: '',
    isDeleting: false,
  });
};

reportActions.method[actionStart(actions.CREATE_SCHEDULE)] = (action, state) => {
  return assign(state, {
    isCreating: true,
    error: null,
  });
};

reportActions.method[actionError(actions.CREATE_SCHEDULE)] = (action, state) => {
  notification.error(action.payload.message);
  return assign(state, {
    isCreating: false,
  });
};

reportActions.method[actionSuccess(actions.CREATE_SCHEDULE)] = (action, state) => {
  const data = get(action, 'payload.result', '');
  return assign(state, {
    data,
    isCreating: false,
  });
};

reportActions.method[actions.CLEAR_SCHEDULE] = (action, state) => {
  return assign(state, {
    data: '',
    isRequesting: false,
  });
};

const formattedSchedule = ({
  id,
  recipients = [],
  final,
  name,
  repeat,
  type,
  params,
  occurrences,
  start,
}) => {
  const initialValues = {};
  if (id) {
    initialValues.existingSchedule = true;
    initialValues.id = id;
    initialValues.recipients = recipients;
    if (!final && !occurrences) {
      initialValues.toDate = 'never';
    }

    if (final) {
      initialValues.endDate = final;
      initialValues.toDate = 'on';
    }

    if (occurrences) {
      initialValues.occurrences = occurrences;
      initialValues.toDate = 'after';
    }

    if (name) {
      initialValues.name = name;
    }

    if (start) {
      initialValues.startDate = start;
    }

    if (repeat) {
      initialValues.frequency = find(frequencyOptions, { value: repeat }) || {};
    }

    if (type) {
      initialValues.reportType = type;
    }

    if (params) {
      initialValues.reportParams = params;
    }
  }
  return initialValues;
};

export default (state = initialState, action) => reportActions(action, state);
