import { API } from '../../../../environment/api';
import { fetchEntityById } from '../../../../redux/modules/entities/actions';
import { tokenThunk } from '../../../../utils/api';
import graphApi from '../../../../lib/http/graph';
import { astPlusOwnerTypes } from '../../../../utils/elements';

const actions = {
  FETCH_ELEMENT_ANALYTICS: 'collectionManagement/FETCH_ELEMENT_ANALYTICS',
  FETCH_THREAT_ANALYTICS: 'collectionManagement/FETCH_THREAT_ANALYTICS',
};

export function fetchElementAnalytics(workspaceId, collectionId) {
  const ast = ['and', ['=', 'collectionIds', collectionId], ['=', 'type', astPlusOwnerTypes]];
  const analytics = graphApi.builder
    .analytics()
    .groupByAgg('numElementsByScore', 'ticScore')
    .limit(10000)
    .groupByAgg('types', 'type')
    .limit(10000)
    .build();
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.FETCH_ELEMENT_ANALYTICS,
      payload: graphApi.api.analytics(token, workspaceId, ast, analytics),
    });
  });
}

export function fetchThreatAnalytics(workspaceId, collectionId) {
  const ast = [
    'and',
    ['=', 'left.collectionIds', collectionId],
    ['or', ['=', 'type', 'vulnerable-to'], ['=', 'type', 'associated-with']],
  ];
  // Analytics query for risks
  const analytics = graphApi.builder
    .analytics()
    .groupByAgg('risks', 'riskId')
    .limit(10000)
    .fields(['ticScore'])
    .build();
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.FETCH_THREAT_ANALYTICS,
      payload: graphApi.api.analytics(token, workspaceId, ast, analytics),
    });
  });
}

export function fetchCollection(collectionId, fields) {
  return fetchEntityById('collections', API.COLLECTIONS_READ, {
    'collection-id': collectionId,
    attributes: fields,
  });
}

export default actions;
