/**
 * Returns an array of values if values is not an array
 * @param values
 * @returns {Array}
 */
export const normalizeToArray = values => {
  if (!values) {
    return [];
  }
  return Array.isArray(values) ? values : [values];
};

export const sortedByName = array =>
  array.sort((a, b) => {
    let nameA = a.name.toLowerCase();
    let nameB = b.name.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
