export default theme => ({
  wrapper: {
    height: '100%',
    width: '100%',
  },
  editor: ({ maxHeight }) => ({
    border: 'solid 1px rgb(248, 248, 248)',
    height: '100%',
    paddingLeft: theme.spacing,
    paddingRight: theme.spacing,
    fontSize: '1rem',
    paddingTop: 0,
    maxHeight: maxHeight || 'auto',
    // TODO: make sure this is right! changing this to overflow auto always
    overflow: 'auto',
  }),
  editing: {
    minHeight: 150,
  },
  footer: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    height: 45,
    flexShrink: 0,
    paddingLeft: theme.spacing,
    paddingRight: theme.spacing,
    border: '1px solid',
    borderColor: theme.colors.lighterGray,
    backgroundColor: theme.colors.lightestGray,
    width: `calc(100% - ${theme.spacing * 2}px)`,
  },
  readOnly: {
    maxHeight: '100% !important',
  },
  toolbar: {
    padding: theme.spacing,
    backgroundColor: theme.colors.lightestGray,
    border: 'none',
    boxShadow: theme.boxShadows.navbar,
  },
  sticky: {
    position: 'sticky',
    top: -1,
    zIndex: theme.zIndex.wysiwyg,
  },
});
