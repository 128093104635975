const actions = {
  SET_SORT_DIRECTION: 'SIDEBAR/SET_SORT_DIRECTION',
  SET_SORT_FIELD: 'SIDEBAR/SET_SORT_FIELD',
};

export function setSortIndex(sortByFieldIndex) {
  return dispatch => {
    dispatch({
      type: actions.SET_SORT_FIELD,
      payload: sortByFieldIndex,
    });
  };
}

export function setSortDirection(sortByDirection) {
  return dispatch => {
    dispatch({
      type: actions.SET_SORT_DIRECTION,
      payload: sortByDirection,
    });
  };
}

export default actions;
