import React from 'react';
import injectStyles from 'react-jss';
import cx from 'classnames';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { generateEditorState } from '../../../WYSIWYG/utils';
import WYSIWYG from '../../../WYSIWYG';
import FieldError from '../../FieldError';
import styles from '../../styles';

class WYSIWYGField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: generateEditorState(props.input.value),
    };
  }

  /**
   * Handles updating the internal `EditorState` reference returned from the WYSIWYG
   * and update the internal input form state with the raw content in html form
   * @param editorState
   */
  onEditorStateChange = editorState => {
    this.setState({ editorState }, () => {
      const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      this.props.input.onChange && this.props.input.onChange(html);
    });
  };

  render() {
    const { classes, className, id, maxCharacters, meta, suggestions = [] } = this.props;
    return (
      <div className={classes.field}>
        <WYSIWYG
          id={id}
          editorState={this.state.editorState}
          editorClassName={cx(classes.editor, className)}
          maxCharacters={maxCharacters}
          onEditorStateChange={this.onEditorStateChange}
          readOnly={false}
          toolbarHidden={false}
          toolbarClassName={classes.toolbar}
          mention={
            suggestions
              ? {
                  separator: ' ',
                  trigger: '@',
                  suggestions: suggestions,
                }
              : {}
          }
        />
        <FieldError {...meta} />
      </div>
    );
  }
}

export default injectStyles(styles)(WYSIWYGField);
