import React from 'react';
import { Checkbox } from '../../index';
import { has } from 'lodash';
import cx from 'classnames';

class CheckboxField extends React.Component {
  /**
   * The onChange function in the Checkbox component returns the target.value.
   * This handles both scenarios where type="checkbox": so checked=boolean and
   * also no type definition: so value=(boolean or empty string, if just initialized)
   */
  render() {
    const { className, id, input } = this.props;
    const props = { id, ...input };
    if (!has(input, 'checked') && has(input, 'value')) {
      props.checked = Boolean(input.value);
    }
    return <Checkbox className={cx(className)} {...props} />;
  }
}

export default CheckboxField;
