import { camelCase, drop, kebabCase, map, mapKeys, size, sortBy, take } from 'lodash';
import { determineTokenAndAuth } from './auth.js';

/**
 * Returns a new-lined string showing what fields were updated based on operation, field-name
 * @param updates
 * @returns string
 */
export const updatedFieldStrings = updates =>
  updates.map(update => `Successfully updated ${update['field-name']}.`);

/**
 * Takes a map of `values` and builds a `field-updates` array of maps.
 * @param valueMap
 * @param operation
 * @returns array
 */
export const buildFieldSetUpdates = (valueMap, operation = 'set') =>
  map(valueMap, (value, key) => ({
    operation,
    'field-name': key,
    value,
  }));

/**
 * Thunk that adds in a session token into a cb
 * @param request
 * @returns {Function}
 */
export const tokenThunk = cb => async (dispatch, getState) => {
  // IMPORTANT! sessionToken should not be used directly from Redux. By using determineTokenAndAuth(), when in oauth2
  // auth mode sessionToken will be obtained directly via Okta component/package, avoiding a copy from possibly becoming stale.
  let result = getState().auth.oauth2;
  cb(dispatch, result.accessToken);
};

/**
 * Quotes a query string
 * @param query
 * @returns {string}
 */
export const quotedQuery = query => `"${query}"`;

export function camelCaseKeys(o) {
  return mapKeys(o, (v, k) => camelCase(k));
}

export function kebabCaseKeys(o) {
  return mapKeys(o, (v, k) => kebabCase(k));
}

export function pageResults(results, from = 0, limit = size(results), sortPair) {
  // sort before paging for consistency
  if (sortPair) {
    const [sortField, sortDir] = sortPair;
    results = sortBy(results, sortField);
    if (sortDir.toLowerCase() === 'desc') {
      results = results.reverse();
    }
  }
  return take(drop(results, from), limit);
}

/**
 * Converts the sessionToken from a legacy auth response to an object with full proper
 * configuration.
 *
 * @param response
 * @returns {*}
 */
export function modernizeAuthResponse(response) {
  response.sessionToken = {
    domain: process.env.REACT_APP_SESSION_DOMAIN,
    type: 'sp',
    value: response['e_session-key_s'],
  };
  return response;
}
