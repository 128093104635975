import constant from 'lodash/constant';
import { apiRequest } from '../../../lib/http';
import { API } from '../../../environment/api';
import { buildFieldSetUpdates, tokenThunk } from '../../../utils/api';

const actions = {
  SET_DEFAULT_WORKSPACE: 'user/SET_DEFAULT_WORKSPACE',
};

/**
 * Returns a thunk that sets the default workspace to `workspaceId` for a `userId`
 * @param userId
 * @param workspaceId
 * @returns {Function}
 */
export const setDefaultWorkspace = ({ userId, workspaceId }) =>
  tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.SET_DEFAULT_WORKSPACE,
      payload: apiRequest(token, API.USERS_UPDATE, {
        'field-updates': buildFieldSetUpdates({
          'default-workspace-id': workspaceId,
        }),
        'user-id': userId,
      }).then(constant(workspaceId)),
    });
  });

export default actions;
