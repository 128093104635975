import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ENVIRONMENTS, PAGEVIEW } from '../constants/analytics';
import ReactGA from 'react-ga4';

const getTrackingId = () => {
  const productionEnvironments = ENVIRONMENTS;
  const currentLocation = window.location.origin;
  const matchingEnvironment = productionEnvironments.find(({ url }) => url === currentLocation);
  return matchingEnvironment?.trackingId;
};

const useAnalytics = () => {
  const location = useLocation();
  const isInitialized = useRef(false);

  useEffect(() => {
    const trackingId = getTrackingId();

    if (trackingId) {
      ReactGA.initialize(trackingId);
      isInitialized.current = true;
    }

    return () => {
      isInitialized.current = false;
    };
  }, []);

  useEffect(() => {
    if (isInitialized.current) {
      ReactGA.send({
        hitType: PAGEVIEW,
        page: location.pathname + location.search,
      });
    }
  }, [location, isInitialized]);
};

export default useAnalytics;
