import React from 'react';
import injectStyles from 'react-jss';
import cx from 'classnames';
import styles from './styles';

const OneColumn = ({ classes, className, children, id }) => (
  <section id={id} className={cx(classes.container, className)}>
    <div className={classes.column}>{children}</div>
  </section>
);

export default injectStyles(styles)(OneColumn);
