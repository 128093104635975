import React from 'react';
import injectStyles from 'react-jss';
import { FormattedNumber } from 'react-intl';
import { Container } from 'react-grid-system';
import classNames from 'classnames';

const StandardError = props => {
  const {
    classes,
    context,
    error,
    header,
    headerClass,
    description,
    descriptionClass,
    footer,
    footerClass,
  } = props;
  return (
    <Container className={classes.container} fluid>
      <div className={classes.error__container}>
        <h1 className={classNames([classes.header, headerClass])}>{header}</h1>
        <div className={classNames([classes.error__description, descriptionClass])}>
          {description}
        </div>
        {context && <p className={classes.error__description}>{context}</p>}
        <div className={classNames([classes.footer, footerClass])}>{footer}</div>
      </div>
      {error && (
        <aside className={classes.code__container}>
          <h2 className={classes.code__title}>
            <FormattedNumber value={error} />
          </h2>
        </aside>
      )}
    </Container>
  );
};

const styles = theme => ({
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
  },
  error__container: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing * 3,
  },
  header: {
    fontSize: '8rem',
    fontWeight: 600,
    color: theme.colors.primary,
    margin: theme.spacing * 4,
    marginTop: 0,
    marginBottom: theme.spacing,
  },
  error__description: {
    fontSize: '1.5rem',
    fontWeight: 500,
    color: theme.colors.darkerGray,
    maxWidth: 400,
    marginLeft: theme.spacing * 4,
    marginTop: 0,
  },
  footer: {
    fontSize: '1.25rem',
    marginLeft: theme.spacing * 4,
    textDecoration: 'none',
    width: 'auto',
    color: theme.colors.link,
    cursor: 'pointer',
    '&:before': {
      content: '- ',
    },
  },
  code__container: {
    display: 'flex',
    paddingTop: theme.spacing * 5,
    justifyContent: 'center',
    width: '50%',
  },
  code__title: {
    fontSize: '12rem',
    fontWeight: 700,
    color: theme.colors.darkerGray,
    marginTop: 0,
    height: 265,
    width: 325,
  },
});

export default injectStyles(styles)(StandardError);
