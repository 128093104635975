import { tokenThunk } from '../../../../../utils/api';
import graphApi from '../../../../../lib/http/graph';
import { get } from 'lodash';

const actions = {
  FETCH_THREATS: 'elementDetails/fileThreats/FETCH',
};

function addClause(ast, file, field, astField = field) {
  const v = get(file, field);
  if (!v) {
    return;
  }
  ast.push(['=', `left.${astField}`, get(file, field)]);
}

export function fetchThreats(workspaceId, file) {
  const ast = ['and', ['=', 'type', 'indicates']];
  addClause(ast, file, 'ref.id', 'fileId');
  addClause(ast, file, 'md5');
  addClause(ast, file, 'sha1');
  addClause(ast, file, 'sha256');
  addClause(ast, file, 'sha512');
  const params = {
    fields: ['right.classifications', 'right.name', 'right.ticScore', 'sources'],
    from: 0,
    limit: 1000,
    sortBy: [['right.tic-score', 'desc']],
  };
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.FETCH_THREATS,
      payload: graphApi.api.query(token, workspaceId, ast, params),
    });
  });
}

export default actions;
