import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Boundary from './Boundary';
import { initOauth2 } from '../../../redux/modules/auth/actions';

const OKTA_LOGOUT_REDIRECT = '/login';

function BoundaryHooks(props) {
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (authState?.isAuthenticated) {
      const curSessionToken = {
        domain: process.env.REACT_APP_SESSION_DOMAIN,
        id: authState.idToken.idToken,
        type: 'oauth2',
        value: authState.accessToken.accessToken,
      };
      const login = () => oktaAuth.signInWithRedirect();
      const logout = (pathname = OKTA_LOGOUT_REDIRECT) =>
        this.oktaAuth.signOut({
          postLogoutRedirectUri: window.location.origin + pathname,
        });
      props.initOauth2(
        login,
        logout,
        () => curSessionToken,
        () => authState.isAuthenticated
      );
    }
  }, [authState?.isAuthenticated]);

  if (!authState) {
    return null;
  }

  return <Boundary {...props} isAuthenticated={authState.isAuthenticated} />;
}

const mapDispatchToProps = {
  initOauth2,
};

export default connect(null, mapDispatchToProps)(BoundaryHooks);
