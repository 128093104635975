import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { tokenThunk } from '../../../../utils/api';
import { findBestMatch } from 'string-similarity';
import graphApi from '../../../../lib/http/graph';
import { astPlusThreatTypes } from '../../../../utils/elements';

const actions = {
  FETCH_SUGGESTIONS: 'search/FETCH_SUGGESTIONS',
  CLEAR_SUGGESTIONS: 'search/CLEAR_SUGGESTIONS',
  CLEAR_OPTIONS: 'search/CLEAR_OPTIONS',
  SET_SEARCH: 'search/SET_SEARCH',
  SET_TYPE: 'search/SET_TYPE',
  CLEAR_ALL_SELECTED: 'search/CLEAR_ALL_SELECTED',
  SET_SORT: 'search/SET_SORT',
  SET_QUERY_ERROR: 'search/SET_QUERY_ERROR',
  CLEAR_QUERY_ERROR: 'search/CLEAR_QUERY_ERROR',
};

/**
 * Clears the selected rows
 * @returns {{type: string, payload: {selectedRows: Array}}}
 */
export const clearAnySelectedRows = () => ({
  type: actions.CLEAR_ALL_SELECTED,
  payload: {
    selectedRows: [],
  },
});

/**
 * Saves sort criteria to redux to be used in the current session
 * @param {text} sort
 * @param {text} order
 */
export const setSort = (sort, order) => ({
  type: actions.SET_SORT,
  payload: {
    sort,
    order,
  },
});

/**
 * Returns an array of maps that normalizes to "suggestions" from the `searchResults`
 * @param searchResults
 * @returns {*}
 */
const normalizeToSuggestions = searchResults =>
  searchResults.map(result => ({
    search: result.name,
    'element-type': result.ref.type,
  }));

/**
 * Combines two search results into one ordering by the best "string" match we can find.
 * @param originalQuery
 * @param generalResults
 * @param nameResults
 * @returns {*}
 */
const suggestionBestMatchHandler = (
  originalQuery,
  [{ results: generalResults }, { results: nameResults }]
) => {
  const combinedResults = uniqBy(generalResults.concat(nameResults), 'name');
  if (isEmpty(combinedResults)) {
    return [];
  }
  // Establish the ratings based on string similiarity algorithm, "Dices coefficient"
  const { ratings } = findBestMatch(
    originalQuery,
    combinedResults.map(({ name }) => name)
  );
  // Generate result lookup by name field
  const resultLookup = keyBy(combinedResults, 'name');
  // Pluck out the results in the ratings order
  const ratingOrderedResults = orderBy(ratings, 'rating', 'desc').map(
    ({ target }) => resultLookup[target]
  );
  // Normalize result set so we can use it within the app context
  return normalizeToSuggestions(ratingOrderedResults);
};

/**
 * Performs a search request for "suggestions" across multiple network element types
 * @param query
 * @param limit
 * @param start
 * @param signal
 * @returns {Function}
 */
export const fetchSearchSuggestions = ({ query, limit = 7, start = 0, workspaceId }, { signal }) =>
  tokenThunk((dispatch, token) => {
    /*
     * We fire off 2 queries here due to "name" not returning synthetic results.
     * Sometimes we need to suggest an "exact/strict" element match that does not exist in the system data wise.
     *
     * e.g, Imagine "1.1.1.1" does not exist in the system but a user types it in.
     * The user would expect to be able to search on it.
     *
     * In this case, the query `name:"1.1.1.1"` will not return "1.1.1.1." This can lead to a weird end user experience.
     * Right now, a search input that strictly matches an element i.e "1.1.1.1" will not be "searchable".
     * It will take you directly to the element details page but no search suggestion will exist to just do a search.
     * To mitigate this edge case two queries are run. One specifically against "name" and one with
     * just the query which just returns the first result which would be an exact match or synthetic result.
     */

    const requestOpts = {
      // Pass in signal to abort in-flight requests that were already made
      signal,
    };

    dispatch({
      type: actions.FETCH_SUGGESTIONS,
      payload: Promise.all([
        graphApi.api
          .query(
            token,
            workspaceId,
            ['and', ['=', 'type', astPlusThreatTypes], query],
            { fields: ['name'], from: 0, limit: 1 },
            requestOpts
          )
          .catch(() => ({ results: [] })),
        graphApi.api
          .query(
            token,
            workspaceId,
            ['and', ['=', 'type', astPlusThreatTypes], ['like', 'name', `*${query}*`]],
            { fields: ['name'], from: 0, limit: limit - 1 },
            requestOpts
          )
          .catch(() => ({ results: [] })),
      ]).then(suggestionBestMatchHandler.bind(this, query)),
    });
  });

/**
 * Clears out the suggestions
 * @returns {{type: string}}
 */
export const clearSearchSuggestions = () => ({
  type: actions.CLEAR_SUGGESTIONS,
});

/**
 * Clears the selected rows
 * @returns {{type: string}}
 */
export const clearSearchOptions = () => ({
  type: actions.CLEAR_OPTIONS,
});

/**
 * Sets query error
 * @returns {{type: string}}
 */
export const setQueryError = () => ({
  type: actions.SET_QUERY_ERROR,
});

/**
 * Clears out the query error
 * @returns {{type: string}}
 */
export const clearQueryError = () => ({
  type: actions.CLEAR_QUERY_ERROR,
});

/**
 * Sets the current search input value and search type if provided
 * @param type
 * @param value
 * @returns {{type: string, payload: {value: *}}}
 */
export const setSearch = ({ type, value }) => ({
  type: actions.SET_SEARCH,
  payload: {
    value,
    ...(type && { type }),
  },
});

/**
 * Sets the current search type e.g, "basic", "whois"
 * @param type
 * @returns {{type: string, payload: {type: *}}}
 */
export const setSearchType = type => ({
  type: actions.SET_TYPE,
  payload: { type },
});

export default actions;
