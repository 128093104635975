import React from 'react';
import NumericLabel from 'react-pretty-numbers';

export default ({ params, children, className: cssClass }) => (
  <NumericLabel
    params={{
      shortFormatPrecision: 2,
      shortFormat: true,
      shortFormatMinValue: 100000,
      title: true,
      cssClass: [cssClass],
      ...params,
    }}>
    {children}
  </NumericLabel>
);
