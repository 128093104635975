import React from 'react';

import RadioButton from './RadioButton';

class RadioButtons extends React.Component {
  renderChildren = () => {
    const { checkedClassName, children, disabledClassName, onChange, value } = this.props;
    return React.Children.map(children, (child, index) => {
      if (child.type === RadioButton) {
        return (
          <RadioButton
            key={index}
            checkedClassName={checkedClassName}
            disabledClassName={disabledClassName}
            {...child.props}
            onChange={onChange}
            selectedValue={value}
          />
        );
      }
    });
  };

  render() {
    const { className } = this.props;
    return <div className={className}>{this.renderChildren()}</div>;
  }
}

export default RadioButtons;
