import React from 'react';
import cx from 'classnames';
import injectStyles from 'react-jss';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import FormattedLabel from '../../FormattedLabel';

class ViewAllLink extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    to: PropTypes.string.isRequired,
  };

  static defaultProps = {
    defaultMessage: 'View All',
    messageId: 'app-view-all',
  };

  render() {
    const { classes, className, count, to, defaultMessage, messageId } = this.props;

    return (
      <Link to={to} className={cx(classes.link, className)} data-test="view-all-link">
        {count ? (
          <FormattedLabel
            defaultMessage="View All ({count})"
            messageId="app-view-all-count"
            values={{ count }}
          />
        ) : (
          <FormattedLabel defaultMessage={defaultMessage} messageId={messageId} />
        )}
      </Link>
    );
  }
}

const styles = theme => ({
  link: {
    ...theme.link,
    display: 'inline-block',
    marginTop: theme.spacing,
  },
});

export default injectStyles(styles)(ViewAllLink);
