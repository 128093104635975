import { reducer as form } from 'redux-form';
import app from './app/reducer';
import app2 from './app2';
import auth from './auth/reducer';
import elements from './elements/reducer';
import entities from './entities/reducer';
import locale from './locale/reducer';
import metrics from './metrics';
import report from './report/reducer';
import user from './user/reducer';
import workspace from './workspace/';

export default {
  app,
  app2,
  auth,
  elements,
  entities,
  form,
  locale,
  metrics,
  report,
  user,
  workspace,
};
