import React from 'react';
import injectStyles from 'react-jss';
import ClearIndicator from '../../../../selects/ClearIndicator';
import Select from '../../../../selects/Select';
import selectStyles from '../../../../selects/styles';
import FieldError from '../../../errors/FieldError';
import cx from 'classnames';
import styles from '../../../styles';

export default injectStyles(styles)(
  ({
    classes,
    className,
    disabled,
    input: field,
    isClearable = true,
    isSearchable = true,
    multi = false,
    meta,
    // not using 'body' to avoid conflict with other possible portaling
    menuPortalTarget = document.querySelector('#root'),
    onInputChange,
    options,
    placeholder,
    value,
  }) => (
    <div className={cx(classes.field, className)}>
      <Select
        cacheOptions={false}
        className={classes.select}
        components={{
          ClearIndicator,
        }}
        defaultOptions={true}
        isDisabled={disabled}
        isMulti={multi}
        isClearable={isClearable}
        isSearchable={isSearchable}
        menuPortalTarget={menuPortalTarget}
        onBlur={field.onBlur}
        onChange={field.onChange}
        onInputChange={onInputChange}
        options={options}
        placeholder={placeholder}
        styles={selectStyles({ multi })}
        value={value}
      />
      <FieldError {...meta} />
    </div>
  )
);
