import actions from './actions';

const initialState = {
  data: [],
  error: null,
};

const elementsReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${actions.ELEMENTS_SEARCH}_START`: {
      return {
        ...state,
        error: null,
      };
    }

    case `${actions.ELEMENTS_SEARCH}_SUCCESS`: {
      return {
        ...state,
        data: payload,
        error: null,
      };
    }

    case `${actions.ELEMENTS_SEARCH}_ERROR`: {
      return {
        ...state,
        error: payload,
      };
    }

    case actions.CLEAR_ELEMENTS: {
      return {
        ...state,
        data: payload,
        error: null,
      };
    }

    default:
      return state;
  }
};

export default elementsReducer;
