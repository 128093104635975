import React from 'react';
import { FaTimesCircle as RemoveIcon } from 'react-icons/fa';

const CustomClearIcon = () => <RemoveIcon size={15} />;

const ClearIndicator = props => {
  const {
    children = <CustomClearIcon />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
      {children}
    </div>
  );
};

export default ClearIndicator;
