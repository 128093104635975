import { get, isEmpty } from 'lodash';

class Multimethod {
  constructor(dispatch, dfault) {
    if (!dispatch) {
      throw new Error(`Multimethod requires dispatch.`);
    }
    if (dispatch instanceof Function) {
      this.dispatch = dispatch;
    } else {
      this.dispatch = x => get(x, dispatch);
    }
    this.default = dfault;
    this.method = {};
  }

  resolveMethod(args) {
    const v = this.dispatch.apply(this, args);
    const f = this.method[v] || this.default;
    if (!f) {
      throw new Error(`No method found for dispatch value ${v}.`);
    }
    if (!(f instanceof Function)) {
      throw new Error(`Method for dispatch value ${v} is not a function.`);
    }
    return f;
  }

  call(...args) {
    if (isEmpty(args)) {
      throw new Error('Multimethod does not support 0-arity.');
    }
    const method = this.resolveMethod(args);
    return method.apply(this, args);
  }
}

/**
 * A multimethod (dynamic dispatch) geared toward simple function-based polymorphism. The
 * factory takes a dispatch function and an optional default. The function returned
 * can have methods defined using the `method` member. Calling it will route the call to
 * the proper `method` implementation based on what the dispatch function returns.
 *
 * If a non-function is passed as `dispatch`, it is assumed the multimethod will take an
 * object as its first arg, and the dispatch function will use lodash's get with the
 * `dispatch` value and that first argument during Call. This facilitates usages similar
 * to Clojure's keyword syntax.
 *
 * Note that since this uses JavaScript map syntax for defining methods, the dispatch
 * value is tied to whatever that syntax supports. It is up to the developer to decide
 * what they want to risk, but strings are safest.
 *
 * Usage:
 *
 * Define a multimethod via factory.
 *
 * const mm = multimethod(x => x.type);
 *
 * Define a method on the Multimethod via map syntax.
 *
 * mm.method.fqdn = (element) => {
 *   console.log("This is an fqdn element:", element);
 * };
 *
 * Call the method.
 *
 * mm({
 *   name: 'hamburgers.com',
 *   type: 'fqdn',
 * });
 *
 */
export default function multimethod(dispatch, dfault) {
  const mm = new Multimethod(dispatch, dfault);
  const f = (...args) => {
    return mm.call.apply(mm, args);
  };
  f.method = mm.method;
  return f;
}
