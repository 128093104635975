import get from 'lodash/get';
import { apiRequest } from '../../../lib/http';
import { API } from '../../../environment/api';
import { tokenThunk } from '../../../utils/api';

const actions = {
  FETCH_VERSION: 'app/FETCH_VERSION',
  REMOVE_STATUS_BANNER: 'app/REMOVE_STATUS_BANNER',
  SERVER_DOWN: 'app/SET_SERVER_DOWN',
  SERVER_UP: 'app/SERVER_UP',
  SET_STATUS_BANNER: 'app/SET_STATUS_BANNER',
  SET_WORKSPACE: 'app/SET_WORKSPACE',
  SET_ORGANIZATION: 'app/SET_ORGANIZATION',
};

/**
 * Sets the server down banner
 * @param message
 * @param isShowing
 * @param type
 * @returns {Function}
 */
export const setStatusBanner = ({ message = '', isShowing = true, type = 'error' }) => dispatch => {
  dispatch({
    type: actions.SET_STATUS_BANNER,
    payload: {
      statusBanner: {
        isShowing,
        message,
        type,
      },
    },
  });
};

export const clearStatusBanner = () => dispatch => {
  dispatch({
    type: actions.REMOVE_STATUS_BANNER,
  });
};

export const serverDown = () => dispatch => {
  dispatch({
    type: actions.SERVER_DOWN,
    payload: true,
  });
};

export const serverUp = () => dispatch => {
  dispatch({
    type: actions.SERVER_UP,
    payload: false,
  });
};

export const setWorkspace = workspaceId => {
  return {
    type: actions.SET_WORKSPACE,
    payload: { workspaceId },
  };
};

export const setOrganization = organizationId => {
  return {
    type: actions.SET_ORGANIZATION,
    payload: { organizationId },
  };
};

/**
 * Fetches the system version
 * @returns {Function}
 */
export const systemVersion = () =>
  tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.FETCH_VERSION,
      payload: apiRequest(token, API.SYSTEM_VERSION).then(response =>
        get(response, ['result', 'build-version'], 'Unknown Version')
      ),
    });
  });

export default actions;
