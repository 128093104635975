import React from 'react';
import injectStyles from 'react-jss';

const SomethingWentWrong = ({ classes, error, info }) => (
  <div className={classes.container}>
    <h1 className={classes.title}>Something went wrong! Please Refresh your page.</h1>
  </div>
);

const styles = theme => ({
  container: {
    display: 'flex',
    overflowY: 'auto',
    flexGrow: 1,
    padding: theme.spacing * 2,
    flexDirection: 'column',
  },
  title: {
    ...theme.typography.h1,
    color: theme.colors.red,
    margin: 0,
    fontWeight: 600,
  },
});

export default injectStyles(styles)(SomethingWentWrong);
