import { get, orderBy } from 'lodash';

/**
 * Orders and transforms `items` into valid `Select` component maps with a value and label field.
 * The value and label are determined by the `valueKey` and `labelKey` passed in which extracts
 * the values from each item of `items`.
 *
 * Optionally pass in a `labelFormatter` function that will format the label string.
 * An `id` for elements is optional.
 *
 * @param items
 * @param valueKey
 * @param labelKey
 * @param opts
 * @param idKey
 * @returns {*}
 */
export const toSelectOptions = (items, valueKey, labelKey, opts = {}, idKey = null) => {
  const { labelFormatter } = opts;
  if (!idKey) {
    idKey = valueKey;
  }
  return !items
    ? []
    : orderBy(
        items,
        item => {
          if (!item) {
            return;
          }
          const label = item[labelKey];
          return label ? label.toLowerCase() : '';
        },
        'asc'
      )
        // remove nils to prevent the usages below from exploding
        .filter(item => item)
        .map(item => ({
          id: item[idKey],
          label: labelFormatter ? labelFormatter(item) : get(item, labelKey, ''),
          value: get(item, valueKey),
        }));
};
