import React from 'react';

// Configuration
import PropTypes from 'prop-types';

// Styles
import injectStyles from 'react-jss';
import cx from 'classnames';

// Components
import { injectIntl } from 'react-intl';
import Tooltip from '../Tooltip';

class Label extends React.Component {
  static propTypes = {
    defaultMessage: PropTypes.string,
    htmlFor: PropTypes.string,
    labelAfterInput: PropTypes.bool,
    messageId: PropTypes.string,
    renderHelp: PropTypes.func,
    required: PropTypes.bool,
    // TODO: this label "value" (which is really the label text) needs to be combined
    // with defaultMessage/messageId and all usages refactored
    value: PropTypes.string,
  };

  static defaultProps = {
    // whether the label goes before the children or after, some controls (ex: text)
    // typically have the label before, others (ex: checkboxes) have it after
    labelAfterInput: false,
    required: false,
  };

  caption = () => {
    const {
      defaultMessage,
      intl,
      messageId,
      renderHelp,
      required,
      showToolTip,
      tooltip,
      tooltipPosition,
      value,
    } = this.props;
    let caption = '';
    if (messageId) {
      caption = intl.formatMessage({
        id: messageId,
        defaultMessage,
      });
    }
    if (value) {
      caption = value;
    }
    if (required) {
      caption += ' *';
    }

    if (tooltip && showToolTip) {
      caption = (
        <Tooltip
          id={tooltip}
          place={tooltipPosition}
          type="dark"
          effect="float"
          text={tooltip}
          aria-haspopup="true">
          {caption}
        </Tooltip>
      );
    }
    return (
      <React.Fragment>
        {caption}
        {renderHelp && renderHelp()}
      </React.Fragment>
    );
  };

  render() {
    const {
      classes,
      className,
      children,
      fieldClassName,
      htmlFor,
      inline,
      labelAfterInput,
      srOnly,
    } = this.props;
    const caption = this.caption();
    return (
      <label
        className={cx(
          classes.label,
          className,
          inline && classes.inlineLabel,
          srOnly && classes.srOnly,
          'form-label'
        )}
        htmlFor={htmlFor}>
        {!labelAfterInput && caption}
        {!inline && <div className={cx(classes.label__field, fieldClassName)}>{children}</div>}
        {labelAfterInput && caption}
      </label>
    );
  }
}

const styles = theme => ({
  ...theme.forms,
  inlineLabel: {
    display: 'inline',
  },
  label__field: {
    marginTop: 5,
  },
});

export default injectIntl(injectStyles(styles)(Label));
