import React from 'react';
import injectStyles from 'react-jss';
import cx from 'classnames';
import styles from '../../styles';

class ErrorBlock extends React.Component {
  render() {
    const { classes, className, error } = this.props;
    return error ? <div className={cx(classes.error, className, 'ErrorBlock')}>{error}</div> : null;
  }
}

export default injectStyles(styles)(ErrorBlock);
