export default theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    width: 'auto',
  },
  full: {
    display: 'flex',
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    zIndex: 500,
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colors.iris,
  },
  text: {
    ...theme.typography.h1,
    color: theme.colors.iris,
    fontSize: '1.0rem',
    marginBottom: theme.spacing,
    fontWeight: 400,
    letterSpacing: 0,
    maxWidth: '50vw',
  },
  hidden: {
    display: 'none',
  },
});
