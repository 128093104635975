import { combineReducers } from 'redux';
import alerts from '../../../components/app/NotificationAlert/reducer';
import collectionManagement from '../../../components/app/CollectionManagement/reducer';
import elementDetails from '../../../components/app/ElementDetails/reducer';
import report from '../../../components/app/Report/reducer';
import threatEditor from '../threatEditor/reducer';

/*
 * Unify module reducers for a nested reducer state tree in workspace
 */

export default combineReducers({
  alerts,
  collectionManagement,
  elementDetails,
  report,
  threatEditor,
});
