export default theme => ({
  container: {
    width: 400,
    height: 'auto',
  },
  no: {
    backgroundColor: 'transparent',
    border: 0,
  },
  description: {
    ...theme.typography.body,
    fontSize: '1.1rem',
    lineHeight: '32px',
    marginBottom: 0,
  },
});
