import React from 'react';

import cx from 'classnames';
import injectStyles from 'react-jss';
import { abstractPropTypes, default as AbstractButton } from './AbstractButton';

class PrimaryButton extends React.Component {
  static propTypes = abstractPropTypes;

  static defaultProps = {
    type: 'submit',
  };

  render() {
    const { classes, className, ...buttonProps } = this.props;
    return <AbstractButton className={cx(classes.primary, className)} {...buttonProps} />;
  }
}

const styles = theme => ({
  primary: {
    background: `linear-gradient(114.24deg,${theme.colors.jade} 17.28%, ${theme.colors.iris} 84.62%)`,
    borderColor: theme.colors.pearlWhite,
    color: theme.colors.white,
    transition: 'all 0.4s ease-in-out',
    '&:not(:disabled):hover': {
      borderColor: theme.colors.secondaryButtonAccent,
      color: theme.colors.secondaryButtonAccent,
      background: theme.colors.white,
    },
    '&:disabled': {
      backgroundColor: theme.colors.buttonDisabled,
      borderColor: theme.colors.buttonDisabled,
    },
  },
});

export default injectStyles(styles)(PrimaryButton);
