import React from 'react';
import { Field } from 'redux-form';
import { sortBy } from 'lodash';

// Buttons
import ActionButton from './buttons/ActionButton';
import CancelButton from './buttons/CancelButton';
import SubmitButton from './buttons/SubmitButton';

// Custom Fields
import AssociatedElementsField from './fields/custom/AssociatedElementsField';
import ClassificationsField from './fields/custom/ClassificationsField';
import CriticalityField from './fields/custom/CriticalityField';
import DescriptionField from './fields/custom/DescriptionField';
import UnassociatedThreatsField from './fields/custom/UnassociatedThreatsField';
import MultiMetadataSelectorField from './fields/custom/MultiMetadataSelectorField';
import SelectAndInputField from './fields/custom/SelectAndInputField';
import SourceCriticalityField from './fields/custom/SourceCriticalityField';
import ThreatInputField from './fields/custom/ThreatInputField';
import UserGeneratedThreatSelectField from './fields/custom/UserGeneratedThreatSelectField';

// Fields
import AsyncCreatableField from './fields/AsyncCreatableField';
import AsyncSelectField from './fields/AsyncSelectField';
import CheckboxField from './CheckboxField';
import CreatableField from './fields/CreatableField';
import DatePicker from './DatePicker';
import Fieldset from './fields/Fieldset';
import InputField from './fields/InputField';
import RadioButtons from './RadioButtons';
import RadioButton from './RadioButtons/RadioButton';
import RadioButtonsField from './RadioButtons/RadioButtonsField';
import SelectField from './fields/SelectField';
import TextAreaField from './fields/TextAreaField';
import WYSIWYGField from './fields/WYSIWYGField';

// Errors
import ErrorBlock from './errors/ErrorBlock';
import FieldError from './errors/FieldError';

// Groups
import Group from './groups/Group';
import GroupHelp from './groups/GroupHelp';
import InlineGroup from './groups/InlineGroup';

// Others
import Footer from './Footer';
import Form from './Form';
import Label from './Label';

const CollectionField = ({ collections = [], name, ...rest }) => {
  const collectionOptions = sortBy(
    collections.map(collection => ({
      label: collection.name,
      value: collection['collection-id'],
    })),
    [o => o.label && o.label.toLowerCase()]
  );
  return <Field component={SelectField} name={name} options={collectionOptions} {...rest} />;
};

export default {
  ActionButton,
  AssociatedElementsField,
  AsyncCreatableField,
  AsyncSelectField,
  CancelButton,
  CheckboxField,
  CollectionField,
  ClassificationsField,
  CreatableField,
  CriticalityField,
  DatePicker,
  DescriptionField,
  ErrorBlock,
  FieldError,
  Fieldset,
  Footer,
  Form,
  Group,
  GroupHelp,
  InputField,
  InlineGroup,
  Label,
  MultiMetadataSelectorField,
  RadioButton,
  RadioButtons,
  RadioButtonsField,
  SelectField,
  SelectAndInputField,
  SourceCriticalityField,
  SubmitButton,
  TextAreaField,
  ThreatInputField,
  UnassociatedThreatsField,
  UserGeneratedThreatSelectField,
  WYSIWYGField,
};
