import { API } from '../../../../../environment/api';
import { apiRequestRaw } from '../../../../../lib/http';
import { tokenThunk } from '../../../../../utils/api';

const actions = {
  FETCH_ENUMERATION: 'elementDetails/FETCH_ENUMERATION',
};

export function fetchEnumeration(ip) {
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.FETCH_ENUMERATION,
      payload: apiRequestRaw('GET', `${API.IPV_SHODAN}?ip=${ip}`, token),
    });
  });
}

export default actions;
