import React from 'react';

// Configuration
import PropTypes from 'prop-types';

// Components
import Autosuggest from 'react-autosuggest';

// Utils
import ReactDOM from 'react-dom';
import { min } from 'lodash';

/**
 * CustomAutoComplete is a react component which wraps AutoSuggest component.
 */
class BulkSelectSuggestions extends React.Component {
  static propTypes = {
    fetchSuggestions: PropTypes.func,
    renderSuggestion: PropTypes.func.isRequired,
    selectedOptions: PropTypes.array.isRequired,
  };

  rect;

  normalizedInputText = () => {
    const {
      renderInputProps: { value },
    } = this.props;
    return (value && value.trim().toLowerCase()) || '';
  };

  theme = {
    container: 'react-autosuggest__container',
    containerOpen: 'react-autosuggest__container--open',
    input: 'react-autosuggest__input',
    inputOpen: 'react-autosuggest__input--open',
    inputFocused: 'react-autosuggest__input--focused',
    suggestionsContainer: 'react-autosuggest__suggestions-container',
    suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
    suggestionsList: 'react-autosuggest__suggestions-list',
    suggestion: 'react-autosuggest__suggestion',
    suggestionFirst: 'react-autosuggest__suggestion--first',
    suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
    sectionContainer: 'react-autosuggest__section-container',
    sectionContainerFirst: 'react-autosuggest__section-container--first',
    sectionTitle: 'react-autosuggest__section-title',
  };

  getSuggestions = () => {
    const { fetchSuggestions, selectedOptions } = this.props;
    if (!fetchSuggestions) {
      return [];
    }
    return fetchSuggestions(this.normalizedInputText(), selectedOptions);
  };

  onSuggestionSelected = (e, { method, suggestion }) => {
    if (method !== 'click' && !e.target.value) {
      // if the user didn't type anything in, presumably enough to actually show the
      // suggestion box, don't assume a suggestion was chosen
      e.preventDefault();
      return;
    }
    const {
      renderInputProps: { addTag },
    } = this.props;
    addTag(suggestion);
  };

  // Limiting the input length to 50 chars to avoid overflowing.
  getMaxWidth = len => {
    return min([50, len + 5]);
  };

  handleOnChange = (e, { newValue, method }) => {
    const {
      renderInputProps: { onChange },
      clearRejectedText,
    } = this.props;
    const methods = ['up', 'down', 'enter', 'click'];

    if (methods.includes(method)) {
      e.preventDefault();
    } else {
      // Change event should only trigger when the user types text in the input box.
      onChange(e);

      // A callback function is called whenever there is an onChange event on the input.
      // This helps us notify the UserAutocomplete component whenever there is a change event on input.
      clearRejectedText();
    }
  };

  inputProps = () => {
    const {
      renderInputProps: { addTag, value, ...props },
    } = this.props;
    const inputLength = this.normalizedInputText().length;
    const style = {
      width: `${this.getMaxWidth(inputLength)}ch`,
    };

    return {
      ...props,
      style,
      onChange: this.handleOnChange,
      value,
    };
  };

  componentDidMount() {
    this.rect = ReactDOM.findDOMNode(this);
  }

  render() {
    const {
      renderInputProps: { ref },
      renderSuggestion,
    } = this.props;
    if (this.rect) {
      const dimensions = this.rect.getBoundingClientRect();
      const distanceFromBottom = window.innerHeight - dimensions.bottom;
      let position = 'react-autosuggest__suggestions-container--open suggestions___position__below';
      if (distanceFromBottom < 200) {
        position = 'react-autosuggest__suggestions-container--open suggestions___position__above';
      }
      this.theme.suggestionsContainerOpen = position;
    }
    return (
      <Autosuggest
        getSuggestionValue={suggestion => suggestion.username}
        highlightFirstSuggestion={true}
        inputProps={this.inputProps()}
        onSuggestionSelected={this.onSuggestionSelected}
        onSuggestionsClearRequested={() => {}}
        onSuggestionsFetchRequested={() => {}}
        ref={ref}
        renderSuggestion={renderSuggestion}
        shouldRenderSuggestions={value => value && value.trim().length > 0}
        suggestions={this.getSuggestions()}
        theme={this.theme}
      />
    );
  }
}

export default BulkSelectSuggestions;
