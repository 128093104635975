import React from 'react';

import cx from 'classnames';
import injectStyles from 'react-jss';
import { abstractPropTypes, default as AbstractButton } from './AbstractButton';

class SecondaryModalButton extends React.Component {
  static propTypes = abstractPropTypes;

  static defaultProps = {
    type: 'button',
  };

  render() {
    const { classes, className, ...buttonProps } = this.props;
    return <AbstractButton className={cx(classes.secondary, className)} {...buttonProps} />;
  }
}

const styles = theme => ({
  secondary: {
    backgroundColor: theme.colors.lighterGray,
    borderColor: theme.colors.lighterGray,
    '&:not(:disabled):hover': {
      borderColor: theme.colors.secondaryButtonAccent,
      color: theme.colors.secondaryButtonAccent,
    },
    '&:not(:disabled):active': {
      backgroundColor: theme.colors.buttonHover,
      color: theme.colors.white,
    },
    '&:disabled': {
      backgroundColor: theme.colors.white,
      borderColor: theme.colors.buttonDisabled,
    },
  },
});

export default injectStyles(styles)(SecondaryModalButton);
