export default (theme) => ({
  content: {
    backgroundColor: theme.colors.white,
    height: '100%',
    position: 'relative',
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row-reverse',
    overflow: 'hidden',
    display: 'flex',
  },
  blur: {
    filter: 'blur(2px)',
  },
});
