import graphApi from '../../../../../lib/http/graph';
import { tokenThunk } from '../../../../../utils/api';
import multimethod from '../../../../../lib/multimethod';
import { concat, get, map, union, update } from 'lodash';

const actions = {
  FETCH_OWNERSHIP: 'elementDetails/ownership/FETCH',
};

const MAX_CHILDREN = 1000;

// Tree Building
// =============

function treeKey(type, name) {
  return `${type}:${name}`;
}

function parseTreeKey(treeKey) {
  return treeKey.split(/:(.+)/);
}

function addToTree(tree, parentRef, childType, childIds) {
  // add children
  update(tree, [treeKey(parentRef.type, parentRef.id), childType], children =>
    union(children, childIds)
  );
  // add parents
  childIds.forEach(childId => {
    update(tree, [treeKey(childType, childId), parentRef.type], parents =>
      union(parents, [parentRef.id])
    );
  });
}

function treeElement(k, v) {
  const [type, id] = parseTreeKey(k);
  return {
    ...v,
    ref: { id, type },
  };
}

function flattenTree(tree) {
  return map(tree, (v, k) => {
    return treeElement(k, v);
  });
}

// Ownership Queries
// =================

const fetchElementOwnership = multimethod(
  (token, workspaceId, element) => element.ref.type,
  (action, state) => state
);

function ownersIntoTree(tree, element) {
  get(element, 'owners', []).forEach(owner => {
    addToTree(tree, { id: owner, type: 'owner' }, element.ref.type, [element.ref.id]);
  });
}

// Asn
// ---

function asnToTree(asn, cidrv4s) {
  const tree = {};
  // owners
  ownersIntoTree(tree, asn);
  // cidrv4s
  addToTree(tree, asn.ref, 'cidrv4', cidrv4s.map(c => c.ref.id));
  return flattenTree(tree);
}

fetchElementOwnership.method.asn = (token, workspaceId, element) => {
  const ast = [
    'and',
    ['or', ['=', 'type', 'cidrv4'], ['=', 'type', 'cidrv6']],
    ['=', 'asns', element.ref.id],
  ];
  // need to look up the cidrv4s that the asn owns
  return graphApi.api
    .query(token, workspaceId, ast, { fields: ['name'], limit: MAX_CHILDREN })
    .then(response => asnToTree(element, response.results));
};

// Converts data for cidrv4 and cidrv6 into structured ownership tree.
// ------

function cidrvToTree(cidrv, asns) {
  const tree = {};
  // direct owners
  ownersIntoTree(tree, cidrv);
  // asns
  asns.forEach(asn => {
    // the asn itself
    addToTree(tree, asn.ref, cidrv.ref.type, [cidrv.ref.id]);
    // the asn owners
    ownersIntoTree(tree, asn);
  });
  return flattenTree(tree);
}

fetchElementOwnership.method.cidrv4 = (token, workspaceId, element) => {
  // need to look up the owners of the cidrv(4|6)'s asns to properly form a tree
  const refs = get(element, 'asns', []).map(asn => ({ id: asn, type: 'asn' }));
  return graphApi.api
    .get(token, workspaceId, refs, ['name', 'owners'])
    .then(response => cidrvToTree(element, response));
};

fetchElementOwnership.method.cidrv6 = fetchElementOwnership.method.cidrv4;

// Converts data for ipv4 and ipv6 into structured ownership tree.
// ----

function ipvToTree(ipv, ancestors) {
  const tree = {};
  // cidrvs
  const cidrType = ipv.ref.type === 'ipv6' ? 'cidrv6' : 'cidrv4';
  get(ipv, `${cidrType}s`, []).forEach(cidrv => {
    addToTree(tree, { id: cidrv, type: cidrType }, ipv.ref.type, [ipv.ref.id]);
  });
  ancestors.forEach(ancestor => {
    // the owners of the ancestor
    ownersIntoTree(tree, ancestor);
    // the asn parents of the ipv's cidrvs
    if (ancestor.ref.type === cidrType) {
      get(ancestor, 'asns', []).forEach(asn => {
        addToTree(tree, { id: asn, type: 'asn' }, ancestor.ref.type, [ancestor.ref.id]);
      });
    }
  });
  return flattenTree(tree);
}

fetchElementOwnership.method.ipv4 = (token, workspaceId, element) => {
  // need to look up the owners of the ipv(4|6)'s asns and cidrv(4|6)s to properly form a tree
  const cidrType = element.ref.type === 'ipv6' ? 'cidrv6' : 'cidrv4';
  const refs = concat(
    get(element, 'asns', []).map(asn => ({ id: asn, type: 'asn' })),
    get(element, `${cidrType}s`, []).map(cidrv => ({ id: cidrv, type: cidrType }))
  );
  return graphApi.api
    .get(token, workspaceId, refs, ['asns', 'name', 'owners'])
    .then(response => ipvToTree(element, response));
};

fetchElementOwnership.method.ipv6 = fetchElementOwnership.method.ipv4;

// what is requested and how it is then rolled into a standard ownership tree is different
// per element type
export function fetchOwnership(workspaceId, element) {
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.FETCH_OWNERSHIP,
      payload: fetchElementOwnership(token, workspaceId, element),
    });
  });
}

export default actions;
