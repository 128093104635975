export default theme => ({
  error__container: {
    display: 'inline-flex',
  },
  //todo: refactor IconButton so I don't have to use !important
  error__icon: {
    backgroundColor: theme.colors.white,
    color: theme.colors.red,
    paddingTop: '3px',
  },
  error__message: {
    color: theme.colors.threatAssocHintGray,
    fontSize: '12px',
    paddingLeft: '2px',
    paddingTop: '5px',
  },
  header__group: {
    display: 'flex',
    marginBottom: 0,
    width: '782px',
  },
  input: {
    borderColor: theme.colors.threatAssocGray,
    height: '34px',
  },
  input__assoc: {
    color: theme.colors.darkGray,
    fontSize: '14px',
    fontWeight: 'inherit',
    maxHeight: '300px',
    resize: 'vertical',
    width: '765px',
  },
  input__file: {
    color: theme.colors.primaryLight,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row-reverse',
    margin: 0,
    padding: 0,
    textTransform: 'uppercase',
    '&:hover, &:active, &:focus': {
      color: theme.colors.primaryLight,
    },
  },
  input__hidden: {
    opacity: 0,
    position: 'absolute',
    zIndex: -1,
  },
  label: {
    fontSize: '12px',
    fontWeight: 400,
    whiteSpace: 'nowrap',
    width: 'auto',
  },
  label__hint: {
    color: theme.colors.threatAssocHintGray,
    fontSize: '12px',
    paddingLeft: '2px',
  },
});
