import multimethod from '../../../../../lib/multimethod';
import graphApi from '../../../../../lib/http/graph';
import actions from './actions';
import { actionError, actionStart, actionSuccess } from '../../../../../redux/common';
import { get, keyBy, set } from 'lodash';
import assign from 'lodash/fp/assign';

const threatProperties = multimethod('type', (action, state) => state);

threatProperties.method[actions.CLEAR_THREAT_PROPERTIES_UPDATE] = (action, state) => {
  return assign(state, {
    updateError: null,
  });
};

threatProperties.method[actionStart(actions.FETCH_THREAT_ASSOCIATIONS)] = (action, state) => {
  return assign(state, {
    associations: null,
    isLoadingAssociations: true,
    isRequestingAssociations: true,
  });
};

threatProperties.method[actionSuccess(actions.FETCH_THREAT_ASSOCIATIONS)] = (action, state) => {
  return assign(state, {
    associations: graphApi.response
      .toAnalyticsResults(action.payload)
      .types.results.reduce((acc, r) => {
        return set(acc, r['left.type'], r._count);
      }, {}),
    isLoadingAssociations: false,
    isRequestingAssociations: false,
  });
};

threatProperties.method[actionError(actions.FETCH_THREAT_ASSOCIATIONS)] = (action, state) => {
  return assign(state, {
    error: action.payload,
    isLoadingAssociations: false,
    isRequestingAssociations: false,
  });
};

threatProperties.method[actionStart(actions.FETCH_THREAT_PROPERTIES)] = (action, state) => {
  return assign(state, {
    properties: null,
    isLoadingProperties: true,
    isRequestingProperties: true,
  });
};

const elementToProperty = multimethod('ref.type');

elementToProperty.method.classification = element => {
  return {
    affectedThreats: get(element, 'relationshipCounts.right.classified-as'),
    field: 'ticClassificationScore',
    name: element.name,
    ref: element.ref,
    type: element.ref.type,
    value: element.ticClassificationScore,
  };
};

elementToProperty.method.source = element => {
  return {
    affectedThreats: get(element, 'relationshipCounts.right.sourced-from'),
    field: 'ticSourceScore',
    name: element.name,
    ref: element.ref,
    type: element.ref.type,
    value: element.ticSourceScore,
  };
};

threatProperties.method[actionSuccess(actions.FETCH_THREAT_PROPERTIES)] = (action, state) => {
  const properties = action.payload.filter(p => p.found !== false).map(elementToProperty);
  properties.push(action.criticalityProperty);
  return assign(state, {
    isLoadingProperties: false,
    isRequestingProperties: false,
    properties,
  });
};

threatProperties.method[actionError(actions.FETCH_THREAT_PROPERTIES)] = (action, state) => {
  return assign(state, {
    error: action.payload,
    isLoadingProperties: false,
    isRequestingProperties: false,
  });
};

threatProperties.method[actionStart(actions.UPDATE_THREAT_PROPERTIES)] = (action, state) => {
  return assign(state, {
    isUpdating: true,
    updateError: null,
  });
};

threatProperties.method[actionSuccess(actions.UPDATE_THREAT_PROPERTIES)] = (action, state) => {
  const updatedProperties = keyBy(action.updatedProperties, 'ref.id');
  return assign(state, {
    isUpdating: false,
    properties: state.properties.map(p => get(updatedProperties, p.ref.id, p)),
  });
};

threatProperties.method[actionError(actions.UPDATE_THREAT_PROPERTIES)] = (action, state) => {
  return assign(state, {
    isUpdating: false,
    updateError: action.payload,
  });
};

const initialState = {
  associations: null,
  error: null,
  isLoadingAssociations: false,
  isRequestingAssociations: false,
  isLoadingProperties: false,
  isRequestingProperties: false,
  properties: null,
};

export default (state = initialState, action) => threatProperties(action, state);
