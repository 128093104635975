import React from 'react';
import injectStyles from 'react-jss';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { isInteger, isUndefined } from 'lodash';
import cx from 'classnames';
import {
  FaAngleLeft as PreviousPageIcon,
  FaAngleDoubleLeft as PreviousJumpIcon,
  FaAngleRight as NextPageIcon,
  FaAngleDoubleRight as NextJumpIcon,
} from 'react-icons/fa';
import { KEYCODE, noop } from '../../../utils/dom';
import IconButton from '../buttons/IconButton';
import ArrowKeyNavigation from '../ArrowKeyNavigation';
import Pager from './Pager';
import Options from './Options';
import styles from './styles';
import { PAGINATION_LIMIT, MAX_RESULT_WINDOW } from '../../../utils/pagination';

class Pagination extends React.Component {
  static propTypes = {
    current: PropTypes.number,
    defaultCurrent: PropTypes.number,
    defaultPageSize: PropTypes.number,
    hideOnSinglePage: PropTypes.bool,
    jumpPrevIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    jumpNextIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    nextIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    onChange: PropTypes.func,
    pageSize: PropTypes.number,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.string),
    prevIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    showSizeChanger: PropTypes.bool,
    showLessItems: PropTypes.bool,
    hideGoto: PropTypes.bool,
    hideArrows: PropTypes.bool,
    showPrevNextJumpers: PropTypes.bool,
    showQuickJumper: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    total: PropTypes.number,
    showTotal: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    defaultCurrent: 1,
    defaultPageSize: 10,
    hideOnSinglePage: false,
    onChange: noop,
    showPrevNextJumpers: false,
    showQuickJumper: false,
    showSizeChanger: true,
    showLessItems: false,
    total: 0,
    hideGoto: false,
    hideArrows: false,
  };

  constructor(props) {
    super(props);

    const hasOnChange = props.onChange !== noop;
    const hasCurrent = 'current' in props;
    if (hasCurrent && !hasOnChange) {
      console.warn(
        'Warning: You provided a `current` prop to a Pagination component ' +
          'without an `onChange` handler. This will render a read-only component.'
      ); // eslint-disable-line
    }

    let current = props.defaultCurrent;
    if ('current' in props) {
      current = props.current;
    }

    let pageSize = props.defaultPageSize;
    if ('pageSize' in props) {
      pageSize = props.pageSize;
    }

    this.state = {
      current,
      currentInputValue: current,
      pageSize,
    };
  }

  componentDidUpdate({ current, value = '' }) {
    if (!isUndefined(this.props.current) && current !== this.props.current) {
      this.setState({
        current: this.props.current,
        currentInputValue: this.props.current,
      });
    }

    if (!isUndefined(this.props.pageSize)) {
      const newState = {};
      let current = this.state.current;
      const newCurrent = this.calculatePage(this.props.pageSize);
      current = current > newCurrent ? newCurrent : current;
      if (!this.props.current) {
        newState.current = current;
        newState.currentInputValue = current;
      }
      newState.pageSize = this.props.pageSize;
      this.setState(newState);
    }

    if (this.props.value !== value && !isUndefined(this.props.value)) {
      this.setState({ current: 1, currentInputValue: 1, pageSize: this.props.defaultPageSize });
    }
  }

  calculatePage = p => {
    let pageSize = p;
    if (typeof pageSize === 'undefined') {
      pageSize = this.state.pageSize;
    }
    const totalPages = Math.floor((this.props.total - 1) / pageSize) + 1;
    const end = (totalPages - 1) * pageSize + pageSize;

    if (end > MAX_RESULT_WINDOW) {
      return Math.floor(MAX_RESULT_WINDOW / pageSize);
    } else {
      return totalPages;
    }
  };

  isValid = page => {
    return isInteger(page) && page >= 1 && page !== this.state.current;
  };

  handleKeyDown = e => {
    if (e.keyCode === KEYCODE.ARROW_UP || e.keyCode === KEYCODE.ARROW_DOWN) {
      e.preventDefault();
    }
  };

  changePageSize = size => {
    const current = 1;

    if (typeof size === 'number') {
      if (!('pageSize' in this.props)) {
        this.setState({
          pageSize: size,
        });
      }
      if (!('current' in this.props)) {
        this.setState({
          current,
          currentInputValue: current,
        });
      }
    }
    this.props.onChange(current, size);
  };

  handleChange = p => {
    let page = p;
    if (this.isValid(page)) {
      if (page > this.calculatePage()) {
        page = this.calculatePage();
      }

      if (!('current' in this.props)) {
        this.setState({
          current: page,
          currentInputValue: page,
        });
      }

      const pageSize = this.state.pageSize;
      this.props.onChange(page, pageSize);

      return page;
    }

    return this.state.current;
  };

  prev = () => {
    if (this.hasPrev()) {
      this.handleChange(this.state.current - 1);
    }
  };

  next = () => {
    if (this.hasNext()) {
      this.handleChange(this.state.current + 1);
    }
  };

  getJumpPrevPage = () => {
    return Math.max(1, this.state.current - (this.props.showLessItems ? 3 : 5));
  };

  getJumpNextPage = () => {
    return Math.min(this.calculatePage(), this.state.current + (this.props.showLessItems ? 3 : 5));
  };

  jumpPrev = () => {
    this.handleChange(this.getJumpPrevPage());
  };

  jumpNext = () => {
    this.handleChange(this.getJumpNextPage());
  };

  hasPrev = () => {
    return this.state.current > 1;
  };

  hasNext = () => {
    return this.state.current < this.calculatePage();
  };

  runIfEnter = (event, callback, ...restParams) => {
    if (event.key === 'Enter' || event.charCode === 13) {
      callback(...restParams);
    }
  };

  runIfEnterPrev = e => {
    this.runIfEnter(e, this.prev);
  };

  runIfEnterNext = e => {
    this.runIfEnter(e, this.next);
  };

  runIfEnterJumpPrev = e => {
    this.runIfEnter(e, this.jumpPrev);
  };

  runIfEnterJumpNext = e => {
    this.runIfEnter(e, this.jumpNext);
  };

  render() {
    // When hideOnSinglePage is true and there is only 1 page, hide the pager
    if (this.props.hideOnSinglePage === true && this.props.total <= this.state.pageSize) {
      return <div />;
    }

    const { classes, className, intl, ...props } = this.props;
    const prefixCls = props.prefixCls;
    const allPages = this.calculatePage();
    const pagerList = [];
    let jumpPrev = null;
    let jumpNext = null;
    let firstPager = null;
    let lastPager = null;

    const goButton = props.showQuickJumper && props.showQuickJumper.goButton;
    const pageBufferSize = props.showLessItems ? 1 : 2;
    const { current, pageSize } = this.state;

    const prevPage = current - 1 > 0 ? current - 1 : 0;
    const nextPage = current + 1 < allPages ? current + 1 : Math.max(1, allPages);

    const dataOrAriaAttributeProps = Object.keys(props).reduce((prev, key) => {
      if (key.substr(0, 5) === 'data-' || key.substr(0, 5) === 'aria-' || key === 'role') {
        prev[key] = props[key];
      }
      return prev;
    }, {});
    if (allPages <= 5 + pageBufferSize * 2) {
      for (let i = 1; i <= allPages; i++) {
        const active = this.state.current === i;
        pagerList.push(
          <Pager
            hidePageNumber={props.hideArrows}
            classes={classes}
            intl={intl}
            onClick={this.handleChange}
            onKeyPress={this.runIfEnter}
            key={i}
            page={i}
            active={active}
          />
        );
      }
    } else {
      const prevItemTitle = props.showLessItems
        ? intl.formatMessage({
            id: 'pagination-prev-3',
            defaultMessage: 'Previous 3 Pages',
          })
        : intl.formatMessage({
            id: 'pagination-prev-5',
            defaultMessage: 'Previous 5 Pages',
          });
      const nextItemTitle = props.showLessItems
        ? intl.formatMessage({
            id: 'pagination-next-3',
            defaultMessage: 'Next 3 Pages',
          })
        : intl.formatMessage({
            id: 'pagination-next-5',
            defaultMessage: 'Next 5 Pages',
          });
      if (props.showPrevNextJumpers && !props.hideArrows) {
        jumpPrev = (
          <li
            title={prevItemTitle}
            key="prev"
            onClick={this.jumpPrev}
            tabIndex="0"
            onKeyPress={this.runIfEnterJumpPrev}
            className={classes.pager__jump}>
            <IconButton
              className={classes.jump__prev}
              icon={<PreviousJumpIcon size={15} />}
              onClick={this.getJumpPrevPage}
              tooltip={prevItemTitle}
            />
          </li>
        );

        jumpNext = (
          <li
            title={nextItemTitle}
            key="next"
            tabIndex="0"
            onClick={this.jumpNext}
            onKeyPress={this.runIfEnterJumpNext}
            className={classes.pager__jump}>
            <IconButton
              className={classes.jump__next}
              icon={<NextJumpIcon size={15} />}
              onClick={this.getJumpNextPage}
              tooltip={nextItemTitle}
            />
          </li>
        );
      }

      lastPager = (
        <Pager
          classes={classes}
          hidePageNumber={props.hideArrows}
          intl={intl}
          last
          onClick={this.handleChange}
          onKeyPress={this.runIfEnter}
          key={allPages}
          page={allPages}
          active={false}
          style={{ marginRight: 0 }}
        />
      );

      firstPager = (
        <Pager
          classes={classes}
          hidePageNumber={props.hideArrows}
          intl={intl}
          onClick={this.handleChange}
          onKeyPress={this.runIfEnter}
          key={1}
          page={1}
          active={false}
        />
      );

      let left = Math.max(1, current - pageBufferSize);
      let right = Math.min(current + pageBufferSize, allPages);

      if (current - 1 <= pageBufferSize) {
        right = 1 + pageBufferSize * 2;
      }

      if (allPages - current <= pageBufferSize) {
        left = allPages - pageBufferSize * 2;
      }

      for (let i = left; i <= right; i++) {
        const active = current === i;
        pagerList.push(
          <Pager
            classes={classes}
            hidePageNumber={props.hideArrows}
            intl={intl}
            onClick={this.handleChange}
            onKeyPress={this.runIfEnter}
            key={i}
            page={i}
            active={active}
          />
        );
      }

      if (current - 1 >= pageBufferSize * 2 && current !== 1 + 2) {
        pagerList[0] = React.cloneElement(pagerList[0]);
        pagerList.unshift(jumpPrev);
      }
      if (allPages - current >= pageBufferSize * 2 && current !== allPages - 2) {
        pagerList[pagerList.length - 1] = React.cloneElement(pagerList[pagerList.length - 1]);
        pagerList.push(jumpNext);
      }

      if (left !== 1) {
        pagerList.unshift(firstPager);
      }
      if (right !== allPages) {
        pagerList.push(lastPager);
      }
    }

    let totalText = null;

    if (props.showTotal) {
      totalText = (
        <li className={`${prefixCls}-total-text`}>
          {props.showTotal(props.total, [
            (current - 1) * pageSize + 1,
            current * pageSize > props.total ? props.total : current * pageSize,
          ])}
        </li>
      );
    }
    const prevDisabled = !this.hasPrev();
    const nextDisabled = !this.hasNext();

    return (
      <ArrowKeyNavigation
        component="ul"
        data-test="pagination"
        mode={ArrowKeyNavigation.mode.HORIZONTAL}
        className={cx(classes.pager, className)}
        {...dataOrAriaAttributeProps}>
        {totalText}
        {!props.hideArrows && (
          <li
            title={intl.formatMessage({
              id: 'pagination-prev-page',
              defaultMessage: 'Previous Page',
            })}
            onClick={this.prev}
            tabIndex={prevDisabled ? null : 0}
            onKeyPress={this.runIfEnterPrev}
            className={cx({
              [classes.pager__action]: true,
              [classes.pager__prev]: true,
              [classes.disabled]: prevDisabled,
            })}
            aria-disabled={prevDisabled}>
            <IconButton
              className={classes.arrows}
              disabled={prevDisabled}
              icon={<PreviousPageIcon size={15} />}
              tooltip={prevPage}
            />
          </li>
        )}
        {pagerList}
        {!props.hideArrows && (
          <li
            title={intl.formatMessage({
              id: 'pagination-next-page',
              defaultMessage: 'Next Page',
            })}
            onClick={this.next}
            tabIndex={nextDisabled ? null : 0}
            onKeyPress={this.runIfEnterNext}
            className={cx({
              [classes.pager__action]: true,
              [classes.pager__next]: true,
              [classes.disabled]: nextDisabled,
            })}
            aria-disabled={nextDisabled}>
            <IconButton
              className={classes.arrows}
              disabled={nextDisabled}
              icon={<NextPageIcon size={15} />}
              tooltip={nextPage}
            />
          </li>
        )}
        <Options
          allPages={allPages}
          classes={classes}
          hideGoto={props.hideGoto}
          intl={intl}
          changeSize={this.props.showSizeChanger ? this.changePageSize : null}
          current={this.state.current}
          pageSize={this.state.pageSize}
          pageSizeOptions={this.props.pageSizeOptions}
          quickGo={this.props.showQuickJumper ? this.handleChange : null}
          goButton={goButton}
        />
      </ArrowKeyNavigation>
    );
  }
}

export default injectIntl(injectStyles(styles)(Pagination));
