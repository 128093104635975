import { tokenThunk } from '../../../../../utils/api';
import graph from '../../../../../lib/http/graph';

const actions = {
  FETCH_ProductInformation: 'elementDetails/FETCH_ProductInformation',
};

export function fetchProductInformation(element, workspaceId) {
  const ast = [
    'and',
    ['=', 'type', 'is-running'],
    ['=', `left.${element.ref.type}`, element.ref.id],
  ];
  const params = {
    fields: ['right.cpes', 'right.inferredVulnerabilityIds'],
    limit: 10,
  };
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.FETCH_ProductInformation,
      payload: graph.api.query(token, workspaceId, ast, params),
    });
  });
}

export default actions;
