import React from 'react';

// Configuration
import PropTypes from 'prop-types';

// Styles
import cx from 'classnames';
import injectStyles from 'react-jss';

// Components
import { injectIntl } from 'react-intl';
import { MdKeyboardArrowDown as DropdownArrow } from 'react-icons/md';

class ActionsButton extends React.Component {
  static propTypes = {
    arrowSize: PropTypes.number,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    arrowSize: 20,
  };

  render() {
    const { arrowSize, classes, className, disabled, id, intl, onClick } = this.props;
    const caption = intl.formatMessage({
      id: 'action-menu-actions',
      defaultMessage: 'Actions',
    });
    return (
      <button
        className={cx(classes.button, className)}
        data-test="actions-button"
        disabled={disabled}
        id={id}
        onClick={onClick}>
        <span className={classes.text} data-text={caption}>
          {caption}
        </span>
        <DropdownArrow
          className={cx({
            [classes.dropdownArrow]: true,
          })}
          size={arrowSize}
        />
      </button>
    );
  }
}

const styles = theme => ({
  button: {
    alignItems: 'center',
    backgroundColor: theme.colors.lilac,
    border: {
      color: theme.colors.lightLilac,
      style: 'solid',
      width: 1,
    },
    borderRadius: theme.borderRadius.default,
    color: theme.colors.black,
    cursor: 'pointer',
    display: 'flex',
    padding: {
      left: 15,
      // right padding (15) + arrow width (10) + space between text and icon (8)
      right: 33,
    },
    ...theme.buttons,
    position: 'relative',
    '&:disabled': {
      border: 'none',
      backgroundColor: theme.colors.disabled,
      color: theme.colors.lightGray,
      cursor: 'default',
    },
    '& span': {
      pointerEvents: 'none',
    },
  },
  dropdownArrow: {
    color: theme.colors.headerGray,
    marginTop: 2,
    pointerEvents: 'none',
    position: 'absolute',
    bottom: '0.5em',
    right: 10,
  },
  // force the text size to lay out as if bolded (because of a secret always-there-but-invisible
  // pseudo-element) so that the size doesn't jiggle...if this capability is needed elsewhere,
  // we can make a component or a reusable class
  text: {
    '&::after': {
      color: 'transparent',
      content: 'attr(data-text)',
      display: 'block',
      fontWeight: 'bold',
      height: 1,
      overflow: 'hidden',
      visibility: 'hidden',
    },
  },
});

export default injectIntl(injectStyles(styles)(ActionsButton));
