import React from 'react';

// Components
import { DisguisedButton } from './index';

// Styles
import injectStyles from 'react-jss';

// Utils
import withSessionToken from '../../common/withSessionToken';
import { reportDownloadable } from '../../../utils/reports';

class DownloadReport extends React.Component {
  render() {
    const { classes, report, sessionToken } = this.props;
    return (
      <DisguisedButton
        className={classes.link}
        onClick={reportDownloadable(sessionToken, report)}
        text={report.name}
      />
    );
  }
}

const styles = theme => ({
  link: {
    ...theme.link,
    padding: `${theme.spacing} 0`,
  },
});

export default withSessionToken(injectStyles(styles)(DownloadReport));
