import actions, { localeLanguages } from './actions';
import locales from '../../../assets/locales';

const initialState = {
  locale: 'en',
  language: 'en-US',
  messages: locales['en-US'],
  loadingLocale: false,
  localeLanguages,
};

const localeReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${actions.CHANGE_LOCALE}_START`: {
      return {
        ...state,
        loadingLocale: true,
      };
    }

    case `${actions.CHANGE_LOCALE}_SUCCESS`: {
      return {
        ...state,
        ...payload,
        loadingLocale: false,
      };
    }

    case `${actions.CHANGE_LOCALE}_ERROR`: {
      return {
        ...state,
        error: payload.error,
        loadingLocale: false,
      };
    }

    default:
      return state;
  }
};

export default localeReducer;
