import { combineReducers } from 'redux';
import header from './Header/reducer';
import owners from './Owners/reducer';
import classifications from './Classifications/reducer';
import multimethod from '../../../../lib/multimethod';
import graphApi from '../../../../lib/http/graph';
import { actionError, actionStart, actionSuccess, apiData } from '../../../../redux/common';
import actions from './actions';
import { size } from 'lodash';
import assign from 'lodash/fp/assign';
import { bucketizeSeverities, bucketizeThreats } from '../../../../utils/tic';

const elementAnalytics = multimethod('type', (action, state) => state);

elementAnalytics.method[actionStart(actions.FETCH_ELEMENT_ANALYTICS)] = apiData.fetchStarted;

elementAnalytics.method[actionSuccess(actions.FETCH_ELEMENT_ANALYTICS)] = (action, state) => {
  const results = graphApi.response.toAnalyticsResults(action.payload);
  return assign(state, {
    data: {
      severities: bucketizeSeverities(results.numElementsByScore.results),
      types: results.types.results,
    },
    isLoading: false,
    isRequesting: false,
  });
};

elementAnalytics.method[actionError(actions.FETCH_ELEMENT_ANALYTICS)] = apiData.fetchErrored;

const threatAnalytics = multimethod('type', (action, state) => state);

threatAnalytics.method[actionStart(actions.FETCH_THREAT_ANALYTICS)] = apiData.fetchStarted;

threatAnalytics.method[actionSuccess(actions.FETCH_THREAT_ANALYTICS)] = (action, state) => {
  // Analytics query for risks
  const risks = graphApi.response.toAnalyticsResults(action.payload).risks.results;

  return assign(state, {
    data: bucketizeThreats(risks),
    isLoading: false,
    isRequesting: false,
    total: size(risks),
  });
};

threatAnalytics.method[actionError(actions.FETCH_THREAT_ANALYTICS)] = apiData.fetchErrored;

export default combineReducers({
  classifications,
  elementAnalytics: (state = apiData.initialState, action) => elementAnalytics(action, state),
  header,
  owners,
  threatAnalytics: (state = apiData.initialState, action) => threatAnalytics(action, state),
});
