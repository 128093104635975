export default theme => ({
  button__add: {
    color: theme.colors.primaryLight,
    height: 'auto',
    padding: 0,
    '&:hover, &:active, &:focus': {
      color: theme.colors.primaryLight,
    },
  },
  colon: {
    fontSize: '15px',
    padding: '6px',
  },
  field: {
    borderColor: theme.colors.threatAssocGray,
    width: '250px',
  },
  group: {
    display: 'flex',
    paddingBottom: '14px',
    width: '100%',
  },
  input: {
    borderColor: theme.colors.threatAssocGray,
    height: '34px',
    width: '500px',
  },
  remove: {
    color: theme.colors.threatAssocGray,
    background: 'none',
    height: '38px',
    paddingLeft: '10px',
  },
  select: {
    borderColor: theme.colors.threatAssocGray,
    width: '250px',
  },
});
