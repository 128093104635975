import React from 'react';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';

function getQueryParams(location) {
  return qs.parse(location.search, { ignoreQueryPrefix: true });
}

function withQueryParams(queryParamsToProps) {
  function wrapper(WrappedComponent) {
    const component = props => {
      const queryParams = getQueryParams(props.location);
      return <WrappedComponent {...props} {...queryParamsToProps(queryParams)} />;
    };
    return withRouter(component);
  }
  return wrapper;
}

export default withQueryParams;
