import React from 'react';
import injectStyles from 'react-jss';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Button from '../buttons/Base';
import styles from './styles';

const StatusBanner = ({ children, classes, id, isShowing, message, onDismiss, size, type }) => (
  <div
    id={id}
    className={cx({
      [classes.status__container]: true,
      [classes.showing]: isShowing,
      [classes[type]]: true,
    })}>
    <div className={classes.status}>
      <span className={classes.text}>{message}</span>
      <Button
        id="dismiss-status"
        role="text"
        className={classes.dismiss}
        value="Dismiss"
        onClick={onDismiss}
      />
    </div>
    {children && children.length > 0 && <div className={classes.actions}>{children}</div>}
  </div>
);

StatusBanner.defaultProps = {
  indicator: 'error',
  isShowing: false,
  status: '',
};

StatusBanner.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  indicator: PropTypes.string,
  size: PropTypes.number,
  type: PropTypes.oneOf(['info', 'warning', 'error']),
  children: PropTypes.node,
};

export default injectStyles(styles)(StatusBanner);
