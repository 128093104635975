import { get } from 'lodash';
import assign from 'lodash/fp/assign';
import multimethod from '../../../../../lib/multimethod';
import actions from './actions';
import { DEFAULT_SEARCH_LIMIT } from '../../actions';
import { actionError, actionStart, actionSuccess, apiData } from '../../../../../redux/common';

const initialState = {
  data: [],
  error: null,
  isDeleted: false,
  isRequesting: false,
  reportExists: null,
  page: 1,
  query: '',
  size: DEFAULT_SEARCH_LIMIT,
  sortBy: '',
  totalResults: 0,
};

const { fetchErrored, fetchStarted } = apiData;
const reportActions = multimethod('type', (action, state) => state);

reportActions.method[actionStart(actions.READ_REPORTS)] = fetchStarted;

reportActions.method[actionError(actions.READ_REPORTS)] = fetchErrored;

reportActions.method[actionSuccess(actions.READ_REPORTS)] = (action, state) => {
  const data = get(action, 'payload.result', []);
  const { size, sortBy, page, query } = action;
  const totalResults = get(action, 'payload.summary.total-results', 0);
  return assign(state, {
    data: data.map(report => {
      report.name = report.name.substring(0, report.name.length - 4);
      return report;
    }),
    isRequesting: false,
    size,
    sortBy,
    page,
    query,
    totalResults,
  });
};

reportActions.method[actionStart(actions.READ_REPORT)] = (action, state) => {
  return assign(state, {
    reportExists: null,
    isRequesting: true,
  });
};

reportActions.method[actionError(actions.READ_REPORT)] = (action, state) => {
  return assign(state, {
    error: action.payload,
    isRequesting: false,
  });
};

reportActions.method[actionSuccess(actions.READ_REPORT)] = (action, state) => {
  const data = get(action, 'payload.result');
  return assign(state, {
    reportExists: Boolean(data),
    isRequesting: false,
  });
};

reportActions.method[actionStart(actions.DELETE_REPORT)] = (action, state) => {
  return assign(state, {
    isDeleted: false,
    isRequesting: true,
  });
};

reportActions.method[actionError(actions.DELETE_REPORT)] = (action, state) => {
  return assign(state, {
    error: action.payload,
    isRequesting: true,
    isDeleted: false,
  });
};

reportActions.method[actionSuccess(actions.DELETE_REPORT)] = (action, state) => {
  const ok = get(action, 'payload.ok');
  const { id } = action;
  let { data } = state;
  data = data.filter(report => report.id !== id);
  return assign(state, {
    data,
    isDeleted: Boolean(ok),
    isRequesting: false,
  });
};

export default (state = initialState, action) => reportActions(action, state);
