import { tokenThunk } from '../../../../utils/api';
import graph from '../../../../lib/http/graph';
import { isEmpty } from 'lodash';
import { astPlusOwnerTypes } from '../../../../utils/elements';

const actions = {
  ASSOC_PER_DAY: 'threatStats/ASSOC_PER_DAY',
  COMPROMISED_ELEMENT_TYPES: 'threatStats/COMPROMISED_ELEMENT_TYPES',
  FEED_ANALYTICS: 'threatStats/FEED_ANALYTICS',
  FEED_OBSERVABLES_PAGE: 'threats/FEED_OBSERVABLES_PAGE',
  FEED_OBSERVABLES: 'threatStats/FEED_OBSERVABLES',
  FEEDS: 'threatStats/FEEDS',
  SELECT_FEED: 'threatStats/SELECT_FEED',
  SUMMARY: 'threatStats/SUMMARY',
  THREAT_SEVERITIES: 'threatStats/THREAT_SEVERITIES',
  THREATS_PER_FEED: 'threatStats/THREATS_PER_FEED',
};

export function feedObservablesPage(page, pageSize, sortBy) {
  return {
    page,
    pageSize,
    sortBy,
    type: actions.FEED_OBSERVABLES_PAGE,
  };
}

export function fetchAssociationsPerDay(workspaceId) {
  const ast = ['=', 'type', 'associated-with'];
  const analytics = graph.builder
    .analytics()
    .dateOverlapTrendAgg('numAssociationsPerDay', 14, 'day', 'firstSeen', 'lastSeen')
    .build();
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.ASSOC_PER_DAY,
      payload: graph.api.analytics(token, workspaceId, ast, analytics),
    });
  });
}

export function fetchCompromisedElementTypes(workspaceId) {
  const ast = ['and', ['=', 'type', astPlusOwnerTypes], ['like', 'threatIds', '*']];
  const analytics = graph.builder
    .analytics()
    .groupByAgg('numElementsByType', 'type')
    .limit(10000)
    .build();
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.COMPROMISED_ELEMENT_TYPES,
      payload: graph.api.analytics(token, workspaceId, ast, analytics),
    });
  });
}

export function fetchFeeds(workspaceId) {
  const ast = '*';
  const analytics = graph.builder
    .analytics()
    .groupByAgg('numElementsBySource', 'sources')
    .limit(10000)
    .build();
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.FEEDS,
      payload: graph.api.analytics(token, workspaceId, ast, analytics),
    });
  });
}

export function fetchFeedAnalytics(workspaceId, feed) {
  const ast = ['and', ['=', 'type', 'threat'], ['=', 'sources', feed]];
  const analytics = graph.builder
    .analytics()
    .groupByAgg('numThreatsByClassification', 'classifications')
    .limit(10000)
    .groupByAgg('numThreatsByScore', 'ticScore')
    .limit(10000)
    .build();
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.FEED_ANALYTICS,
      payload: graph.api.analytics(token, workspaceId, ast, analytics),
    });
  });
}

export function fetchFeedObservables(workspaceId, feed, page, pageSize, sortBy) {
  const ast = ['and', ['=', 'type', 'threat'], ['=', 'sources', feed]];

  let params = {
    fields: ['name', 'ticHistory7d', 'ticScore'],
    from: page * pageSize,
    limit: pageSize,
  };
  if (!isEmpty(sortBy)) {
    params['sort-by'] = sortBy;
  }
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.FEED_OBSERVABLES,
      payload: graph.api.query(token, workspaceId, ast, params),
    });
  });
}

export function fetchSummary(workspaceId) {
  const ast = ['=', 'type', 'associated-with'];
  const analytics = graph.builder
    .analytics()
    .minAgg('firstSeenAt', 'firstSeen')
    .cardinalityAgg('numSources', 'sources')
    .cardinalityAgg('numThreats', 'right.threatName')
    .build();
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.SUMMARY,
      payload: graph.api.analytics(token, workspaceId, ast, analytics),
    });
  });
}

export function fetchThreatsPerFeed(workspaceId) {
  const ast = ['=', 'type', 'threat'];
  const analytics = graph.builder
    .analytics()
    .groupByAgg('numThreatsPerSource', 'sources')
    .limit(10000)
    .build();
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.THREATS_PER_FEED,
      payload: graph.api.analytics(token, workspaceId, ast, analytics),
    });
  });
}

export function fetchThreatSeverities(workspaceId) {
  const ast = ['=', 'type', 'threat'];
  const analytics = graph.builder
    .analytics()
    .groupByAgg('numThreatsByScore', 'ticScore')
    .limit(10000)
    .build();
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.THREAT_SEVERITIES,
      payload: graph.api.analytics(token, workspaceId, ast, analytics),
    });
  });
}

export function selectFeed(feed) {
  return {
    feed,
    type: actions.SELECT_FEED,
  };
}

export default actions;
