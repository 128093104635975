import React from 'react';
import injectStyles from 'react-jss';
import cx from 'classnames';
import NumericLabel from '../NumericLabel';
import styles from './styles';

const TotalResults = ({
  classes,
  className,
  hideTotal,
  rangeCount,
  resultCount,
  totalCount,
  text,
}) => (
  <div className={cx(classes.container, className)} data-test="total-results">
    <span className={classes.label}>{text}</span>
    {rangeCount ? (
      <span className={classes.range}>
        <NumericLabel className="TotalResults__resultCount">
          {Math.min(resultCount + 1, totalCount)}
        </NumericLabel>
        <span className={classes.separator}>-</span>
        <NumericLabel className="TotalResults__range">
          {Math.min(resultCount + rangeCount, totalCount)}
        </NumericLabel>
      </span>
    ) : (
      <NumericLabel className="TotalResults__resultCount">{resultCount}</NumericLabel>
    )}
    {!hideTotal && (
      <React.Fragment>
        <span className={classes.separator}>of</span>
        <NumericLabel className="TotalResults__totalCount">{totalCount}</NumericLabel>
      </React.Fragment>
    )}
  </div>
);

TotalResults.defaultProps = {
  text: 'Showing',
};

export default injectStyles(styles)(TotalResults);
