import React from 'react';
import { Field } from 'redux-form';
import InputField from '../../InputField';
import cx from 'classnames';
import injectStyles from 'react-jss';

class SourceCriticialityField extends React.Component {
  render() {
    const { classes, min = 10, max = 100 } = this.props;
    return (
      <Field
        id="source-criticality"
        className={cx(classes.input, classes.input__sourceCriticality)}
        component={InputField}
        min={min}
        max={max}
        name="sourceCriticality"
        required={true}
        type="number"
      />
    );
  }
}

const styles = theme => ({
  input: {
    height: '34px',
    borderColor: theme.colors.threatAssocGray,
  },
  input__sourceCriticality: {
    width: '58px',
  },
});

export default injectStyles(styles)(SourceCriticialityField);
