import multimethod from '../../../../../lib/multimethod';
import graphApi from '../../../../../lib/http/graph';
import { actionError, actionStart, actionSuccess, apiData } from '../../../../../redux/common';
import actions from './actions';
import assign from 'lodash/fp/assign';

const ownedElements = multimethod('type', (action, state) => state);

ownedElements.method[actionStart(actions.FETCH_OWNED_ELEMENTS)] = apiData.fetchStarted;

ownedElements.method[actionSuccess(actions.FETCH_OWNED_ELEMENTS)] = (action, state) => {
  return assign(state, {
    data: graphApi.response.toResults(action.payload),
    isLoading: false,
    isRequesting: false,
    total: graphApi.response.toTotalHits(action.payload),
  });
};

ownedElements.method[actionError(actions.FETCH_OWNED_ELEMENTS)] = apiData.fetchErrored;

export default (state = apiData.initialState, action) => ownedElements(action, state);
