import React from 'react';

// Configuration
import PropTypes from 'prop-types';

// Styles
import injectStyles from 'react-jss';
import cx from 'classnames';
import styles from './styles';

// Components
import { injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

// Utils
import { localizedText } from '../../../utils/localization';

class Title extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    titleValues: PropTypes.object,
    withHelmet: PropTypes.bool,
  };

  static defaultProps = {
    title: '',
    // In case you want to override the title programmatically on the component instead
    withHelmet: false,
  };

  render() {
    const { classes, className, intl, title, titleId, titleValues, withHelmet } = this.props;
    const titleText = localizedText(intl, title, titleId, titleValues);
    return (
      <h1 className={cx(classes.heading, className)} id={titleId}>
        {titleText}
        {withHelmet && (
          <Helmet>
            <title> ScoutPRIME: {titleText}</title>
          </Helmet>
        )}
      </h1>
    );
  }
}

export default injectIntl(injectStyles(styles)(Title));
