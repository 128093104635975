/**
 * Immutable functions. All functions in here will not modify the objects passed to them.
 *
 * These are largely built on top of lodash/fp, but customized for use in this project.
 * This customization tends to be:
 *   * Changing args to be a similar order to clojure since that makes sense, and tends
 *     to put the update function last which facilitates formatting for anonymous
 *     functions.
 *   * Adding some niceties and checks.
 *
 */
import _omit from 'lodash/fp/omit';
import _reduce from 'lodash/fp/reduce';
import _set from 'lodash/fp/set';
import _update from 'lodash/fp/update';
import { has } from 'lodash';

// lodash/fp documentation can be found here: https://gist.github.com/jfmengels/6b973b69c491375117dc
// To match

export { default as assign } from 'lodash/fp/assign';

/**
 * Returns 'object' without `keys`.
 *
 * @param object
 * @param keys
 * @returns {*}
 */
export function omit(object, keys) {
  return _omit(keys, object);
}

/**
 * Returns the accumulated result of applying `reducer` to each value in `collection`.
 *
 * @param collection
 * @param accumulator
 * @param reducer A function that takes the current value of the accumulator and
 *                successively each value in the collection reducer(accumulator, collection).
 * @returns {Function|Object}
 */
export function reduce(collection, accumulator, reducer) {
  return _reduce(reducer, accumulator, collection);
}

/**
 * Sets `value` in `object` at `path`.
 *
 * @param object
 * @param path
 * @param value
 * @returns Object
 */
export function set(object, path, value) {
  return _set(path, value, object);
}

/**
 * Updates the value at `path` by passing it to `updater` and setting its value to what
 * that function returns. `updater` takes on arg, the value in `object` at `path`.
 *
 * @param object
 * @param path
 * @param updater
 * @returns {*}
 */
export function update(object, path, updater) {
  return _update(path, updater, object);
}

/**
 * Same as `update`, but only calls `updater` if `object` has a value at `path`. `object`
 * returns unmodified if `path` has no value.
 *
 * @param object
 * @param path
 * @param updater
 * @returns {*}
 */
export function updateWhen(object, path, updater) {
  if (!has(object, path)) {
    return object;
  }
  return update(object, path, updater);
}
