import { apiRequestRaw } from '../http';
import { API } from '../../environment/api';

export function acknowledgeNotificationsLog(token, ids) {
  const params = {
    body: {
      ids,
    },
  };
  return apiRequestRaw('PATCH', API.NOTIFICATION_ALERTS_ACKNOWLEDGE, token, params);
}

export function getNotificationsLog(
  token,
  workspaceId,
  from = 0,
  limit = 0,
  sortBy = [['createdAt', 'desc']]
) {
  const params = {
    body: {
      from,
      limit,
      sortBy,
      query: ['=', 'workspaceId', workspaceId],
    },
  };
  return apiRequestRaw('POST', API.NOTIFICATION_ALERTS, token, params);
}

export function getUnreadNotificationsLog(
  token,
  workspaceId,
  from = 0,
  limit = 0,
  sortBy = [['createdAt', 'desc']]
) {
  const params = {
    body: {
      from,
      limit,
      sortBy,
      query: ['=', 'workspaceId', workspaceId],
    },
  };
  return apiRequestRaw('POST', API.NOTIFICATION_ALERTS_UNREAD, token, params);
}
