import React from 'react';
import cx from 'classnames';
import FieldError from '../../errors/FieldError';
import injectStyles from 'react-jss';
import styles from '../../styles';

class InputField extends React.Component {
  render() {
    const {
      autoComplete,
      classes,
      className,
      id,
      input,
      label,
      type,
      meta,
      cssClassName,
      ...inputProps
    } = this.props;

    return (
      <div className={classes.field}>
        <input
          id={id}
          autoComplete={autoComplete}
          className={cx(classes.input, className, 'form-input-field') + ' ' + cssClassName}
          {...input}
          placeholder={label}
          type={type}
          {...inputProps}
        />
        <FieldError {...meta} />
      </div>
    );
  }
}

export default injectStyles(styles)(InputField);
