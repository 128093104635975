import { Link } from 'react-router-dom';
import { convertFromHTML, CompositeDecorator, ContentState, EditorState } from 'draft-js';
import { findImageEntities, findLinkEntities } from '../decorators';

/**
 * Return an `EditorState` reference based on provided `html`
 * @param html
 * @returns {*}
 */
export const generateEditorState = html => {
  if (!html) {
    return EditorState.createEmpty();
  }

  const blocksFromHTML = convertFromHTML(html);

  if (blocksFromHTML.contentBlocks) {
    const decorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: Link,
      },
      {
        strategy: findImageEntities,
        component: Image,
      },
    ]);
    return EditorState.createWithContent(
      ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap),
      decorator
    );
  }

  return EditorState.createEmpty();
};
