import { downloadable } from './download';
import { downloadReport } from '../api/reports';

export function reportDownloadable(sessionToken, report) {
  return downloadable(
    {
      defaultMessage: 'Downloading report...',
      id: 'report-downloading',
    },
    {
      defaultMessage: 'Download complete.',
      id: 'report-downloaded',
    },
    () => downloadReport(sessionToken, report)
  );
}
