import React from 'react';
import injectStyles from 'react-jss';
import { injectIntl } from 'react-intl';
import { Prompt, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal, ModalButtons, Title } from '../';
import { PrimaryButton, SecondaryModalButton } from '../buttons';

class PromptDialog extends React.Component {
  static propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
    when: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      confirmNavigation: false,
      location: null,
      showModal: false,
    };
  }

  componentDidMount() {
    this.setState({
      location: null,
    });
  }

  closeModal = cb => {
    this.setState(
      {
        showModal: false,
      },
      () => {
        if (typeof cb === 'function') {
          cb();
        }
      }
    );
  };

  showModal = location => {
    const { showModal } = this.state;
    if (!showModal) {
      this.setState({
        showModal: true,
        location,
      });
    }
  };

  handleBlockedNavigation = nextLocation => {
    const { confirmNavigation } = this.state;
    if (!confirmNavigation) {
      this.showModal(nextLocation);
      return false;
    }
    return true;
  };

  handleConfirmNavigationClick = () =>
    this.closeModal(() => {
      const { history } = this.props;
      const { location } = this.state;
      if (location) {
        this.setState(
          {
            confirmNavigation: true,
          },
          () => {
            history.push(location);
          }
        );
      }
    });

  renderConfirmationModal = () => {
    let { classes, description, intl } = this.props;
    const { showModal } = this.state;
    description =
      description ||
      intl.formatMessage({
        id: 'form-unsaved-changes-description',
        defaultMessage: 'Unsaved changes will be lost if you navigate away from this page.',
      });

    return (
      showModal && (
        <Modal onRequestClose={this.closeModal}>
          <div className={classes.container}>
            <Title
              title="Are you sure you want to leave this page?"
              titleId="form-unsaved-changes-title"
              className={classes.title}
            />
            <div className={classes.description}>{description}</div>
            <ModalButtons>
              <PrimaryButton
                data-test="stay-button"
                onClick={this.closeModal}
                text="Stay"
                textId="form-unsaved-changes-stay"
                type="button"
              />
              <SecondaryModalButton
                data-test="leave-button"
                onClick={this.handleConfirmNavigationClick}
                text="Leave Page"
                textId="form-unsaved-changes-leave"
              />
            </ModalButtons>
          </div>
        </Modal>
      )
    );
  };

  render() {
    const { when } = this.props;

    return (
      <>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        {this.renderConfirmationModal()}
      </>
    );
  }
}

const styles = {
  container: {
    width: 'auto 0',
  },
  description: {
    minHeight: 50,
  },
  title: {
    fontSize: 14,
  },
};

export default withRouter(injectIntl(injectStyles(styles)(PromptDialog)));
