import React from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import omit from 'lodash/omit';
import { uuid } from '../../../utils/components';

const DATA_ATTRIBUTE_INDEX = 'data-focus-index';
const DATA_ATTRIBUTE_SKIP = 'data-focus-skip';

/**
 * ArrowKeyNavigation is designed not to care about the component types it is wrapping. Due to this, you can pass
 * whatever HTML tag you  like into `props.component` or even a React component you've made elsewhere. Additional props
 * passed to `<ArrowKeyNavigation ...>` will  be forwarded on to the component or HTML tag name you've supplied.
 *
 * The children, similarly, can be any type of component.
 */
class ArrowKeyNavigation extends React.PureComponent {
  static mode = {
    HORIZONTAL: uuid(),
    VERTICAL: uuid(),
    BOTH: uuid(),
  };

  static propTypes = {
    '*': PropTypes.any,

    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

    defaultActiveChildIndex: PropTypes.number,

    mode: PropTypes.oneOf([
      ArrowKeyNavigation.mode.BOTH,
      ArrowKeyNavigation.mode.HORIZONTAL,
      ArrowKeyNavigation.mode.VERTICAL,
    ]),
  };

  static defaultProps = {
    component: 'div',
    defaultActiveChildIndex: 0,
    mode: ArrowKeyNavigation.mode.BOTH,
    onKeyDown: () => {},
  };

  static internalKeys = Object.keys(ArrowKeyNavigation.defaultProps);

  state = {
    activeChildIndex: this.props.defaultActiveChildIndex,
    children: [],
  };

  getFilteredChildren(props = this.props) {
    return React.Children.toArray(props.children).filter(Boolean);
  }

  setActiveChildIndex() {
    if (this.state.activeChildIndex !== 0) {
      const numChildren = React.Children.count(this.state.children);

      if (numChildren === 0) {
        this.setState({ activeChildIndex: 0 });
      } else if (this.state.activeChildIndex >= numChildren) {
        this.setState({ activeChildIndex: numChildren - 1 });
      }
    }
  }

  componentDidMount() {
    this.setState({ children: this.getFilteredChildren() });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { children } = this.props;
    if (prevProps.children !== children) {
      return this.setState(
        { children: this.getFilteredChildren(this.props) },
        this.setActiveChildIndex
      );
    }
    const { activeChildIndex } = this.state;
    if (prevState.activeChildIndex !== activeChildIndex) {
      this.setFocus(activeChildIndex);
      this.setActiveChildIndex();
    }
  }

  setFocus(index) {
    const childNode = this.$wrapper.children[index];

    if (childNode && childNode.hasAttribute(DATA_ATTRIBUTE_SKIP)) {
      this.moveFocus(
        childNode.compareDocumentPosition(document.activeElement) & Node.DOCUMENT_POSITION_FOLLOWING
          ? -1
          : 1
      );
    } else if (childNode && document.activeElement !== childNode) {
      childNode.focus();
    }
  }

  findFirstFocusableIndex(children) {
    return children.findIndex(({ props: { tabIndex } }) => tabIndex !== -1);
  }

  moveFocus(delta) {
    const numChildren = this.state.children ? React.Children.count(this.state.children) : 0;
    let nextIndex = this.state.activeChildIndex + delta;

    if (nextIndex >= numChildren) {
      nextIndex = this.findFirstFocusableIndex(this.state.children); // loop
    } else if (nextIndex < 0) {
      nextIndex = numChildren - 1; // reverse loop
    }

    this.setState({ activeChildIndex: nextIndex });
  }

  handleKeyDown = event => {
    switch (event.key) {
      case 'ArrowUp':
        if (
          this.props.mode === ArrowKeyNavigation.mode.VERTICAL ||
          this.props.mode === ArrowKeyNavigation.mode.BOTH
        ) {
          event.preventDefault();
          this.moveFocus(-1);
        }

        break;

      case 'ArrowLeft':
        if (
          this.props.mode === ArrowKeyNavigation.mode.HORIZONTAL ||
          this.props.mode === ArrowKeyNavigation.mode.BOTH
        ) {
          event.preventDefault();
          this.moveFocus(-1);
        }

        break;

      case 'ArrowDown':
        if (
          this.props.mode === ArrowKeyNavigation.mode.VERTICAL ||
          this.props.mode === ArrowKeyNavigation.mode.BOTH
        ) {
          event.preventDefault();
          this.moveFocus(1);
        }

        break;

      case 'ArrowRight':
        if (
          this.props.mode === ArrowKeyNavigation.mode.HORIZONTAL ||
          this.props.mode === ArrowKeyNavigation.mode.BOTH
        ) {
          event.preventDefault();
          this.moveFocus(1);
        }

        break;

      default:
        break;
    }

    if (this.props.onKeyDown) {
      this.props.onKeyDown(event, this.state.activeChildIndex);
    }
  };

  handleFocus = event => {
    if (event.target.hasAttribute(DATA_ATTRIBUTE_INDEX)) {
      const index = parseInt(event.target.getAttribute(DATA_ATTRIBUTE_INDEX), 10);
      const child = React.Children.toArray(this.state.children)[index];

      this.setState({ activeChildIndex: index });

      if (child && child.props && child.props.onFocus) {
        child.props.onFocus(event);
      }
    }
  };

  renderChildren() {
    return React.Children.map(this.state.children, (child, index) => {
      return React.cloneElement(child, {
        [DATA_ATTRIBUTE_INDEX]: index,
        [DATA_ATTRIBUTE_SKIP]: parseInt(child.props.tabIndex, 10) === -1 || undefined,
        key: child.key || index,
        tabIndex: this.state.activeChildIndex === index ? 0 : -1,
      });
    });
  }

  persistWrapperElementReference = unknownType => {
    this.$wrapper = unknownType instanceof HTMLElement ? unknownType : findDOMNode(unknownType);
  };

  render() {
    return (
      <this.props.component
        {...omit(this.props, ArrowKeyNavigation.internalKeys)}
        ref={this.persistWrapperElementReference}
        onFocus={this.handleFocus}
        onKeyDown={this.handleKeyDown}>
        {this.renderChildren()}
      </this.props.component>
    );
  }
}

export default ArrowKeyNavigation;
