import { tokenThunk } from '../../../utils/api';
import {
  acknowledgeNotificationsLog,
  getUnreadNotificationsLog,
} from '../../../lib/http/notifications';

const actions = {
  READ_NOTIFICATIONS: 'notifications/UNREAD_NOTIFICATIONS',
  ACKNOWLEDGE_NOTIFICATIONS: 'notifications/ACKNOWLEDGE_NOTIFICATIONS',
};

export function refresh(workspaceId, updateRefreshCount, from = 0, limit = 10000) {
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.READ_NOTIFICATIONS,
      payload: getUnreadNotificationsLog(token, workspaceId, from, limit),
      updateRefreshCount,
    });
  });
}

export function acknowledge(ids) {
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.ACKNOWLEDGE_NOTIFICATIONS,
      payload: acknowledgeNotificationsLog(token, ids),
    });
  });
}

export default actions;
