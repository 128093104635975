import { buildFieldSetUpdates, tokenThunk } from '../../../../../utils/api';
import graph from '../../../../../lib/http/graph';

const actions = {
  CANCEL_EDIT_THREAT_DESCRIPTION: 'elementDetails/CANCEL_EDIT_THREAT_DESCRIPTION',
  EDIT_THREAT_DESCRIPTION: 'elementDetails/EDIT_THREAT_DESCRIPTION',
  UPDATE_THREAT_DESCRIPTION: 'elementDetails/UPDATE_THREAT_DESCRIPTION',
};

export function cancelEditThreatDescription() {
  return {
    type: actions.CANCEL_EDIT_THREAT_DESCRIPTION,
  };
}

export function editThreatDescription() {
  return {
    type: actions.EDIT_THREAT_DESCRIPTION,
  };
}

export function updateThreatDescription(workspaceId, threatRef, description) {
  const fieldUpdates = buildFieldSetUpdates({ userDescription: description });
  return tokenThunk((dispatch, token) => {
    dispatch({
      description,
      payload: graph.api.update(token, workspaceId, threatRef, fieldUpdates),
      type: actions.UPDATE_THREAT_DESCRIPTION,
    });
  });
}

export default actions;
