import { tokenThunk } from '../../../utils/api';
import * as graphApi from '../../../api/graph';

const actions = {
  FETCH_ELEMENT: 'elementDetails/FETCH_ELEMENT',
  CLEAR_ELEMENT: 'elementDetails/CLEAR_ELEMENT',
};

export function fetchElement(workspaceId, type, id, fields, merge = true) {
  return tokenThunk((dispatch, token) => {
    dispatch({
      elementType: type,
      merge,
      type: actions.FETCH_ELEMENT,
      payload: graphApi.fetchElement(token, workspaceId, type, id, fields),
    });
  });
}

export const clearElement = () => dispatch => {
  dispatch({
    type: actions.CLEAR_ELEMENT,
  });
};

export default actions;
