import React from 'react';
import injectStyles from 'react-jss';
import cx from 'classnames';
import styles from './styles';

const TwoColumn = ({ classes, className, children: [Sidebar, Main], id }) => (
  <section id={id} className={cx(classes.container, className)}>
    <aside className={classes.columnOne}>{Sidebar}</aside>
    <div className={classes.columnTwo}>{Main}</div>
  </section>
);

TwoColumn.defaultProps = {
  sidebarWidth: 285,
};

export default injectStyles(styles)(TwoColumn);
