import actions from './actions';
import appActions from '../app/actions';
import deepmerge from 'deepmerge';
import { get, keyBy, keys, mergeWith, omit, pick } from 'lodash';
import { assign, update } from '../../../lib/immutable';
import { workspaceCollections } from '../../../utils/collections';

function stateToNormalizingKey(state, entityType) {
  return state[entityType].normalizingKey;
}

function stateToEntityId(state, action, entityType) {
  return get(action, ['meta', 'action', 'params', stateToNormalizingKey(state, entityType)]);
}

// Remove Stale Collections
// ========================

function removeStaleCollections(state, currentWorkspaceId) {
  return update(state, 'collections.data', collectionsMap => {
    return workspaceCollections(collectionsMap, currentWorkspaceId);
  });
}

// Fetch
// =====

function fetchStart(state, action, entityType) {
  const opts = get(action, 'meta.action.opts', {});
  return update(state, entityType, entitiesState => {
    return assign(entitiesState, {
      error: null,
      loading: !opts.silently,
      // clear out data if requested
      data: opts.clear ? {} : entitiesState.data,
    });
  });
}

/**
 * Merges the newly fetched entities with the existing entities. How the merge occur is
 * based on what's in `opts`.
 *
 * Options:
 *   * clear - Returns the new entities verbatim because the old ones were cleared out.
 *   * merge - Merge the top-level values from the old and new entity (true) or completely
 *             stomp on it (false).
 *
 * IMPORTANT: Right now, it is assumed that the entire state of the entities are being
 * fetched (ex: all a workspace's collections, or all of an org's users), so it clears
 * out any entities that aren't in `newEntities`, regardless of any options. If more
 * nuance needs to happen (ex: fetching based on a query to limit the size of the fetch
 * and updated), this will need to be changed.
 *
 * @param oldEntities
 * @param newEntities
 * @param opts
 * @returns {*}
 */
function mergeEntities(oldEntities, newEntities, opts = {}) {
  if (opts.clear && !opts.merge) {
    return newEntities;
  }
  // clear out the old entities, even if merging
  oldEntities = pick(oldEntities, keys(newEntities));
  return mergeWith(oldEntities, newEntities, assign);
}

function fetchSuccess(state, action, entityType) {
  const opts = get(action, 'meta.action.opts');
  return update(state, entityType, entitiesState => {
    return assign(entitiesState, {
      error: null,
      lastFetched: Date.now(),
      loading: false,
      // clear out data if requested
      data: mergeEntities(
        entitiesState.data,
        keyBy(action.payload, stateToNormalizingKey(state, entityType)),
        opts
      ),
    });
  });
}

function fetchError(state, action, entityType) {
  return update(state, entityType, entitiesState => {
    return assign(entitiesState, {
      error: action.payload,
      loading: false,
    });
  });
}

// Fetch by Id
// ===========

function fetchByIdStart(state, action, entityType) {
  const entityId = stateToEntityId(state, action, entityType);
  return update(state, [entityType, 'data', entityId], entityState => {
    return assign(entityState, {
      error: null,
      loading: true,
    });
  });
}

function fetchByIdSuccess(state, action, entityType) {
  const entityId = stateToEntityId(state, action, entityType);
  return update(state, [entityType, 'data', entityId], entityState => {
    return assign(entityState, {
      ...action.payload,
      loading: false,
    });
  });
}

function fetchByIdError(state, action, entityType) {
  const entityId = stateToEntityId(state, action, entityType);
  return update(state, [entityType, 'data', entityId], entityState => {
    return assign(entityState, {
      error: action.payload,
      loading: false,
    });
  });
}

// Reducer
// =======

const initialEntitiesState = normalizingKey => ({
  normalizingKey,
  creating: false,
  data: {},
  lastFetched: null,
  loading: true,
  error: null,
});

const initialState = {
  collections: initialEntitiesState('collection-id'),
  incidentFolders: initialEntitiesState('folder-id'),
  incidents: initialEntitiesState('incident-id'),
  notes: initialEntitiesState('note-id'),
  organizations: initialEntitiesState('organization-id'),
  reports: initialEntitiesState('id'),
  savedSearch: initialEntitiesState('saved-search-id'),
  searchHistory: initialEntitiesState('search-history-id'),
  tokens: initialEntitiesState('id'),
  users: initialEntitiesState('user-id'),
  workspaces: initialEntitiesState('workspace-id'),
};

const entitiesReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case appActions.SET_WORKSPACE: {
      // when the workspace changes, clean out any collections that don't belong to the
      // new workspace
      return removeStaleCollections(state, payload.workspaceId);
    }
    default:
    // fall through and hit the next switch
  }

  // everything below here is operating on a specific entity type
  const entityType = get(action, 'meta.action.entityType');
  if (!entityType) {
    return state;
  }
  const normalizingKey = state[entityType].normalizingKey;
  switch (type) {
    // Fetching entities
    case `${actions.FETCH}_START`: {
      return fetchStart(state, action, entityType);
    }

    case `${actions.FETCH}_SUCCESS`: {
      return fetchSuccess(state, action, entityType);
    }

    case `${actions.FETCH}_ERROR`: {
      return fetchError(state, action, entityType);
    }

    // Fetching single entity by id
    case `${actions.FETCH_BY_ID}_START`: {
      return fetchByIdStart(state, action, entityType);
    }

    case `${actions.FETCH_BY_ID}_SUCCESS`: {
      return fetchByIdSuccess(state, action, entityType);
    }

    case `${actions.FETCH_BY_ID}_ERROR`: {
      return fetchByIdError(state, action, entityType);
    }

    // Create an entity
    case `${actions.CREATE}_START`: {
      return {
        ...state,
        [entityType]: {
          ...state[entityType],
          creating: true,
          error: null,
        },
      };
    }

    case `${actions.CREATE}_SUCCESS`: {
      const entityId = get(payload, normalizingKey);
      const timestamp = new Date().getTime();
      return {
        ...state,
        [entityType]: {
          ...state[entityType],
          creating: false,
          error: null,
          data: {
            ...get(state, [entityType, 'data']),
            // Insert created object into our lookup map
            [entityId]: {
              ...payload,
              /*
               * TODO: Get API to return updated-at and created-at timestamp,
               * so we dont need to do this hack to ensure proper ordering and
               * not have to always re-query for the data
               * https://jira.lookingglasscyber.com/browse/SP-1978
               */
              'created-at': timestamp,
              'updated-at': timestamp,
            },
          },
        },
      };
    }

    case `${actions.CREATE}_ERROR`: {
      return {
        ...state,
        [entityType]: {
          ...state[entityType],
          creating: false,
          error: payload,
        },
      };
    }

    // Deleting an entity by id
    // Optimistically removes the entity as well for better UX experience
    case `${actions.DELETE}_START`: {
      const entityId = get(action, ['meta', 'action', 'params', normalizingKey]);
      return {
        ...state,
        [entityType]: {
          ...state[entityType],
          data: {
            ...get(state, [entityType, 'data']),
            [entityId]: {
              // Retain object for when the request actually errors and we need to revert it back
              pendingDeletion: get(state, [entityType, 'data', entityId]),
              deleting: true,
              error: null,
            },
          },
        },
      };
    }

    case `${actions.DELETE}_SUCCESS`: {
      const entityId = get(action, ['meta', 'action', 'params', normalizingKey]);
      return {
        ...state,
        // dissoc key from app state
        [entityType]: {
          ...state[entityType],
          data: omit(get(state, [entityType, 'data']), entityId),
        },
      };
    }

    case `${actions.DELETE}_ERROR`: {
      const entityId = get(action, ['meta', 'action', 'params', normalizingKey]);
      return {
        ...state,
        [entityType]: {
          ...state[entityType],
          data: {
            ...get(state, [entityType, 'data']),
            [entityId]: {
              // Retain object
              ...get(state, [entityType, 'data', entityId]),
              // Put back the pending deleted element data
              ...get(state, [entityType, 'data', entityId, 'pendingDeletion']),
              pendingDeletion: null,
              deleting: false,
              error: payload,
            },
          },
        },
      };
    }

    case actions.UPSERT: {
      // Normalize to a collection
      const entities = Array.isArray(payload.entity) ? payload.entity : [payload.entity];
      const keyedEntity = keyBy(entities, normalizingKey);
      return {
        ...state,
        [entityType]: {
          ...state[entityType],
          data: deepmerge(get(state, [entityType, 'data']), keyedEntity),
        },
      };
    }

    case actions.UPDATE: {
      const entityId = get(action, ['meta', 'action', 'entityId']);
      return {
        ...state,
        [entityType]: {
          ...state[entityType],
          data: {
            ...get(state, [entityType, 'data']),
            [entityId]: {
              ...get(state, [entityType, 'data', entityId]),
              ...payload.entity,
            },
          },
        },
      };
    }

    default:
      return state;
  }
};

export default entitiesReducer;
