import React from 'react';

// Configuration
import PropTypes from 'prop-types';

// Styles
import injectStyles from 'react-jss';
import cx from 'classnames';

class DisguisedButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string,
  };

  render() {
    const { classes, className, 'data-test': dataTest, text, onClick } = this.props;
    return (
      <button
        className={cx(classes.disguisedButton, className)}
        data-test={dataTest}
        onClick={onClick}>
        {text}
      </button>
    );
  }
}

const styles = () => ({
  disguisedButton: {
    background: 'none !important',
    border: 'none',
    fontFamily: 'Open sans, sans-serif',
    fontSize: 14,
    outlineColor: 'transparent',
    padding: '0 !important',
    textAlign: 'left',
    '&:focus': {
      outline: 0,
    },
  },
});

export default injectStyles(styles)(DisguisedButton);
