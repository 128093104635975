import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Loadable from 'react-loadable';
import { useIntl, IntlProvider } from 'react-intl';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { Security } from '@okta/okta-react';
import { Theme } from '@riskive/lookingglass-design-system';
import { useDispatch } from 'react-redux';

import get from 'lodash/get';
import Matomo from '../../lib/Matomo';
import { AsyncLoader, LoadingIndicator, RouteWithTitle } from '../base';
import App from '.';
import { fetchAuthConfig, setAuthenticated } from '../../redux/modules/auth/actions';
import { LastLocationProvider } from 'react-router-last-location';
import withSessionToken from '../common/withSessionToken';
import { Toast } from '../common/Toast';
import useAnalytics from '../../hooks/useAnalytics';

const ApiDocs = Loadable({
  loader: () => import('./Docs/Api'),
  loading: AsyncLoader,
});

const BootstrapUser = Loadable({
  loader: () => import('./Auth/Bootstrap'),
  loading: AsyncLoader,
});

const ConvertToken = Loadable({
  loader: () => import('./Auth/Convert'),
  loading: AsyncLoader,
});

const ForgotPassword = Loadable({
  loader: () => import('./ForgotPassword'),
  loading: AsyncLoader,
});

const Login = Loadable({
  loader: () => import('./Login/OktaLogin'),
  loading: AsyncLoader,
});

const Logout = Loadable({
  loader: () => import('./Logout'),
  loading: AsyncLoader,
});

const SetPassword = Loadable({
  loader: () => import('./SetPassword'),
  loading: AsyncLoader,
});

function MainRouter(props) {
  const intl = useIntl();
  const { authState, oktaAuth } = useOktaAuth();
  const dispatch = useDispatch();
  useAnalytics();

  useEffect(() => {
    dispatch(setAuthenticated(authState?.isAuthenticated, authState?.accessToken?.accessToken));
  }, [authState, dispatch]);

  return (
    <Switch>
      <RouteWithTitle
        path="/docs/api"
        component={ApiDocs}
        title={intl.formatMessage({
          id: 'route-title-api-docs',
          defaultMessage: 'API Documentation',
        })}
      />
      <RouteWithTitle
        exact
        path="/auth/bootstrap"
        title={intl.formatMessage({
          id: 'route-title-auth-bootstrap',
          defaultMessage: 'Bootstrapping User',
        })}
        component={BootstrapUser}
      />
      <RouteWithTitle
        exact
        path="/auth/convert"
        title={intl.formatMessage({
          id: 'route-title-auth-convert',
          defaultMessage: 'Resuming Session',
        })}
        component={ConvertToken}
      />
      <RouteWithTitle
        exact
        path="/forgot-password"
        title={intl.formatMessage({
          id: 'route-title-forgot-password',
          defaultMessage: 'Forgot Password',
        })}
        component={ForgotPassword}
      />
      <RouteWithTitle
        exact
        path="/set-password/token/:token"
        title={intl.formatMessage({
          id: 'route-title-set-password',
          defaultMessage: 'Set Your Password',
        })}
        component={SetPassword}
      />
      <Route
        exact
        path="/login"
        title={intl.formatMessage({
          id: 'route-title-login',
          defaultMessage: 'Login',
        })}
        component={Login}
      />
      <RouteWithTitle
        exact
        path="/logout"
        title={intl.formatMessage({
          id: 'route-title-logout',
          defaultMessage: 'Logout',
        })}
        component={Logout}
      />
      <Route
        path="/login/callback"
        render={() => <LoadingIndicator fadeIn="quarter" showing={true} full />}
      />
      <Route path="/" component={App} />
    </Switch>
  );
}

class LocalizedRoot extends React.Component {
  state = {
    history: createBrowserHistory(),
    oktaAuth: null
  };

  // added to avoid the 'Can't perform a React state update on an unmounted component.' warning
  compMounted = false;
  initializeMatomo = () => {
    const { matomo, matomoUserTrackingId } = this.props;
    const matomoHook = new Matomo({
      ...matomo,
      userId: matomoUserTrackingId,
    }).initialize();

    this.setState(({ history }) => ({
      history: matomoHook.connectToHistory(history),
    }));
  };

  async componentDidUpdate({ matomo, config }) {
    // Initialize matomo and integrate with router history
    if (
      !matomo &&
      this.props.matomo &&
      this.props.matomo.url &&
      (this.props.matomo.siteId ||
        get(matomo, 'url') !== this.props.matomo.url ||
        get(matomo, 'siteId') !== this.props.matomo.siteId)
    ) {
      this.initializeMatomo();
    }

    if (this.props.config.data && !config.data) {
      const oktaAuthConfig = {
        scopes:
          process.env.REACT_APP_OKTA_SCOPES ||
          get(this.props.config, 'data.oauth2.scopes', [
            'email',
            'openid',
            'profile',
            'offline_access',
            'suite:profile',
          ]),
        clientId:
          process.env.REACT_APP_OKTA_CLIENT_ID || get(this.props.config, 'data.oauth2.clientId'),
        issuer:
          process.env.REACT_APP_OKTA_ISSUER || get(this.props.config, 'data.oauth2.issuerUrl'),
        redirectUri: `${window.location.origin}/login/callback`,
      };
      this.setState({
        oktaAuth: new OktaAuth(oktaAuthConfig),
      });
    }
  }

  componentDidMount() {
    this.compMounted = true;

    // Add locales
    this.props.fetchAuthConfig();
    if (
      this.props.isAuthenticated &&
      this.props.matomo &&
      typeof this.props.matomo.url !== 'undefined' &&
      typeof this.props.matomo.siteId !== 'undefined'
    ) {
      this.initializeMatomo();
    }
  }

  componentWillUnmount() {
    this.compMounted = false;
  }

  onAuthRequired = () => {
    this.state.history.push('/login');
  };

  restoreOriginalUri = async (_oktaAuth, originalUri) => {
    this.state.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  render() {
    const { locale, messages } = this.props;

    return (
      <IntlProvider key={locale} locale={locale} messages={messages}>
        <Theme>
          <Toast>
            <Router history={this.state.history}>
              <LastLocationProvider>
                {this.state.oktaAuth && (
                  <Security
                    oktaAuth={this.state.oktaAuth}
                    onAuthRequired={this.onAuthRequired}
                    restoreOriginalUri={this.restoreOriginalUri}>
                    <React.Fragment>
                      {/* <Okta /> */}
                      <MainRouter />
                    </React.Fragment>
                  </Security>
                )}
              </LastLocationProvider>
            </Router>
          </Toast>
        </Theme>
      </IntlProvider>
    );
  }
}

const mapStateToProps = ({
  auth: {
    apiTokens: { matomo },
    config,
  },
  user: { username, userId },
  locale,
}) => ({
  ...locale,
  config,
  matomo,
  matomoUserTrackingId: `${username}:${userId}`,
});

const mapDispatchToProps = {
  fetchAuthConfig,
};

export default withSessionToken(connect(mapStateToProps, mapDispatchToProps)(LocalizedRoot));
