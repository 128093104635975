import React from 'react';
import styles from './styles';
import { regexPattern } from '../../../../../utils/elements';
import injectStyles from 'react-jss';
import TextAreaField from '../TextAreaField';

class HighlightedTextArea extends React.Component {
  state = {
    height: '34px',
  };
  /**
   * 'value' is a string. 'highlightedWords' are the words in the string that you would like highlighted.
   * 'highlightedWords' expects an array of strings.
   * @param value
   * @param highlightWords
   * @returns {*}
   */
  applyHighlights = (value, highlightWords = []) => {
    if (value) {
      const formattedData = highlightWords.toString().replace(/,/g, '|');
      return value
        .replace(/\n$/g, '\n\n')
        .replace(regexPattern(formattedData, 'g'), '<mark>$&</mark>');
    }
    return '';
  };

  adjustHeight = () => {
    const textAreaHeight = document.getElementById('highlight-textarea').offsetHeight;
    return this.setState({ height: textAreaHeight });
  };

  //todo: Research how to reference DOM elements and refactor.
  handleInput = e => {
    const { highlightWords } = this.props;

    const textAreaHeight = document.getElementById('highlight-textarea').offsetHeight;

    const highlightedText = this.applyHighlights(e.target.value, highlightWords);
    document.getElementById('highlights').innerHTML = highlightedText;

    this.setState({
      height: textAreaHeight,
    });
    return e;
  };

  handleScroll = () => {
    let highlightBackdrop = document.getElementById('highlight-backdrop');
    let highlightTextArea = document.getElementById('highlight-input__textarea');
    if (highlightBackdrop.scrollTop) {
      highlightBackdrop.scrollTop = highlightTextArea.scrollTop;
      highlightBackdrop.scrollLeft = highlightTextArea.scrollLeft;
    }
  };

  componentDidMount() {
    this.adjustHeight();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { input: field } = this.props;
    const { input: previousField } = prevProps;

    if (field.value !== previousField.value) {
      this.adjustHeight();
    }
  }

  render() {
    const { classes, containsError, input: field } = this.props;
    const { height } = this.state;
    return (
      <div id="highlight-container" className={classes.container} style={{ height: height }}>
        <div id="highlight-backdrop" style={{ height: height }} className={classes.backdrop}>
          <div id="highlights" className={classes.highlights} />
        </div>
        <TextAreaField
          spellCheck={false}
          id="highlight-textarea"
          onInput={this.handleInput}
          onScroll={this.handleScroll}
          className={containsError ? classes.textarea__error : classes.textarea}
          onChange={field.onChange}
          value={field.value}
        />
      </div>
    );
  }
}

export default injectStyles(styles)(HighlightedTextArea);
