import React from 'react';

// Styles
import injectStyles from 'react-jss';

// Utils
import { drop, first, isError, size } from 'lodash';
import { injectIntl } from 'react-intl';

function isBulk(error) {
  return isError(error) && error.reason === 'batch-failure' && error.errors;
}

class ErrorBlock extends React.Component {
  state = {
    isErrorsOpen: false,
  };

  primaryMessage = () => {
    const { error } = this.props;
    if (isBulk(error)) {
      return first(error.errors);
    }
    return error && error.toString();
  };

  openErrors = () => {
    this.setState({ isErrorsOpen: true });
  };

  renderError = message => {
    const { classes } = this.props;
    return <div className={classes.error}>{message}</div>;
  };

  renderErrors = () => {
    const { classes, error, intl } = this.props;
    if (!isBulk(error)) {
      return null;
    }
    // the first error is shown as the primary message
    const errors = drop(error.errors, 1);
    const numErrors = size(errors);
    if (numErrors === 0) {
      return null;
    }
    const { isErrorsOpen } = this.state;
    if (isErrorsOpen) {
      return errors.map(this.renderError);
    } else {
      return (
        <button className={classes.more} onClick={this.openErrors}>
          {intl.formatMessage(
            { id: 'more-errors', defaultMessage: '+{numErrors} more' },
            { numErrors }
          )}
        </button>
      );
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        {this.renderError(this.primaryMessage())}
        {this.renderErrors()}
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    overflow: 'auto',
  },
  error: {
    ...theme.typography.body,
    color: theme.colors.error,
    marginBottom: 5,
  },
  more: {
    ...theme.link,
    background: 'none',
    border: 'none',
    marginBottom: 5,
  },
});

export default injectIntl(injectStyles(styles)(ErrorBlock));
