export default theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    width: '100%',
  },
  actions: {
    paddingRight: theme.spacing,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 45,
    flexShrink: 0,
    paddingLeft: theme.spacing,
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    width: '100%',
  },
  title: {
    ...theme.typography.h6,
    margin: 0,
    fontWeight: 600,
    fontSize: '0.9rem',
    color: theme.colors.black,
  },
  body: {
    display: 'flex',
  },
});
