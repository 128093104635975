import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import injectStyles from 'react-jss';
import { injectIntl } from 'react-intl';

import Label from '../Label';

class RadioButton extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    labelAfterInput: PropTypes.bool,
    labelId: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };

  static defaultProps = {
    labelAfterInput: true,
  };

  render() {
    let {
      children,
      classes,
      className,
      checkedClassName,
      disabledClassName,
      disabled,
      id,
      intl,
      fieldClassName,
      label,
      labelAfterInput,
      labelClassName,
      labelId,
      onChange,
      selectedValue,
      showToolTip,
      tooltip,
      tooltipId,
      tooltipPosition,
      value,
    } = this.props;
    const checked = selectedValue === value;

    if (tooltipId) {
      tooltip = intl.formatMessage({
        defaultMessage: tooltip,
        id: tooltipId,
      });
    }

    return (
      <div
        className={cx(
          classes.radioContainer,
          className,
          checked && checkedClassName,
          disabled && disabledClassName
        )}>
        <Label
          id={id}
          className={cx(classes.label, labelClassName)}
          defaultMessage={label}
          fieldClassName={cx(classes.field, fieldClassName)}
          labelAfterInput={labelAfterInput}
          messageId={labelId}
          showToolTip={showToolTip}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}>
          <input
            checked={checked}
            disabled={disabled}
            onChange={() => onChange(value)}
            type="radio"
            value={value}
          />
        </Label>
        {children}
      </div>
    );
  }
}

const styles = (theme) => ({
  check: {
    color: theme.colors.white,
    display: 'none',
  },
  field: {
    marginRight: 5,
  },
  label: {
    alignItems: 'baseline',
    display: 'flex',
    marginRight: 5,
    width: 'auto',
    "& input[type='radio']": {
      appearance: 'none',
      display: 'inline-block',
      width: '13px',
      height: '13px',
      backgroundClip: 'content-box',
      border: `2px solid ${theme.colors.lightIris}`,
      backgroundColor: `${theme.colors.white}`,
      borderRadius: '50%',
      '&:checked': {
        backgroundColor: `${theme.colors.iris}`,
      },
    },
  },
  radioContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 5,
  },
});

export default injectIntl(injectStyles(styles)(RadioButton));
