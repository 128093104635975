import React from 'react';
import injectStyles from 'react-jss';
import cx from 'classnames';
import styles from './styles';

const NavList = ({ className, classes, children }) => (
  <nav className={cx(classes.nav, className)}>
    <ul className={classes.list}>
      {React.Children.map(children, (child, index) => {
        return (
          <li key={index} className={classes.item}>
            {/* Rebuild the child component with some additional props embedded */}
            <child.type className={classes.link} {...child.props} />
          </li>
        );
      })}
    </ul>
  </nav>
);

NavList.defaultProps = {
  vertical: false,
  itemHeight: 'auto',
  gap: 0,
};

export default injectStyles(styles)(NavList);
