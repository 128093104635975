import React from 'react';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import ClearIndicator from './ClearIndicator';
import styles from './styles';

export default ({
  className,
  clearable = true,
  creatable,
  disabled,
  loadOptions,
  height,
  multi = false,
  meta,
  menuPortalTarget,
  onChange,
  overrideStyles,
  placeholder = 'Select...',
  searchable = true,
  value,
  ...rest
}) => {
  let Select = AsyncSelect;
  if (creatable) {
    Select = AsyncCreatableSelect;
  }
  return (
    <Select
      className={className}
      classNamePrefix={className}
      components={{
        ClearIndicator,
      }}
      defaultOptions={true}
      isClearable={clearable}
      isDisabled={disabled}
      isMulti={multi}
      isSearchable={searchable}
      loadOptions={loadOptions}
      menuPortalTarget={menuPortalTarget}
      onChange={onChange}
      placeholder={placeholder}
      styles={styles({ height, multi, overrideStyles })}
      value={value}
      {...rest}
    />
  );
};
