import React from 'react';
import injectStyles from 'react-jss';
import styles from '../styles';
import cx from 'classnames';

class Form extends React.Component {
  render() {
    const { classes, children, className, id, onSubmit } = this.props;
    return (
      <form id={id} className={cx(classes.form, className, 'form')} onSubmit={onSubmit}>
        {children}
      </form>
    );
  }
}

export default injectStyles(styles)(Form);
