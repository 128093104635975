import { apiRequestRaw } from '../http';
import { API } from '../../environment/api';
import * as immutable from '../../lib/immutable';
import * as builders from './_graph/builders';
import { isString } from 'lodash';
import { normalizeToArray } from '../../utils/transforms';
import { internalizeRef } from '../../utils/elements';

// Checks
// ======

/**
 * The signature of the graph API read endpoints changed recently, this is to catch (as
 * early as possible) situations where the workspaceId is not being passed correctly.
 *
 * This can be removed eventually if desired.
 *
 * @param workspaceId
 */
function checkWorkspaceId(workspaceId) {
  if (!workspaceId) {
    throw new Error('workspaceId is required for graph endpoints.');
  }
  if (!isString(workspaceId)) {
    throw new Error(`workspaceId must be a string (is ${typeof workspaceId}).`);
  }
}

// Response
// ========

function toAnalyticsResults(response) {
  return response['analyticsResults'];
}

function toResults(response) {
  return response.results;
}

function toTotalHits(response) {
  return response['totalHits'];
}

function mapResults(response, mapFunc) {
  return immutable.update(response, 'results', results => {
    return results.map(mapFunc);
  });
}

// API
// ===

const refPaths = ['ref', 'ref.left', 'ref.right', 'left.ref', 'right.ref'];

function internalizeRefs(result) {
  refPaths.forEach(path => {
    result = immutable.updateWhen(result, path, internalizeRef);
  });
  return result;
}

function analytics(token, workspaceId, ast, analytics, requestOpts = {}) {
  checkWorkspaceId(workspaceId);
  return apiRequestRaw('POST', API.GRAPH_ANALYTICS, token, {
    ...requestOpts,
    body: {
      analytics: analytics,
      query: ast,
      workspaceIds: [workspaceId],
    },
  });
}

// Deletes an object from the graph.
// params example: {type: 'threat', id: '1234'}
function deletion(token, params = {}, requestOpts = {}, isImmediate = false) {
  return apiRequestRaw('DELETE', `${API.GRAPH}${isImmediate ? '?immediately=true' : ''}`, token, {
    ...requestOpts,
    body: {
      ref: {
        ...params,
      },
    },
  });
}

function get(token, workspaceId, elementRefs, fields, requestOpts = {}) {
  checkWorkspaceId(workspaceId);
  return apiRequestRaw('POST', API.GRAPH, token, {
    ...requestOpts,
    body: {
      fields,
      refs: elementRefs,
      workspaceIds: [workspaceId],
    },
  }).then(response => response.map(internalizeRefs));
}

// Accepts batch calls
// Example: [{type: 'associated-with', ...}, {type: 'associated-with', ...}]
function put(token, params, isImmediate = false) {
  return apiRequestRaw('PUT', `${API.GRAPH}${isImmediate ? '?immediately=true' : ''}`, token, {
    body: params,
  }).then(response => response);
}

function query(token, workspaceId, ast, params = {}, requestOpts = {}) {
  checkWorkspaceId(workspaceId);
  return apiRequestRaw('POST', API.GRAPH_QUERY, token, {
    ...requestOpts,
    body: {
      query: ast,
      workspaceIds: [workspaceId],
      ...params,
    },
  }).then(response =>
    immutable.update(response, 'results', results => results.map(internalizeRefs))
  );
}

function updateObject(token, workspaceId, refOrRefs, fieldUpdates, requestOpts = {}) {
  const refs = normalizeToArray(refOrRefs);
  return apiRequestRaw('PATCH', API.GRAPH, token, {
    ...requestOpts,
    body: {
      fieldUpdates,
      refs,
      workspaceId,
    },
  }).then(ref => {
    // the API is currently returning a ref on an update, this doesn't seem particularly
    // useful so this translates it into something that looks like an element that contains
    // a ref...hopefully sometime soon the API will return what was actually updated
    return {
      ref: internalizeRef(ref),
    };
  });
}

function queryAssociatedRisks(
  token,
  workspaceId,
  ast,
  idField = 'riskIds', // param values: 'riskIds' (default), 'right.threatId', 'right.vulnerabilityId', or 'threatIds'
  params = {},
  requestOpts = {}
) {
  // Analytics query for risks
  const risksSpec = builders
    .analyticsBuilder()
    .groupByAgg('risks', idField)
    .fields(params.fields)
    .filter(params.filter)
    .from(params.from)
    .limit(params.limit)
    .sortBy(params.sortBy)
    .build();
  const analyticsSpec = { ...risksSpec };

  return analytics(token, workspaceId, ast, analyticsSpec, requestOpts).then(response => {
    const { risks } = toAnalyticsResults(response);
    return {
      results: [...risks.results],
      totalHits: risks.totalHits,
    };
  });
}

export default {
  api: {
    analytics,
    deletion,
    get,
    put,
    query,
    queryAssociatedRisks,
    update: updateObject,
  },
  builder: {
    analytics: builders.analyticsBuilder,
  },
  response: {
    toAnalyticsResults,
    toResults,
    toTotalHits,
    mapResults,
  },
};
