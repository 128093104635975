import { API } from '../../../../../environment/api';
import { apiRequest } from '../../../../../lib/http';
import { tokenThunk } from '../../../../../utils/api';

const actions = {
  FETCH_WHOIS: 'elementDetails/FETCH_WHOIS',
};

export function fetchWhois(ref) {
  let params = {
    domain: ref.id,
  };
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.FETCH_WHOIS,
      payload: apiRequest(token, API.WHOIS, params),
    });
  });
}

export default actions;
