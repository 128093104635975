/**
 * Capitalize first letter of the string
 * @param { String } string -- the string to capitalize
 */
export function capitalize(string = '') {
  return string
    .split(' ')
    .map(str => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    })
    .join(' ');
}

export const removeWhiteSpaces = word => (word ? word.replace(/\s+/g, ' ').trim() : '');
