import React from 'react';
import Button from '../../../buttons/Base';
import injectStyles from 'react-jss';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { workspaceUrl } from '../../../../../utils/routing';
import cx from 'classnames';
import styles from './styles';

class CancelButton extends React.Component {
  //  todo: refactor and move this to utils/routing
  backTo = () => {
    const { history, location } = this.props;
    // location.key is more reliable than history.length
    // to check if the page loaded is for the first time
    if (location.key) {
      return history.goBack();
    }
    return history.push(workspaceUrl(location));
  };

  render() {
    const { className, classes, intl, onClick } = this.props;
    return (
      <Button
        className={cx(classes.button, className)}
        role="inverse"
        value={intl.formatMessage({
          id: 'threat-assoc-cancel-button',
          defaultMessage: 'Cancel',
        })}
        onClick={onClick || this.backTo}
      />
    );
  }
}

export default withRouter(injectStyles(styles)(injectIntl(CancelButton)));
