import actions from './actions';
import { deleteSessionToken } from '../../../lib/session';
import { get, keyBy } from 'lodash';
import assign from 'lodash/fp/assign';
import set from 'lodash/fp/set';
import { omit, reduce, update } from '../../../lib/immutable';
import { actionError, actionStart, actionSuccess } from '../../common';
import { permissionToId } from '../../../utils/permissions';

const initialState = {
  config: {
    error: null,
    isLoading: true,
  },
  error: null,
  isLoggingIn: false,
  isLoggingOut: false,
  logoutReason: null,
  oauth2: {
    login: null,
    logout: null,
    getSessionToken: null,
    isAuthenticated: null,
  },
  sessionToken: null,
  apiTokens: {
    sentry: null,
    matomo: null,
  },
  permitted: {},
};

/* Init */

function initOauth2(action, state) {
  return update(state, 'oauth2', oauth2State =>
    assign(oauth2State, {
      login: action.login,
      logout: action.logout,
      getSessionToken: action.getSessionToken, //(via oktaAuth object)
      isAuthenticated: action.isAuthenticated,
    })
  );
}

/* Auth Config */

function fetchAuthConfigStart(action, state) {
  return update(state, 'config', configState =>
    assign(configState, { error: null, isLoading: true })
  );
}

function fetchAuthConfigSuccess(action, state) {
  const config = keyBy(get(action, 'payload.result'), 'type');
  return update(state, 'config', configState =>
    assign(configState, { data: config, isLoading: false })
  );
}

function fetchAuthConfigError(action, state) {
  let err = action.payload;
  let data = null;
  if (err.code && err.code === 404) {
    // if the config endpoint was not available, assume basic SP auth
    err = null;
    data = set('config', { sp: {} }, state);
  }
  // other errors will be recorded but ignored until SP auth is unavailable
  return update(state, 'config', configState =>
    assign(configState, { data, error: err, isLoading: false })
  );
}

/* Login */

function loginStart(action, state) {
  return assign(state, {
    isLoggingIn: true,
    loginError: null,
    logoutReason: null,
  });
}

function loginStop(action, state) {
  return assign(state, {
    isLoggingIn: false,
  });
}

function loginResponseToGoogle(response) {
  return get(response, ['google_o', 'api-key_s'], process.env.REACT_APP_GOOGLE_API_KEY);
}

function loginResponseToMatomo(response) {
  return process.env.REACT_APP_DEVELOPMENT !== 'true'
    ? {
        url: get(response, ['matomo_o', 'reporting_url'], process.env.REACT_APP_MATOMO_URL),
        siteId: get(response, ['matomo_o', 'site_id_ui'], process.env.REACT_APP_MATOMO_SITEID),
      }
    : {};
}

function loginResponseToSentry(response) {
  return (
    process.env.REACT_APP_DEVELOPMENT !== 'true' &&
    get(response, ['sentry_o', 'dsn_url'], process.env.REACT_APP_SENTRY_DSN)
  );
}

function loginSuccess({ payload }, state) {
  const { sessionToken } = payload;
  return assign(state, {
    apiTokens: {
      google: loginResponseToGoogle(payload),
      matomo: loginResponseToMatomo(payload),
      sentry: loginResponseToSentry(payload),
    },
    isLoggingIn: false,
    sessionToken,
  });
}

function updateSessionToken({ payload }, state) {
  const { sessionToken } = payload;
  return assign(state, {
    sessionToken,
  });
}

function loginError({ payload }, state) {
  return assign(state, {
    isLoggingIn: false,
    loginError: payload,
  });
}

/* Logout */

function normalizeLogoutReason(logoutReason, state) {
  // if we were trying to log in and the log out reason was unauthenticated, then
  // the real reason we weren't able to login was due to login failing, this is due
  // to authentication mechanisms that don't establish an explicit session
  if (state.isLoggingIn && logoutReason.reason === 'unauthenticated') {
    logoutReason.reason = 'loginFailed';
    return logoutReason;
  }
  // if we weren't ever logged in, don't pretend we just logged out, it's silly
  if (!state.sessionToken) {
    return null;
  }
  return logoutReason;
}

function logoutSuccess({ payload }, state) {
  deleteSessionToken(state.sessionToken);
  return {
    ...state,
    isLoggingIn: false,
    isLoggingOut: false,
    logoutReason: normalizeLogoutReason(payload, state),
    sessionToken: null,
    apiTokens: {
      sentry: null,
      matamo: null,
    },
  };
}

// Permissions
// ===========

/**
 * Put the full permission (action, resourceType, resourceId, permission) into the
 * app state.
 *
 * @param authState
 * @param subjectId
 * @param action
 * @param resourceType
 * @param resourceId
 * @param permission
 * @returns {*}
 */
function permissionUpdated(
  authState,
  subjectId,
  { action, 'resource-type': resourceType, 'resource-id': resourceId, permission }
) {
  const id = permissionToId(subjectId, action, resourceType, resourceId);
  return update(authState, ['permitted', id], permissionState => {
    return assign(permissionState, {
      checking: false,
      isPermitted: permission,
      lastChecked: new Date().getTime(),
    });
  });
}

function fetchPermissionsSuccess(action, authState) {
  const subjectId = get(action, 'meta.action.subjectId');
  const permissions = get(action, 'payload.result');
  let newAuthState = omit(authState, ['isFetchingPermissions']);
  newAuthState = reduce(permissions, newAuthState, (authState, permission) => {
    return permissionUpdated(authState, subjectId, permission);
  });
  return newAuthState;
}

function fetchPermissionsError(action, authState) {
  // this error is not displayed to the user because the various AsyncCan calls will still
  // allow permissions to be checked as normal
  console.error('Could not bulk fetch permissions.', action.payload);
  return omit(authState, ['isFetchingPermissions']);
}

// TODO: switch to multimethod
const authReducer = (state = initialState, action) => {
  const { meta, payload, type } = action;
  switch (type) {
    case actions.SET_AUTHENTICATED:
      return {
        ...state,
        oauth2: {
          ...state.oauth2,
          accessToken: {
            type: 'oauth2',
            id: action.accessToken,
            value: action.accessToken,
          },
          isAuthenticated: () => action.isAuthenticated,
        },
      };
    case actions.CLEAR_LOGIN_ERROR:
      return { ...state, loginError: null };

    case actions.INIT_OAUTH2:
      return initOauth2(action, state);

    case actionStart(actions.FETCH_AUTH_CONFIG):
      return fetchAuthConfigStart(action, state);

    case actionSuccess(actions.FETCH_AUTH_CONFIG):
      return fetchAuthConfigSuccess(action, state);

    case actionError(actions.FETCH_AUTH_CONFIG):
      return fetchAuthConfigError(action, state);

    case actionStart(actions.FETCH_PERMISSIONS):
      return assign(state, {
        isFetchingPermissions: true,
      });

    case actionSuccess(actions.FETCH_PERMISSIONS):
      return fetchPermissionsSuccess(action, state);

    case actionError(actions.FETCH_PERMISSIONS):
      return fetchPermissionsError(action, state);

    case actions.LOGIN_START:
    case actionStart(actions.CONVERT_TOKEN):
      return loginStart(action, state);

    case actions.LOGIN_STOP:
      return loginStop(action, state);

    case actions.LOGIN_SUCCESS:
    case actionSuccess(actions.CONVERT_TOKEN):
      return loginSuccess(action, state);

    case actions.UPDATE_TOKEN:
      return updateSessionToken(action, state);

    case actions.LOGIN_ERROR:
    case actionError(actions.CONVERT_TOKEN):
      return loginError(action, state);

    case `${actions.IS_PERMITTED}_START`: {
      const id = get(meta, 'action.id');
      return update(state, ['permitted', id], permissionState => {
        return assign(permissionState, {
          checking: true,
          error: null,
          // Hold onto the last permitted state so we dont keep seeing
          // the thing disappear when it requeries for the permission
          isPermitted: get(state, ['permitted', id, 'isPermitted'], false),
          lastChecked: get(state, ['permitted', id, 'lastChecked']),
        });
      });
    }

    case `${actions.IS_PERMITTED}_SUCCESS`: {
      return permissionUpdated(state, get(meta, 'action.subjectId'), payload);
    }

    case `${actions.IS_PERMITTED}_ERROR`: {
      const id = get(meta, 'action.id');
      return update(state, ['permitted', id], permissionState => {
        return assign(permissionState, {
          checking: false,
          error: payload,
          isPermitted: false,
          lastChecked: null,
        });
      });
    }

    case `${actions.LOGOUT}_START`: {
      return {
        ...state,
        isLoggingOut: true,
      };
    }

    case `${actions.LOGOUT}_SUCCESS`:
      return logoutSuccess(action, state);

    case `${actions.LOGOUT}_ERROR`: {
      return {
        ...state,
        isLoggingIn: false,
        isLoggingOut: false,
        error: payload,
      };
    }

    default:
      return state;
  }
};

export default authReducer;
