import {
  dismissNotification,
  updateNotificationMessage,
  default as notification,
} from '../components/common/notification';

export function downloadable(startedMessage, finishedMessage, download) {
  return async () => {
    let autoClose = false;
    const n = notification.success(startedMessage, autoClose);
    try {
      await download();
      autoClose = true;
      updateNotificationMessage(n, finishedMessage, autoClose);
    } catch (error) {
      console.error('Error while downloading.', error);
      dismissNotification(n);
      notification.error(`Could not complete download: (${error.code}) ${error.toString()}`);
    }
  };
}
