export default theme => ({
  status__container: {
    display: 'hidden',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 10px',
    boxSizing: 'border-box',
    width: '100%',
    height: 0,
    opacity: 0,
    color: theme.colors.black,
    backgroundColor: theme.colors.white,
    transition: 'opacity, height 0.5s ease-in',
    zIndex: theme.zIndex.statusBanner,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    height: '100%',
  },
  status: {
    display: 'inline-flex',
    alignItems: 'center',
    color: 'inherit',
  },
  text: {
    ...theme.typography.h2,
    fontWeight: 300,
    marginLeft: theme.spacing,
    color: 'inherit',
    fontSize: '0.9rem',
  },
  showing: {
    display: 'flex',
    opacity: 1,
    height: 90,
  },
  dismiss: {
    color: theme.colors.white,
    marginLeft: theme.spacing,
    textTransform: 'unset',
    '&:hover': {
      color: theme.colors.white,
      textDecoration: 'underline',
    },
  },
  // banner styles
  error: {
    color: theme.colors.white,
    backgroundColor: theme.colors.error,
  },
  info: {
    color: theme.colors.white,
    backgroundColor: theme.colors.info,
  },
  warning: {
    color: theme.colors.white,
    backgroundColor: theme.colors.warning,
  },
});
