import React from 'react';
import injectStyles from 'react-jss';
import cx from 'classnames';

const ButtonGroup = ({ children, classes, className, gap, vertical }) => {
  const renderableChildren = Array.isArray(children) ? children.filter(i => i) : [children];
  const childCount = renderableChildren.length - 1;
  return (
    <div className={cx(classes.buttonGroup, vertical && classes.vertical__group, className)}>
      {React.Children.map(renderableChildren, (child, index) => (
        <div
          key={index}
          className={cx({
            [classes.buttonGroup__item]: true,
            [classes.noMargin]: index === childCount,
          })}
          style={{
            marginRight: gap,
            ...(vertical && {
              marginRight: 0,
              marginBottom: gap,
            }),
          }}>
          {child}
        </div>
      ))}
    </div>
  );
};

const styles = {
  buttonGroup: ({ alignRight, fullWidth }) => ({
    alignItems: 'center',
    display: 'inline-flex',
    height: 'auto',
    justifyContent: alignRight ? 'flex-end' : 'flex-start',
    position: 'relative',
    width: fullWidth ? '100%' : 'auto',
  }),
  vertical__group: {
    flexDirection: 'column',
  },
  buttonGroup__item: {
    height: 'auto',
    width: 'auto',
  },
  noMargin: {
    marginBottom: '0 !important',
    marginRight: '0 !important',
  },
};

ButtonGroup.defaultProps = {
  alignRight: false,
  fullWidth: false,
  gap: 10,
  vertical: false,
};

export default injectStyles(styles)(ButtonGroup);
