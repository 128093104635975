import { pickBy } from 'lodash';
import { API } from '../../../../../environment/api';
import { apiRequest } from '../../../../../lib/http';
import { tokenThunk } from '../../../../../utils/api';
import { buildFieldSetUpdates } from '../../../../../utils/api';

const actions = {
  CLEAR_SCHEDULE: 'report/CLEAR_SCHEDULE',
  CREATE_SCHEDULE: 'report/CREATE_SCHEDULE',
  DELETE_SCHEDULE: 'report/DELETE_SCHEDULE',
  READ_SCHEDULE: 'report/READ_SCHEDULE',
};

export function fetchScheduledReport(scheduleId) {
  const params = {
    'schedule-id': scheduleId,
  };

  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.READ_SCHEDULE,
      payload: apiRequest(token, API.SCHEDULES_READ, params),
    });
  });
}

export function deleteScheduledReport(scheduleId) {
  const params = {
    'schedule-id': scheduleId,
  };

  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.DELETE_SCHEDULE,
      payload: apiRequest(token, API.SCHEDULES_DELETE, params),
    });
  });
}

export function createScheduledReport(
  {
    endDate,
    frequency,
    name,
    occurrences,
    recipients,
    reportParams,
    reportType,
    startDate,
    toDate,
  },
  scheduleId,
  workspaceId
) {
  // the recipients need to be massaged from the format that the RecipientSelect uses
  recipients = recipients.map(r => {
    if (r.type === 'user') {
      return { type: 'user', 'user-id': r.userId };
    } else {
      return { type: 'email', 'email-address': r.email };
    }
  });

  let params = {
    name,
    params: reportParams,
    recipients,
  };

  if (frequency) {
    params['repeat'] = frequency.value;
  }

  if (startDate) {
    const dateTime = new Date(startDate);
    params['start'] = dateTime.getTime();
    params['repeat-cron'] = `0 ${dateTime.getUTCMinutes()} ${dateTime.getUTCHours()} ? * * *`;
  }

  params['final'] = null;
  params['occurrences'] = null;
  if (toDate === 'after') {
    params['occurrences'] = +occurrences;
  } else if (toDate === 'on') {
    params['final'] = new Date(endDate).getTime();
  }

  let apiEndPoint = API.SCHEDULES_CREATE;
  if (scheduleId) {
    apiEndPoint = API.SCHEDULES_UPDATE;
    const fieldUpdates = buildFieldSetUpdates(params);
    params = {
      'schedule-id': decodeURIComponent(scheduleId),
      'field-updates': fieldUpdates,
    };
  } else {
    params['workspace-id'] = workspaceId;
    params['type'] = reportType;
    params = pickBy(params, i => i);
  }

  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.CREATE_SCHEDULE,
      payload: apiRequest(token, apiEndPoint, params),
    });
  });
}

export function clearScheduledReport() {
  return {
    type: actions.CLEAR_SCHEDULE,
    payload: [],
  };
}
export default actions;
