import { get } from 'lodash';
import assign from 'lodash/fp/assign';
import multimethod from '../../../../../lib/multimethod';
import actions from './actions';
import { DEFAULT_SEARCH_LIMIT } from '../../actions';
import { actionError, actionStart, actionSuccess, apiData } from '../../../../../redux/common';

const initialState = {
  data: [],
  error: null,
  isRequesting: false,
  page: 1,
  query: '',
  size: DEFAULT_SEARCH_LIMIT,
  sortBy: '',
  totalResults: 0,
};

const { fetchErrored, fetchStarted } = apiData;
const reportActions = multimethod('type', (action, state) => state);

reportActions.method[actionStart(actions.READ_SCHEDULES)] = fetchStarted;

reportActions.method[actionError(actions.READ_SCHEDULES)] = fetchErrored;

reportActions.method[actionSuccess(actions.READ_SCHEDULES)] = (action, state) => {
  const data = get(action, 'payload.result', []);
  const { size, sortBy, page, query } = action;
  const totalResults = get(action, 'payload.summary.total-results', 0);
  return assign(state, {
    data,
    isRequesting: false,
    size,
    sortBy,
    page,
    query,
    totalResults,
  });
};

export default (state = initialState, action) => reportActions(action, state);
