import React from 'react';
import { Field } from 'redux-form';
import cx from 'classnames';
import InputField from '../../InputField';
import styles from './styles';
import { injectIntl } from 'react-intl';
import injectStyles from 'react-jss';
import PropTypes from 'prop-types';

class ThreatInputField extends React.Component {
  render() {
    const { classes, name } = this.props;
    return (
      <Field
        className={cx(classes.input, classes.input__threat)}
        id="threat-name"
        component={InputField}
        name={name}
        type="text"
        autoFocus
      />
    );
  }
}

ThreatInputField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default injectIntl(injectStyles(styles)(ThreatInputField));
