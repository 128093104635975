import { apiDownload } from '../lib/http';

export function downloadReport(sessionToken, report) {
  return apiDownload(
    'GET',
    `/reports/file?report-id=${encodeURIComponent(report.id)}`,
    sessionToken,
    report.name,
    {
      accept: report['mime-type'],
    }
  );
}
