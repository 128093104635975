export const DEFAULT_SEARCH_LIMIT = 100;

export const frequencyOptions = [
  {
    label: 'Daily',
    value: 'daily',
  },

  {
    label: 'Weekly',
    value: 'weekly',
  },

  {
    label: 'Semi-Monthly',
    value: 'semimonthly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Quarterly',
    value: 'quarterly',
  },
];
