import isEmpty from 'lodash/isEmpty';
import { combineReducers } from 'redux';
import actions from './actions';

export const defaultSearchOptions = [
  {
    label: 'All',
    value: 'search',
    alias: 'basic',
    props: {
      defaultSearchLimit: 100,
      defaultSort: 'ticScore',
      defaultOrder: 'desc',
    },
  },
  {
    label: 'Map',
    value: 'map',
    props: {
      defaultSearchLimit: 100,
      defaultSort: 'ticScore',
      defaultOrder: 'desc',
    },
  },
  {
    label: 'Reverse Whois',
    value: 'whois',
    props: {
      defaultSearchLimit: 1000,
      defaultSort: 'domains',
      defaultOrder: 'asc',
    },
  },
  {
    label: 'Associated Risks',
    value: 'risks',
    props: {
      defaultSearchLimit: 100,
      defaultSort: 'ticScore',
      defaultOrder: 'desc',
    },
  },
];

const initialState = {
  error: null,
  options: defaultSearchOptions,
  suggestions: {
    data: [],
    error: null,
    loading: false,
  },
  type: defaultSearchOptions[0],
  value: '',
  sort: 'ticScore',
  order: 'desc',
  queryError: false,
};

const searchReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.SET_SEARCH:
    case actions.SET_TYPE: {
      return {
        ...state,
        ...payload,
      };
    }

    case actions.SET_QUERY_ERROR: {
      return {
        ...state,
        queryError: true,
      };
    }

    case actions.CLEAR_QUERY_ERROR: {
      return {
        ...state,
        queryError: false,
      };
    }

    // Clear suggestions
    case actions.CLEAR_SUGGESTIONS: {
      return {
        ...state,
        suggestions: {
          data: [],
          error: null,
          loading: false,
        },
      };
    }

    // Reset default options and suggestions
    case actions.CLEAR_OPTIONS: {
      return initialState;
    }

    // Clear suggestions
    case actions.SET_SORT: {
      return {
        ...state,
        ...payload,
      };
    }

    // Fetch suggestions
    case `${actions.FETCH_SUGGESTIONS}_START`: {
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          error: null,
          loading: true,
        },
      };
    }

    case `${actions.FETCH_SUGGESTIONS}_SUCCESS`: {
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          data: payload,
          loading: false,
        },
      };
    }

    case `${actions.FETCH_SUGGESTIONS}_ERROR`: {
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          // This can have cancelled requests i.e empty error payloads
          error: !isEmpty(payload) ? payload : null,
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default combineReducers({
  core: searchReducer,
});
