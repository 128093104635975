// Opt-in ie9-ie11 support polyfills
import './polyfills';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'react-jss';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorker from './serviceWorker';
import { configureStore } from './redux/store';
import theme from './theme';
import LocalizedRoot from './components/app/LocalizedRoot';
import { CacheBuster } from './components/base';

// Stylesheet
import './components/common/Placeholders/stylesheets/PlaceholderAnimation.css';

// Initialize the redux store
const reduxStore = configureStore();

const { store, persistor } = reduxStore;

if (process.env.NODE_ENV !== 'production') {
  window.store = store;
}

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              refreshCacheAndReload();
            }

            return <LocalizedRoot />;
          }}
        </CacheBuster>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

// Render root into DOM
ReactDOM.render(<Root />, document.getElementById('root') || document.createElement('div'));

// TODO: SP-2514
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export { store, persistor };
