export default theme => {
  const labelStyles = {
    lightBlue: {
      backgroundColor: theme.colors.lightBlue,
      color: theme.colors.white,
    },
    blue: {
      backgroundColor: theme.colors.blue,
      color: theme.colors.white,
    },
    lightGray: {
      backgroundColor: theme.colors.lightGray,
      color: theme.colors.black,
    },
    gray: {
      backgroundColor: theme.colors.gray,
      color: theme.colors.white,
    },
    darkGray: {
      backgroundColor: theme.colors.darkGray,
      color: theme.colors.white,
    },
    green: {
      backgroundColor: theme.colors.green,
      color: theme.colors.white,
    },
    red: {
      backgroundColor: theme.colors.red,
      color: theme.colors.white,
    },
    transparent: {
      backgroundColor: 'transparent',
      color: theme.colors.black,
    },
  };
  return {
    label: ({ type }) => ({
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '5px 10px',
      borderRadius: theme.borderRadius.none,
      ...labelStyles[type],
    }),
    value: {
      fontSize: 'inherit',
      color: 'inherit',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
  };
};
