import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

export default class Tooltip extends React.Component {
  static propTypes = {
    text: PropTypes.string,
  };

  render() {
    const { children, text } = this.props;
    return (
      <React.Fragment>
        <span data-tip={text} data-event-off={'click'}>
          {children}
        </span>
        <ReactTooltip />
      </React.Fragment>
    );
  }
}
