export default theme => ({
  container: {
    display: 'inline-flex',
    height: '100%',
    width: '100%',
  },
  columnLeft: ({ leftWidth: width }) => ({
    display: 'flex',
    width,
    height: '100%',
    flexShrink: 0,
    backgroundColor: 'inherit',
    boxShadow: theme.boxShadows.medium,
    zIndex: 2,
  }),
  columnMain: ({ leftWidth, rightWidth }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: `calc(100% - ${leftWidth + rightWidth}px)`,
    height: '100%',
    flexGrow: 1,
    backgroundColor: 'inherit',
    zIndex: 1,
  }),
  columnRight: ({ rightWidth: width }) => ({
    display: 'flex',
    flexDirection: 'column',
    width,
    height: '100%',
    flexGrow: 1,
    backgroundColor: 'inherit',
    boxShadow: theme.boxShadows.medium,
    zIndex: 2,
  }),
});
