import React from 'react';
import cx from 'classnames';
import Button from '../../../buttons/Base';
import injectStyles from 'react-jss';
import styles from '../../styles';

class ActionButton extends React.Component {
  render() {
    const {
      id,
      className,
      disabled,
      onClick,
      role = 'base',
      type = 'button',
      value,
      cssClassName,
    } = this.props;

    return (
      <Button
        id={id}
        role={role}
        type={type}
        className={`${cx(className, 'form-action-button')}  ${cssClassName ? cssClassName : ''}`}
        onClick={onClick}
        value={value}
        disabled={disabled}
      />
    );
  }
}

export default injectStyles(styles)(ActionButton);
