import React, { Component } from 'react';
import injectStyles from 'react-jss';
import StandardError from './StandardError';
import { systemVersion } from '../../../redux/modules/app/actions';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

class BadGateway extends Component {
  constructor(props) {
    super(props);
    const { retryInterval = 10000 } = props;
    this.interval = setInterval(this.pingServer, retryInterval);
  }

  pingServer = () => {
    this.props.systemVersion();
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { classes, intl } = this.props;
    return (
      <StandardError
        error={502}
        header={<FormattedMessage id="bad-gateway-title" defaultMessage={'Unable to Connect'} />}
        headerClass={classes.header__class}
        context={intl.formatMessage({
          id: 'app-500-context',
          defaultMessage: 'Please contact support if this continues to happen.',
        })}
        description={
          <p>
            <FormattedMessage
              id="bad-gateway-description"
              defaultMessage={'We are unable to connect at this time.'}
            />
          </p>
        }
        footer={
          <div onClick={() => window.location.reload(true)}>
            <FormattedMessage id="bad-gateway-refresh" defaultMessage={'Refresh'} />
          </div>
        }
      />
    );
  }
}

const styles = theme => ({
  header__class: {
    fontSize: '3em',
    color: 'red',
    marginTop: '2em',
  },
});

const mapDispatchToProps = {
  systemVersion,
};

export default connect(
  null,
  mapDispatchToProps
)(injectIntl(injectStyles(styles)(BadGateway)));
