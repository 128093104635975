import React from 'react';
import styles from '../../styles';
import injectStyles from 'react-jss';
import cx from 'classnames';

class InlineGroup extends React.Component {
  render() {
    const { classes, children, className, innerClassName } = this.props;
    const childCount = React.Children.count(children) - 1;
    return (
      <div className={cx(classes.inlineGroup, className, 'form-inline-group')}>
        {React.Children.map(children, (child, index) => (
          <div key={index} className={cx(classes.inlineGroup__item, innerClassName)}>
            <div
              className={cx(classes.inlineGroup__inner, index === childCount && classes.noPadding)}>
              {child}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default injectStyles(styles)(InlineGroup);
