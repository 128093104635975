/**
 * This began life as https://github.com/olahol/react-tagsinput. Development was seemingly
 * abandoned on that library and it was interned. Modifications have been made to make it
 * work in a more consistent way with how it is actually used.
 */
import React from 'react';

// Configuration
import PropTypes from 'prop-types';

// Utils
import { isError } from 'lodash';

function uniq(arr) {
  let out = [];

  for (let i = 0; i < arr.length; i++) {
    if (out.indexOf(arr[i]) === -1) {
      out.push(arr[i]);
    }
  }

  return out;
}

/* istanbul ignore next */
function getClipboardData(e) {
  if (window.clipboardData) {
    return window.clipboardData.getData('Text');
  }

  if (e.clipboardData) {
    return e.clipboardData.getData('text/plain');
  }

  return '';
}

/* eslint-disable */
function defaultRenderTag(props) {
  let { tag, key, disabled, onRemove, classNameRemove, tagToDisplayValue, ...other } = props;
  return (
    <span key={key} {...other}>
      {tagToDisplayValue(tag)}
      {!disabled && <a className={classNameRemove} onClick={e => onRemove(key)} />}
    </span>
  );
}
/* eslint-enable */

defaultRenderTag.propTypes = {
  key: PropTypes.number,
  tag: PropTypes.string,
  onRemove: PropTypes.func,
  classNameRemove: PropTypes.string,
  getTagDisplayValue: PropTypes.func,
};

function defaultRenderInput({ addTag, ...props }) {
  let { onChange, value, ...other } = props;
  return <input type="text" onChange={onChange} value={value} {...other} />;
}

defaultRenderInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  addTag: PropTypes.func,
};

function defaultRenderLayout(tagComponents, inputComponent) {
  return (
    <span>
      {tagComponents}
      {inputComponent}
    </span>
  );
}

const defaultInputProps = {
  className: 'react-tagsinput-input',
  placeholder: 'Type...',
};

class BulkSelectInput extends React.Component {
  /* istanbul ignore next */
  constructor() {
    super();
    this.state = { tag: '', isFocused: false };
    this.focus = this.focus.bind(this);
    this.blur = this.blur.bind(this);
  }

  static propTypes = {
    addKeys: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    addOnBlur: PropTypes.bool,
    currentValue: PropTypes.string,
    disabled: PropTypes.bool,
    focusedClassName: PropTypes.string,
    inputProps: PropTypes.object,
    inputValue: PropTypes.string,
    maxTags: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onChangeInput: PropTypes.func,
    onlyUnique: PropTypes.bool,
    preventSubmit: PropTypes.bool,
    removeKeys: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    renderInput: PropTypes.func,
    renderTag: PropTypes.func,
    renderLayout: PropTypes.func,
    separators: PropTypes.arrayOf(PropTypes.string),
    tagConstructor: PropTypes.func,
    tagProps: PropTypes.object,
    tagToDisplayValue: PropTypes.func,
    value: PropTypes.array.isRequired,
  };

  static defaultProps = {
    addKeys: [9, 13],
    addOnBlur: false,
    className: 'react-tagsinput',
    disabled: false,
    focusedClassName: 'react-tagsinput--focused',
    inputProps: {},
    maxTags: -1,
    onlyUnique: false,
    preventSubmit: true,
    removeKeys: [8],
    renderInput: defaultRenderInput,
    renderTag: defaultRenderTag,
    renderLayout: defaultRenderLayout,
    separators: [',', '\\s+', ';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n', '\r'],
    tagConstructor: tagText => tagText,
    tagProps: { className: 'react-tagsinput-tag', classNameRemove: 'react-tagsinput-remove' },
    tagToDisplayValue: tag => tag.toString(),
  };

  _makeTag = tagText => {
    const { tagConstructor } = this.props;
    try {
      return tagConstructor(tagText);
    } catch (err) {
      err.tagText = tagText;
      return err;
    }
  };

  _removeTag(index) {
    let value = this.props.value.concat([]);
    if (index > -1 && index < value.length) {
      let changed = value.splice(index, 1);
      this.props.onChange(value, changed, [index]);
    }
  }

  _clearInput() {
    if (this.hasControlledInput()) {
      this.props.onChangeInput('');
    } else {
      this.setState({ tag: '' });
    }
  }

  _tag() {
    if (this.hasControlledInput()) {
      return this.props.inputValue;
    }

    return this.state.tag;
  }

  _addTags(tags) {
    let {
      maxTags,
      onChange,
      onlyUnique,
      onValidationReject,
      tagToDisplayValue,
      value,
    } = this.props;

    const rejectedTags = tags.filter(tag => isError(tag));
    tags = tags.filter(tag => !isError(tag));

    if (onlyUnique) {
      tags = uniq(tags);
      tags = tags.filter(tag =>
        value.every(currentTag => tagToDisplayValue(currentTag) !== tagToDisplayValue(tag))
      );
    }

    tags = tags.filter(tag => {
      let tagDisplayValue = tagToDisplayValue(tag);
      if (typeof tagDisplayValue.trim === 'function') {
        return tagDisplayValue.trim().length > 0;
      } else {
        return tagDisplayValue;
      }
    });

    if (maxTags >= 0) {
      let remainingLimit = Math.max(maxTags - value.length, 0);
      tags = tags.slice(0, remainingLimit);
    }

    if (onValidationReject && rejectedTags.length > 0) {
      onValidationReject(rejectedTags);
    }

    if (tags.length > 0) {
      let newValue = value.concat(tags);
      let indexes = [];
      for (let i = 0; i < tags.length; i++) {
        indexes.push(value.length + i);
      }
      onChange(newValue, tags, indexes);
      this._clearInput();
      return true;
    }

    if (rejectedTags.length > 0) {
      return false;
    }

    this._clearInput();
    return false;
  }

  _shouldPreventDefaultEventOnAdd(added, empty, keyCode) {
    if (added) {
      return true;
    }

    if (keyCode === 13) {
      return this.props.preventSubmit || (!this.props.preventSubmit && !empty);
    }

    return false;
  }

  focus() {
    if (this.input && typeof this.input.focus === 'function') {
      this.input.focus();
    } else if (this.input.autowhatever) {
      const renderedInput = this.input.autowhatever.input;
      renderedInput && renderedInput.focus();
    }

    this.handleOnFocus();
  }

  blur() {
    if (this.input && typeof this.input.blur === 'function') {
      this.input.blur();
    }

    this.handleOnBlur();
  }

  accept() {
    let data = this._tag();
    if (data) {
      const tags = [this._makeTag(data)];
      return this._addTags(tags);
    }

    return false;
  }

  addTag(tag) {
    return this._addTags([tag]);
  }

  clearInput() {
    this._clearInput();
  }

  handlePaste = e => {
    let { separators } = this.props;
    e.preventDefault();
    let data = getClipboardData(e);
    const regex = new RegExp(separators.join('|'));
    const tags = data
      .split(regex)
      .map(token => token.trim())
      .filter(token => token)
      .map(this._makeTag);
    this._addTags(tags);
  };

  handleKeyDown(e) {
    if (e.defaultPrevented) {
      return;
    }

    let { value, removeKeys, addKeys } = this.props;
    const tag = this._tag();
    let empty = tag === '';
    let keyCode = e.keyCode;
    let key = e.key;
    let add = addKeys.indexOf(keyCode) !== -1 || addKeys.indexOf(key) !== -1;
    let remove = removeKeys.indexOf(keyCode) !== -1 || removeKeys.indexOf(key) !== -1;

    if (add) {
      let added = this.accept();
      if (this._shouldPreventDefaultEventOnAdd(added, empty, keyCode)) {
        e.preventDefault();
      }
    }

    if (remove && value.length > 0 && empty) {
      e.preventDefault();
      this._removeTag(value.length - 1);
    }
  }

  handleClick(e) {
    if (e.target === this.div) {
      this.focus();
    }
  }

  handleChange(e) {
    let { onChangeInput } = this.props;
    let { onChange } = this.props.inputProps;
    let tag = e.target.value;

    if (onChange) {
      onChange(e);
    }

    if (this.hasControlledInput()) {
      onChangeInput(tag);
    } else {
      this.setState({ tag });
    }
  }

  handleOnFocus(e) {
    let { onFocus } = this.props.inputProps;

    if (onFocus) {
      onFocus(e);
    }

    this.setState({ isFocused: true });
  }

  handleOnBlur(e) {
    let { onBlur } = this.props.inputProps;

    this.setState({ isFocused: false });

    if (e == null) {
      return;
    }

    if (onBlur) {
      onBlur(e);
    }

    if (this.props.addOnBlur) {
      this.accept();
    }
  }

  handleRemove(tag) {
    this._removeTag(tag);
  }

  inputProps() {
    // eslint-disable-next-line
    let { onChange, onFocus, onBlur, ...otherInputProps } = this.props.inputProps;

    let props = {
      ...defaultInputProps,
      ...otherInputProps,
    };

    if (this.props.disabled) {
      props.disabled = true;
    }

    return props;
  }

  inputValue(props) {
    return props.currentValue || props.inputValue || '';
  }

  hasControlledInput() {
    const { inputValue, onChangeInput } = this.props;

    return typeof onChangeInput === 'function' && typeof inputValue === 'string';
  }

  componentDidMount() {
    if (this.hasControlledInput()) {
      return;
    }

    this.setState({
      tag: this.inputValue(this.props),
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { currentValue } = this.props;
    /* istanbul ignore next */
    if (this.hasControlledInput()) {
      return;
    }

    if (!this.inputValue(this.props)) {
      return;
    }

    if (prevProps.currentValue !== currentValue) {
      this.setState({
        tag: this.inputValue(this.props),
      });
    }
  }

  render() {
    /* eslint-disable */
    let {
      value,
      onChange,
      tagProps,
      renderLayout,
      renderTag,
      renderInput,
      addKeys,
      removeKeys,
      className,
      focusedClassName,
      addOnBlur,
      inputProps,
      onlyUnique,
      maxTags,
      disabled,
      inputValue,
      onChangeInput,
      tagToDisplayValue,
      ...other
    } = this.props;
    /* eslint-enable */

    let { isFocused } = this.state;

    if (isFocused) {
      className += ' ' + focusedClassName;
    }

    let tagComponents = value.map((tag, index) => {
      return renderTag({
        key: index,
        tag,
        onRemove: this.handleRemove.bind(this),
        disabled,
        tagToDisplayValue,
        ...tagProps,
      });
    });

    let inputComponent = renderInput({
      ref: r => {
        this.input = r;
      },
      value: this._tag(),
      onPaste: this.handlePaste,
      onKeyDown: this.handleKeyDown.bind(this),
      onChange: this.handleChange.bind(this),
      onFocus: this.handleOnFocus.bind(this),
      onBlur: this.handleOnBlur.bind(this),
      addTag: this.addTag.bind(this),
      ...this.inputProps(),
    });

    return (
      <div
        ref={r => {
          this.div = r;
        }}
        onClick={this.handleClick.bind(this)}
        className={className}>
        {renderLayout(tagComponents, inputComponent)}
      </div>
    );
  }
}

export default BulkSelectInput;
