import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import qs from 'query-string';
import { dialoglessPath } from './routing';

/**
 * Returns a map of query params without values that are nil or empty
 * @param params
 * @returns {Object}
 */
export const cleanQueryParams = params => {
  return omitBy(params, v => isNil(v) || (!isNumber(v) && isEmpty(v)));
};

/**
 * Similar to pushing a location onto the history stack, this pushes params onto it. It
 * inserts the passed params into the url's search component without touching the other
 * existing search params. This will replace any existing params.
 * @param history The browser history object.
 * @param params An object containing URL params.
 */
export const pushParams = (history, params) => {
  const currentParams = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const newParams = cleanQueryParams(Object.assign(currentParams, params));
  history.push({
    search: qs.stringify(newParams),
  });
};

/**
 * Similar to pushing a location onto the history stack, this pushes params onto it. It
 * inserts the passed params into the url's search component without touching the other
 * existing search params. This will replace any existing params.
 * @param history The browser history object.
 * @param params An object containing URL params.
 */
export const replaceParams = (history, params) => {
  const currentParams = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const newParams = cleanQueryParams(Object.assign(currentParams, params));
  history.replace({
    search: qs.stringify(newParams),
  });
};

/**
 * If the current location is a dialog location (ends with /dialog/*), removes the
 * dialog part of the path and navigates immediately to the rest of the path.
 *
 * If the location isn't a dialog location, nothing should happen.
 *
 * @param history
 */
export const closeDialog = history => {
  history.replace({
    pathname: dialoglessPath(history.location),
    search: history.location.search,
  });
};
