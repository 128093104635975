import actions from './actions';

const initialState = {
  editorState: null,
  error: '',
  isEditing: false,
  isSaving: false,
};

const summaryReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case actions.SET_EDITOR_STATE: {
      return {
        ...state,
        editorState: payload,
      };
    }

    case actions.EDIT_SUMMARY: {
      return {
        ...state,
        isEditing: true,
        error: null,
      };
    }

    case actions.CANCEL_EDIT_SUMMARY: {
      return {
        ...state,
        isEditing: false,
        error: null,
      };
    }

    case `${actions.SAVE_SUMMARY}_START`: {
      return {
        ...state,
        isSaving: true,
        error: null,
      };
    }

    case `${actions.SAVE_SUMMARY}_SUCCESS`: {
      return {
        ...state,
        isSaving: false,
        error: null,
      };
    }

    case `${actions.SAVE_SUMMARY}_ERROR`: {
      return {
        ...state,
        error: payload,
        isSaving: false,
      };
    }

    default:
      return state;
  }
};

export default summaryReducer;
