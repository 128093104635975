export default theme => ({
  container: {
    display: 'inline-flex',
    height: '100%',
    width: '100%',
  },
  columnOne: ({ sidebarWidth: width }) => ({
    backgroundColor: theme.colors.white,
    boxShadow: theme.boxShadows.large,
    display: 'flex',
    flexShrink: 0,
    height: '100%',
    overflowY: 'auto',
    width,
    zIndex: 2,
  }),
  columnTwo: ({ sidebarWidth: width }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: `calc(100% - ${width}px)`,
    height: '100%',
    flexGrow: 1,
    backgroundColor: theme.colors.lighterGray,
    zIndex: 1,
  }),
});
