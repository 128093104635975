import React from 'react';
import Warning from '../../../../assets/images/icon_warning.svg';
import injectStyles from 'react-jss';

const WarningIcon = ({ classes }) => (
  <img src={Warning} alt="error" className={classes.icon__error} />
);

const styles = theme => ({
  icon__error: {
    backgroundColor: 'transparent',
    color: theme.colors.red,
    flexShrink: 0,
    height: '24px',
    width: '24px',
  },
});

export default injectStyles(styles)(WarningIcon);
