import multimethod from '../../../../../lib/multimethod';
import actions from './actions';
import { actionError, actionStart, actionSuccess, apiData } from '../../../../../redux/common';
import { get } from 'lodash';
import assign from 'lodash/fp/assign';

const whois = multimethod('type', (action, state) => state);

whois.method[actionStart(actions.FETCH_WHOIS)] = apiData.fetchStarted;

whois.method[actionSuccess(actions.FETCH_WHOIS)] = (action, state) => {
  return assign(state, {
    data: get(action, 'payload.result.whois-record'),
    isLoading: false,
    isRequesting: false,
  });
};

whois.method[actionError(actions.FETCH_WHOIS)] = apiData.fetchErrored;

export default (state = apiData.initialState, action) => whois(action, state);
