import withQueryParams from './withQueryParams';
import { toNumber } from 'lodash';

function pagifyParams({ order, page, pageSize, sort }) {
  const props = {};
  if (page) {
    props.page = toNumber(page);
  }
  if (pageSize) {
    props.pageSize = toNumber(pageSize);
  }
  if (order && sort) {
    props.sortBy = [[sort, order.toLowerCase()]];
  }
  if (order && sort && sort !== 'name') {
    props.sortBy.push(['name', 'asc']);
  }
  return props;
}

function withPage(WrappedComponent) {
  return withQueryParams(pagifyParams)(WrappedComponent);
}

export default withPage;
