import { apiRequest, apiUrl, request } from '../../../lib/http';
import { API } from '../../../environment/api';
import { tokenThunk } from '../../../utils/api';
import { persistor } from '../../../';
import { get } from 'lodash';
import { actionError } from '../../common';

const actions = {
  CLEAR_LOGIN_ERROR: 'auth/CLEAR_LOGIN_ERROR',
  CONVERT_TOKEN: 'auth/CONVERT_TOKEN',
  FETCH_AUTH_CONFIG: 'auth/FETCH_AUTH_CONFIG',
  FETCH_PERMISSIONS: 'auth/FETCH_PERMISSIONS',
  INIT_OAUTH2: 'auth/INIT_OAUTH2',
  IS_PERMITTED: 'auth/IS_PERMITTED',
  LOGIN_ERROR: 'auth/LOGIN_ERROR',
  LOGIN_START: 'auth/LOGIN_START',
  LOGIN_STOP: 'auth/LOGIN_STOP',
  LOGIN_SUCCESS: 'auth/LOGIN_SUCCESS',
  UPDATE_TOKEN: 'auth/UPDATE_TOKEN',
  LOGOUT: 'auth/LOGOUT',
  OAUTH2_LOGIN: 'auth/OAUTH2_LOGIN',
  SET_AUTHENTICATED: 'auth/SET_AUTHENTICATED',
};

export function setAuthenticated(isAuthenticated, accessToken) {
  return {
    type: actions.SET_AUTHENTICATED,
    isAuthenticated,
    accessToken,
  };
}

export function initOauth2(login, logout, getSessionToken, isAuthenticated) {
  return {
    type: actions.INIT_OAUTH2,
    login,
    logout,
    getSessionToken, //(via oktaAuth object)
    isAuthenticated,
  };
}

export function fetchAuthConfig() {
  return {
    type: actions.FETCH_AUTH_CONFIG,
    payload: request(apiUrl(API.SYSTEM_AUTH), {
      method: 'POST',
      body: JSON.stringify({
        params: {},
      }),
    }),
  };
}

/**
 * Performs a raw unauthenticated validate password token request
 * @param passwordToken
 * @returns {Promise<any>}
 */
export const validateTokenRaw = setPasswordToken =>
  request(apiUrl(API.USERS_VALIDATE_TOKEN), {
    method: 'POST',
    body: JSON.stringify({
      params: {
        'password-token': setPasswordToken,
      },
    }),
  });

/**
 * Performs a raw change password request
 * @param token
 * @param currentPassword
 * @param newPassword
 * @returns {*}
 */
export const changePasswordRaw = (token, { currentPassword, newPassword }) =>
  apiRequest(
    token,
    API.CHANGE_PASSWORD,
    {
      'current-password': currentPassword,
      'new-password': newPassword,
    },
    { noToastError: true }
  );

/**
 * Performs a raw set password by token request
 * @param token
 * @param setPasswordToken
 * @param newPassword
 * @returns {*}
 */
export const setPasswordRaw = (token, { setPasswordToken, newPassword }) =>
  apiRequest(
    token,
    API.USERS_SET_PASSWORD,
    {
      'password-token': setPasswordToken,
      password: newPassword,
    },
    { noToastError: true }
  );

/**
 * Performs a raw forgot password request
 * @param username
 * @returns {Promise<any>}
 */
export const forgotPasswordRaw = username =>
  request(`/api${API.USERS_FORGOT_PASSWORD}`, {
    method: 'POST',
    body: JSON.stringify({
      params: {
        username,
      },
    }),
  });

/**
 * Performs a raw login request
 * @param username
 * @param password
 * @returns {Promise<any>}
 */
export const loginRaw = (username, password) =>
  request(apiUrl(API.LOGIN_URL), {
    method: 'POST',
    body: JSON.stringify({
      params: {
        username,
        password,
      },
    }),
  });

export function loginStart() {
  return {
    type: actions.LOGIN_START,
  };
}

export function loginStop() {
  return {
    type: actions.LOGIN_STOP,
  };
}

/**
 * Returns an action creator after a successful login
 * @param authResponse
 * @returns {{type: string, payload: *}}
 */
export const loginSucceeded = authResponse => {
  return {
    type: actions.LOGIN_SUCCESS,
    payload: authResponse,
  };
};

export const loginError = error => {
  return {
    type: actions.LOGIN_ERROR,
    payload: error,
  };
};

/**
 * Logs a user out of the system
 * If there is still a token to logout with go ahead and do that
 * otherwise just clear out the state w/ success action
 * @returns {Function}
 */
export const logout = () =>
  tokenThunk(async (dispatch, token) => {
    const logoutDispatcher = {
      type: `${actions.LOGOUT}_SUCCESS`,
      payload: {
        message: 'You were successfully logged out!',
      },
    };
    if (!token) {
      return dispatch(logoutDispatcher);
    }
    try {
      persistor.purge();
      const { ok } = await apiRequest(token, API.LOGOUT_URL);
      if (ok) {
        return dispatch(logoutDispatcher);
      }
    } catch (error) {
      console.warn(error);
      return dispatch(logoutDispatcher);
    }
  });

/**
 * Clears out the local storage by purging the persistor. Adds a logoutReason
 * that tells the user why they were logged out.
 * @returns {Function}
 * @param opts
 */
export const clearApplication = opts => dispatch => {
  const reason = get(opts, 'reason', 'logout');
  persistor.purge();
  dispatch({
    type: `${actions.LOGOUT}_SUCCESS`,
    payload: { reason },
  });
};

export function clearLoginError() {
  return { type: actions.CLEAR_LOGIN_ERROR };
}

export function convertToken(sessionToken, intl) {
  return {
    payload: apiRequest(sessionToken, API.VALIDATE_SESSION, {}).then(response => {
      if (!response.ok) {
        let errorMsg = intl.formatMessage({
          id: 'auth-not-validate',
          defaultMessage: 'Could not validate session.',
        });
        if (response.message) {
          errorMsg = `${errorMsg} ${response.message}`;
        }
        let error = new Error(errorMsg);
        error.name = 'TokenError'; // used for checking for this error
        throw error;
      }
      // a bit of transformation needs to occur here since this is handled by the normal
      // login process which is not expecting an API response, but simply the result out
      // of the API response
      const { result } = response;
      result.sessionToken = sessionToken;
      return result;
    }),
    sessionToken,
    type: actions.CONVERT_TOKEN,
  };
}

export function convertTokenError(err) {
  return {
    payload: err,
    type: actionError(actions.CONVERT_TOKEN),
  };
}

export function convertTokenUpdate(sessionToken) {
  return {
    payload: { sessionToken },
    type: actions.UPDATE_TOKEN,
  };
}

// Permissions
// ===========

/**
 * Performs an isPermitted check
 * @param action
 * @param id
 * @param resourceId
 * @param resourceType
 * @param subjectId
 * @param subjectType
 * @returns {Function}
 */
export const isPermitted = ({ action, id, resourceId, resourceType, subjectId, subjectType }) => {
  const permission = {
    action,
    'resource-id': resourceId,
    'resource-type': resourceType,
  };
  return tokenThunk((dispatch, token) => {
    dispatch({
      id,
      payload: apiRequest(token, API.PERMISSIONS_IS_PERMITTED, {
        'subject-type': subjectType,
        'subject-id': subjectId,
        ...permission,
      }).then(({ result }) => ({
        ...permission,
        permission: result.permission,
      })),
      subjectId,
      type: actions.IS_PERMITTED,
    });
  });
};

export function fetchPermissions(subjectId, permissions) {
  const params = {
    'subject-type': 'user',
    'subject-id': subjectId,
    permissions,
  };
  return tokenThunk((dispatch, token) => {
    dispatch({
      payload: apiRequest(token, API.PERMISSIONS_IS_PERMITTED, params),
      subjectId,
      type: actions.FETCH_PERMISSIONS,
    });
  });
}

export default actions;
