import { API } from '../../../../../environment/api';
import { apiRequest } from '../../../../../lib/http';
import { tokenThunk } from '../../../../../utils/api';
import { DEFAULT_SEARCH_LIMIT } from '../../actions';
const actions = {
  READ_REPORTS: 'report/READ_REPORTS',
  READ_REPORT: 'report/READ_REPORT',
  DELETE_REPORT: 'report/DELETE_REPORT',
};

export function fetchReports(
  workspaceId,
  page = 1,
  sortBy = ['created-at', 'desc'],
  size = DEFAULT_SEARCH_LIMIT
) {
  const query = `status:available AND workspace-id:"${workspaceId}"  NOT (status:deleted)`;
  const start = (page - 1) * size;
  const params = {
    limit: size,
    query,
    start,
    'sort-by': sortBy,
  };

  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.READ_REPORTS,
      size,
      sortBy,
      page,
      query,
      payload: apiRequest(token, API.REPORTS_QUERY, params),
    });
  });
}

export function fetchReport(id) {
  const params = {
    id,
  };

  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.READ_REPORT,
      payload: apiRequest(token, API.REPORTS_STATUS, params),
    });
  });
}

export function deleteReport(id) {
  const params = {
    id,
  };

  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.DELETE_REPORT,
      id,
      payload: apiRequest(token, API.REPORTS_DELETE, params),
    });
  });
}

export default actions;
