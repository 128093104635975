import React from 'react';
import { FormattedMessage } from 'react-intl';

export function localizedText(intl, text, textId, textValues) {
  if (!textId) {
    return text;
  }
  return intl.formatMessage(
    {
      defaultMessage: text,
      id: textId,
    },
    textValues
  );
}

export function renderLocalized(children, text, textId) {
  if (textId) {
    return <FormattedMessage defaultMessage={text} id={textId} />;
  }
  if (text) {
    return text;
  }
  return children;
}
