import qs from 'query-string';

/**
 * Splits the passed url into the pre-query part and the query part. These are returned
 * as a pair.
 *
 * @param url
 * @returns {*}
 */
export const splitUrlAndQuery = url => {
  const c = url.indexOf('?');
  if (c < 0) {
    return [url, ''];
  }
  return [url.substring(0, c), url.substring(c + 1)];
};

/**
 * Combines the passed url (without a query) and the passed query. If the query is empty,
 * the url is returned unmodified.
 *
 * @param querylessUrl
 * @param queryString
 * @returns {*}
 */
export const combineUrlAndQuery = (querylessUrl, queryString) => {
  if (queryString === '') {
    return querylessUrl;
  }
  return `${querylessUrl}?${queryString}`;
};

/**
 * Sets params in the passed url, replacing any values that are there. This leaves the
 * non-query part of the url untouched. No validation of the non-query part of the url
 * is done, so anything can be passed (ex: relative or absolute urls).
 *
 * This does not take responsibility for decoding or encoding query keys or values.
 *
 * @param url
 * @param params
 * @returns {*|string}
 */
export const setParams = (url, params) => {
  const [querylessUrl, queryString] = splitUrlAndQuery(url);
  const query = Object.assign(qs.parse(queryString), params);
  return combineUrlAndQuery(querylessUrl, qs.stringify(query));
};

/**
 * Removes a param from the passed url This leaves the non-query part of the url
 * untouched. No validation of the non-query part of the url is done, so anything can be
 * passed (ex: relative or absolute urls).
 *
 * This does not take responsibility for decoding or encoding query keys or values.
 *
 * @param url
 * @param paramName
 * @returns {*|string}
 */
export const removeParam = (url, paramName) => {
  const [querylessUrl, queryString] = splitUrlAndQuery(url);
  const query = qs.parse(queryString);
  delete query[paramName];
  return combineUrlAndQuery(querylessUrl, qs.stringify(query));
};
