import React from 'react';

// Configuration
import PropTypes from 'prop-types';

// Styles
import injectStyles from 'react-jss';

// Components
import { FormattedMessage } from 'react-intl';
import BulkSelectInput from './BulkSelectInput';
import BulkSelectSuggestions from './BulkSelectSuggestions';
import ButtonGroup from '../../buttons/ButtonGroup';
import Button from '../../Button';

class BulkSelect extends React.Component {
  static propTypes = {
    addKeys: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    addOnBlur: PropTypes.bool,
    fetchSuggestions: PropTypes.func,
    maxTags: PropTypes.number,
    onChange: PropTypes.func,
    onlyUnique: PropTypes.bool,
    onValidationReject: PropTypes.func,
    optionConstructor: PropTypes.func,
    pillCaption: PropTypes.func,
    renderSuggestion: PropTypes.func.isRequired,
    selectedOptions: PropTypes.array.isRequired,
    separators: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    addOnBlur: true,
    onlyUnique: true,
  };

  state = {
    currentValue: '',
  };

  rejectedTags = errors => {
    let { onValidationReject } = this.props;
    const rejectedText = errors.map(e => e.tagText).join(' ');

    // Update the currentValue with the rejectedText to allow users to fix the errors.
    this.setState({
      currentValue: rejectedText,
    });

    if (onValidationReject) {
      onValidationReject(rejectedText);
    }
  };

  /**
   * Clear the currentValue text when the user is still focused on the AutoSuggestion Input.
   */
  clearRejectedText = () => {
    let { onValidationReject } = this.props;
    this.setState({
      currentValue: '',
    });
    onValidationReject && onValidationReject('');
  };

  removeAll = e => {
    e.preventDefault();
    const { onChange } = this.props;
    if (onChange) {
      // this should act like the TagsInput removed all the tags, go up to the parent
      // component, then flow back down in SelectedOptions
      onChange([]);
    }
  };

  renderSuggestions = renderInputProps => {
    let { fetchSuggestions, renderSuggestion, selectedOptions } = this.props;
    return (
      <BulkSelectSuggestions
        clearRejectedText={this.clearRejectedText}
        fetchSuggestions={fetchSuggestions}
        renderSuggestion={renderSuggestion}
        renderInputProps={renderInputProps}
        selectedOptions={selectedOptions}
      />
    );
  };

  render() {
    const {
      addKeys,
      addOnBlur,
      classes,
      inputValue,
      maxTags,
      optionConstructor,
      pillCaption,
      onChange,
      onChangeInput,
      onlyUnique,
      selectedOptions,
      separators,
    } = this.props;
    const { currentValue } = this.state;
    return (
      <div>
        <BulkSelectInput
          addKeys={addKeys}
          addOnBlur={addOnBlur}
          currentValue={currentValue}
          inputValue={inputValue}
          maxTags={maxTags}
          onChange={onChange}
          onChangeInput={onChangeInput}
          onlyUnique={onlyUnique}
          onValidationReject={this.rejectedTags}
          renderInput={this.renderSuggestions}
          separators={separators}
          tagConstructor={optionConstructor}
          tagToDisplayValue={pillCaption}
          value={selectedOptions}
        />
        <ButtonGroup alignRight fullWidth>
          <Button className={classes.button__removeAll} onClick={this.removeAll}>
            <FormattedMessage defaultMessage="Remove All" id="bulk-select-remove-all" />
          </Button>
        </ButtonGroup>
      </div>
    );
  }
}

const styles = theme => ({
  button__removeAll: {
    background: 'transparent',
    border: 0,
    color: theme.colors.darkGray,
    fontFamily: theme.fontFamily,
    height: 'auto',
    margin: {
      bottom: 10,
      left: 0,
      right: 0,
      top: 10,
    },
    padding: 0,
    textTransform: 'none',
    width: 'auto',
  },
});

export default injectStyles(styles)(BulkSelect);
