import { apiRequest } from '../../../../lib/http';
import { API } from '../../../../environment/api';
import { tokenThunk } from '../../../../utils/api';

const actions = {
  READ_ALL_COLLECTIONS: '@@collection/READ_ALL_COLLECTIONS',
  READ_SYSTEM_COLLECTIONS: '@@collection/READ_SYSTEM_COLLECTIONS',
  SET_FILTER_INDEX: '@@collection/SET_FILTER_INDEX',
};

export const setFilterIndex = index => {
  return {
    type: actions.SET_FILTER_INDEX,
    payload: { filterIndex: index },
  };
};

export const getCollectionsByWorkspace = (id, touchSessionFalse) => {
  return tokenThunk((dispatch, token) => {
    let params = {
      'workspace-id': id,
      attributes: [
        'assigned-to',
        'collection-id',
        'children',
        'description',
        'name',
        'parent-id',
        'tic-history',
        'tic-score',
        'workspace-id',
      ],
    };
    return dispatch({
      type: actions.READ_ALL_COLLECTIONS,
      payload: apiRequest(
        token,
        API.COLLECTIONS_READ_ALL,
        params,
        {},
        { 'touch-session': !touchSessionFalse }
      ),
    });
  });
};

export default actions;
