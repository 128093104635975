// fetch polyfill
import 'whatwg-fetch';
import { store } from '../';
import {
  clearStatusBanner,
  serverDown,
  serverUp,
  setStatusBanner,
} from '../redux/modules/app/actions';
import { clearApplication } from '../redux/modules/auth/actions';
import { APIError } from '../utils/errors';
import { serverUrl } from '../utils/env';
import { API } from '../environment/api';
import { get, set } from 'lodash';
import notification from '../components/common/notification';
import { saveAs } from 'file-saver';
import { isTokenValid } from '../utils/auth.js';

export const mimeTypeJson = 'application/json';

function responseBodyToMessage(responseBody) {
  if (responseBody.message) {
    return responseBody.message;
  }
  return responseBody.toString();
}

/**
 * Takes a fetch response, handles it (gets its response body) and throws an appropriate
 * error.
 *
 * @param response The fetch response.
 * @param message (optional) Override the message from the response with this.
 * @param reason (optional) Override the reason from the response with this.
 * @returns {*} A promise.
 */
export function requestError(response, message, reason) {
  return response.text().then(responseBody => {
    let errors;
    try {
      const parsedResponseBody = JSON.parse(responseBody);
      if (!message) {
        message = responseBodyToMessage(parsedResponseBody);
        message = parsedResponseBody.message;
      }
      if (!reason && parsedResponseBody.reason) {
        reason = parsedResponseBody.reason;
      }
      if (reason === 'batch-failure' && parsedResponseBody.failures) {
        errors = parsedResponseBody.failures.map(responseBodyToMessage);
      }
    } catch (err) {
      if (!message) {
        message = responseBody;
      }
    }
    if (!message) {
      message = 'Unknown response error.';
    }
    if (!reason) {
      reason = 'unknown';
    }
    const apiError = new APIError(response.status, `[${response.statusText}] ${message}`);
    apiError.errors = errors;
    apiError.reason = reason;
    throw apiError;
  });
}

/**
 * Converts the response body into a JavaScript object (null, string, object, array, etc.).
 */
function parseResponse(response) {
  const contentType = response.headers.get('content-type');
  switch (contentType) {
    case 'application/json':
    case 'application/json;charset=utf-8':
      return response.text().then(data => {
        if (!data) {
          return null;
        } else {
          return JSON.parse(data);
        }
      });
    case null:
      return null;
    /* Other content types can be handled here. */
    default:
      throw new APIError(501, 'Unhandled content type.');
  }
}

/**
 * Valid http status codes will return a json object otherwise an error is thrown. Assumes
 * caller will catch the exception.
 * @param response
 * @returns {*}
 */
const checkStatus = response => {
  const status = response.status;

  if (status >= 200 && status < 300) {
    // Everything is good
    if (store.getState().app.serverDown) {
      store.dispatch(clearStatusBanner());
      store.dispatch(serverUp());
    }
    return parseResponse(response);
  } else if (status === 401 || status === 403) {
    store.dispatch(clearApplication({ reason: 'unauthenticated' }));
    return requestError(response);
  } else if (status === 404) {
    throw new APIError(404, 'The endpoint requested was not found.');
  } else if (status >= 400 && status < 500) {
    return requestError(response);
  } else if (status === 502 || status === 504) {
    // Server may be down, show banner
    store.dispatch(
      setStatusBanner({
        message:
          'The server is currently experiencing problems. Please refresh or contact support for more details.',
      })
    );
    store.dispatch(serverDown());
    throw new APIError(502, 'The server is currently down.');
  } else {
    return requestError(response);
  }
};

/**
 * Normalization handler for the API since the LG-API has some inconsistent methods for error responses
 * @param data
 * @returns {{data: *, code: number}}
 */
const extractResult = data => {
  const { ok, result, message, summary, errors } = data;
  if (!ok) {
    // TODO: I hate everything about doing this, many sighs
    const code = message === 'Authentication required' ? 401 : 400;
    if (code === 401) {
      store.dispatch(clearApplication({ reason: 'unauthenticated' }));
    }
  }
  return {
    ok,
    result,
    message,
    summary,
    errors,
  };
};

export const REQUEST_TIMEOUT_MS = 600000;

/**
 * Takes a promise and wraps the promise resolution in a client-side timer based on milliseconds, ms.
 * If time ms passes without a `resolve` this will reject the request with an error.
 * @param ms
 * @param promise
 * @returns {Promise<any>}
 */
export function timedRequest(ms, promise) {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new APIError(502, 'The request took too long to return.'));
    }, ms);
    promise.then(
      res => {
        clearTimeout(timeoutId);
        resolve(res);
      },
      () => {
        clearTimeout(timeoutId);
        reject(new APIError(499, 'Cancelled request'));
      }
    );
  });
}

/**
 * Performs an http request given a url and params. Internally uses `fetch` standard to perform the request.
 * @param url
 * @param options
 * @returns {Promise<any>}
 */
export const request = (url, options) => {
  return new Promise((resolve, reject) => {
    if (!url) {
      return reject(new APIError(400, 'Request url is a required field'));
    }
    if (!options) {
      return reject(new APIError(400, 'Request options is a required field'));
    }
    const reqOptions = {
      headers: {
        Accept: mimeTypeJson,
        'Content-Type': mimeTypeJson,
        ...options.headers,
      },
      ...options,
    };
    timedRequest(REQUEST_TIMEOUT_MS, fetch(url, reqOptions))
      .then(checkStatus)
      .then(extractResult)
      .then(resolve)
      .catch(reject);
  });
};

export const apiUrl = apiPath => {
  if (apiPath.startsWith('/')) {
    // we want the path to not start with a slash so that the base path
    // can be overridden by just passing /
    apiPath = apiPath.substring(1);
  }
  let apiBasePath = process.env.REACT_APP_API_PATH;
  if (!apiBasePath) {
    // if there is no dev override for the path, use the default ScoutPrime API base
    apiBasePath = '/api/';
  }
  return serverUrl(`${apiBasePath}${apiPath}`);
};

function addAuth(options, token) {
  if (!token) {
    return options;
  }
  switch (token.type) {
    case 'oauth2':
      // check for expired tokens (for testing and debugging -- could be remvoed in future)
      isTokenValid(token.id, 'addAuth', false);
      isTokenValid(token.value, 'addAuth', false);

      return set(options, ['headers', 'Authorization'], `Bearer ${token.value}`);
    default:
      return set(options, ['headers', 'x-lg-session'], token.value);
  }
}

export const apiRequest = (token, apiPath, params, afterFinish = {}, extraBody = {}, opts = {}) => {
  let options = {
    method: 'POST',
    body: JSON.stringify({
      params,
      ...extraBody,
    }),
    // For abortable requests e.g, autocomplete usecase

    signal: opts && opts.signal,
  };
  options = addAuth(options, token);
  return request(apiUrl(apiPath), options)
    .then(res => {
      if (!res.ok && apiPath !== API.NOTES_READ_ALL) {
        let errorMessage = res.message || 'Server Error';
        if (errorMessage === 'Authentication required') {
          return res;
        }
        if (extraBody.errorMessage) {
          errorMessage = extraBody.errorMessage;
        }

        if (res.errors && Array.isArray(res.errors)) {
          errorMessage += '\n\n' + res.errors.join(', ');
        }

        if (!afterFinish.noToastError) {
          notification.error(errorMessage);
        }
      }
      return res;
    })
    .catch(err => {
      // Aborted request handler
      if (err.code === 499) {
        return { aborted: true };
      }
      throw err;
    });
};

const defaultOpts = {
  accept: mimeTypeJson,
  contentType: mimeTypeJson,
};

function buildFetchOptions(method, token, passedOpts) {
  const opts = Object.assign({}, defaultOpts, passedOpts);
  let reqOptions = {
    headers: {
      Accept: opts.accept,
      'Content-Type': opts.contentType,
      // IE is overly cachey, need to tell it not to cache API stuff
      'Cache-Control': 'no-store',
      Pragma: 'no-cache',
      ...opts.headers,
    },
    method,
    signal: opts.signal,
  };
  reqOptions = addAuth(reqOptions, token);
  if (get(reqOptions, 'headers.Content-Type') === mimeTypeJson && opts.body) {
    reqOptions.body = JSON.stringify(opts.body);
  }
  return reqOptions;
}

export function apiRequestRaw(method, apiPath, token, passedOpts = {}) {
  const fetchOpts = buildFetchOptions(method, token, passedOpts);
  return timedRequest(REQUEST_TIMEOUT_MS, fetch(apiUrl(apiPath), fetchOpts)).then(checkStatus);
}

export function apiDownload(method, path, token, filename, passedOpts = {}) {
  const fetchOpts = buildFetchOptions(method, token, passedOpts);
  return fetch(apiUrl(path), fetchOpts)
    .then(response => {
      if (response.status !== 200) {
        throw new APIError(response.status, response.statusText);
      }
      return response.blob();
    })
    .then(blob => {
      saveAs(blob, filename);
    });
}
