import multimethod from '../../../../../lib/multimethod';
import { actionError, actionStart, actionSuccess, apiData } from '../../../../../redux/common';
import actions from './actions';
import graphApi from '../../../../../lib/http/graph';
import assign from 'lodash/fp/assign';
import set from 'lodash/fp/set';

const owners = multimethod('type', (action, state) => state);

owners.method[actionStart(actions.FETCH_OWNERS)] = apiData.fetchStarted;

owners.method[actionSuccess(actions.FETCH_OWNERS)] = (action, state) => {
  const analyticsResults = graphApi.response.toAnalyticsResults(action.payload);
  return assign(state, {
    data: analyticsResults.owners.results,
    isLoading: false,
    isRequesting: false,
    isRefreshing: false,
    total: analyticsResults.numOwners,
  });
};

owners.method[actionError(actions.FETCH_OWNERS)] = (action, state) => {
  const s = apiData.fetchErrored(action, state);
  return set('isRefreshing', false, s);
};

owners.method[actions.REFRESH_OWNERS] = (action, state) => {
  return set('isRefreshing', true, state);
};

export default (state = apiData.initialState, action) => owners(action, state);
