const arrow = alignedRight => ({
  '&:before': {
    border: 'inset 6px',
    content: '""',
    display: 'block',
    height: 0,
    width: 0,
    borderColor: 'rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #FFF rgba(0, 0, 0, 0)',
    borderBottomStyle: 'solid',
    position: 'absolute',
    top: -12,
    zIndex: 9,
    ...(alignedRight && { right: 10 }),
    ...(!alignedRight && { left: 10 }),
  },
  '&:after': {
    border: 'inset 7px',
    content: '""',
    display: 'block',
    height: 0,
    width: 0,
    borderColor: 'rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #ccc rgba(0, 0, 0, 0)',
    borderBottomStyle: 'solid',
    position: 'absolute',
    top: -14,
    zIndex: 8,
    ...(alignedRight && { right: 9 }),
    ...(!alignedRight && { left: 9 }),
  },
});

export default theme => ({
  container: {
    position: 'relative',
    display: 'inline-block',
  },
  arrowRight: arrow(true),
  arrowLeft: arrow(false),
  button: {
    display: 'flex',
    alignItems: 'center',
    height: 25,
    cursor: 'pointer',
    transition: 'color 0.2s ease-in-out',
    '&:hover': {
      '& div, svg': {
        color: theme.colors.iris,
      },
    },
  },
  button__disabled: {
    cursor: 'auto',
  },
  dropdownArrow: {
    color: theme.colors.gray,
    marginTop: 3,
    paddingLeft: theme.formFields.arrowPadding,
  },
  dropdownArrow__disabled: {
    color: theme.colors.lightGray,
  },
  hidden: {
    display: 'none !important',
  },
  item: {
    alignItems: 'center',
    display: 'flex',
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  list__item: {
    '&:not(:last-child):after': theme.separator.bottom,
    '&:hover': {
      backgroundColor: theme.colors.lightestGray,
    },
    '& a': {
      color: theme.colors.black,
      textDecoration: 'none',
    },
    cursor: 'pointer',
    padding: theme.spacing,
    position: 'relative',
  },
  menu: ({ topOffset, width, alignedRight }) => ({
    position: 'absolute',
    top: topOffset,
    zIndex: 10,
    marginTop: 2,
    width,
    maxWidth: 300,
    background: theme.colors.white,
    border: '1px solid',
    borderColor: theme.colors.lighterGray,
    paddingLeft: 0,
    listStyle: 'none',
    boxShadow: theme.boxShadows.large,
    ...(alignedRight && { right: -7 }),
    ...(!alignedRight && { left: 0 }),
  }),
});
