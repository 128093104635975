import React from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import { determineTokenAndAuth } from '../../utils/auth.js';

// IMPORTANT! sessionToken should not be used directly from Redux. By using determineTokenAndAuth(), when in oauth2
// auth mode sessionToken will be obtained directly via Okta component/package, avoiding a copy from possibly becoming stale.
const mapStateToProps = (state, ownProps) => {
  return determineTokenAndAuth(state, ownProps);
};

export default WrappedComponent => {
  class WithSessionToken extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  return withOktaAuth(connect(mapStateToProps)(WithSessionToken));
};
