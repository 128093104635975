import React from 'react';
import cx from 'classnames';
import injectStyles from 'react-jss';
import styles from '../styles';

class Footer extends React.Component {
  render() {
    const { classes, children, className } = this.props;
    return <div className={cx(classes.footer, className, 'form-footer')}>{children}</div>;
  }
}

export default injectStyles(styles)(Footer);
