import jwt_decode from 'jwt-decode';
import { get } from 'lodash';

/**
 * If the token is a valid JSON Web Token (JWT) and if...
 *   ...not expired return true
 *   ...expired return false.
 * If the token is not a valid JWT, return null (no exp time or exeption when decoding)
 * If displayPositive = false only console log if token is expired.
 *
 * This can be used to verify that tokens are being updated and there are not tokens used which are
 * expired. For example, prior to being used before an API request to the scoutPRIME server:
 *    if (sessionToken && sessionToken.type === 'oauth2') {
 *      // for catching expired tokens and debugging
 *      isTokenValid(sessionToken.id, 'graph analytics', false);
 *      isTokenValid(sesssionToken.value, 'graph analytics', false);
 *    } else if (!sessionToken) {
 *      console.log("sessionToken not specified in graph analytics");
 *    }
 *    ...
 */
export function isTokenValid(token, useStr, displayPositive = true) {
  try {
    const tokenPayload = jwt_decode(token);
    const tokenType = 'jti' in tokenPayload ? tokenPayload.jti.substring(0, 2) : 'unknown type';

    if ('exp' in tokenPayload) {
      const curTimeSecs = Math.floor(Date.now() / 1000); // convert UTC epoch ms to secs
      const secsRemaining = tokenPayload.exp - curTimeSecs; // tokenPayload.exp is in epoch seconds
      if (secsRemaining < 0) {
        console.log(
          'In ' +
            useStr +
            ', token has expired by ' +
            Math.abs(secsRemaining) +
            ' seconds (' +
            tokenType +
            ').'
        );
      } else {
        if (displayPositive) {
          console.log(
            'In ' +
              useStr +
              ', there are ' +
              secsRemaining +
              ' remaining seconds before token expires (' +
              tokenType +
              ').'
          );
        }
      }
      return curTimeSecs < tokenPayload.exp;
    } else {
      console.log('Token does not have exp time (' + tokenType + ').');
      if (token) {
        console.log(token);
      }
    }
  } catch (error) {
    console.log('In ' + useStr + ', not a valid JWT (expected until user session is established).');
    if (token) {
      console.log(token);
    }
  }
  return null;
}

/**
 * This function is to be used to determine the sessionToken, isAuthenticated and authType.
 * IMPORTANT! These elements should NOT be obtained or derived directly from Redux,
 * which avoiding a copy from possibly becoming stale.
 * It is used in ...
 *   -- withSessionToken.js, which MUST be used instead of mapStateToProps to obtain sessionToken directly
 *   -- tokenThunk in api.js
 * Returns {sessionToken, isAuthenticated}
 */
export function determineTokenAndAuth(state, ownProps) {
  let { config, sessionToken, oauth2 } = state.auth;
  let curSessionToken = null;
  let isAuthenticated = false;
  let authType = 'oauth2'; // no need to look up authType since it will now always be oauth2

  if (config && config.data && config.data.oauth2) {
    // Only set isAuthenticated true if sessionToken has been saved in Redux (which may only occur initially),
    // because this ensures that add'l needed initialization has occurred (thus !!sessionToken below).
    let isAuthenticatedRedux = !!sessionToken;
    // being called in withSessionToken.js which uses withOktaAuth, authState should be available in props
    let isAuthenticatedOkta =
      ownProps && ownProps.authState ? ownProps.authState.isAuthenticated : false;
    isAuthenticated = isAuthenticatedRedux && isAuthenticatedOkta;
    // Generally Okta authentication will occur first as the user signs in via Okta,
    // then the sessionToken is temporarily stored in Redux and the session with the
    // scoutPRIME is validated. But then the page is refreshed, for example, isAuthenticatedRedux
    // may be true, while isAuthenticatedOkta is false, until oktaAuth object is reinstantiated
    // (in OktaSecurity and OktaLifecycle).
  }

  // need to check tokens, since they seem to be ready before isAuthenticated is set
  if (
    ownProps.authState &&
    ownProps.authState.accessToken &&
    ownProps.authState.accessToken.accessToken &&
    ownProps.authState.idToken &&
    ownProps.authState.idToken.idToken
  ) {
    let accessToken = ownProps.authState.accessToken.accessToken;
    let idToken = ownProps.authState.idToken.idToken;

    if (
      isTokenValid(accessToken, 'determineTokenAndAuth', false) &&
      isTokenValid(idToken, 'determineTokenAndAuth', false)
    ) {
      // for backwards compatibility place tokens in Session Token construct
      curSessionToken = {
        domain: process.env.REACT_APP_SESSION_DOMAIN || get(ownProps.config, 'data.oauth2.domain'),
        id: idToken,
        type: authType,
        value: accessToken,
      };
    } else {
      console.log('Problem getting current/valid Access or ID Token.');
    }
    // } else { // this else normally occurs frequently, so does not add much value except for debugging
    //   console.log('Cannot get valid token(s) because user is not yet authenticated or authentication needs to be reestablished.');
  }

  if (curSessionToken) {
    return {
      sessionToken: curSessionToken,
      isAuthenticated,
      authType,
    };
  } else {
    return {};
  }
}
