import React from 'react';
import cx from 'classnames';
import { injectIntl } from 'react-intl';
import injectStyles from 'react-jss';
import { withRouter } from 'react-router-dom';

import { ModalButtons } from '../Modal';
import { PrimaryButton, SecondaryModalButton } from '../buttons';
import { localizedText } from '../../../utils/localization';
import styles from './styles';
import Title from '../Title';

const DeleteConfirmationDialog = ({
  classes,
  description,
  descriptionId,
  history,
  intl,
  isProcessing,
  onNo,
  onYes,
  title,
  titleId,
  titleValues,
}) => {
  description = localizedText(intl, description, descriptionId);

  return (
    <div className={cx(classes.container, 'confirmation-dialog')}>
      <Title title={title} titleId={titleId} titleValues={titleValues} />
      <p className={cx(classes.description, 'confirmation-dialog-description')}>{description}</p>
      <ModalButtons>
        {/* The modal buttons are reversed intentionally, UX wants the non-destructive action to have more focus */}
        <PrimaryButton
          data-test="no-button"
          onClick={onNo || history.goBack}
          processing={isProcessing}
          text="No"
          textId="button-no"
          type="button"
        />
        <SecondaryModalButton
          data-test="yes-button"
          disabled={isProcessing}
          onClick={onYes}
          text="Yes"
          textId="button-yes"
        />
      </ModalButtons>
    </div>
  );
};

export default withRouter(injectIntl(injectStyles(styles)(DeleteConfirmationDialog)));
