import urljoin from 'url-join';

class Matomo {
  constructor(opts) {
    this.opts = {
      isProduction: process.env && process.env.NODE_ENV === 'production',
      trackErrors: false,
      trackErrorHandler: this.trackError,
      enableLinkTracking: true,
      // Option to dynamically trigger the consent when we need this to happen
      requiresConsent: false,
      ignoreInitialVisit: false,
      injectScript: true,
      clientTrackerName: 'piwik.js',
      serverTrackerName: 'piwik.php',
      url: null,
      siteId: 0,
      ...opts,
    };
  }

  previousPath = null;

  unlistenFromHistory = null;

  /**
   * Returns the a base url to initialize the Matomo instance
   * @returns {string|string}
   */
  getBaseUrl = () => {
    let baseUrl = '';
    let url = this.opts.url;
    if (url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1) {
      baseUrl = url + '/';
    } else {
      baseUrl =
        'https:' === document.location.protocol ? 'https://' + url + '/' : 'http://' + url + '/';
    }
    return baseUrl;
  };

  /**
   * Returns whether Matomo has already been initialized properly
   * @returns {boolean}
   */
  isInitialized = () => {
    if (typeof window._paq === 'undefined' || typeof window._paq.push !== 'function') {
      return false;
    }

    let hasSiteId = false;
    let hasTrackerUrl = false;

    if (window._paq.length === undefined) {
      return true;
    }

    for (let j = 0, l = window._paq.length; j < l; j++) {
      if (~window._paq[j].indexOf('setSiteId')) {
        hasSiteId = true;
      }

      if (~window._paq[j].indexOf('setTrackerUrl')) {
        hasTrackerUrl = true;
      }

      if (hasTrackerUrl && hasSiteId) {
        return true;
      }
    }

    return false;
  };

  /**
   * Pushes the specified args to the Matomo tracker.
   * @param args
   */
  push = args => {
    window._paq.push(args);
  };

  /**
   * Sets a user ID to the Matomo tracker.
   * @param userId
   */
  setUserId = userId => {
    this.push(['setUserId', userId]);
  };

  /**
   * Sets whether or not we should be tracking
   * @param consent
   */
  setConsent = consent => {
    if (!consent) {
      this.push(['requireConsent']);
    }
  };

  /**
   * Adds a page view for the given location
   * @param location
   */
  track = location => {
    let currentPath;

    if (location.path) {
      currentPath = location.path;
    } else if (location.basename) {
      currentPath = urljoin(location.basename, location.pathname, location.search);
    } else {
      currentPath = urljoin(location.pathname, location.search);
    }

    if (this.previousPath === currentPath) {
      return;
    }

    this.push(['setDocumentTitle', document.title]);
    this.push(['setCustomUrl', currentPath]);
    this.push(['trackPageView']);

    this.previousPath = currentPath;
  };

  /**
   * Tracks occurring javascript errors as a `JavaScript Error`
   * @param e
   * @param eventName
   */
  trackError = (e, eventName) => {
    eventName = eventName || 'JavaScript Error';
    this.push(['trackEvent', eventName, e.message, e.filename + ': ' + e.lineno]);
  };

  /**
   * Connects to the given history
   * @param history
   * @param modifier
   * @returns {*}
   */
  connectToHistory = (history, modifier) => {
    modifier = typeof modifier === 'function' ? modifier : location => location;

    const applyModifierAndTrackLocation = (modifier, location) => {
      const modifiedLocation = modifier(location);
      if (modifiedLocation !== undefined) {
        this.track(modifiedLocation);
      }
    };

    // Store reference in case we want to unload the history listening
    this.unlistenFromHistory = history.listen(applyModifierAndTrackLocation.bind(null, modifier));

    if (!this.opts.ignoreInitialVisit && history.location) {
      applyModifierAndTrackLocation(modifier, history.location);
    }

    return history;
  };

  /**
   * Disconencts the history from tracking and returns a boolean if it happened
   * @returns {boolean}
   */
  disconnectFromHistory = () => {
    if (this.unlistenFromHistory) {
      this.unlistenFromHistory();
      return true;
    }
    return false;
  };

  /**
   * Return a Matomo instance and dynamically injects the matomo integration script based on the opts provided
   * @returns {Matomo}
   */
  initialize = () => {
    window._paq = window['_paq'] || [];

    console.info('Matomo initializing...');

    const alreadyInitialized = this.isInitialized();

    console.info('Matomo initialized?', alreadyInitialized);

    if (!alreadyInitialized) {
      console.info('Initializing matomo', this.opts);

      // Set up matomo hooks
      const baseUrl = this.getBaseUrl();
      this.push(['setSiteId', this.opts.siteId]);
      this.push(['setTrackerUrl', baseUrl + this.opts.serverTrackerName]);

      // Initialize the tracking mechanism dynamically
      const scriptEl = document.createElement('script');
      scriptEl.id = 'matomo-integration';
      scriptEl.async = true;
      scriptEl.defer = true;
      scriptEl.src = `${baseUrl}${this.opts.clientTrackerName}`;

      // Embed script in head
      const head = document.head || document.getElementsByTagName('head')[0];
      head.appendChild(scriptEl);
    }

    // This can be set after initialization as well
    if (this.opts.userId) {
      this.setUserId(this.opts.userId);
    }

    if (this.opts.enableLinkTracking) {
      this.push(['enableLinkTracking']);
    }

    if (!this.opts.requiresConsent) {
      this.setConsent(true);
    }

    return this;
  };
}

export default Matomo;
