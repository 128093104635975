/**
 * Wrapper class around errors to add in some additional stack information as well as `code` and `timestamp`
 */
export class APIError extends Error {
  constructor(code = 200, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(params);
    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError);
    }
    // remove "name", to remove "Error: " prefix
    this.name = '';
    // Add in code and timestamp for references
    this.code = code;
    this.date = new Date();
  }
}

export function isCancelledRequest(error) {
  if (!(error instanceof APIError)) {
    return false;
  }
  return error.code === 499;
}
