import graphApi from '../../../../../lib/http/graph';
import { tokenThunk } from '../../../../../utils/api';

const actions = {
  FETCH_CLASSIFICATIONS: 'collectionManagement/classifications/FETCH',
  REFRESH_CLASSIFICATIONS: 'collectionManagement/classifications/REFRESH',
};

export function fetchClassifications(
  workspaceId,
  collectionId,
  limit = 16,
  sortPairsOrField = null,
  sortDir = null
) {
  const ast = [
    'and',
    ['=', 'left.collectionIds', collectionId],
    ['or', ['=', 'type', 'associated-with'], ['=', 'type', 'vulnerable-to']],
  ];
  const analytics = graphApi.builder
    .analytics()
    .groupByAgg('classifications', 'right.classifications')
    .limit(limit)
    .sortBy(sortPairsOrField, sortDir)
    .addSubAggs()
    .cardinalityAgg('numElements', 'left.name')
    .cardinalityAgg('numRisks', 'riskId')
    .build();
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.FETCH_CLASSIFICATIONS,
      payload: graphApi.api.analytics(token, workspaceId, ast, analytics),
    });
  });
}

export function refreshClassifications() {
  return {
    type: actions.REFRESH_CLASSIFICATIONS,
  };
}

export default actions;
