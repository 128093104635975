import { first, isArray, isUndefined, last, set, size } from 'lodash';

function setInAgg(agg, path, value) {
  if (!agg) {
    throw new Error(`Assertion failed: Cannot perform operation without an aggregation.`);
  }
  if (isUndefined(value)) {
    // don't pollute the aggregation with nonsense
    return agg;
  }
  return set(agg, path, value);
}

function addAgg(builder, name, agg) {
  last(builder.path)[name] = agg;
  builder.agg = agg;
  return builder;
}

export class AnalyticsBuilder {
  constructor() {
    this.body = {};
    // the current agg that agg-specific options would be applied to
    this.agg = null;
    // where the builder is drilled into so that aggs can be nested
    this.path = [this.body];
  }

  /* Aggregations */
  // Each of these will start a new aggregation that the below aggregation options
  // can be applied to. There's no going back, so order matters when building.

  cardinalityAgg(name, field) {
    return addAgg(this, name, {
      field,
      type: 'cardinality',
    });
  }

  dateOverlapTrendAgg(name, numIntervals, intervalType, startField, endField) {
    return addAgg(this, name, {
      'end-field': endField,
      interval: intervalType,
      intervals: numIntervals,
      'start-field': startField,
      type: 'date-overlap-trend',
    });
  }

  groupByAgg(name, field) {
    return addAgg(this, name, {
      field,
      type: 'group-by',
    });
  }

  minAgg(name, field) {
    return addAgg(this, name, {
      field,
      type: 'min',
    });
  }

  rangeAgg(name, field, ranges = []) {
    return addAgg(this, name, {
      field,
      type: 'range',
      ranges: ranges,
    });
  }

  trendAgg(name, field, intervalType) {
    return addAgg(this, name, {
      field,
      type: 'trend',
      interval: intervalType,
    });
  }

  /* Traversal */

  addSubAggs() {
    if (!this.agg) {
      throw new Error(`Assertion failed: Cannot push sub aggs into an agg that doesn't exist.`);
    }
    this.agg.analytics = {};
    this.path.push(this.agg.analytics);
    return this;
  }

  subAggsDone() {
    if (size(this.path) === 1) {
      // don't pop the body off, otherwise new agg things won't go to the right place
      return this;
    }
    this.path.pop();
    return this;
  }

  /* Per Agg Options */

  fields(fieldNames) {
    setInAgg(this.agg, 'fields', fieldNames);
    return this;
  }

  filter(queryOrAst) {
    setInAgg(this.agg, 'filter', queryOrAst);
    return this;
  }

  from(index) {
    setInAgg(this.agg, 'from', index);
    return this;
  }

  limit(atMost) {
    setInAgg(this.agg, 'limit', atMost);
    return this;
  }

  sortBy(sortPairsOrField, sortDir) {
    if (sortDir) {
      sortPairsOrField = [[sortPairsOrField, sortDir]];
    } else if (!isArray(first(sortPairsOrField))) {
      sortPairsOrField = [sortPairsOrField];
    }
    setInAgg(this.agg, 'sortBy', sortPairsOrField);
    return this;
  }

  /* Build */

  build() {
    return this.body;
  }
}

export function analyticsBuilder() {
  return new AnalyticsBuilder();
}
