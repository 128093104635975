import { combineReducers } from 'redux';
import collectionHealth from './collection-health/reducer';
import incidentManagement from './incident-management/reducer';
import notes from './notes/reducer';
import search from './search/reducer';
import summary from './summary/reducer';

/*
 * Unify module reducers for a nested reducer state tree in workspace
 */

export default combineReducers({
  collectionHealth,
  incidentManagement,
  notes,
  search,
  summary,
});
