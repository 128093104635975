import { apiRequest } from '../../../../lib/http';
import { API } from '../../../../environment/api';
import { tokenThunk } from '../../../../utils/api';

const actions = {
  NOTES_READ_ALL: '@@note/GET_ALL',
  NOTES_CREATE: '@@note/CREATE',
  NOTES_DELETE: '@@note/DELETE',
  NOTES_UPDATE: '@@note/UPDATE',
  SET_NOTES: '@@note/SET_NOTES',
};

export const readAllNotes = (workspaceId, collectionId) => {
  return tokenThunk((dispatch, token) => {
    let params = {
      'workspace-id': workspaceId,
      'parent-id': collectionId,
    };
    return dispatch({
      type: actions.NOTES_READ_ALL,
      payload: apiRequest(token, API.NOTES_READ_ALL, params),
    });
  });
};

export const createNote = (workspaceId, collectionId, note, author) => {
  return tokenThunk((dispatch, token) => {
    let params = {
      'parent-type': 'collection',
      'workspace-id': workspaceId,
      'parent-id': collectionId,
      ...note,
    };
    return dispatch({
      type: actions.NOTES_CREATE,
      payload: apiRequest(token, API.NOTES_CREATE, params),
    });
  });
};

export const setNotes = notes => {
  return {
    type: actions.SET_NOTES,
    payload: notes,
  };
};

const updateableFields = ['note', 'subject'];
//['author', 'note', 'parent-id', 'parent-type', 'subject', 'workspace-id'];
export const updateNote = (noteId, note) => {
  let updates = [];
  for (let i = 0; i < updateableFields.length; i++) {
    if (updateableFields[i] in note) {
      updates.push({
        operation: 'set',
        'field-name': updateableFields[i],
        value: note[updateableFields[i]],
      });
    }
  }
  return tokenThunk((dispatch, token) => {
    let params = {
      'note-id': noteId,
      'field-updates': updates,
    };
    return dispatch({
      type: actions.NOTES_UPDATE,
      payload: apiRequest(token, API.NOTES_UPDATE, params),
    });
  });
};

export const deleteNote = noteId => {
  return tokenThunk((dispatch, token) => {
    let params = {
      'note-id': noteId,
    };
    return dispatch({
      type: actions.NOTES_DELETE,
      payload: apiRequest(token, API.NOTES_DELETE, params),
    });
  });
};

export default actions;
