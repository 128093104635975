import graphApi from '../../../../../lib/http/graph';
import { buildFieldSetUpdates, tokenThunk } from '../../../../../utils/api';
import { omit } from 'lodash';

const actions = {
  CLEAR_THREAT_PROPERTIES_UPDATE: 'elementDetails/CLEAR_THREAT_PROPERTIES_UPDATE',
  FETCH_THREAT_ASSOCIATIONS: 'elementDetails/FETCH_THREAT_ASSOCIATIONS',
  FETCH_THREAT_PROPERTIES: 'elementDetails/FETCH_THREAT_PROPERTIES',
  UPDATE_THREAT_PROPERTIES: 'elementDetails/UPDATE_THREAT_PROPERTIES',
};

export function clearThreatPropertiesUpdate() {
  return {
    type: actions.CLEAR_THREAT_PROPERTIES_UPDATE,
  };
}

export function fetchThreatAssociations(workspaceId, threatId) {
  const ast = ['and', ['=', 'type', 'associated-with'], ['=', 'right.threatId', threatId]];
  const analytics = graphApi.builder
    .analytics()
    .groupByAgg('types', 'left.type')
    .limit(10000)
    .build();
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.FETCH_THREAT_ASSOCIATIONS,
      payload: graphApi.api.analytics(token, workspaceId, ast, analytics),
    });
  });
}

export function fetchThreatProperties(
  workspaceId,
  criticalityProperty,
  sourceNames,
  classificationNames
) {
  let refs = [];
  if (sourceNames) {
    refs = refs.concat(sourceNames.map(s => ({ id: s, type: 'source' })));
  }
  if (classificationNames) {
    refs = refs.concat(classificationNames.map(c => ({ id: c, type: 'classification' })));
  }
  const fields = ['name', 'relationshipCounts', 'ticClassificationScore', 'ticSourceScore'];
  return tokenThunk((dispatch, token) => {
    dispatch({
      criticalityProperty,
      type: actions.FETCH_THREAT_PROPERTIES,
      payload: graphApi.api.get(token, workspaceId, refs, fields),
    });
  });
}

const propertyToUpdate = (token, workspaceId, property) => {
  return graphApi.api.update(
    token,
    workspaceId,
    property.ref,
    buildFieldSetUpdates({
      [property.field]: property.value,
    })
  );
};

export function updateThreatProperties(workspaceId, properties) {
  const updatedProperties = properties.filter(p => p.updated).map(p => omit(p, 'updated'));
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.UPDATE_THREAT_PROPERTIES,
      payload: Promise.all(updatedProperties.map(p => propertyToUpdate(token, workspaceId, p))),
      updatedProperties,
    });
  });
}

export default actions;
