import { combineReducers } from 'redux';
import history from './sections/ReportHistory/reducer';
import schedules from './sections/ListScheduledReports/reducer';
import schedule from './sections/ScheduledReport/reducer';

export default combineReducers({
  history,
  schedules,
  schedule,
});
