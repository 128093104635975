import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ToastWrapper = styled(motion.div)`
  color: black;
  height: fit-content;
  min-width: 300px;
  padding: 10px 20px;
  right: 24px;
  position: relative;
  overflow: hidden;
`;

export const getPortalStyle = zIndex => `
  box-sizing: border-box;
  position: fixed;
  z-index: ${zIndex};
  bottom: 12px;
  right: 12px;
  width: 45%;
`;

export default theme => ({
  clearIcon: {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    outline: 0,
    margin: 0,
    color: theme.colors.darkerGray,
  },
  messageContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: '10px',
  },
  toastContent: {
    background: theme.colors.pearlWhite,
    borderRadius: '5px',
    boxShadow: '0px 0px 30px rgba(0, 38, 62, 0.15)',
    display: 'flex',
    fontSize: '14px',
    fontWeight: 700,
    justifyContent: 'space-between',
    padding: '32px',
  },
  success: {
    borderLeft: `13px solid ${theme.colors.jade}`,
  },
  info: {
    borderLeft: `13px solid ${theme.colors.toastWarning}`,
  },
  error: {
    borderLeft: `13px solid ${theme.colors.toastError}`,
  },
});
