import { apiRequest } from '../../../lib/http';
import { API } from '../../../environment/api';
import { tokenThunk } from '../../../utils/api';

const actions = { CLEAR_ELEMENTS: 'elements/CLEAR', ELEMENTS_SEARCH: 'elements/SEARCH' };

export const elementsClear = () => ({
  type: actions.CLEAR_ELEMENTS,
  payload: [],
});

export const elementsSearch = (query, type, limit = 100) => {
  return tokenThunk((dispatch, token) => {
    let params = {
      query: query,
      types: [type],
      limit: limit,
    };
    return dispatch({
      type: actions.ELEMENTS_SEARCH,
      payload: apiRequest(token, API.ELEMENTS_SEARCH, params).then(({ result }) => {
        return result;
      }),
    });
  });
};

export default actions;
