import actions from './actions';
import { actionError, actionStart, actionSuccess } from '../../../common';
import { first, isEmpty, isNil, map, sortBy, toNumber } from 'lodash';
import assign from 'lodash/fp/assign';
import set from 'lodash/fp/set';
import update from 'lodash/fp/update';
import moment from 'moment';
import { capitalize } from '../../../../utils/strings';
import multimethod from '../../../../lib/multimethod';
import { bucketizeSeverities } from '../../../../utils/tic';
import graph from '../../../../lib/http/graph';

const threatStats = multimethod('type', (action, state) => state);

/* Common */

// Two weeks in MS
const FORTNIGHT_MS = 12096e5;

function fetchStart(payload, state) {
  // for threat stats we're making the assumption that if we have data already, the
  // update should be silent (occur without a loading indicator)
  return assign(state, {
    error: null,
    isLoading: !isEmpty(state.data),
  });
}

function fetchStarted(path) {
  return (action, state) => {
    return update(path, fetchStart.bind(this, action.payload), state);
  };
}

function fetchError(payload, state) {
  return assign(state, {
    error: payload,
    isLoading: false,
  });
}

function fetchErrored(path) {
  return (action, state) => {
    return update(path, fetchError.bind(this, action.payload), state);
  };
}

function toPoint(count, id) {
  return {
    id: id,
    label: capitalize(id),
    value: count,
  };
}

/* Simple State Management */

threatStats.method[actions.FEED_OBSERVABLES_PAGE] = (action, state) => {
  return update('feed.observables', observablesPageChanged.bind(this, action), state);
};

threatStats.method[actions.SELECT_FEED] = (action, state) => {
  return set('feed.selected', action.feed, state);
};

/* Associations per Day */

function recent() {
  const nowMs = new Date().getTime();
  const twoWeeksAgoMs = nowMs - FORTNIGHT_MS;
  return point => point.dayMs >= twoWeeksAgoMs && point.dayMs <= nowMs;
}

function toAssociationsPerDayPoint(numAssociations, dayMs) {
  dayMs = toNumber(dayMs);
  return {
    dayMs,
    label: moment.utc(dayMs).format('MM/DD'),
    value: numAssociations,
  };
}

function fetchAssociationsPerDaySuccess(response, associationsPerDayState) {
  const numAssociationsByDayMs = graph.response.toAnalyticsResults(response).numAssociationsPerDay;
  const points = map(numAssociationsByDayMs, toAssociationsPerDayPoint).filter(recent());
  return assign(associationsPerDayState, {
    data: sortBy(points, 'dayMs'),
    error: null,
    isLoading: false,
  });
}

threatStats.method[actionStart(actions.ASSOC_PER_DAY)] = fetchStarted('associationsPerDay');

threatStats.method[actionSuccess(actions.ASSOC_PER_DAY)] = (action, state) => {
  return update(
    'associationsPerDay',
    fetchAssociationsPerDaySuccess.bind(this, action.payload),
    state
  );
};

threatStats.method[actionError(actions.ASSOC_PER_DAY)] = fetchErrored('associationsPerDay');

/* Compromised Element Types */

function toCompromisedElementTypePoint({ _count, type }) {
  return {
    id: type,
    label: type,
    value: _count,
  };
}

function fetchCompromisedElementTypesSuccess(response, compromisedElementTypesState) {
  return assign(compromisedElementTypesState, {
    data: graph.response
      .toAnalyticsResults(response)
      .numElementsByType.results.map(toCompromisedElementTypePoint),
    error: null,
    isLoading: false,
  });
}

threatStats.method[actionStart(actions.COMPROMISED_ELEMENT_TYPES)] = fetchStarted(
  'compromisedElementTypes'
);

threatStats.method[actionSuccess(actions.COMPROMISED_ELEMENT_TYPES)] = (action, state) => {
  return update(
    'compromisedElementTypes',
    fetchCompromisedElementTypesSuccess.bind(this, action.payload),
    state
  );
};

threatStats.method[actionError(actions.COMPROMISED_ELEMENT_TYPES)] = fetchErrored(
  'compromisedElementTypes'
);

/* Feed Analytics */

function fetchFeedClassificationsSuccess(response, feedClassificationsState) {
  return assign(feedClassificationsState, {
    data: graph.response
      .toAnalyticsResults(response)
      .numThreatsByClassification.results.map(({ _count, classifications }) => {
        return toPoint(_count, first(classifications));
      }),
    error: null,
    isLoading: false,
  });
}

function fetchFeedSeveritiesSuccess(response, feedSeverities) {
  const severities = bucketizeSeverities(
    graph.response.toAnalyticsResults(response).numThreatsByScore.results
  );
  return assign(feedSeverities, {
    data: map(severities, toPoint),
    error: null,
    isLoading: false,
  });
}

threatStats.method[actionStart(actions.FEED_ANALYTICS)] = (action, state) => {
  const s = update('feed.classifications', fetchStart.bind(this, action.payload), state);
  return update('feed.severities', fetchStart.bind(this, action.payload), s);
};

threatStats.method[actionSuccess(actions.FEED_ANALYTICS)] = (action, state) => {
  const s = update(
    'feed.classifications',
    fetchFeedClassificationsSuccess.bind(this, action.payload),
    state
  );
  return update('feed.severities', fetchFeedSeveritiesSuccess.bind(this, action.payload), s);
};

threatStats.method[actionError(actions.FEED_ANALYTICS)] = (action, state) => {
  const s = update('feed.classifications', fetchError.bind(this, action.payload), state);
  return update('feed.severities', fetchError.bind(this, action.payload), s);
};

/* Feed Observables */

function fetchFeedObservablesSuccess(response, observablesState) {
  const totalHits = graph.response.toTotalHits(response);
  return assign(observablesState, {
    data: graph.response.toResults(response),
    error: null,
    isLoading: false,
    pages: Math.ceil(totalHits / observablesState.pageSize),
    total: totalHits,
  });
}

function observablesPageChanged({ page, pageSize, sortBy }, observablesState) {
  return assign(observablesState, {
    page: isNil(page) ? observablesState.page : page,
    pageSize: isNil(pageSize) ? observablesState.pageSize : pageSize,
    sortBy: isNil(sortBy) ? observablesState.sortBy : sortBy,
  });
}

threatStats.method[actionStart(actions.FEED_OBSERVABLES)] = fetchStarted('feed.observables');

threatStats.method[actionSuccess(actions.FEED_OBSERVABLES)] = (action, state) => {
  return update('feed.observables', fetchFeedObservablesSuccess.bind(this, action.payload), state);
};

threatStats.method[actionError(actions.FEED_OBSERVABLES)] = fetchErrored('feed.observables');

/* Feeds */

function fetchFeedsSuccess(response, feedsState) {
  return assign(feedsState, {
    data: graph.response
      .toAnalyticsResults(response)
      .numElementsBySource.results.map(({ sources }) => first(sources))
      .sort(),
    error: null,
    isLoading: false,
  });
}

threatStats.method[actionStart(actions.FEEDS)] = fetchStarted('feeds');

threatStats.method[actionSuccess(actions.FEEDS)] = (action, state) => {
  return update('feeds', fetchFeedsSuccess.bind(this, action.payload), state);
};

threatStats.method[actionError(actions.FEEDS)] = fetchErrored('feeds');

/* Summary */

function fetchSummarySuccess(response, summaryState) {
  const { firstSeenAt, numSources, numThreats } = graph.response.toAnalyticsResults(response);
  const firstSeen = moment.utc(firstSeenAt);
  const now = moment.utc();
  return assign(summaryState, {
    error: null,
    isLoading: false,
    numDaysOfData: now.diff(firstSeen, 'days'),
    numFeeds: numSources,
    numThreats: numThreats,
    numThreatAssociations: graph.response.toTotalHits(response),
  });
}

threatStats.method[actionStart(actions.SUMMARY)] = fetchStarted('summary');

threatStats.method[actionSuccess(actions.SUMMARY)] = (action, state) => {
  return update('summary', fetchSummarySuccess.bind(this, action.payload), state);
};

threatStats.method[actionError(actions.SUMMARY)] = fetchErrored('summary');

/* Threat Severities */

function fetchThreatSeveritiesSuccess(response, threatSeveritiesState) {
  const severities = bucketizeSeverities(
    graph.response.toAnalyticsResults(response).numThreatsByScore.results
  );
  return assign(threatSeveritiesState, {
    data: map(severities, toPoint),
    error: null,
    isLoading: false,
  });
}

threatStats.method[actionStart(actions.THREAT_SEVERITIES)] = fetchStarted('threatSeverities');

threatStats.method[actionSuccess(actions.THREAT_SEVERITIES)] = (action, state) => {
  return update('threatSeverities', fetchThreatSeveritiesSuccess.bind(this, action.payload), state);
};

threatStats.method[actionError(actions.THREAT_SEVERITIES)] = fetchErrored('threatSeverities');

/* Threats per Feed */

function toThreatsPerFeedPoint({ _count, sources }) {
  return {
    label: first(sources),
    value: _count,
  };
}

function fetchThreatsPerFeedSuccess(response, threatsPerFeedState) {
  const points = graph.response
    .toAnalyticsResults(response)
    .numThreatsPerSource.results.map(toThreatsPerFeedPoint);
  return assign(threatsPerFeedState, {
    data: sortBy(points, 'value').reverse(),
    error: null,
    isLoading: false,
    totalHits: response.totalHits,
  });
}

threatStats.method[actionStart(actions.THREATS_PER_FEED)] = fetchStarted('threatsPerFeed');

threatStats.method[actionSuccess(actions.THREATS_PER_FEED)] = (action, state) => {
  return update('threatsPerFeed', fetchThreatsPerFeedSuccess.bind(this, action.payload), state);
};

threatStats.method[actionError(actions.THREATS_PER_FEED)] = fetchErrored('threatsPerFeed');

const initialState = {
  associationsPerDay: {
    error: null,
    isLoading: false,
  },
  compromisedElementTypes: {
    error: null,
    isLoading: false,
  },
  feed: {
    classifications: {
      error: null,
      isLoading: false,
    },
    observables: {
      isLoading: false,
      page: 0,
      pageSize: 10,
    },
    selected: null,
    severities: {
      error: null,
      isLoading: false,
    },
  },
  feeds: {
    error: null,
    isLoading: false,
  },
  summary: {
    error: null,
    isLoading: false,
  },
  threatSeverities: {
    error: null,
    isLoading: false,
  },
  threatsPerFeed: {
    error: null,
    isLoading: false,
  },
};

export default (state = initialState, action) => threatStats(action, state);
