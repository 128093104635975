import multimethod from '../../../../../lib/multimethod';
import { actionError, actionStart, actionSuccess, apiData } from '../../../../../redux/common';
import actions from './actions';
import { get } from 'lodash';
import assign from 'lodash/fp/assign';

const dnsHistory = multimethod('type', (action, state) => state);

dnsHistory.method[actionStart(actions.FETCH_DNS_HISTORY)] = apiData.fetchStarted;

dnsHistory.method[actionSuccess(actions.FETCH_DNS_HISTORY)] = (action, state) => {
  const data = get(action, 'payload.result.dns-history');
  data.sort((a, b) => {
    return a['time-last'] < b['time-last'] ? 1 : -1;
  });
  return assign(state, {
    data,
    isLoading: false,
    isRequesting: false,
  });
};

dnsHistory.method[actionError(actions.FETCH_DNS_HISTORY)] = apiData.fetchErrored;

export default (state = apiData.initialState, action) => dnsHistory(action, state);
