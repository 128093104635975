import React from 'react';
import injectStyles from 'react-jss';
import cx from 'classnames';
import styles from './styles';

const ThreeColumn = ({ asMain, classes, className, children: [Left, Main, Right], id }) => {
  return (
    <section id={id} className={cx(classes.container, className)}>
      <div className={classes.columnLeft}>{Left}</div>
      {React.createElement(asMain ? 'main' : 'div', {
        className: classes.columnMain,
        children: Main,
      })}
      <div className={classes.columnRight}>{Right}</div>
    </section>
  );
};

ThreeColumn.defaultProps = {
  asMain: false,
  leftWidth: 285,
  rightWidth: 250,
};

export default injectStyles(styles)(ThreeColumn);
