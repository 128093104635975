import Color from 'color';
import createButtons from './createButtons';
import createForms from './createForms';
import createTypography from './createTypography';

const borderRadius = {
  none: 0,
  default: '5px',
  rounded: '10px',
};

// these are the colors from the UX Interface Guide, they should be aliased based on
// usage in the colorMapping
// https://lgscout.atlassian.net/wiki/spaces/EN/pages/712769574/Color+Usage
const guidelineColorMapping = {
  darkBlue: Color('#11284B'),
  lgBlue: Color('#00549F'),
  lightGray1: Color('#F6F6F6'),
  lightGray2: Color('#E6E6E6'),
  lightGray3: Color('#CECECE'),
  lightGray4: Color('#F2F2F2'),
  linkColor: Color('#3B3FB6'),
  mediumGray1: Color('#989898'),
  mediumGray2: Color('#CBCBCB'),
  // Restyle Colors
  electricBlue: Color('#5357DD'),
  iris: Color('#3B3FB6'),
  jade: Color('#26AC97'),
  lavander: Color('#C8CAFF'),
  lightIris: Color('#ADB0FD'),
  lilac: Color('#F1F4FF'),
  lightLilac: Color('#E9EDFF'),
  medGray: Color('#717584'),
  softBlack: Color('#00263E'),
};

const boxShadows = {
  large: '0 22px 60px 0 rgba(38,39,82,0.15)',
  medium: '0 11px 30px 0 rgba(38,39,82,0.15)',
  small: '0 6px 15px 0 rgba(38,39,82,0.15)',
  navbar: '0 4px 2px -2px rgba(0,0,0,0.12)',
  card: '0 4px 8px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12)',
  searchDropdownHeader: `0px 5px 15px -10px ${guidelineColorMapping.electricBlue}`,
  searchDropdown: '0px 0px 6px 3px rgba(59, 63, 182, 0.35)',
};

const colorMapping = {
  brand: guidelineColorMapping.iris,
  primary: guidelineColorMapping.darkBlue,
  primaryLight: Color('#1970E7'),
  primaryDark: guidelineColorMapping.darkBlue,
  accent: guidelineColorMapping.iris,
  black: Color('#000000'),
  darkestGray: Color('#262D37'),
  darkerGray: Color('#333A44'),
  darkGray: Color('#777777'),
  gray: Color('#6C6F70'),
  lightGray: guidelineColorMapping.mediumGray1,
  lighterGray: Color('#EEEEEE'),
  lightestGray: Color('#F8F8F8'),
  pearlWhite: Color('#FAFAFA'),
  white: Color('#FFFFFF'),
  yellow: Color('#F3DA94'),
  red: Color('#DB283A'),
  orange: Color('#E6902A'),
  green: Color('#46C624'),
  blue: Color('#42A8F6'),
  purple: Color('#8e44ad'),
  gold: Color('#FBAB13'),
  fontBlue: Color('#4C5056'),
  darkBlue: Color('#2568a2'),
  lightBlue: Color('#278ce5'),
  menuGray: guidelineColorMapping.lightGray4,
  outlineGray: guidelineColorMapping.lightGray3,
  darkOrange: Color('#ff7f0e'),
  darkGrey: Color('#717584'),
  violet: Color('#797FFF'),

  // Restyle Colors
  electricBlue: guidelineColorMapping.electricBlue,
  iris: guidelineColorMapping.iris,
  jade: guidelineColorMapping.jade,
  lavander: guidelineColorMapping.lavander,
  lightIris: guidelineColorMapping.lightIris,
  lilac: guidelineColorMapping.lilac,
  lightLilac: guidelineColorMapping.lightLilac,
  medGray: guidelineColorMapping.medGray,
  softBlack: guidelineColorMapping.softBlack,
  toastError: Color('#FF5245'),
  toastWarning: Color('#FF9700'),

  // Input Colors
  inputDisabled: guidelineColorMapping.lightGray1,
  inputDisabledText: guidelineColorMapping.mediumGray1,

  // Button Colors
  actionColor: guidelineColorMapping.iris,
  buttonGreen: Color('#4caf50'),
  buttonDisabled: guidelineColorMapping.lightGray1,
  buttonDisabledText: guidelineColorMapping.lightGray3,
  buttonHover: guidelineColorMapping.iris,
  dropdownArrow: guidelineColorMapping.mediumGray2,
  primaryButton: guidelineColorMapping.darkBlue,
  secondaryButtonAccent: guidelineColorMapping.linkColor,
  disabled: Color('#E3E3E5'),

  // State based color mappings
  link: guidelineColorMapping.iris,
  info: Color('#6589DE'),
  success: Color('#46C624'),
  warning: Color('#E6902A'),
  error: Color('#DB283A'),
  errorLight: Color('#DE858E'),
  highlight: Color('#F9ECC9'),
  selectedRow: Color('#e9F6fd'),

  // Search
  localSearchBar: Color('#D7D7D7'),
  pinMapDisabledBackground: Color('#F2F2F2'),
  pinMapDisabledText: Color('lightgray'),

  // TIC Coloring
  critical: Color('#E01A4F'),
  elevated: Color('#EC8C01'),
  normal: guidelineColorMapping.jade,

  // Elements
  asn: Color('#674196'),
  cidrv4: Color('#8bd058'),
  fqdn: Color('#00263e'),
  ipv4: Color('#35d5ef'),
  cidrv6: Color('#15C798'),
  ipv6: Color('#0D526F'),
  owner: guidelineColorMapping.iris,

  elementRed: Color('#f44336'),
  elementRedBorder: Color('#F32C1E'),

  // Collection Management
  collectionTitle: Color('#464646'),

  // Report Colors
  reportPreviewBackground: Color('#323639'),
  reportPreviewHeader: Color('#525659'),

  // Icon Colors
  iconGray: Color('#AAAAAA'),
  headerGray: Color('#717584'),

  // Incident Management
  headerTeal: Color('#16A085'),
  actionButtonBorder: Color('#DDDDDD'),

  // Collection Health
  dropDownBackground: Color('#212228'),
  dropDownItemHover: Color('#4D0000'),
  whitestGray: Color('#F0F0F0'),

  // Threat Association
  threatAssocGray: Color('#E6E6E6'),
  threatAssocHintGray: guidelineColorMapping.mediumGray2,
  threatHeader: Color('#000000'),

  // Proportion
  progress: guidelineColorMapping.iris,
  progressBorder: guidelineColorMapping.iris,
  remaining: guidelineColorMapping.lightGray1,

  // Labels & tags
  labelBackground: guidelineColorMapping.lightGray4,
};

// Originates from lookingglass-design-system `colors` in lookingglassPalette.ts
// note: guideline colors are those reused for other named colors
const guidelineDesignColorMapping = {
  aquamarine: Color('#31CEB6'),
  orange: Color('#FF5245'),
  yellow: Color('#FF9700'),
};

// if colors are used for other named colors move to guidelineDesignColorMapping (above) and reassign here
const designColorMapping = {
  aquamarine: guidelineDesignColorMapping.aquamarine,
  black: Color('#00263E'),
  cloud: Color('#E3E3E5'),
  darkCloud: Color('#DBDBDE'),
  lightGray: Color('#C4C4C4'),
  darkGray: Color('#717584'),
  veryDarkGray: Color('#454953'),
  darkJade: Color('#209582'),
  darkLavander: Color('#ADB0FD'),
  darkLilac: Color('#E9EDFF'),
  deepSkyblue: Color('#20BBFF'),
  denim: Color('#014573'),
  electricBlue: Color('#5357DD'),
  grape: Color('#292C96'),
  gray: Color('#A2A6AF'),
  green: Color('#117E15'),
  iris: Color('#3B3FB6'),
  jade: Color('#26AC97'),
  lavander: Color('#C8CAFF'),
  lightAquamarine: Color('#81DBCD'),
  lightCloud: Color('#EAEAEB'),
  lightDenim: Color('#034E80'),
  lightJade: Color('#E4F2F0'),
  lilac: Color('#F1F4FF'),
  olympicBlue: Color('#0094D5'),
  orange: guidelineDesignColorMapping.orange,
  porpoiseGray: Color('#406378'),
  red: Color('#E01A4F'),
  steelBlue: Color('#0273BE'),
  transparent: Color('#00000000'),
  violet: Color('#797FFF'),
  white: Color('#FFFFFF'),
  whitePearl: Color('#FAFAFA'),
  yellow: guidelineDesignColorMapping.yellow,

  // design chart colors
  low: guidelineDesignColorMapping.aquamarine,
  medium: guidelineDesignColorMapping.yellow,
  high: guidelineDesignColorMapping.orange,
}

const gradients = {};

const fontSize = 14;

const fontFamily = 'Lato, sans-serif';
const fontFamilySecondary = `'Exo 2', sans-serif`;

const fontWeights = {
  xs: 300,
  s: 400,
  m: 500,
  l: 600,
};

const srOnly = {
  position: 'absolute',
  left: -10000,
  top: 'auto',
  width: 1,
  height: 1,
  overflow: 'hidden',
};

const spacing = 15;

// These are going to be applied universally to all buttons. Modifying this will have wide ranging
// impacts across the app
const buttons = {
  fontFamily,
  fontSize,
  height: 40,
};

const formFields = {
  paddingLeft: 10,
  paddingRight: 10,
  arrowPadding: 5,
  '&:focus': {
    outline: 0,
  },
};

const overflow = {
  width: '100%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflowX: 'hidden',
};

const paginationArrows = {
  backgroundColor: 'transparent',
  color: colorMapping.gray,
  '& > span': {
    display: 'flex',
    alignItems: 'center',
  },
};

const positions = {
  centerText: {
    textAlign: 'center',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inlineCenter: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  verticalCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  horizontalCenter: {
    margin: '0 auto',
  },
};

// Transforms `Color` objects into actual hex values to use
const colorsMapStrings = colorMap => {
  return Object.keys(colorMap).reduce((acc, colorKey) => {
    acc[colorKey] = colorMap[colorKey].string();
    return acc;
  }, {});
};

const colors = colorsMapStrings(colorMapping);
const designColors = colorsMapStrings(designColorMapping);
const guidelineColors = colorsMapStrings(guidelineColorMapping);

const table = {
  header: guidelineColors.mediumGray1,
  alternateRow: guidelineColors.lightGray1,
};

const sectionBorder = guidelineColors.lineColor;

const separator = {
  backgroundColor: guidelineColors.lightGray2,
  content: '""',
  display: 'block',
  position: 'absolute',
};

const upArrow = {
  width: 0,
  height: 0,
  borderLeft: '5px solid transparent',
  borderRight: '5px solid transparent',
  borderBottom: `5px solid ${colorMapping.lightGray}`,
};

const downArrow = {
  width: 0,
  height: 0,
  borderLeft: '5px solid transparent',
  borderRight: '5px solid transparent',
  borderTop: `5px solid ${colorMapping.lightGray}`,
};

// Placed this here since it is used to determine color for graphs in PRIME, specifically for DoughnutChart component
// color prop is limited to a few supported strings in DoughnutChart ("high", "medium" & "low" are 3 of them)
export const scoreChartColor = (score) => {
  if (score >= 75) {
    return "high";    // designColor.aquamarine in PRIME or palette.aquamarine in lookngglass-design-system
  } else if (score >= 50) {
    return "medium"; // designColor.yellow in PRIME or palette.yellow in lookngglass-design-system
  } else {
    return "low";    // designColor.orange in PRIME or palette.orange in lookngglass-design-system
  }
}

/*
 * Returns a configured theme object. This can be used directly or
 * re-compiled into a css library of choice
 */
export default {
  navbarHeight: 75,
  backgrounds: {
    login: colors.primary,
    main: colors.white,
    header: colors.pearlWhite,
  },
  boxShadows,
  borderRadius,
  breakpoints: {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
  },
  buttons,
  // buttonsLegacy are a deprecated way of styling that is still in use, if you need to style a button,
  // use theme.buttons...however, make sure an existing button component (ex: PrimaryButton) isn't
  // already doing what you want to do
  buttonsLegacy: createButtons({
    boxShadows,
    borderRadius,
    colorMapping,
    fontFamily,
    fontSize,
    spacing,
    config: {
      base: {
        background: colorMapping.lighterGray,
        color: colorMapping.darkerGray,
      },
      primary: {
        background: colorMapping.primary,
        color: colorMapping.white,
      },
      secondary: {
        background: colorMapping.white,
        borderColor: colorMapping.brand,
        color: colorMapping.brand,
        hoverColor: colorMapping.white,
        hoverBackgroundColor: colorMapping.primaryLight,
      },
      primaryDark: {
        background: colorMapping.primaryDark,
        color: colorMapping.white,
      },
      accent: {
        background: colorMapping.accent,
        color: colorMapping.white,
      },
      error: {
        background: colorMapping.error,
        color: colorMapping.white,
      },
      success: {
        background: colorMapping.success,
        color: colorMapping.white,
      },
      warning: {
        background: colorMapping.warning,
        color: colorMapping.white,
      },
      info: {
        background: colorMapping.info,
        color: colorMapping.white,
      },
      inverse: {
        background: colorMapping.white,
        color: colorMapping.primary,
      },
      text: {
        background: Color('transparent'),
        color: colorMapping.primary,
      },
      link: {
        background: Color('transparent'),
        color: colorMapping.link,
      },
    },
  }),
  colors,
  designColors,
  elements: {
    asn: {
      backgroundColor: colors.asn,
      color: colors.white,
    },
    cidrv4: {
      backgroundColor: colors.cidrv4,
      color: colors.white,
    },
    cidrv6: {
      backgroundColor: colors.cidrv6,
      color: colors.white,
    },
    fqdn: {
      backgroundColor: colors.fqdn,
      color: colors.white,
    },
    ipv4: {
      backgroundColor: colors.ipv4,
      color: colors.white,
    },
    ipv6: {
      backgroundColor: colors.ipv6,
      color: colors.white,
    },
    owner: {
      backgroundColor: colors.owner,
      color: colors.white,
    },
  },
  filterInput: {
    borderColor: colors.actionButtonBorder,
    boxShadow: 'none',
    boxSizing: 'border-box',
    height: 30,
    paddingLeft: spacing,
    width: 250,
  },
  focused: {
    outline: {
      color: colors.primaryLight,
      style: 'solid',
      width: 2,
    },
  },
  fontFamily,
  fontFamilySecondary,
  fontWeights,
  forms: createForms({
    borderRadius,
    colors: colorMapping,
    fontFamily,
    fontSize,
    spacing,
    srOnly,
  }),
  formFields,
  hide: {
    display: 'none',
  },
  gradients,
  overflow,
  paginationArrows,
  positions,
  spacing,
  srOnly,
  typography: createTypography({
    baseFontColor: colorMapping.black.toString(),
    baseFontSize: fontSize,
    fontFamily,
  }),
  lib: {
    Color,
  },
  link: {
    color: colors.link,
    textDecoration: 'none',
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  userGenerated: {
    fontStyle: 'italic',
  },
  buttonWithOutline: {
    border: `2px solid ${colors.primary}`,
    padding: '7px 15px',
    textDecoration: 'none',
    color: colors.primary,
    cursor: 'pointer',
  },
  sectionBorder,
  table,
  separator: {
    // these is meant to be used in an `:after` or ':before' selector, the root selector
    // it's applied to needs to be positioned (ex: position: 'relative')
    bottom: {
      ...separator,
      bottom: 0,
      height: 1,
      left: spacing,
      right: spacing,
    },
    left: {
      ...separator,
      bottom: spacing,
      left: 0,
      top: spacing,
      width: 1,
    },
    right: {
      ...separator,
      bottom: spacing,
      right: 0,
      top: spacing,
      width: 1,
    },
    top: {
      ...separator,
      top: 0,
      height: 1,
      left: spacing,
      right: spacing,
    },
  },
  // Error block
  errorBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: spacing,
    padding: spacing,
    backgroundColor: colors.white,
    borderRadius: borderRadius,
  },
  errorBlock__title: {
    color: colors.red,
    margin: 0,
    fontSize: '1.1rem',
    fontWeight: 400,
  },
  errorBlock__text: {
    color: colors.black,
    marginTop: spacing,
    marginBottom: spacing * 0.5,
  },
  icons: {
    upArrow,
    downArrow,
  },
  zIndex: {
    topNavbar: 100000,
    select: 1001,
    toast: 1000,
    modal: 999,
    header: 998,
    loader: 997,
    statusBanner: 995,
    wysiwyg: 994,
  },
};
