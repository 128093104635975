import { get } from 'lodash';
import assign from 'lodash/fp/assign';
import actions from './actions';
import multimethod from '../../../lib/multimethod';
import { actionError, actionStart, actionSuccess, apiData } from '../../common';

const initialState = {
  classifications: [],
  sources: [],
  threats: [],
  threatId: '',
  sourceId: '',
  classificationIds: [],
  error: null,
};

const { fetchedErrored, fetchedStarted } = apiData;
const threatEditorActions = multimethod('type', (action, state) => state);

// Create Classification
threatEditorActions.method[actionStart(actions.CREATE_CLASSIFICATION)] = fetchedStarted;

threatEditorActions.method[actionSuccess(actions.CREATE_CLASSIFICATION)] = (action, state) => {
  const data = get(action, 'payload', '');
  return assign(state, { classificationIds: [...state.classificationIds, data.id] });
};

threatEditorActions.method[actionError(actions.CREATE_CLASSIFICATION)] = fetchedErrored;

// Create Source
threatEditorActions.method[actionStart(actions.CREATE_SOURCE)] = fetchedStarted;

threatEditorActions.method[actionSuccess(actions.CREATE_SOURCE)] = (action, state) => {
  const data = get(action, 'payload', '');
  return assign(state, { sourceId: data.id });
};

threatEditorActions.method[actionError(actions.CREATE_SOURCE)] = fetchedErrored;

// Fetch Classifications
threatEditorActions.method[actionStart(actions.CLASSIFICATIONS)] = fetchedStarted;

threatEditorActions.method[actionSuccess(actions.CLASSIFICATIONS)] = (action, state) => {
  const data = get(action, 'payload.results', []);
  return assign(state, { classifications: data });
};

threatEditorActions.method[actionError(actions.CLASSIFICATIONS)] = fetchedErrored;

// Fetch Sources
threatEditorActions.method[actionStart(actions.SOURCES)] = fetchedStarted;

threatEditorActions.method[actionSuccess(actions.SOURCES)] = (action, state) => {
  const data = get(action, 'payload.results', []);
  return assign(state, { sources: data });
};

threatEditorActions.method[actionError(actions.SOURCES)] = fetchedErrored;

// Fetch Threats
threatEditorActions.method[actionStart(actions.THREATS)] = fetchedStarted;

threatEditorActions.method[actionSuccess(actions.THREATS)] = (action, state) => {
  const data = get(action, 'payload.results', []);
  return assign(state, { threats: data });
};

threatEditorActions.method[actionError(actions.THREATS)] = fetchedErrored;

// Get Source
threatEditorActions.method[actionStart(actions.GET_SOURCE)] = fetchedStarted;

threatEditorActions.method[actionSuccess(actions.GET_SOURCE)] = (action, state) => {
  const data = get(action, 'payload', '');
  return assign(state, { sourceId: data[0].ref.id });
};

threatEditorActions.method[actions.CLEAR_THREATS_FORM] = (action, state) => {
  return assign(state, initialState);
};

threatEditorActions.method[actionError(actions.GET_SOURCE)] = fetchedErrored;

export default (state = initialState, action) => threatEditorActions(action, state);
