import React from 'react';
import injectStyles from 'react-jss';
import cx from 'classnames';
import { FaCalendar as CalendarIcon } from 'react-icons/fa';

class CustomInput extends React.Component {
  render() {
    const { classes, error, onClick, redOutlineOnError, value } = this.props;
    const [date, time, period] = value.split(' ');
    const showErrorStyling = redOutlineOnError && error;
    return (
      <>
        {!date && (
          <span onClick={onClick}>
            <input placeholder="MM-DD-YYYY" type="text" readOnly className={classes.noDate} />
            <CalendarIcon className={classes.iconNoDate} size="14" />
          </span>
        )}
        {date && (
          <span onClick={onClick}>
            <span className={classes.input}>
              {date}
              <CalendarIcon className={classes.icon} size="14" />
            </span>
            {!showErrorStyling && (
              <span className={cx(classes.input)}>
                {time} {period}
              </span>
            )}
            {showErrorStyling && (
              <span className={cx(classes.input, classes.errorTime)}>
                {time} {period}
              </span>
            )}
          </span>
        )}
      </>
    );
  }
}

const styles = theme => ({
  errorTime: {
    borderColor: `${theme.colors.red} !important`,
  },
  input: {
    border: `2px solid ${theme.colors.lighterGray}`,
    padding: '5px 8px',
    marginRight: 5,
    display: 'inline-block',
  },
  icon: {
    color: theme.colors.gray,
    paddingLeft: theme.spacing,
  },
  iconNoDate: {
    color: theme.colors.gray,
    paddingLeft: theme.spacing,
    position: 'absolute',
    right: 4,
    top: 6,
  },
  noDate: {
    border: `1px solid ${theme.colors.lighterGray}`,
    borderRadius: theme.borderRadius.default,
    padding: '5px 8px',
    width: 98,
    fontFamily: theme.fontFamily,
  },
});

export default injectStyles(styles)(CustomInput);
