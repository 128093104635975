const textArea = {
  backgroundColor: 'transparent',
  border: '1px solid',
  borderRadius: 0,
  display: 'block',
  font: '14px ‘Open Sans’, sans-serif',
  fontSize: '14px',
  height: '34px',
  letterSpacing: '1px',
  margin: 0,
  maxHeight: 'none',
  minHeight: '40px',
  padding: '5px',
  paddingBottom: 0,
  position: 'absolute',
  resize: 'none',
  transition: 'transform 1s',
  width: '771px',
  zIndex: 2,
};

export default theme => ({
  backdrop: {
    border: '1px solid',
    borderColor: 'transparent',
    height: '34px',
    overflow: 'hidden',
    pointerEvents: 'none',
    position: 'absolute',
    transition: 'transform 1s',
    width: '781px',
    zIndex: 1,
  },
  container: {
    display: 'block',
    height: '34px',
    minHeight: '50px',
    transform: 'translateZ(0)',
    width: '782px',
    '-webkit-text-size-adjust': 'none',
  },
  highlights: {
    color: 'transparent',
    font: '14px ‘Open Sans’, sans-serif',
    height: '34px',
    letterSpacing: '1px',
    padding: '5px',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  mark: {
    borderRadius: '3px',
    color: 'transparent',
  },
  textarea: {
    borderColor: theme.colors.threatAssocGray,
    ...textArea,
  },
  textarea__error: {
    borderColor: theme.colors.red,
    ...textArea,
  },
});
