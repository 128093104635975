import locales from '../../../assets/locales';

const actions = {
  CHANGE_LOCALE: 'locale/CHANGE_LOCALE',
};

const keyToLocaleValue = {
  'en-US': 'English',
};

/**
 * Returns a select list of locales
 * @type {Array}
 */
export const localeLanguages = Object.keys(locales).reduce((acc, localeKey) => {
  acc.push({
    value: keyToLocaleValue[localeKey],
    label: localeKey,
  });
  return acc;
}, []);

/**
 * Returns a map of locales
 * @type {{}}
 */
export const languageMaps = Object.keys(locales).reduce((acc, localeKey) => {
  acc[localeKey] = locales[localeKey];
  return acc;
}, {});

/**
 * Returns the locale data from the language maps
 * @param language
 * @returns {Promise}
 */
const getLocaleData = language =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      const locale = language.substring(0, language.indexOf('-'));
      const messages = languageMaps[language];
      if (language) {
        return resolve({
          locale,
          messages,
          language,
          loading: false,
        });
      }
      return reject({ error: 'Missing language pack', loading: false });
    }, 300);
  });

/**
 * Returns an action creator that sets the proper locale based on `language`
 * @param language
 * @returns {{type: string, language: *, payload: Promise}}
 */
export const changeLocale = ({ value: language }) => ({
  type: actions.CHANGE_LOCALE,
  language,
  payload: getLocaleData(language),
});

export default actions;
