import React from 'react';
import { Field } from 'redux-form';
import InputField from '../../InputField';
import cx from 'classnames';
import styles from './styles';
import injectStyles from 'react-jss';

class CriticialityField extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Field
        id="threat-criticality"
        className={cx(classes.input, classes.input__criticality)}
        component={InputField}
        name="criticality"
        required={true}
        type="number"
      />
    );
  }
}

export default injectStyles(styles)(CriticialityField);
