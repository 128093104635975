import React from 'react';

// Configuration
import PropTypes from 'prop-types';

// Styles
import injectStyles from 'react-jss';
import cx from 'classnames';

// Components
import { MdCheck as CheckIcon } from 'react-icons/md';

class Checkbox extends React.Component {
  // this intentionally does not expose every prop from the underlying input,
  // they can be added as the need for them arises
  static propTypes = {
    checked: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    onChange: PropTypes.func,
    name: PropTypes.string,
    size: PropTypes.number,
  };

  static defaultProps = {
    size: 12,
  };

  render() {
    const { checked, className, defaultChecked, classes, id, name, onChange, size } = this.props;
    return (
      <label
        className={cx(classes.checkbox, className)}
        data-test="checkbox"
        style={{ height: size, width: size }}>
        <input
          checked={checked}
          defaultChecked={defaultChecked}
          id={id}
          name={name}
          onChange={onChange}
          type="checkbox"
        />
        <span className={classes.fake}>
          <CheckIcon className={classes.check} size={size - 2} />
        </span>
      </label>
    );
  }
}

const styles = theme => ({
  check: {
    color: theme.colors.white,
    display: 'none',
  },
  checkbox: {
    /* input must be at least 1x1 (or visible) to have focus */
    '& input': {
      '&:checked ~ $fake': {
        '& $check': {
          outline: 'none !important',
          display: 'block',
        },
        backgroundColor: theme.colors.iris,
        border: {
          color: theme.colors.iris,
        },
        outline: 'none !important',
      },
      outline: 'none !important',
      '&:focus ~ $fake': theme.focused,
      '&:hover ~ $fake': {
        /* this is here for completeness, currently there are no hover styles */
      },
      cursor: 'pointer',
      height: 1,
      position: 'absolute',
      opacity: 0,
      width: 1,
    },
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    cursor: 'pointer',
    display: 'inline-block',
    position: 'relative',
    userSelect: 'none',
  },
  fake: {
    backgroundColor: theme.colors.white,
    border: {
      color: theme.colors.lightIris,
      style: 'solid',
      width: '1.7px',
      radius: '2px',
    },
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    outline: 'none !important',
    height: 12,
    width: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default injectStyles(styles)(Checkbox);
