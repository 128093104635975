const initialState = {
  statuses: [
    {
      value: 'New',
      label: 'New',
    },
    {
      value: 'In Progress',
      label: 'In Progress',
    },
    {
      value: 'Closed - Resolved',
      label: 'Closed - Resolved',
    },
    {
      value: 'Closed - No Action Required',
      label: 'Closed - No Action Required',
    },
  ],
};

const incidentManagementReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    default:
      return state;
  }
};

export default incidentManagementReducer;
