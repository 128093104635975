import { combineReducers } from 'redux';
import threatStats from './threatStats/reducer';

/*
 * Unify module reducers for a nested reducer state tree in workspace
 */

export default combineReducers({
  threatStats,
});
