import React from 'react';
import TextAreaAutosize from 'react-autosize-textarea';
import cx from 'classnames';
import injectStyles from 'react-jss';
import styles from '../../styles';
import FieldError from '../../errors/FieldError';

class TextAreaField extends React.Component {
  render() {
    const { classes, className, id, input, label, meta, ...rest } = this.props;
    return (
      <div className={classes.field}>
        <TextAreaAutosize
          id={id}
          className={cx(classes.textarea, className, 'form-textarea-field')}
          {...input}
          placeholder={label}
          {...rest}
        />
        <FieldError {...meta} />
      </div>
    );
  }
}

export default injectStyles(styles)(TextAreaField);
