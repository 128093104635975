import Papa from 'papaparse';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import { isFqdn, isIpv4, isIpv6 } from '../../../../../../utils/elements';
import { Field, change, formValueSelector } from 'redux-form';
import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import Group from '../../../groups/Group';
import InputField from '../../InputField';
import Label from '../../../Label';
import { injectIntl } from 'react-intl';
import injectStyles from 'react-jss';
import styles from './styles';
import HighlightedTextArea from '../../HighlightedTextArea';
import { MdWarning as WarningIcon } from 'react-icons/md/index';

class AssociatedElementsField extends React.Component {
  // Functions
  parseFile = e => {
    const file = e.target.files[0];
    const { currentElements, dispatch, form } = this.props;

    Papa.parse(file, {
      complete: results => {
        let data = uniq(get(results.data, ['0'], []));
        const associatedElementsData = [];

        if (data.length > 0) {
          data.forEach(value => {
            associatedElementsData.push(value.trim());
          });
        }

        if (associatedElementsData.length > 0) {
          dispatch(
            change(
              form,
              'associatedElements',
              currentElements
                ? currentElements.concat(',' + associatedElementsData.toString())
                : ''.concat(associatedElementsData.toString())
            )
          );
        }
      },
    });

    //clear path after import
    return (e.target.value = '');
  };

  /**
   * Takes the user input and splits it to check which values are invalid.
   * The invalid values are placed into an array and will used to determine
   * which elements should be highlighted.
   * @returns {Array}
   */
  invalidElements = () => {
    const { currentElements } = this.props;
    const invalidElements = [];
    if (currentElements) {
      const data = currentElements.split(',');

      data.forEach(value => {
        if (!isFqdn(value) && !isIpv4(value) && !isIpv6(value)) {
          invalidElements.push(value.trim());
        }
      });
    }
    //ignore whitespace with the power of Boolean
    return invalidElements.filter(Boolean);
  };

  // LifeCycles
  componentDidMount() {
    const { currentElements, dispatch, form, match } = this.props;
    const { params } = match;
    if (currentElements) {
      this.invalidElements();
    }
    if (params.id) {
      dispatch(change(form, 'associatedElements', params.id));
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentElements !== this.props.currentElements) {
      this.invalidElements();
    }
  }

  render() {
    const { classes, intl } = this.props;
    return (
      <Group className={classes.group}>
        <Group className={classes.header__group}>
          <Label
            className={classes.label}
            htmlFor="threat-associated-elements"
            value={intl.formatMessage({
              id: 'new-threat-elements-label',
              defaultMessage: 'Associated Elements',
            })}
          />
          <Label
            className={classes.label__hint}
            htmlFor="parenthesis"
            value={intl.formatMessage({
              id: 'new-threat-comma-hint-label',
              defaultMessage: '(comma separated)',
            })}
          />
          <Label
            htmlFor="import-file"
            className={classes.input__file}
            value={intl.formatMessage({
              id: 'new-threat-add-elements-input-label',
              defaultMessage: 'add elements',
            })}
          />
          <InputField
            id="import-file"
            name="import-file"
            className={classes.input__hidden}
            onChange={e => this.parseFile(e)}
            type="file"
          />
        </Group>
        <Field
          className={cx(classes.input, classes.input__assoc)}
          id="threat-associated-elements"
          containsError={this.invalidElements().length > 0}
          component={HighlightedTextArea}
          highlightWords={this.invalidElements()}
          name="associatedElements"
        />
        {this.invalidElements().length > 0 && (
          <div className={classes.error__container}>
            <WarningIcon size={20} className={classes.error__icon} />
            <Label
              className={classes.error__message}
              value={intl.formatMessage(
                {
                  id: 'invalid-elements-label',
                  defaultMessage: '{invalidElement} invalid {name}. {message}',
                },
                {
                  invalidElement: this.invalidElements().length,
                  name: this.invalidElements().length === 1 ? 'name' : 'names',
                  message:
                    this.invalidElements().length === 1
                      ? 'Enter a valid name'
                      : 'Enter valid names',
                }
              )}
            />
          </div>
        )}
      </Group>
    );
  }
}

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);
  const currentElements = selector(state, 'associatedElements');
  return {
    currentElements: currentElements,
  };
};

export default connect(mapStateToProps)(injectIntl(injectStyles(styles)(AssociatedElementsField)));
