import { pickBy } from 'lodash';
import moment from 'moment';

/**
 * Filters the collections to be only those that belong to the workspace (identified by
 * workspaceId).
 *
 * @param collections A map of collection id to collection entity.
 * @param workspaceId The workspaceId to limit collections to.
 */
export function workspaceCollections(collections, workspaceId) {
  return pickBy(collections, collection => {
    return collection['workspace-id'] === workspaceId;
  });
}

export function collectionFilename(prefix, collectionName) {
  let name = collectionName.replace(/\W/g, '');
  if (!name) {
    // if replacing all the non-word characters empties out the name,
    // use a hardcoded value
    name = 'collection';
  }
  const timestamp = moment.utc().format('YYYYMMDDHmmss');
  return `${prefix}-${name}-${timestamp}.csv`;
}
