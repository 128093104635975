import theme from '../../../theme';

const inverseStyles = {
  border: '1px solid rgba(255, 255, 255, 0.4)',
  boxShadow: 'none',
  color: theme.colors.lightestGray,
};

export default ({ height, inverse, multi, overrideStyles = {} }) => ({
  container: styles => ({
    ...styles,
    minWidth: 150,
    ...overrideStyles.container,
  }),
  control: (styles, { isDisabled }) => ({
    ...styles,
    ...theme.forms.select,
    border: inverse ? inverseStyles.border : 'none !important',
    boxShadow: inverse ? inverseStyles.boxShadow : 'none',
    borderRadius: theme.borderRadius.default,
    color: isDisabled ? theme.colors.black : theme.colors.white,
    background: isDisabled ? theme.colors.lightestGray : theme.colors.white,
    height,
    minHeight: 32,
    ...(multi && {
      height: 'auto',
    }),
    width: 'auto',
    ...overrideStyles.control,
    cursor: 'pointer',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: `${inverse ? inverseStyles.color : theme.colors.headerGray} !important`,
    ...overrideStyles.dropdownIndicator,
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none !important',
    ...overrideStyles.indicatorSeparator,
  }),
  input: styles => ({
    ...styles,
    color: inverse ? inverseStyles.color : styles.color,
  }),
  menu: styles => ({
    ...styles,
    borderRadius: `0px 0px ${theme.borderRadius.default} ${theme.borderRadius.default}`,
    minWidth: '100%',
    marginTop: '0px',
    ...overrideStyles.menu,
    boxShadow: `0 0 4px ${theme.colors.iris}`,
    border: `1px solid ${theme.colors.lilac}`,
  }),
  menuPortal: styles => ({
    ...styles,
    zIndex: theme.zIndex.select,
  }),
  multiValueLabel: styles => ({
    ...styles,
    ...overrideStyles.multiValueLabel,
  }),
  multiValueRemove: styles => ({
    ...styles,
    ...overrideStyles.multiValueRemove,
  }),
  option: (base, opts) => {
    let overrideOption = {};
    if (overrideStyles.option) {
      overrideOption = overrideStyles.option(base, opts);
    }
    return {
      ...base,
      backgroundColor: opts.isSelected
        ? theme.colors.lightIris
        : opts.isFocused
        ? theme.colors.lavander
        : base.backgroundColor,
      color: opts.isSelected || opts.isFocused ? theme.colors.link : theme.colors.black,
      ...theme.overflow,
      ...overrideOption,
      cursor: 'pointer',
    };
  },
  placeholder: styles => ({
    ...styles,
    color: inverse ? theme.colors.lightestGray : theme.colors.headerGray,
  }),
  singleValue: styles => ({
    ...styles,
    color: inverse ? inverseStyles.color : styles.color,
    ...overrideStyles.singleValue,
  }),
});
