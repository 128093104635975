import mapKeys from 'lodash/mapKeys';
import pick from 'lodash/pick';
import keys from 'lodash/keys';
import actions from './actions';
import loginActions from '../auth/actions';
import { actionSuccess } from '../../common';

const initialState = {
  defaultWorkspaceId: null,
  issuedBy: null,
  isSavingDefault: false,
  lastAccessed: null,
  organizationId: null,
  savingDefaultError: null,
  sessionDuration: null,
  userId: null,
  username: null,
};

/**
 * Takes an `authObject` response and normalizes it into our user app state
 * @param authObject
 * @returns {Object}
 */
const normalizeAuthObject = authObject => {
  // TODO (DH): the way this maps is kinda bad
  const apiKeyToStateKey = {
    // these are turned via users/me
    'default-workspace-id': 'defaultWorkspaceId',
    'organization-id': 'organizationId',
    'user-id': 'userId',
    username: 'username',
    // these are returned as part of auth/login
    'e_last-access_l': 'lastAccessed',
    e_org_z: 'organizationId',
    'e_session-duration_i': 'sessionDuration',
    'e_user-default-workspace_z': 'defaultWorkspaceId',
    'e_user-identity_s': 'username',
    e_user_z: 'userId',
    'n_issued-by_s': 'issuedBy',
  };
  return mapKeys(pick(authObject, keys(apiKeyToStateKey)), (value, key) => {
    return apiKeyToStateKey[key];
  });
};

const userReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${actions.SET_DEFAULT_WORKSPACE}_START`: {
      return {
        ...state,
        isSavingDefault: true,
        savingDefaultError: null,
      };
    }

    case `${actions.SET_DEFAULT_WORKSPACE}_SUCCESS`: {
      return {
        ...state,
        defaultWorkspaceId: payload,
        isSavingDefault: false,
        savingDefaultError: null,
      };
    }

    case `${actions.SET_DEFAULT_WORKSPACE}_ERROR`: {
      return {
        ...state,
        isSavingDefault: false,
        savingDefaultError: payload,
      };
    }

    case loginActions.LOGIN_SUCCESS:
    case actionSuccess(loginActions.CONVERT_TOKEN): {
      return {
        ...state,
        ...normalizeAuthObject(payload),
      };
    }

    case loginActions.LOGOUT: {
      // Don't clear out all the user fields
      return {
        ...initialState,
        ...pick(state, ['lastAccessed', 'issuedBy']),
      };
    }

    default:
      return state;
  }
};

export default userReducer;
