import assign from 'lodash/fp/assign';
import { get } from 'lodash';

import actions from './actions';
import { actionError, actionStart, actionSuccess, apiData } from '../../../redux/common';
import multimethod from '../../../lib/multimethod';

const { fetchErrored, fetchStarted } = apiData;

const initialState = {
  count: 0,
  data: [],
  error: null,
  isRequesting: false,
  refreshTriggerCount: 0,
};

const notificationActions = multimethod('type', (action, state) => state);

notificationActions.method[actionStart(actions.READ_NOTIFICATIONS)] = (action, state) => {
  let { refreshTriggerCount } = state;
  const { updateRefreshCount } = action;
  if (updateRefreshCount) {
    refreshTriggerCount++;
  }
  return assign(fetchStarted(action, state), {
    count: 0,
    refreshTriggerCount,
  });
};

notificationActions.method[actionError(actions.READ_NOTIFICATIONS)] = fetchErrored;

notificationActions.method[actionSuccess(actions.READ_NOTIFICATIONS)] = (action, state) => {
  let totalHits = get(action, 'payload.totalHits', []);
  const results = get(action, 'payload.results', []);
  const count = totalHits > 99 ? '99+' : totalHits;
  const data = results.map(({ id }) => id);

  return assign(state, {
    count,
    data,
    error: null,
    isRequesting: false,
  });
};

notificationActions.method[actionStart(actions.ACKNOWLEDGE_NOTIFICATIONS)] = fetchStarted;

notificationActions.method[actionError(actions.ACKNOWLEDGE_NOTIFICATIONS)] = fetchErrored;

notificationActions.method[actionSuccess(actions.ACKNOWLEDGE_NOTIFICATIONS)] = (action, state) => {
  return assign(state, {
    count: 0,
    data: [],
    error: null,
    isRequesting: false,
  });
};

export default (state = initialState, action) => notificationActions(action, state);
