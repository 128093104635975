import { API } from '../../../../../environment/api';
import { apiRequest } from '../../../../../lib/http';
import { tokenThunk } from '../../../../../utils/api';

const actions = {
  FETCH_DNS_HISTORY: 'elementDetails/FETCH_DNS_HISTORY',
};

export function fetchDnsHistory(ref) {
  let params = {
    name: ref.id,
    type: ref.type,
  };
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.FETCH_DNS_HISTORY,
      payload: apiRequest(token, API.DNS_HISTORY, params),
    });
  });
}

export default actions;
