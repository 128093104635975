import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import SelectField from '../../SelectField';
import uniqBy from 'lodash/uniqBy';
import { injectIntl, FormattedMessage } from 'react-intl';
import graph from '../../../../../../lib/http/graph';
import injectStyles from 'react-jss';
import styles from './styles';
import cx from 'classnames';
import withSessionToken from '../../../../../common/withSessionToken';

class UnassociatedThreatsField extends React.Component {
  static propTypes = {
    workspaceId: PropTypes.string.isRequired,
  };

  // State
  state = {
    data: [],
  };

  // Functions
  existingAssociations = () => {
    const { elementId, sessionToken, workspaceId } = this.props;
    const ast = [
      'and',
      ['=', 'type', 'associated-with'],
      ['=', 'userUploaded', true],
      ['=', 'left.name', elementId],
    ];

    const threats = ['or'];

    return graph.api
      .query(sessionToken, workspaceId, ast)
      .then(data => {
        data.results.map(({ ref: { right: threat } }) => {
          return threats.push(['=', 'threatId', threat.id]);
        });
      })
      .then(() => {
        this.fetchUserGeneratedThreats(threats);
      });
  };

  fetchUserGeneratedThreats = threats => {
    const { sessionToken, workspaceId } = this.props;

    const ast =
      threats.length > 1
        ? ['and', ['=', 'type', 'threat'], ['=', 'userUploaded', true], ['not', threats]]
        : ['and', ['=', 'type', 'threat'], ['=', 'userUploaded', true]];

    const params = { sortBy: [['threatName', 'asc']] };

    return graph.api
      .query(sessionToken, workspaceId, ast, params)
      .then(data => this.setState({ data: data.results }));
  };

  formattedData = () => {
    const { data } = this.state;
    if (data) {
      return uniqBy(
        data.map(({ classifications, sources, threatName, threatId, ticScore }) => ({
          label: threatName,
          value: threatName,
          id: threatId,
          classifications,
          sources,
          ticScore,
        })),
        'value'
      );
    }
  };

  // Lifecycle Components
  componentDidMount() {
    this.existingAssociations();
  }

  render() {
    const { classes, className, intl } = this.props;
    return (
      <>
        <Field
          className={cx(classes.select__threat, className)}
          closeMenuOnSelect={true}
          component={SelectField}
          name="unassociatedThreats"
          options={this.formattedData()}
          placeholder={intl.formatMessage({
            id: 'add-association-select-placeholder',
            defaultMessage: 'Select Threat',
          })}
          disabled={this.formattedData().length === 0}
          required={true}
        />
        {this.formattedData().length === 0 && (
          <div className={classes.span__noData}>
            <FormattedMessage
              id={'add-association-no-threats'}
              defaultMessage={'There are no existing threats that can be associated.'}
            />
          </div>
        )}
      </>
    );
  }
}

export default withSessionToken(injectIntl(injectStyles(styles)(UnassociatedThreatsField)));
