import jwt_decode from 'jwt-decode';

// default idle timeout value (30 mins specified in milliseconds)
export const DEFAULT_IDLE_TIMEOUT = 30 * 60 * 1000;
// default access token expiration time (60 mins specified in milliseconds)
export const DEFAULT_ACCESS_TOKEN_TIME = 60 * 60 * 1000;
// keep alive percentage of timeout
export const KEEP_ALIVE_PERCENT = 0.8;
// default to 80% of 2 mins (since 2 should be the minimum configurable value) to ensure it is never 0
export const MIN_KEEP_ALIVE_TIME = 96 * 1000;

export function getOauth2TimeoutValue(sessionToken) {
  let idleTimeout = DEFAULT_IDLE_TIMEOUT; // (in milliseconds)
  let defaultTimoutMsg =
    'Problem getting user timeout from Access Token, using default of ' +
    idleTimeout / 1000 +
    ' seconds.';
  if (sessionToken && sessionToken.type === 'oauth2') {
    const accessToken = sessionToken.value;

    try {
      const accessPayload = jwt_decode(accessToken);
      // For reference, but not currently used (needs getIdToken in import)
      //  const idToken = getIdToken();
      //  const idPayload = jwt_decode(idToken);

      if ('timeout' in accessPayload) {
        // set to custom Okta user profile timeout, if it exists in access token
        // (expected to be in MILLISECONDS, and converted to ms and default set in claims before leaving Okta)
        idleTimeout = accessPayload.timeout;
      }
    } catch (error) {
      console.log(defaultTimoutMsg);
    }
  } else {
    console.log(defaultTimoutMsg);
  }

  return idleTimeout;
}

export function getOauth2AccessTokenTime(sessionToken) {
  let tokenTime = DEFAULT_ACCESS_TOKEN_TIME; // (in milliseconds)
  let defaultTokenTimeMsg =
    'Problem getting Access Token lifetime, using default of ' + tokenTime / 1000 + ' seconds.';

  if (sessionToken && sessionToken.type === 'oauth2') {
    const accessToken = sessionToken.value;
    try {
      const accessPayload = jwt_decode(accessToken);
      if ('exp' in accessPayload && 'iat' in accessPayload) {
        // calculate expiration time (lifetime) of the access token
        // (expected in SECONDS from epoch time stamps and converted to ms)
        tokenTime = (accessPayload.exp - accessPayload.iat) * 1000;
        console.log(
          'Access token lifetime is ' + tokenTime / 1000 + ' seconds (from access token).'
        );
      }
    } catch (error) {
      console.log(defaultTokenTimeMsg);
    }
  } else {
    console.log(defaultTokenTimeMsg);
  }

  return tokenTime;
}

// reduce interval time to 80% of actual timeout to refresh before timing out
// (timeout and keep alive times are in MILLISECONDS)
export function calcKeepAliveTime(timeoutValue) {
  let envAliveTime = parseInt(process.env.REACT_APP_KEEP_ALIVE_TIME); // (for dev/testing)
  if (!isNaN(envAliveTime)) {
    console.log(
      'Keep Okta tokens alive time is ' + envAliveTime / 1000 + ' secs (from environment).'
    );
    return envAliveTime;
  } else {
    return Math.floor(timeoutValue * KEEP_ALIVE_PERCENT) || MIN_KEEP_ALIVE_TIME;
  }
}
