import React from 'react';
import PropTypes from 'prop-types';
import injectStyles from 'react-jss';
import {
  Wrapper,
  Button as ARIAButton,
  Menu as ARIAMenu,
  MenuItem as ARIAItem,
} from 'react-aria-menubutton';
import cx from 'classnames';
import { MdKeyboardArrowDown as DropdownArrow } from 'react-icons/md';
import styles from './styles';

/**
 * ActionMenu
 * Component that returns a "popver" menu that is triggered by a button interaction.
 * Menu items are defined by `children` living within the component declaration.
 *
 * Note:
 * There is a convention here where the first child is the `button/trigger` reference and
 * subsequent children are the menu items
 *
 * e.g,
 * <ActionMenu><div>Trigger Here</div><div>Menu Item 1</div></ActionMenu>
 */

class ActionMenu extends React.Component {
  static propTypes = {
    alignedRight: PropTypes.bool.isRequired,
    arrow: PropTypes.bool.isRequired,
    buttonClassName: PropTypes.string,
    disabledButtonClassName: PropTypes.string,
    disabledClassName: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    itemHeight: PropTypes.number.isRequired,
    isVisible: PropTypes.bool,
    onSelected: PropTypes.func,
    topOffset: PropTypes.number,
    width: PropTypes.number.isRequired,
  };

  static defaultProps = {
    alignedRight: false,
    arrow: true,
    disabled: false,
    isVisible: true,
    itemHeight: 40,
    topOffset: 35,
    width: 250,
  };

  render() {
    const {
      alignedRight,
      arrow,
      buttonClassName,
      children: [button, ...menuItems],
      className,
      classes,
      dataTest,
      disabled,
      disabledButtonClassName,
      disabledClassName,
      id,
      itemClassName = classes.item,
      isVisible,
      listItemClassName = classes.list__item,
      onSelected,
    } = this.props;
    const renderableItems = Array.isArray(menuItems) ? menuItems.filter(i => i) : [menuItems];
    return (
      <Wrapper
        id={id}
        className={cx({
          [classes.container]: true,
          [className]: !!className,
          [classes.hidden]: !isVisible,
          [disabledClassName]: disabled && disabledClassName,
        })}
        onSelection={onSelected}
        data-test={dataTest}>
        <ARIAButton
          className={cx({
            [classes.button]: true,
            [classes.button__disabled]: disabled,
            [buttonClassName]: true,
            [disabledButtonClassName]: disabled,
          })}
          disabled={disabled}>
          {button}
          <DropdownArrow
            className={cx({
              [classes.dropdownArrow]: true,
              [classes.dropdownArrow__disabled]: disabled,
            })}
            size={20}
          />
        </ARIAButton>
        <ARIAMenu
          className={cx(
            classes.menu,
            arrow && alignedRight ? classes.arrowRight : classes.arrowLeft
          )}>
          <ul className={classes.list} data-test="action-menu-list">
            {React.Children.map(renderableItems, (item, index) => (
              <li className={listItemClassName} key={index}>
                <ARIAItem className={itemClassName} style={{ height: 'auto' }}>
                  {item}
                </ARIAItem>
              </li>
            ))}
          </ul>
        </ARIAMenu>
      </Wrapper>
    );
  }
}

export default injectStyles(styles)(ActionMenu);
