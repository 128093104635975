import React from 'react';
import PropTypes from 'prop-types';

import notification from '../../common/notification';
import { KEYCODE } from '../../../utils/dom';

import { PAGINATION_LIMIT } from '../../../utils/pagination';

class Options extends React.Component {
  static propTypes = {
    allPages: PropTypes.number,
    changeSize: PropTypes.func,
    classes: PropTypes.object,
    current: PropTypes.number,
    intl: PropTypes.object,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.string),
    pageSize: PropTypes.number,
    quickGo: PropTypes.func,
    selectComponentClass: PropTypes.func,
    hideGoto: PropTypes.bool,
  };

  static defaultProps = {
    pageSizeOptions: ['100', '500', '1000', '5000', '10000', '25000'],
    hideGoto: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      goInputText: '',
    };
  }

  changeSize = e => {
    const value = e.target.value;
    this.props.changeSize(value ? Number(value) : Number(this.props.pageSizeOptions[0]));
  };

  handleChange = e => {
    this.setState({
      goInputText: e.target.value,
    });
  };

  go = e => {
    let val = this.state.goInputText;
    if (val === '') {
      return;
    }
    val = isNaN(val) ? this.props.current : Number(val);

    if (e.keyCode === KEYCODE.ENTER || e.type === 'click') {
      if (val > PAGINATION_LIMIT) {
        notification.error({
          id: 'delete-workspace-success',
          defaultMessage: `The maximum pagination number to jump to is ${PAGINATION_LIMIT}`,
        });
      } else {
        this.props.quickGo(val);
      }
      this.setState({
        goInputText: '',
      });
    }
  };

  render() {
    const {
      allPages,
      classes,
      intl,
      changeSize,
      quickGo,
      goButton,
      pageSizeOptions,
      hideGoto,
    } = this.props;
    const state = this.state;
    let changeSelect = null;
    let goInput = null;
    let gotoButton = null;

    if (!(changeSize || quickGo)) {
      return null;
    }

    if (changeSize) {
      const pageSize = this.props.pageSize || pageSizeOptions[0];
      changeSelect = (
        <div className={classes.select__container}>
          <select className={classes.select} onChange={this.changeSize} value={pageSize}>
            {pageSizeOptions.map(page => (
              <option key={page} value={page}>
                {page} entries
              </option>
            ))}
          </select>
        </div>
      );
    }

    if (quickGo) {
      if (goButton) {
        if (typeof goButton === 'boolean') {
          gotoButton = (
            <button type="button" onClick={this.go} onKeyUp={this.go}>
              {intl.formatMessage({
                id: 'pagination-jump-to-confirm',
                defaultMessage: 'confirm',
              })}
            </button>
          );
        } else {
          gotoButton = (
            <span onClick={this.go} onKeyUp={this.go}>
              {goButton}
            </span>
          );
        }
      }
      goInput = (
        <div className={classes.goto}>
          <span className={classes.goto__text}>
            {intl.formatMessage({
              id: 'pagination-jump-to',
              defaultMessage: 'Goto',
            })}
          </span>
          <input
            className={classes.goto__input}
            type="number"
            value={state.goInputText}
            onChange={this.handleChange}
            onKeyUp={this.go}
            min={1}
            max={allPages}
          />
          {gotoButton}
        </div>
      );
    }

    return (
      <li className={classes.pager__options}>
        {changeSelect}
        {!hideGoto && goInput}
      </li>
    );
  }
}

export default Options;
