import React from 'react';
import SelectAndInputField from '../SelectAndInputField';
import { FieldArray } from 'redux-form';
import PropTypes from 'prop-types';

class MultiMetadataSelectorField extends React.Component {
  render() {
    const { buttonClassName, options, inputClassName, selectClassName } = this.props;
    return (
      <React.Fragment>
        <FieldArray
          {...this.props}
          name={'metadata'}
          buttonValue={'Add Metadata'}
          buttonClassName={buttonClassName}
          component={SelectAndInputField}
          inputClassName={inputClassName}
          options={options}
          selectClassName={selectClassName}
        />
      </React.Fragment>
    );
  }
}

MultiMetadataSelectorField.propTypes = {
  options: PropTypes.array.isRequired,
};

export default MultiMetadataSelectorField;
