import React from 'react';
import { default as ReactModal } from 'react-modal';
import injectStyles from 'react-jss';
import cx from 'classnames';
import deepmerge from 'deepmerge';
import styles, { modalStyles } from './styles';
import './modal.css';
import { withRouter } from 'react-router-dom';
import { closeDialog } from '../../../../utils/history';

// Accessibility entry points for ReactModal
ReactModal.setAppElement(document.getElementById('root'));

class Modal extends React.Component {
  defaultOnRequestClose = () => {
    closeDialog(this.props.history);
  };

  render() {
    const {
      aria,
      children,
      className,
      classes,
      closeModalIcon,
      contentClassName,
      contentLabel,
      cssClassName,
      cssContentClassName,
      isOpen,
      noDecorator,
      noPadding,
      onAfterOpen,
      onRequestClose,
      shouldCloseOnEsc,
      shouldCloseOnOverlayClick,
      styles = {
        dialog: {},
      },
    } = this.props;
    return (
      <ReactModal
        aria={aria}
        ariaHideApp={false}
        closeTimeoutMS={300}
        contentLabel={contentLabel}
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onRequestClose={onRequestClose || this.defaultOnRequestClose}
        shouldCloseOnEsc={shouldCloseOnEsc}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        style={deepmerge(modalStyles.dialog, styles.dialog)}>
        <div className={cx(classes.container, className, cssClassName)}>
          {closeModalIcon && (
            <div className={cx(classes.closeIcon)} data-test="close-icon" onClick={onRequestClose}>
              <i className="fa fa-times" />
            </div>
          )}
          <div className={cx(classes.decorator, noDecorator && classes.noDecorator)} />
          <div
            className={cx(
              classes.content__container,
              noPadding && classes.noPadding,
              contentClassName,
              cssContentClassName
            )}>
            {children}
          </div>
        </div>
      </ReactModal>
    );
  }
}

Modal.defaultProps = {
  contentLabel: 'Modal',
  isOpen: true,
  shouldCloseOnEsc: true,
  shouldCloseOnOverlayClick: true,
};

export default withRouter(injectStyles(styles)(Modal));
