import React from 'react';
import injectStyles from 'react-jss';
import Title from '../../../base/Title';

const ModalTitle = ({ classes, title, titleId, titleValues, withHelmet }) => (
  <Title
    className={classes.heading}
    title={title}
    titleId={titleId}
    titleValues={titleValues}
    withHelmet={withHelmet}
  />
);

const styles = theme => ({
  heading: {
    borderBottom: { color: theme.colors.lighterGray, width: '1pt', style: 'solid' },
    fontSize: '14pt',
    margin: 0,
    paddingBottom: '10pt',
  },
});

export default injectStyles(styles)(ModalTitle);
