import React from 'react';
import injectStyles from 'react-jss';
import LoadingIndicator from '../LoadingIndicator';
import styles from './styles';

/**
 * AsyncLoader
 * Component that handles code split components loaded via `react-loadable`
 */
const AsyncLoader = ({ classes, error, isLoading, pastDelay, timedOut }) => {
  if (isLoading) {
    if (timedOut) {
      return <div className={classes.container}>Loader timed out!</div>;
    } else if (pastDelay) {
      return (
        <LoadingIndicator
          id="async-loading-indicator"
          fadeIn="quarter"
          showing={true}
          text="Loading..."
          full
        />
      );
    } else {
      return null;
    }
  } else if (error) {
    if (error.name === 'ChunkLoadError') {
      return (
        <div className={classes.container}>
          Please refresh your browser to view updates for ScoutPrime.
        </div>
      );
    }
    return <div className={classes.container}>Error! Please refresh the page</div>;
  } else {
    return null;
  }
};

export default injectStyles(styles)(AsyncLoader);
