import graphApi from '../../../../../lib/http/graph';
import { tokenThunk } from '../../../../../utils/api';

const actions = {
  FETCH_OWNED_ELEMENTS: 'elementDetails/FETCH_OWNED_ELEMENTS',
};

export function fetchOwnedElements(workspaceId, owner, from, limit, sortBy) {
  const ast = ['and', ['=', 'type', ['asn', 'cidrv4', 'cidrv6']], ['=', 'owners', owner]];
  const fields = ['name', 'ticScore'];
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.FETCH_OWNED_ELEMENTS,
      payload: graphApi.api.query(token, workspaceId, ast, { fields, from, limit, sortBy }),
    });
  });
}

export default actions;
