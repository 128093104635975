import React from 'react';
import Info from '../../../../assets/images/icon_success.svg';
import injectStyles from 'react-jss';

const SuccessIcon = ({ classes }) => (
  <img src={Info} alt="success" className={classes.icon__success} />
);

const styles = theme => ({
  icon__success: {
    backgroundColor: 'transparent',
    color: theme.colors.green,
    flexShrink: 0,
    height: '24px',
    width: '24px',
  },
});

export default injectStyles(styles)(SuccessIcon);
