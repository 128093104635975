import React, { useEffect, useMemo, useReducer } from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import { AnimatePresence } from 'framer-motion';
import injectStyles from 'react-jss';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import isString from 'lodash/isString';

import { uuid } from '../../../utils/components';
import theme from '../../../theme';
import styles, { ToastWrapper, getPortalStyle } from './styles';
import { ErrorIcon, InfoIcon, SuccessIcon } from '../icons';

const HIDE = 'hide';
const SHOW = 'show';
const UPDATE = 'update';

const toastReducer = (state, action) => {
  switch (action.type) {
    case HIDE:
      return {
        ...state,
        toasts: [...state.toasts.filter((t) => t.id !== action.id)],
      };
    case SHOW:
      return {
        toasts: [...state.toasts, { ...action.toast, id: uuid() }],
      };
    case UPDATE:
      const toastToUpdate =
        state.toasts && state.toasts.length ? state.toasts.find((t) => t.id === action.id) : null;
      let toastCopy = { ...toastToUpdate, ...action.toast };
      const allToastsCopy = [...state.toasts];
      const toastIndex = state.toasts.findIndex((obj) => obj.id === action.id);
      allToastsCopy[toastIndex] = toastCopy;
      return {
        toasts: [...allToastsCopy],
      };
    default:
      throw new Error();
  }
};

const Toast = ({ children, timeout, classes, intl }) => {
  const [toastSetup, dispatchToast] = useReducer(toastReducer, {
    toasts: [],
  });

  const toastPortal = document.getElementById('toast-portal');
  const { toasts } = toastSetup;
  const portalStyle = getPortalStyle(theme.zIndex.toast);
  toastPortal.style.cssText = portalStyle;

  Toast.display = (message, type, autoClose = true) => {
    dispatchToast({
      toast: {
        message,
        type,
        autoClose: autoClose,
      },
      type: SHOW,
    });
    return toastSetup.toasts ? toastSetup.toasts.length + 1 : 0;
  };

  Toast.update = (id, message, autoClose = true) => {
    dispatchToast({
      id,
      toast: {
        message,
        autoClose: autoClose,
      },
      type: UPDATE,
    });
    return toastSetup.toasts ? toastSetup.toasts.length + 1 : 0;
  };

  const hideToast = (id) => {
    dispatchToast({
      id,
      type: HIDE,
    });
  };

  Toast.hide = hideToast;

  const handleHideToast = (id) => () => {
    hideToast(id);
  };

  useEffect(() => {
    if (toastSetup.toasts && toastSetup.toasts.length) {
      toastSetup.toasts.forEach((t) => {
        if (t.autoClose) {
          setTimeout(() => {
            hideToast(t.id);
          }, timeout);
        }
      });
    }
  }, [timeout, toastSetup]);

  const iconsByToastType = useMemo(
    () => ({
      error: <ErrorIcon />,
      info: <InfoIcon />,
      success: <SuccessIcon />,
    }),
    []
  );

  return (
    <>
      {ReactDom.createPortal(
        <AnimatePresence>
          {toasts &&
            !!toasts.length &&
            toasts.map((toast) => (
              <ToastWrapper
                key={toast.id}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5 }}>
                <div type={toast.type} className={cx(classes.toastContent, classes[toast.type])}>
                  <div className={classes.messageContainer} type={toast.type}>
                    {iconsByToastType[toast.type]}
                    <span>
                      {isString(toast.message)
                        ? toast.message
                        : intl.formatMessage(
                            {
                              id: toast.message.id,
                              defaultMessage: toast.message.defaultMessage,
                            },
                            toast.message.values
                          )}
                    </span>
                  </div>
                  <i
                    className={cx(classes.clearIcon, 'fas fa-times-circle')}
                    onClick={handleHideToast(toast.id)}
                  />
                </div>
              </ToastWrapper>
            ))}
        </AnimatePresence>,
        toastPortal
      )}
      {children}
    </>
  );
};

Toast.defaultProps = {
  children: null,
  timeout: 5000,
};

Toast.propTypes = {
  children: PropTypes.node,
  timeout: PropTypes.number,
};

const WrappedToast = injectIntl(injectStyles(styles)(Toast));
export { WrappedToast as Toast };
