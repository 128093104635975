import React from 'react';

/**
 * A pass-through wrapper around a div to provide a name for those cases where there's
 * simply a wrapping container around other components.
 *  - need to get the `ref` so that we can scroll the content on the top of the page
 *
 * @param props
 * @returns {*}
 * @constructor
 */

const Content = React.forwardRef((props, ref) => {
  return <div data-test="content" ref={ref} {...props} />;
});

export default Content;
