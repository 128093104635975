import React from 'react';
import injectStyles from 'react-jss';
import Tooltip from 'react-tooltip';
import cx from 'classnames';
import Base from './Base';
import { uuid } from '../../../utils/components';
import { injectIntl } from 'react-intl';

const IconButton = ({
  id = uuid(),
  className,
  classes,
  'data-test': dataTest,
  icon,
  intl,
  tooltip,
  tooltipId,
  tooltipPosition,
  ...rest
}) => {
  if (tooltipId) {
    tooltip = intl.formatMessage({
      defaultMessage: tooltip,
      id: tooltipId,
    });
  }
  return (
    <Base
      id={id}
      data-test={dataTest}
      className={cx(classes.container, className)}
      type="button"
      {...rest}
      value={
        tooltip ? (
          <React.Fragment>
            <span data-tip data-for={id}>
              {icon}
            </span>
            <Tooltip
              id={id}
              place={tooltipPosition}
              type="dark"
              effect="float"
              aria-haspopup="true">
              {tooltip}
            </Tooltip>
          </React.Fragment>
        ) : (
          icon
        )
      }
    />
  );
};

IconButton.defaultProps = {
  tooltipPosition: 'top',
};

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
    width: 'auto',
    border: 'none',
    minWidth: 'auto',
    paddingLeft: 0,
    paddingRight: 0,
    textTransform: 'none',
    cursor: 'pointer',
    color: theme.colors.gray,
    transition: 'all 0.2s linear',
    '&:hover': {
      color: theme.colors.black,
    },
  },
});

export default injectIntl(injectStyles(styles)(IconButton));
