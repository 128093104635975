import graphApi from '../../../../../lib/http/graph';
import { tokenThunk } from '../../../../../utils/api';
import { astPlusOwnerTypes } from '../../../../../utils/elements';

const actions = {
  FETCH_OWNERS: 'collectionManagement/owners/FETCH',
  REFRESH_OWNERS: 'collectionManagement/owners/REFRESH',
};

export function fetchOwners(workspaceId, collectionId) {
  const ast = ['and', ['=', 'collectionIds', collectionId], ['=', 'type', astPlusOwnerTypes]];
  const analytics = graphApi.builder
    .analytics()
    .groupByAgg('owners', 'owners')
    .limit(6)
    .cardinalityAgg('numOwners', 'owners')
    .build();
  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.FETCH_OWNERS,
      payload: graphApi.api.analytics(token, workspaceId, ast, analytics),
    });
  });
}

export function refreshOwners() {
  return {
    type: actions.REFRESH_OWNERS,
  };
}

export default actions;
