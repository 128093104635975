import React from 'react';
import cx from 'classnames';
import injectStyles from 'react-jss';
import { abstractPropTypes, default as AbstractButton } from './AbstractButton';
import { FormattedMessage } from 'react-intl';
import { FaTimes as ClearIcon } from 'react-icons/fa';

class ClearButton extends React.Component {
  static propTypes = abstractPropTypes;

  static defaultProps = {
    text: 'Clear',
    textId: 'button-clear',
    type: 'button',
  };

  render() {
    const { classes, className, text, textId, ...buttonProps } = this.props;
    return (
      <AbstractButton className={cx(classes.clear, className)} {...buttonProps}>
        <FormattedMessage defaultMessage={text} id={textId} />{' '}
        <ClearIcon className={classes.icon} />
      </AbstractButton>
    );
  }
}

const styles = theme => ({
  clear: {
    alignItems: 'center',
    background: 'none',
    border: {
      width: 0,
    },
    color: theme.colors.lightGray,
    display: 'flex',
    fontSize: 14,
    height: 'auto',
    outline: 'none',
    textTransform: 'uppercase',
    '&:not(:disabled):hover': {
      color: theme.colors.link,
    },
  },
  icon: {
    marginLeft: 5,
  },
});

export default injectStyles(styles)(ClearButton);
