import { isIpv4 } from './elements';

/**
 * Parses IPv4 to Integer
 * @param ipv4
 * @returns {*}
 */
export const ipv4ToInt = ipv4 => {
  if (!ipv4) {
    throw new Error('E_UNDEFINED_IP');
  }
  if (!isIpv4) {
    throw new Error('E_INVALID_IP');
  }
  return ipv4
    .split('.')
    .map((octet, index, array) => {
      return parseInt(octet, 10) * Math.pow(256, array.length - index - 1);
    })
    .reduce((prev, curr) => {
      return prev + curr;
    });
};

/**
 * Parses Integer to IPv4
 * @param value
 * @returns {string}
 */
export const intToIpv4 = value => {
  if (value === 0) {
    return '0.0.0.0';
  }

  if (!value) {
    throw new Error('E_UNDEFINED_INTEGER');
  }
  const result = /\d+/.exec(value);
  if (!result) {
    throw new Error('E_INTEGER_NOT_FOUND');
  }
  value = result[0];
  return [(value >> 24) & 0xff, (value >> 16) & 0xff, (value >> 8) & 0xff, value & 0xff].join('.');
};

export function ipv4RangeToCidrv4(ipv4StartOrPair, ipv4End) {
  let ipv4Start = ipv4StartOrPair;
  if (ipv4StartOrPair instanceof Array) {
    ipv4Start = ipv4StartOrPair[0];
    ipv4End = ipv4StartOrPair[1];
  }
  if (ipv4Start > ipv4End) {
    throw new Error('Ipv4 start must not be greater than ipv4 end.');
  }
  let mask = 32;
  let d = ipv4End - ipv4Start + 1;
  for (; mask >= 0; mask--) {
    if (d === 1) {
      break;
    }
    d = d >> 1;
  }
  return `${intToIpv4(ipv4Start)}/${mask}`;
}

export function hashToAlg(hash) {
  if (hash && hash.match(/[0-9a-zA-Z]/)) {
    switch (hash.length) {
      case 32:
        return 'md5';
      case 40:
        return 'sha1';
      case 64:
        return 'sha256';
      case 128:
        return 'sha512';
      default:
    }
  }
  return null;
}
