import React from 'react';

// Config
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// Styles
import injectStyles from 'react-jss';

// Components
import Button from './buttons/Base';

class SecretValue extends React.Component {
  static propTypes = {
    inputWidth: PropTypes.any,
    value: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isMasked: true,
    };
  }

  inputType = () => {
    if (this.state.isMasked) {
      return 'password';
    }
    return 'text';
  };

  toggle = () => {
    this.setState(state => {
      return {
        ...state,
        isMasked: !state.isMasked,
      };
    });
  };

  buttonText = () => {
    const { intl } = this.props;
    if (this.state.isMasked) {
      return intl.formatMessage({
        defaultMessage: 'Show',
        id: 'secret-value-show',
      });
    }
    return intl.formatMessage({
      defaultMessage: 'Hide',
      id: 'secret-value-hide',
    });
  };

  render() {
    const { classes, inputWidth, value } = this.props;
    return (
      <div className={classes.secretValue} data-test="secret">
        <input
          className={classes.input}
          readOnly
          style={{ width: inputWidth }}
          type={this.inputType()}
          value={value}
        />
        <Button className={classes.button} onClick={this.toggle} value={this.buttonText()} />
      </div>
    );
  }
}

const styles = theme => ({
  button: {
    border: '1px solid #eee',
    borderLeft: 'none',
    height: 'auto',
    minWidth: 75,
    textTransform: 'capitalize',
  },
  input: {
    border: '1px solid #eee',
    fontSize: '1.3em',
    padding: 5,
  },
  secretValue: {
    display: 'flex',
  },
});

export default injectIntl(injectStyles(styles)(SecretValue));
