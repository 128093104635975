import { Field } from 'redux-form';
import React from 'react';
import cx from 'classnames';
import TextAreaField from '../../TextAreaField';
import injectStyles from 'react-jss';
import styles from './styles';

class DescriptionField extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Field
        className={cx(classes.input, classes.text__desc)}
        id="threat-description"
        autoComplete="threat-description"
        component={TextAreaField}
        name="threatDescription"
      />
    );
  }
}

export default injectStyles(styles)(DescriptionField);
