export default theme => ({
  arrows: {
    ...theme.paginationArrows,
    '&:hover': {
      color: theme.colors.lightGray,
    },
  },
  pager: {
    display: 'inline-flex',
    padding: 0,
    userSelect: 'none',
    listStyle: 'none',
    alignItems: 'center',
  },
  pager__item: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    cursor: 'pointer',
    borderRadius: theme.borderRadius.default,
    minWidth: 28,
    height: 28,
    textAlign: 'center',
    listStyle: 'none',
    border: '1px solid transparent',
    backgroundColor: 'transparent',
    marginLeft: theme.spacing * 0.5,
    color: theme.colors.gray,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.colors.white,
      color: theme.colors.jade,
      borderColor: theme.colors.jade,
    },
  },
  pager__value: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  pager__action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: theme.colors.gray,
    fontSize: '0.7rem',
    borderRadius: theme.borderRadius.none,
    listStyle: 'none',
    minWidth: 28,
    height: 32,
    textAlign: 'center',
    '&:first-child': {
      marginRight: '-5px;',
    },
  },
  pager__jump: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 28,
  },
  jump__next: {
    marginRight: 5,
  },
  jump__prev: {
    marginRight: 5,
  },
  pager__prev: {},
  pager__next: {},
  item__link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    color: 'inherit',
    textDecoration: 'none',
    textAlign: 'center',
  },
  active: {
    backgroundColor: theme.colors.jade,
    borderColor: theme.colors.jade,
    color: theme.colors.white,
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.6,
    color: theme.colors.lightGray,
  },
  pager__options: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing * 0.5,
  },
  goto: {
    display: 'inline-flex',
    alignItems: 'center',
    height: 32,
    paddingLeft: 5,
    paddingRight: 5,
  },
  goto__input: {
    ...theme.typography.small,
    ...theme.forms.input,
    color: theme.colors.gray,
    borderColor: theme.colors.jade,
    width: 35,
    height: 28,
    borderRadius: '5px',
    marginRight: theme.spacing * 0.5,
    marginLeft: theme.spacing * 0.5,
    padding: '0 !important',
    textAlign: 'center',
    fontSize: '0.8rem',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
  goto__text: {
    ...theme.typography.body,
    color: theme.colors.lightGray,
  },
  select__container: {
    display: 'flex',
    backgroundColor: theme.colors.white,
    border: '1px solid',
    borderColor: theme.colors.lightGray,
    borderRadius: '5px',
    marginRight: theme.spacing,
    height: 28,
  },
  select: {
    height: '100%',
    border: 'none',
    width: 'auto',
    ...theme.typography.small,
    color: theme.colors.gray,
    backgroundColor: 'inherit',
    borderRadius: '5px',
    fontSize: '0.8rem',
    outline: 'none',
  },
});
