import React from 'react';

// Components
import { Helmet } from 'react-helmet';
import { Route } from 'react-router-dom';

// Config
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

// Util
import { get } from 'lodash';
import { localizedText } from '../../../utils/localization';

class RouteWithTitle extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    titleId: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      title: null,
    };
  }

  title = routeProps => {
    if (this.state.title) {
      return this.state.title;
    }
    const { intl, titleId, title } = this.props;
    return localizedText(intl, title, titleId, get(routeProps, 'match.params', {}));
  };

  /**
   * This is potentially called by components that are rendered underneath the Route. It
   * can be called at any time and sets a title in this component's state that overrides
   * any other title.
   *
   * It is necessary because some page titles are not able to be determined until after
   * something loads. For example, in element details the title includes the threat name
   * which is not loaded until after the page renders.
   *
   * @param title The raw title (if titleId is not passed) or localized defaultMessage.
   * @param titleId The id of a localized string.
   * @param titleValues A map of values to substitute in the localized string.
   */
  setTitle = (title, titleId, titleValues) => {
    this.setState({ title: localizedText(this.props.intl, title, titleId, titleValues) });
  };

  render() {
    const { render, component: Component, ...props } = this.props;
    return (
      <Route
        {...props}
        render={routeProps => {
          routeProps = { ...routeProps, setTitle: this.setTitle };
          const RenderComponent =
            typeof render !== 'undefined' ? render(routeProps) : <Component {...routeProps} />;
          return (
            <React.Fragment>
              <Helmet>
                <title>ScoutPRIME: {this.title(routeProps)}</title>
              </Helmet>
              {RenderComponent}
            </React.Fragment>
          );
        }}
      />
    );
  }
}

RouteWithTitle.defaultProps = {
  title: 'ScoutPrime',
};

export default injectIntl(RouteWithTitle);
