import actions from './actions';

const initialState = {
  systemCollection: [],
  workspaceCollection: [],
  currentCollection: {},
  fetchingSystemCollection: false,
  fetchingCollectionsByWorkspace: false,
  filterIndex: 0, // Filter index of 0 is my collections, 1 is all collections
};

const collectionHealthReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case `${actions.READ_SYSTEM_COLLECTIONS}_START`:
      return {
        ...state,
        fetchingSystemCollection: true,
      };
    case `${actions.READ_SYSTEM_COLLECTIONS}_ERROR`:
      return {
        ...state,
        ...payload,
      };
    case `${actions.READ_SYSTEM_COLLECTIONS}_SUCCESS`:
      return {
        ...state,
        systemCollection: payload.result,
        fetchingSystemCollection: false,
      };
    case actions.SET_FILTER_INDEX:
      return {
        ...state,
        filterIndex: payload.filterIndex,
      };
    case `${actions.GET_SPECIFIC_COLLECTION}_SUCCESS`:
      return {
        ...state,
        currentCollection: payload.result,
      };
    case `${actions.READ_ALL_COLLECTIONS}_START`:
      return {
        ...state,
        fetchingCollectionsByWorkspace: true,
      };
    case `${actions.READ_ALL_COLLECTIONS}_ERROR`:
      return {
        ...state,
        ...payload,
      };
    case `${actions.READ_ALL_COLLECTIONS}_SUCCESS`:
      return {
        ...state,
        workspaceCollection: payload.result,
        fetchingCollectionsByWorkspace: false,
      };
    default:
      return state;
  }
};

export default collectionHealthReducer;
