import React from 'react';
import injectStyles from 'react-jss';
import cx from 'classnames';

class ModalContent extends React.Component {
  render() {
    const { children, classes, className } = this.props;
    return <div className={cx(classes.container, className)}>{children}</div>;
  }
}

const styles = theme => ({
  container: { height: 'auto', paddingTop: 20 },
});

export default injectStyles(styles)(ModalContent);
