import theme from '../../../theme/';
import actions from './actions';
import omit from 'lodash/omit';

const initialState = {
  error: null,
  fetchingVersion: false,
  organizationId: null,
  supportEmail: 'ask@zerofox.com',
  statusBanner: {
    message: '',
    type: 'info',
    isShowing: false,
  },
  tic: {
    maxScore: 100,
    defaultScore: 10,
    thresholds: {
      critical: {
        score: 0.75,
        color: theme.colors.critical,
      },
      elevated: {
        score: 0.5,
        color: theme.colors.elevated,
      },
      normal: {
        score: 0.01,
        color: theme.colors.normal,
      },
    },
  },
  version: null,
};

const appReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case actions.SET_ORGANIZATION:
    case actions.SET_STATUS_BANNER:
      return {
        ...state,
        ...payload,
      };

    case actions.REMOVE_STATUS_BANNER: {
      return omit(state, ['statusBanner']);
    }

    case actions.SERVER_DOWN: {
      return {
        ...state,
        serverDown: true,
      };
    }

    case actions.SERVER_UP: {
      return omit(state, ['serverDown']);
    }

    case actions.SET_WORKSPACE: {
      return {
        ...state,
        ...payload,
      };
    }

    case `${actions.FETCH_VERSION}_ERROR`: {
      return {
        ...state,
        error: payload,
        fetchingVersion: false,
      };
    }

    case `${actions.FETCH_VERSION}_START`: {
      return {
        ...state,
        fetchingVersion: true,
      };
    }

    case `${actions.FETCH_VERSION}_SUCCESS`: {
      return {
        ...state,
        version: payload,
        fetchingVersion: false,
      };
    }

    default:
      return state;
  }
};

export default appReducer;
