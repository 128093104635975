import React from 'react';
import injectStyles from 'react-jss';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import cx from 'classnames';
import defaultEditorBar from './toolbar/';
import styles from './styles';

const WYSIWYG = ({
  classes,
  editorClassName,
  editorToolbar = defaultEditorBar,
  isStickyToolbar = false,
  isEditing = false,
  maxCharacters,
  onEditorStateChange,
  readOnly,
  toolbarClassName,
  wrapperClassName,
  ...rest
}) => {
  let { editorState: intialState } = rest;
  let contentLength = (intialState && intialState.getCurrentContent().getPlainText().length) || 0;
  const onChange = editorState => {
    // Limit the character count in editor if the maxCharacters is set
    if (maxCharacters && editorState.getCurrentContent().getPlainText().length > maxCharacters) {
      editorState = EditorState.moveFocusToEnd(truncate(editorState, maxCharacters));
    }
    contentLength = editorState.getCurrentContent().getPlainText().length;
    onEditorStateChange(editorState);
  };
  return (
    <React.Fragment>
      <Editor
        editorClassName={cx(
          {
            [classes.editor]: true,
            [classes.readOnly]: readOnly,
          },
          editorClassName
        )}
        onEditorStateChange={onChange}
        stripPastedStyles={true}
        toolbar={editorToolbar}
        toolbarClassName={cx(
          {
            [classes.toolbar]: true,
            [classes.sticky]: isStickyToolbar,
          },
          toolbarClassName
        )}
        wrapperClassName={cx(classes.wrapper, wrapperClassName)}
        readOnly={readOnly}
        tabIndex={0}
        {...rest}
      />
      {!readOnly && maxCharacters && (
        <div className={cx(classes.footer)}>
          Characters remaining :&nbsp;<strong>{maxCharacters - contentLength}</strong>
        </div>
      )}
    </React.Fragment>
  );
};

WYSIWYG.defaultProps = {
  maxHeight: null,
};

//Iterates over the text blocks from the editor and counts them.
//Once a block goes over the total max, that block is truncated.
//Any blocks after that are removed
const truncate = (editorState, maxChar) => {
  const contentState = editorState.getCurrentContent();
  const blocks = contentState.getBlockMap();

  let count = 0;
  let isTruncated = false;
  const truncatedBlocks = [];
  blocks.forEach(block => {
    if (!isTruncated) {
      const len = block.getLength();
      if (count + len > maxChar) {
        isTruncated = true;
        const truncatedText = block.getText().slice(0, maxChar - count);
        const state = ContentState.createFromText(truncatedText);
        truncatedBlocks.push(state.getFirstBlock());
      } else {
        truncatedBlocks.push(block);
      }
      count += len + 1;
    }
  });

  if (isTruncated) {
    const state = ContentState.createFromBlockArray(truncatedBlocks);
    return EditorState.createWithContent(state);
  }

  return editorState;
};

export default injectStyles(styles)(WYSIWYG);
