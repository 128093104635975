import React from 'react';
import injectStyles from 'react-jss';
import cx from 'classnames';

const Base = ({
  classes,
  className,
  'data-test': dataTest,
  disabled,
  onClick,
  role,
  type,
  value,
  ...rest
}) => (
  <button
    className={cx({
      [className]: className,
      [classes[role]]: true,
      [classes.disabled]: disabled,
      [classes.core]: true,
    })}
    data-test={dataTest}
    type={type}
    onClick={onClick}
    disabled={disabled}
    {...rest}>
    {value}
  </button>
);

Base.defaultProps = {
  disabled: false,
  role: 'base',
  type: 'submit',
  value: '',
};

const styles = theme => ({
  core: {
    fontSize: '0.8rem',
    fontWeight: 400,
    paddingLeft: theme.spacing,
    paddingRight: theme.spacing,
    textTransform: 'uppercase',
    minWidth: 100,
    height: 44,
    background: 'linear-gradient(114.24deg,rgb(38, 172, 151) 17.28%, rgb(59, 63, 182) 84.62%)',
    color: 'rgb(119, 119, 119)',
    border: '1px solid #ddd',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  ...theme.buttonsLegacy,
});

export default injectStyles(styles)(Base);
