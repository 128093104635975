import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Pager = ({
  active,
  classes,
  className,
  itemRender,
  onClick,
  onKeyPress,
  page,
  style,
  hidePageNumber,
}) => {
  if (hidePageNumber) {
    return null;
  }

  return (
    <li
      title={page}
      className={cx({
        [classes.pager__item]: true,
        [classes.active]: active,
        [className]: !!className,
      })}
      onClick={onClick.bind(this, page)}
      onKeyPress={e => {
        onKeyPress(e, onClick, page);
      }}
      tabIndex="0"
      style={style}>
      <span className={classes.pager__value}>{page}</span>
    </li>
  );
};

Pager.propTypes = {
  active: PropTypes.bool,
  hidePageNumber: PropTypes.bool,
  classes: PropTypes.object,
  className: PropTypes.string,
  intl: PropTypes.object,
  itemRender: PropTypes.func,
  last: PropTypes.bool,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  page: PropTypes.number,
  rootPrefixCls: PropTypes.string,
  showTitle: PropTypes.bool,
};

export default Pager;
