/**
 * Intended Usage:
 *  1 -- Case where there is both Primary and a SecondaryModalButton, in that order
 *  <ModalButtons />
 *  2 -- Case where there are two buttons but they are not the usual PrimaryButton & SecondaryModalButton
 *  OR the order of positioning for the buttons is reversed
 *  <ModalButtons><PrimaryButton text="Cancel"/><SecondaryModalButton text="Delete"/></ModalButtons>
 *  3 -- Case where there is a non-PrimaryButton button and a SecondaryModalButton, in that order
 *  <ModalButtons><SomeOtherButton ...props /></ModalButtons>
 */

import React from 'react';

// NPM Modules
import { default as SpinKit } from 'react-spinkit';
import injectStyles from 'react-jss';
import cx from 'classnames';

// Components
import { ButtonGroup, PrimaryButton, SecondaryModalButton } from '../buttons';

class ModalButtons extends React.Component {
  render() {
    const gap = 20;
    let {
      actionClassName,
      actionDisabled,
      actionProcessing,
      actionText,
      buttonClassName,
      cancel,
      children,
      classes,
      onClick,
      updating,
    } = this.props;
    let textId = '';
    let text = '';
    if (!updating && !actionText) {
      text = 'Save';
      textId = 'button-save';
    }
    const [actionButton, cancelButton] = React.Children.toArray(children);
    return (
      <ButtonGroup className={cx(classes.actions, actionClassName)} gap={gap}>
        {cancelButton || (
          <div className={cx(classes.secondaryButton)}>
            <SecondaryModalButton
              data-test="cancel-button"
              onClick={cancel}
              text="Cancel"
              textId="button-cancel"
            />
          </div>
        )}
        {actionButton || (
          <PrimaryButton
            className={buttonClassName}
            data-test={actionText ? `${actionText}-button` : 'save-button'}
            disabled={actionDisabled}
            onClick={onClick}
            processing={actionProcessing}
            text={text}
            textId={textId}>
            {updating ? <SpinKit fadeIn="none" color="#fff" name={'circle'} /> : actionText}
          </PrimaryButton>
        )}
      </ButtonGroup>
    );
  }
}

const styles = theme => ({
  actions: {
    marginTop: 30,
  },
  secondaryButton: {
    marginLeft: 16,
  },
});

export default injectStyles(styles)(ModalButtons);
