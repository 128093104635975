import React from 'react';
import Button from '../../../buttons/Base';
import cx from 'classnames';

class SubmitButton extends React.Component {
  render() {
    const { id, className, disabled, submitting, value, cssClassName, onClick } = this.props;
    return (
      <Button
        id={id}
        className={cx(className, 'form-submit-button') + ' ' + cssClassName ? cssClassName : ''}
        disabled={disabled || submitting}
        onClick={onClick}
        role="primary"
        style={{
          background:
            'linear-gradient(114.24deg,rgb(38, 172, 151) 17.28%, rgb(59, 63, 182) 84.62%) !important',
        }}
        value={submitting ? 'Processing...' : value}
      />
    );
  }
}

export default SubmitButton;
