import actions from './action';

const initialState = {
  sortByFieldIndex: 0,
  sortByDirection: 'DESC',
};

const sortPreference = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case actions.SET_SORT_DIRECTION: {
      const sortByDirection = payload;
      return {
        ...state,
        sortByDirection,
      };
    }

    case actions.SET_SORT_FIELD: {
      const sortByFieldIndex = payload;
      return {
        ...state,
        sortByFieldIndex,
      };
    }

    default:
      return state;
  }
};

export default sortPreference;
