import multimethod from '../../../../../lib/multimethod';
import { actionError, actionStart, actionSuccess, apiData } from '../../../../../redux/common';
import actions from './actions';
import assign from 'lodash/fp/assign';

const ownership = multimethod('type', (action, state) => state);

ownership.method[actionStart(actions.FETCH_OWNERSHIP)] = apiData.fetchStarted;

ownership.method[actionSuccess(actions.FETCH_OWNERSHIP)] = (action, state) => {
  return assign(state, {
    data: action.payload,
    isLoading: false,
    isRequesting: false,
  });
};

ownership.method[actionError(actions.FETCH_OWNERSHIP)] = apiData.fetchErrored;

export default (state = apiData.initialState, action) => ownership(action, state);
