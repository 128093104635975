import { API } from '../../../../../environment/api';
import { apiRequest } from '../../../../../lib/http';
import { tokenThunk } from '../../../../../utils/api';
import { DEFAULT_SEARCH_LIMIT } from '../../actions';
const actions = {
  READ_SCHEDULES: 'report/READ_SCHEDULES',
};

export function fetchSchedules(
  workspaceId,
  page = 1,
  sortBy = ['created-at', 'desc'],
  size = DEFAULT_SEARCH_LIMIT
) {
  const start = (page - 1) * size;
  const params = {
    'workspace-id': workspaceId,
    limit: size,
    start,
    'sort-by': sortBy,
  };

  return tokenThunk((dispatch, token) => {
    dispatch({
      type: actions.READ_SCHEDULES,
      size,
      page,
      sortBy,
      payload: apiRequest(token, API.SCHEDULES_READ_WORKSPACE, params),
    });
  });
}

export default actions;
