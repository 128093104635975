import multimethod from '../../../../../lib/multimethod';
import { actionError, actionStart, actionSuccess } from '../../../../../redux/common';
import actions from './actions';
import { get, size } from 'lodash';
import assign from 'lodash/fp/assign';

const header = multimethod('type', (action, state) => state);

// Notes

header.method[actionStart(actions.FETCH_NUM_NOTES)] = (action, state) => {
  return assign(state, {
    error: null,
    isRequestingNumNotes: true,
  });
};

header.method[actionSuccess(actions.FETCH_NUM_NOTES)] = (action, state) => {
  return assign(state, {
    isReadOnly: false,
    isRequestingNumNotes: false,
    numNotes: size(action.payload.result),
  });
};

// this is an especially silly way to manage read only
function cantViewWorkspace(err) {
  return err.message === 'User not authorized for Action: view on instance of type workspace';
}

header.method[actionError(actions.FETCH_NUM_NOTES)] = (action, state) => {
  let error = action.payload;
  let isReadOnly = false;
  if (cantViewWorkspace(error)) {
    error = null;
    isReadOnly = true;
  }
  return assign(state, {
    error,
    isReadOnly,
    isRequestingNumNotes: false,
  });
};

// Rules

header.method[actionStart(actions.FETCH_NUM_RULES)] = (action, state) => {
  return assign(state, {
    error: null,
    isRequestingNumRules: true,
  });
};

header.method[actionSuccess(actions.FETCH_NUM_RULES)] = (action, state) => {
  return assign(state, {
    isRequestingNumRules: false,
    numRules: get(action, 'payload.summary.total-results'),
  });
};

header.method[actionError(actions.FETCH_NUM_RULES)] = (action, state) => {
  return assign(state, {
    error: action.payload,
    isRequestingNumRules: false,
  });
};

const initialState = {
  error: null,
  isReadOnly: false,
  isRequestingNumNotes: false,
  isRequestingNumRules: false,
  numNotes: null,
  numRules: null,
};

export default (state = initialState, action) => header(action, state);
