import multimethod from '../../../../../lib/multimethod';
import { actionError, actionStart, actionSuccess, apiData } from '../../../../../redux/common';
import actions from './actions';
import graphApi from '../../../../../lib/http/graph';
import assign from 'lodash/fp/assign';
import set from 'lodash/fp/set';

const classifications = multimethod('type', (action, state) => state);

classifications.method[actionStart(actions.FETCH_CLASSIFICATIONS)] = apiData.fetchStarted;

function resultsToClassifications(response) {
  const analyticsResults = graphApi.response.toAnalyticsResults(response);
  // TODO: update post-fix where _count is referring to more aggs (and not a count)
  return analyticsResults.classifications.results.map(aggResult => {
    return {
      name: aggResult['right.classifications'],
      numElements: aggResult._count.numElements,
      numRisks: aggResult._count.numRisks,
    };
  });
}

classifications.method[actionSuccess(actions.FETCH_CLASSIFICATIONS)] = (action, state) => {
  const data = resultsToClassifications(action.payload);
  return assign(state, {
    data,
    isLoading: false,
    isRefreshing: false,
    isRequesting: false,
    total: graphApi.response.toAnalyticsResults(action.payload).classifications.totalHits,
  });
};

classifications.method[actionError(actions.FETCH_CLASSIFICATIONS)] = (action, state) => {
  const s = apiData.fetchErrored(action, state);
  return set('isRefreshing', false, s);
};

classifications.method[actions.REFRESH_CLASSIFICATIONS] = (action, state) => {
  return set('isRefreshing', true, state);
};

export default (state = apiData.initialState, action) => classifications(action, state);
