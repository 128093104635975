export const serverUrl = url => {
  const base = process.env.REACT_APP_SERVER_URL;
  if (base) {
    // the url is relative unless a base is configured
    url = `${base}${url}`;
  }
  return url;
};

export const isDev = () => {
  return process.env.REACT_APP_DEVELOPMENT === 'true';
};
