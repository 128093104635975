import React from 'react';
import cx from 'classnames';
import styles from '../../styles';
import injectStyles from 'react-jss';

class Group extends React.Component {
  render() {
    const { classes, children, className } = this.props;
    return <div className={cx(classes.group, className, 'form-group')}>{children}</div>;
  }
}

export default injectStyles(styles)(Group);
