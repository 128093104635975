import React from 'react';
import { Field } from 'redux-form';
import { IconButton, Button } from '../../../..';
import styles from './styles';
import { injectIntl } from 'react-intl';
import injectStyles from 'react-jss';
import { FaTimes as RemoveIcon } from 'react-icons/fa/index';
import PropTypes from 'prop-types';
import SelectField from '../../SelectField';
import InputField from '../../InputField';
import cx from 'classnames';

/**
 * Returns a SelectField and InputField. The SelectField requires `options`.
 */
class SelectAndInputField extends React.Component {
  render() {
    const {
      buttonValue = 'ADD',
      buttonClassName,
      classes,
      inputClassName,
      selectClassName,
      fields,
      intl,
      options,
      required = true,
    } = this.props;
    return (
      <React.Fragment>
        {fields.map((field, index) => {
          return (
            <div className={classes.group} key={index}>
              <Field
                name={`${field}.select-type`}
                className={cx(classes.select, selectClassName)}
                component={SelectField}
                closeMenuOnSelect={true}
                options={options}
                multi={false}
                placeholder={intl.formatMessage({
                  id: 'form-select-type',
                  defaultMessage: 'Select Type',
                })}
                required={required}
              />
              <div className={classes.colon}>:</div>
              <Field
                component={InputField}
                className={cx(classes.input, inputClassName)}
                placeholder={intl.formatMessage({
                  id: 'form-input',
                  defaultMessage: `Enter value`,
                })}
                name={`${field}.input-value`}
                required={required}
              />
              {index !== 0 ? (
                <IconButton
                  className={classes.remove}
                  onClick={() => fields.remove(index)}
                  icon={<RemoveIcon size={25} />}
                />
              ) : null}
            </div>
          );
        })}
        <Button
          type="button"
          role="text"
          className={cx(classes.button__add, buttonClassName)}
          onClick={() => fields.push({})}
          value={buttonValue}
        />
      </React.Fragment>
    );
  }
}

SelectAndInputField.defaultProps = {
  options: PropTypes.array.isRequired,
};

export default injectIntl(injectStyles(styles)(SelectAndInputField));
